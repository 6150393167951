import {
  makeStyles,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  Box,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useMutation } from "@apollo/client";
import { UPLOAD_DEBITS } from "../../../graphql/mutations";
import { useSnackbar } from "notistack";
import { FileUploader } from "react-drag-drop-files";
import { useFormik } from "formik";
import { DialogContent } from "@mui/material";
import { FETCH_DEBTORS } from "../../../graphql/queries";

export default function CreateDialog({ open, handleClose }) {
  const style = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [uploadDebits, { loading }] = useMutation(UPLOAD_DEBITS, {
    refetchQueries: [{ query: FETCH_DEBTORS }]
  });

  const formikProps = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: (values) => {
      console.log("Arquivos enviados:", values.file);
      uploadDebits({
        variables: {
          file: values.file,
        },
      }).then(() => {
        resetForm();
        enqueueSnackbar("Arquivo enviado com sucesso!", {
          variant: "success",
        });
        handleClose();
      });
    },
  });

  const { handleSubmit, resetForm } = formikProps;

  const file = formikProps.values.file;

  const fileTypes = ["CSV"];

  const handleAddFiles = (e) => {
    const newFile = e;

    if (file && file.name === newFile.name) {
      enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
      return;
    }

    formikProps.setFieldValue("file", newFile);

    handleSubmit();
  };

  const handleSizeError = (file) => {
    enqueueSnackbar("O arquivo supera o limite máximo de 5MB", {
      variant: "error",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Upload de arquivo</DialogTitle>

      <DialogContent>
        <Box className={style.uploadContainer}>
          <FileUploader
            handleChange={(e) => handleAddFiles(e)}
            name="file"
            types={fileTypes}
            onSizeError={(e) => handleSizeError(e)}
            maxSize={5}
            disabled={loading}
          >
            <Box className={style.uploadLabel}>
              {loading ? (
                <CircularProgress className={style.circularProgress} />
              ) : (
                <>
                  <BackupIcon className={style.uploadIcon}></BackupIcon>
                  <Typography className={style.uploadText}>
                    Selecione um arquivo .csv de acordo com o layout
                    pré-estabelecido
                  </Typography>
                </>
              )}
            </Box>
          </FileUploader>
        </Box>

        <Box className={style.marginContainer}>
          <a href="/exemploDebtors.csv">Clique aqui</a> para baixar um .csv de
          exemplo
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  uploadLabel: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",
    padding: "12px 4px 16px 4px",
    border: "1px solid #19857b",
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },

  uploadContainer: {
    maxWidth: "100%",
    padding: "0 2%",
  },

  uploadIcon: {
    margin: "8px 0",
    color: "#1c998d",
  },

  uploadText: {
    color: "#1c998d",
    fontSize: "13px",
    width: "320px",
  },

  marginContainer: {
    margin: 24,
  },
}));

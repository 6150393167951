import { Box, Button, TablePagination, Typography } from "@material-ui/core";
import FilterListIcon from '@mui/icons-material/FilterList';
import { CircularProgress, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { maskMoney } from "../../../../helpers";
import FilterDialog from "./FilterDialog";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { FETCH_RELEASES } from "../../../../graphql/queries";
import LocalProvider from "../../../../components/LocalProvider";
import CreateDialog from "./CreateDialog";
import { useSnackbar } from "notistack";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DELETE_RELEASE } from "../../../../graphql/mutations";
import EditDialog from "./EditDialog";
import { format, parseISO } from "date-fns";
import ImportDialog from "./ImportDialog";
import { ExportCSV } from "./ExportCSV";

export default function Releases({
    openCreate,
    openImport,
    handleClose,
    handleCloseImport,
    handleGlobalRefresh
}) {

    const { enqueueSnackbar } = useSnackbar();

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [openEdit, setOpenEdit] = useState(null);

    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState({
        keyword: "",
        startDate: null,
        finishDate: null,
        type: "",
        categoryId: null,
        company: null,
        subcategoryIds: []
    })


    const { data, loading, refetch, networkStatus } = useQuery(FETCH_RELEASES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: rowsPerPage,
            page: currentPage + 1,
            keyword: filters.keyword,
            type: filters.type,
            start: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : null,
            finish: filters.finishDate ? format(filters.finishDate, 'yyyy-MM-dd') : null,
            category_id: filters.categoryId,
            company_id: filters.company?.id || null,
            subcategory_ids: filters.subcategoryIds
        },
        fetchPolicy: "network-only"
    })

    const [deleteRelease, { loading: loadingDelete }] = useMutation(DELETE_RELEASE);

    const handleOpenDialog = () => {
        setOpenFilter(true)
    }

    const handleDelete = async (id) => {
        try {
            await deleteRelease({
                variables: {
                    id
                }
            })
            enqueueSnackbar("Lançamento deletado com sucesso!", {
                variant: "success"
            });
            refetch()
            handleGlobalRefresh()
        } catch (e) {
            console.error("[Error] => ", e)
            enqueueSnackbar("Erro ao deletar lançamento", {
                variant: "error"
            });
        }
    }

    const handleOpenEdit = (elementData) => {
        setOpenEdit(elementData)
    }

    const handleCloseEdit = () => {
        setOpenEdit(null)
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="h6" component="h2">
                    Lançamentos
                </Typography>

                <Box>
                    <ExportCSV
                        filter={{
                            keyword: filters.keyword,
                            startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : null,
                            finishDate: filters.finishDate ? format(filters.finishDate, 'yyyy-MM-dd') : null,
                            type: filters.type,
                            categoryId: filters.categoryId,
                            company: filters.company?.id || null,
                            subcategoryIds: filters.subcategoryIds
                        }}
                    />

                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<FilterListIcon />}
                        onClick={handleOpenDialog}
                    >
                        Filtros
                    </Button>
                </Box>
            </Box>

            <TableContainer style={{ marginTop: 16, border: "1px solid #e3e3e3", borderRadius: 5 }}>
                {(networkStatus === NetworkStatus.refetch || loading) && <LinearProgress />}
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Origem</TableCell>
                            <TableCell align="left">Empresa</TableCell>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Categoria</TableCell>
                            <TableCell align="left">Tipo</TableCell>
                            <TableCell align="left">Valor&nbsp;(R$)</TableCell>
                            <TableCell align="left">Descrição</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.fetchReleases?.data.length > 0 ?
                                data?.fetchReleases.data.map((row) => {

                                    const parsedDate = parseISO(row.release_at);
                                    const formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm:ss');

                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell align="left" style={{ color: row.category?.type === "Receita" ? "green" : "red" }}>
                                                {row.category?.type || "-"}
                                            </TableCell>
                                            <TableCell align="left">{row.company?.name || "-"}</TableCell>
                                            <TableCell align="left">{formattedDate}</TableCell>
                                            <TableCell align="left">{row.category?.title || "-"}</TableCell>
                                            <TableCell align="left">{row.subcategory?.title || "-"}</TableCell>
                                            <TableCell align="left">{maskMoney(row.value)}</TableCell>
                                            <TableCell align="left">{row.description || "-"}</TableCell>
                                            <TableCell style={{ textAlign: 'right' }}>
                                                <IconButton style={{ padding: 0, marginRight: 24 }} aria-label="delete" onClick={() => handleOpenEdit(row)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    style={{ padding: 0 }}
                                                    aria-label="delete"
                                                    onClick={() => handleDelete(row.id)}
                                                >
                                                    {loadingDelete ? <CircularProgress size={21} /> : <DeleteIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={2}>Nenhum lançamento cadastrado</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <LocalProvider locale="ptBR">
                <TablePagination
                    showFirstButton
                    showLastButton
                    rowsPerPageOptions={[8, 32, 100]}
                    component="div"
                    count={data?.fetchReleases?.total}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(_, newPage) => {
                        setCurrentPage(newPage);
                    }}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(0);
                    }}
                />
            </LocalProvider>


            {
                openFilter &&
                <FilterDialog
                    open={openFilter}
                    handleClose={() => setOpenFilter(false)}
                    applyFilters={(obj) => setFilters(obj)}
                    filledFilters={filters}
                />
            }

            {
                openCreate &&
                <CreateDialog
                    open={openCreate}
                    handleClose={() => handleClose(false)}
                    onCreate={() => {
                        enqueueSnackbar("Lançamento criado com sucesso!", {
                            variant: "success"
                        });
                        handleClose(false);
                        handleGlobalRefresh()
                        refetch()
                    }}
                />
            }

            {
                openImport &&
                <ImportDialog
                    open={openImport}
                    handleClose={handleCloseImport}
                />
            }

            {
                openEdit &&
                <EditDialog
                    open={openEdit ? true : false}
                    handleClose={handleCloseEdit}
                    data={openEdit}
                    onEdit={() => {
                        enqueueSnackbar("Lançamento editado com sucesso!", {
                            variant: "success"
                        });
                        handleCloseEdit();
                        handleGlobalRefresh()
                        refetch()
                    }}
                />
            }
        </>
    )
}
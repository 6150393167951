import * as React from "react";
import { LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.current
        )} de ${props.total}`}</Typography>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchInputDebounced from "../components/SearchInputDebounced";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FETCH_REASONS } from "../graphql/queries";
import { useQuery } from "@apollo/client";

export default function SelectFilterModalReason({
  onChange,
  loading,
  title,
  required,
  error,
  fullWidth,
  style,
  margin,
  errorLabel,
  value,
  reasonState,
}) {
  const [newValue, setNewValue] = useState(reasonState || []);
  const [showDialog, setShowDialog] = useState(false);

  const { data } = useQuery(FETCH_REASONS, {
    fetchPolicy: "network-only",
    variables: {
      all: true,
    },
  });

  // // Search
  const [searchTerm, setSearchTerm] = useState(null);

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    let index = newValue.findIndex((i) => i === value);

    if (index >= 0 && e.target.checked === false) deleteItem(index);
    else {
      let clone = [...newValue];
      clone.push(value);
      setNewValue(clone);
    }
  };

  const deleteItem = (index) => {
    let clone = [...newValue];
    clone.splice(index, 1);
    setNewValue(clone);
  };

  const handleSelect = () => {
    onChange(newValue);
    setShowDialog(false);
  };

  const handleClearFilter = () => {
    onChange(value);
    setNewValue([]);
    setSearchTerm(null);
    setShowDialog(false);
  };

  return (
    <>
      <TextField
        style={style}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon
                style={{ color: "#787878", marginRight: -6 }}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: newValue.length > 0 ? true : false,
        }}
        onClick={() => {
          setShowDialog(true);
        }}
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        value={newValue}
        label={title}
        margin={margin}
        error={error}
        helperText={errorLabel}
      />

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth="md"
      >
        <DialogTitle>{"Motivos"}</DialogTitle>
        <DialogContent>
          <SearchInputDebounced
            typeSearch="inDialog"
            onChange={(value) => {
              setSearchTerm(value);
            }}
          />

          <div style={{ marginTop: 20, width: 300 }}>
            {newValue.map((element, index) => {
              return (
                <Chip label={element} onDelete={() => deleteItem(index)} />
              );
            })}
          </div>

          <div
            style={{
              marginTop: 20,
              height: 300,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormGroup>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {data &&
                  data?.fetchReasons.data.map((reason) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newValue.includes(reason.name)}
                          value={reason.name}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label={reason.name}
                    />
                  ))}
              </div>
            </FormGroup>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClearFilter} color="primary">
            Limpar
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import * as Yup from "yup";
import "../../assets/styleSheet/ScrollBar.css";
import { useFormik } from "formik";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { maskCPF } from "../../helpers";
import { useMutation } from "@apollo/client";
import SelectWithQuerySearch from "../SelectWithQuerySearch";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { FETCH_SPECIES } from "../../graphql/queries";
import { CREATE_LEAD } from "../../graphql/mutations";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

export default function DialogOpenLead({ open, handleClose }) {
  const [createLead, createLeadMutation] = useMutation(CREATE_LEAD);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  const { setFieldValue, handleChange, handleSubmit, errors, values, isValid } =
    useFormik({
      validationSchema: newProposalSchemaCustomer,
      initialValues: {
        document: "",
        name: "",
        email: "",
        birthdate: {
          iso8601: new Date(),
        },
        phone1: "",
        phone2: "",
        benefit_number: "",
        specie: null,
        income: 0,
      },
      onSubmit: (values) => {
        createLead({
          variables: {
            document: values.document,
            name: values.name,
            email: values.email,
            birthdate:
              values.birthdate?.iso8601 &&
              format(new Date(values.birthdate.iso8601), "yyyy-MM-dd"),
            phone1: values.phone1,
            phone2: values.phone2,
            benefit_number: values.benefit_number,
            specie_id: values.specie ? values.specie.id : null,
            income: parseFloat(values.income),
          },
        }).then(({ data }) => {
          enqueueSnackbar("Lead: " + values.name + " criado com sucesso!");
          history.push("/lead/" + data.createLead, {
            id: new URLSearchParams(data.createLead).toString(),
          });
        });
      },
    });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{ marginBottom: "-16px" }}>
        Informe os dados do cliente
      </DialogTitle>

      <DialogContent className="scrollbarCustom">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: -20 }}>
            <TextField
              variant="outlined"
              margin="normal"
              id="reason"
              label="Informe o CPF"
              value={values.document}
              onChange={(e) => {
                setFieldValue("document", maskCPF(e.target.value));
              }}
              autoFocus
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ marginBottom: -20 }}>
            <TextField
              variant="outlined"
              margin="normal"
              label="Nome"
              name="name"
              onChange={handleChange}
              value={values.name}
              error={errors.name && true}
              helperText={errors.name}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Nascimento"
                format="dd/MM/yyyy"
                value={values.birthdate?.iso8601}
                InputAdornmentProps={{ position: "end" }}
                onChange={(event) => {
                  if (event != "Invalid Date") {
                    setFieldValue("birthdate.iso8601", event);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.phone1}
              fullWidth
              label="Telefone 01"
              name="phone1"
              required
              error={errors.phone1 && true}
              helperText={errors.phone1}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.phone2}
              fullWidth
              label="Telefone 02"
              name="phone2"
              error={errors.phone2 && true}
              helperText={errors.phone2}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.benefit_number}
              fullWidth
              label="Nº Benefício"
              required
              name="benefit_number"
              error={errors.benefit_number && true}
              helperText={errors.benefit_number}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWithQuerySearch
              title="Espécie de Benefício *"
              query={FETCH_SPECIES}
              fetchResult="fetchSpecies"
              onChange={(specie) => {
                setFieldValue("specie", specie);
              }}
              fullWidth
              error={errors?.specie && true}
              errorLabel={errors.specie && "Campo Obrigatório"}
              value={values.specie}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              value={values.income}
              onChange={(e, income) => setFieldValue("income", income)}
              fullWidth
              currencySymbol="R$"
              label="Renda"
              decimalCharacter=","
              digitGroupSeparator="."
              error={errors.income && true}
              helperText={errors.income}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={!isValid}
        >
          {createLeadMutation.loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Criar Lead
        </Button>

        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const newProposalSchemaCustomer = Yup.object().shape({
  document: Yup.string().required("Campo obrigatório"),
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Email inválido"),
});

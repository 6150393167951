import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { CREATE_SALE_CONSULTANT } from "../../../../graphql/mutations";
import { FETCH_SALE_CHANNELS, FETCH_SALE_CONSULTANT_GROUPS, FETCH_TEAMS } from "../../../../graphql/queries";
import { useEffect, useState } from "react";
import SelectProfile from "../../../../components/SelectProfile";
import RestrictionsPicker from "../../../../components/RestrictionsPicker";

export default function CreateDialog({ open, handleClose, onCreate }) {

    const [profile, setProfile] = useState(null)

    // Setting mutation
    const [createSaleConsultant, { loading }] = useMutation(CREATE_SALE_CONSULTANT);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({

        initialValues: {
            name: "",
            email: "",
            password: "",
            restrictionsForm: {
                restrictions: [],
                groupByRestrictionsTitle: []
            }
        },

        onSubmit: (values) => {

            const restrictionsIds = Object.keys(values.restrictionsForm.restrictions)
                .filter((key) => values.restrictionsForm.restrictions[key] === true)
                .map((id) => parseInt(id, 10));

            // Chamando a mutation de criação
            createSaleConsultant({
                variables: {
                    sale_consultant_group_id: values.group && values.group.id,
                    team_id: values.team && values.team.id,
                    sale_channel_id: values.saleChannel && values.saleChannel.id,
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    restriction_ids: restrictionsIds
                }
            }).then(() => {
                resetForm();
                onCreate();
            })

        }
    });

    const handleChangeValue = (prop, value) => {
        setFieldValue(`restrictionsForm.${prop}`, value)
    }

    useEffect(() => {
        if (profile) {
            setFieldValue('restrictionsForm.restrictions', [])
            if (profile.restrictions) {
                for (const item of profile.restrictions) {
                    setFieldValue(`restrictionsForm.restrictions.${item.id}`, true);
                }
            }
        }
    }, [profile])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Criar novo consultor</DialogTitle>

                <DialogContent>

                    <SelectWithQuerySearch
                        title="Grupo"
                        query={FETCH_SALE_CONSULTANT_GROUPS}
                        fetchResult="fetchSaleConsultantGroups"
                        onChange={(group) => { setFieldValue('group', group) }}
                        fullWidth
                        value={values.group}
                    />

                    <SelectWithQuerySearch
                        title="Equipe"
                        query={FETCH_TEAMS}
                        fetchResult="fetchTeams"
                        onChange={(team) => { setFieldValue('team', team) }}
                        fullWidth
                        value={values.team}
                        style={{ marginTop: 24 }}
                    />

                    <SelectWithQuerySearch
                        title="Canal de venda"
                        query={FETCH_SALE_CHANNELS}
                        fetchResult="fetchSaleChannels"
                        onChange={(saleChannel) => { setFieldValue('saleChannel', saleChannel) }}
                        fullWidth
                        value={values.saleChannel}
                        style={{ marginTop: 24 }}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={values.email}
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoFocus
                        error={errors.email && true}
                        helperText={errors.email}
                    />

                    <TextField
                        type="password"
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={values.password}
                        required
                        fullWidth
                        id="password"
                        label="Senha"
                        name="password"
                        autoFocus
                        error={errors.password && true}
                        helperText={errors.password}
                    />

                    <SelectProfile
                        value={profile}
                        onChange={(item) => setProfile(item)}
                    />

                    <RestrictionsPicker {...{ handleChangeValue }} formValues={values.restrictionsForm} />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar Consultor
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { useHistory } from "react-router";
import { ModalVisualization } from "../../../../../components/DynamicForm/Modal/VisualizationForm";
import { SaveFabButton } from "../../../../../components/SaveFabButton";
import { VisualizationFabButton } from "../../../../../components/VisualizationFabButton";
import { DragDropContext } from "react-beautiful-dnd";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Box, Container, Grid, Stack, Backdrop } from "@mui/material";
import Header from "../../../../../components/Header";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { Item } from "../../../../../components/Paper";
import { Title } from "../../../../../components/Title";
import ComponentsDynamicForm from "../../../../../components/DynamicForm/ComponentsDynamicForm";
import GroupsDynamicForm from "../../../../../components/DynamicForm/GroupsDynamicForm";
import { useCallback, useState, useEffect } from "react";
import componentsDinamycForm from "../../../../../utils/fieldsDynamicForm";
import Input from "../../../../../components/Input";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DETAIL_DYNAMIC_FORM, FETCH_DETAIL_DYNAMIC_FORM } from "../../../../../graphql/queries";
import { UPDATE_DYNAMIC_FORM } from "../../../../../graphql/mutations";
import { CircularProgress } from "@material-ui/core";

export default function EditDynamicForm() {
    const history = useHistory();
    const { id: idDynamicForm } = useParams();

    const { data: dataDynamicForm, loading: loadingDetail } = useQuery(
        DETAIL_DYNAMIC_FORM,
        {
            fetchPolicy: "network-only",
            variables: { id: Number(idDynamicForm) },
        }
    );

    const [updateDynamicForm, { loading: loadingUpdateDynamicForm }] =
        useMutation(UPDATE_DYNAMIC_FORM);

    const methods = useForm({
        defaultValues: {
            groups: [],
            formName: "",
        },
    });
    const { handleSubmit, control, setValue, getValues, watch } = methods;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "groups",
    });
    const [isOpenModalVisualization, setIsOpenModalVisualization] =
        useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (dataDynamicForm) {
            setValue("formName", dataDynamicForm.detailDynamicForm.name);
            const inputs = JSON.parse(dataDynamicForm.detailDynamicForm.input)
            if (inputs) {
                const formatData = [{
                    id: uuidv4(),
                    name: "",
                    components: inputs.map((item) => ({
                        id: uuidv4(),
                        name: item.label,
                        type: item.type,
                        isEditable:
                            componentsDinamycForm.find((component) => {
                                return component.type === item.type;
                            })?.isEditable || false,
                        isRequired: item.required,
                        isOption: item.options ? true : false,
                        component: componentsDinamycForm.find((component) => {
                            return component.type === item.type;
                        })?.component,
                        options:
                            item.options && item.options.length > 0
                                ? item.options.map((option) => ({
                                    id: option.id,
                                    label: option.name,
                                    value: option.name,
                                }))
                                : null,
                    }))
                }]
                setValue("groups", formatData);
            }
        }
    }, [dataDynamicForm]);

    async function onSubmit(data) {
        const dataFormated = JSON.stringify(data.groups[0].components.map((component, index) => {
            const uuid = uuidv4();
            return {
                name: component.name ? component.name.toLowerCase() + index : uuid,
                type: component.type,
                label: component.name,
                placeholder: component.name,
                required: component.isRequired,
                options:
                    (component.options &&
                        component.options.map((option) => ({
                            id: option.label,
                            name: option.label,
                        }))) ||
                    null,
            };
        }))

        try {
            await updateDynamicForm({
                variables: {
                    id: Number(idDynamicForm),
                    name: data.formName,
                    input: dataFormated,
                },
            });
            enqueueSnackbar("Formulário registrado com sucesso", {
                variant: "success",
            });
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: "error",
            });
        }
    }

    const handleRemoveGroup = useCallback((index) => {
        remove(index);
    }, []);

    const onDragEnd = (result) => {
        const { destination, source } = result;

        const findIndexGroup = fields.findIndex((field) => {
            return field.id === destination.droppableId;
        });

        const findComponent = componentsDinamycForm.find((component) => {
            return component.label === source.droppableId;
        });

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const values = getValues("groups");

        const concatComponents = fields.map((field) => {
            if (field.id === destination.droppableId) {
                return {
                    ...field,
                    name: values[findIndexGroup].name,
                    components: [
                        ...field.components,
                        {
                            id: uuidv4(),
                            name: findComponent?.label,
                            type: findComponent?.type,
                            component: findComponent?.component,
                            isEditable: findComponent?.isEditable,
                            isRequired: findComponent?.isRequired,
                            isOption: findComponent?.isOption,
                            options: findComponent?.options,
                        },
                    ],
                };
            }
            return field;
        });

        setValue("groups", concatComponents);
    };

    const handleRemoveComponent = useCallback(
        (id) => {
            const values = getValues("groups");

            const newComponents = values.map((value) => {
                return {
                    ...value,
                    components: value.components.filter((component) => {
                        return component.id !== id;
                    }),
                };
            });
            setValue("groups", newComponents);
        },
        [fields]
    );

    const handleEditComponent = useCallback(
        (indexGroup, indexComponent, componentEdited) => {
            const findIndexField = fields.findIndex(
                (field) => field.id === fields[indexGroup].id
            );
            const findIndexComponent = fields[indexGroup].components.findIndex(
                (component) =>
                    component.id === fields[indexGroup].components[indexComponent].id
            );
            const values = getValues("groups");

            update(findIndexField, {
                ...fields[findIndexField],
                name: values[findIndexField].name,
                components: fields[indexGroup].components.map((component, index) => {
                    if (index === findIndexComponent) {
                        return componentEdited;
                    }
                    return component;
                }),
            });
        },
        [fields]
    );

    function onRequestOpenModalVisualization() {
        setIsOpenModalVisualization(true);
    }

    function onRequestCloseModalVisualization() {
        setIsOpenModalVisualization(false);
    }

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingDetail}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FormProvider {...methods}>
                <Header
                    title="Formulário"
                    onBack={() => {
                        history.goBack();
                    }}
                />

                <Container style={{ marginTop: 86 }}>
                    <Box sx={{ flexGrow: 1 }} marginY="1rem">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} rowGap={2}>
                                    <Item>
                                        <Stack spacing={4}>
                                            <Box>
                                                <Title
                                                    title="Dados do formulário"
                                                    marginBottom={4}
                                                    fontSize="1.4rem"
                                                />
                                                <Input
                                                    disabled={dataDynamicForm?.detailDynamicForm.is_after_sale}
                                                    name="formName"
                                                    placeholder="Nome do formulário"
                                                    autoFocus
                                                    autoComplete="off"
                                                />
                                            </Box>
                                            <ComponentsDynamicForm />
                                        </Stack>
                                    </Item>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Stack spacing={2}>
                                        {fields.map((field, index) => (
                                            <Item key={field.id}>
                                                <GroupsDynamicForm
                                                    index={index}
                                                    handleRemoveGroup={(index) =>
                                                        handleRemoveGroup(index)
                                                    }
                                                    group={field}
                                                    handleRemoveComponent={(id) =>
                                                        handleRemoveComponent(id)
                                                    }
                                                    handleEditComponent={(
                                                        indexGroup,
                                                        indexComponent,
                                                        component
                                                    ) =>
                                                        handleEditComponent(
                                                            indexGroup,
                                                            indexComponent,
                                                            component
                                                        )
                                                    }
                                                />
                                            </Item>
                                        ))}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DragDropContext>
                    </Box>
                    <VisualizationFabButton
                        onClick={() => onRequestOpenModalVisualization()}
                    />
                    <SaveFabButton
                        onClick={handleSubmit(onSubmit)}
                        isLoading={loadingUpdateDynamicForm}
                    />
                </Container>

                {
                    isOpenModalVisualization &&
                    <ModalVisualization
                        open={isOpenModalVisualization}
                        handleClose={() => onRequestCloseModalVisualization()}
                        buttonText="Fechar"
                    />
                }

            </FormProvider>
        </>
    );
}
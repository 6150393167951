import * as React from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation, EventTracker } from "@devexpress/dx-react-chart";
import {
  withStyles,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  DialogActions,
  Button,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { FETCH_INTERACTIONS_PERFORMANCE } from "../../../graphql/queries";
import { useState } from "react";
import TableViewIcon from "@mui/icons-material/TableView";
import { useEffect } from "react";
import { format } from "date-fns";

export default function InteractionsPerformance({
  teamId,
  startDate,
  finishDate,
  saleConsultantId,
  product_ids,
  company_ids,
}) {
  const [showDetail, setShowDetail] = useState(false);

  const variables = {
    start: startDate ? format(startDate, "yyyy-MM-dd") : null,
    finish: finishDate ? format(finishDate, "yyyy-MM-dd") : null,
    sale_consultant_id: saleConsultantId ? saleConsultantId : null,
    product_ids: product_ids ? product_ids : null,
    team_id: teamId ? teamId : null,
    company_ids: company_ids ? company_ids : null,
  };

  const { loading, error, data, refetch } = useQuery(
    FETCH_INTERACTIONS_PERFORMANCE,
    {
      variables,
    }
  );

  useEffect(() => {
    refetch();
  }, [
    teamId,
    startDate,
    finishDate,
    saleConsultantId,
    product_ids,
    company_ids,
    refetch,
  ]);

  if (error) return <div>{error}</div>;

  let point = {
    marginTop: "7px",
    marginRight: "6px",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    background: "#42a5f5",
  };

  if (loading) return <CircularProgress style={{ marginTop: 12 }} />;

  return (
    <Box>
      {" "}
      {data && (
        <>
          {/* {company && (
            <Chip
              label={"Empresa: " + company.name}
              onDelete={() => setCompany(null)}
            />
          )}
          {team && (
            <Chip
              label={"Equipe: " + team.name}
              onDelete={() => setTeam(null)}
            />
          )}
          {saleConsultant && (
            <Chip
              label={"Consultor: " + saleConsultant.name}
              onDelete={() => setSaleConsultant(null)}
            />
          )} */}
          <IconButton
            onClick={() => setShowDetail(true)}
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <TableViewIcon fontSize="large" />
          </IconButton>

          <Chart data={data.fetchInteractionsPerformance} height={330}>
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries
              name="Interações"
              valueField="interactions"
              argumentField="day"
              color="#ffd700"
            />
            <BarSeries
              name="Reagendadas"
              valueField="scheduled"
              argumentField="day"
              color="#c0c0c0"
            />
            <BarSeries
              name="Sem interesse"
              valueField="no_interest"
              argumentField="day"
              color="#000"
            />
            <BarSeries
              name="Em negociação"
              valueField="in_negotiation"
              argumentField="day"
              color="#00C"
            />
            <BarSeries
              name="Contato inválido"
              valueField="invalid_contact"
              argumentField="day"
              color="#D0021B"
            />
            <BarSeries
              name="Sem perfil"
              valueField="no_profile"
              argumentField="day"
              color="#F08080"
            />
            <BarSeries
              name="Ligação encerrada"
              valueField="call_ended"
              argumentField="day"
              color="#00ff7f"
            />
            <Animation />
            <Stack />
            <EventTracker />
            <Tooltip />
          </Chart>

          <Grid container style={{ marginTop: "25px", paddingLeft: "20px" }}>
            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#ffd700" }}></div>
              <Typography>Interações</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#c0c0c0" }}></div>
              <Typography>Reagendadas</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#000" }}></div>
              <Typography>Sem interesse</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#00C" }}></div>
              <Typography>Em negociação</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#D0021B" }}></div>
              <Typography>Contato inválido</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#F08080" }}></div>
              <Typography>Sem perfil</Typography>
            </Grid>

            <Grid item style={{ display: "flex", marginRight: 24 }}>
              <div style={{ ...point, background: "#00ff7f" }}></div>
              <Typography>Ligação encerrada</Typography>
            </Grid>

            <Dialog open={showDetail} scroll="paper" maxWidth="xl" fullWidth>
              <DialogTitle>Performance das interações</DialogTitle>
              <DialogContent>
                <Table size="small" style={{ marginTop: 12 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell
                        style={{ backgroundColor: "#ffd7004d" }}
                        align="center"
                      >
                        Interações
                      </TableCell>
                      <TableCell align="center">Efetividade</TableCell>
                      <TableCell align="center">%</TableCell>

                      <TableCell
                        style={{ backgroundColor: "#c0c0c04d" }}
                        align="center"
                      >
                        Reagendadas
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#c0c0c04d" }}
                        align="center"
                      >
                        %
                      </TableCell>

                      <TableCell
                        style={{
                          backgroundColor: "#000000b3",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        Sem interesse
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#000000b3",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        %
                      </TableCell>

                      <TableCell
                        style={{ backgroundColor: "#0000CC4d" }}
                        align="center"
                      >
                        Em negociação
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#0000CC4d" }}
                        align="center"
                      >
                        %
                      </TableCell>

                      <TableCell
                        style={{ backgroundColor: "#D0021B4d" }}
                        align="center"
                      >
                        Contato inválido
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#D0021B4d" }}
                        align="center"
                      >
                        %
                      </TableCell>

                      <TableCell
                        style={{ backgroundColor: "#F00", color: "#FFF" }}
                        align="center"
                      >
                        Sem perfil
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#F00" }}
                        align="center"
                      >
                        %
                      </TableCell>

                      <TableCell
                        style={{ backgroundColor: "#00ff7f" }}
                        align="center"
                      >
                        Ligação encerrada
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#00ff7f" }}
                        align="center"
                      >
                        %
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.fetchInteractionsPerformance.map((row) => {
                      let effective =
                        row.scheduled + row.no_interest + row.in_negotiation;
                      return (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.day}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#ffd7004d" }}
                            align="center"
                          >
                            {row.interactions}
                          </TableCell>

                          <TableCell align="center">{effective}</TableCell>
                          <TableCell align="center">
                            <small>
                              {(
                                (effective * 100) / row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{ backgroundColor: "#c0c0c04d" }}
                            align="center"
                          >
                            {row.scheduled}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#c0c0c04d" }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.scheduled * 100) / row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{
                              backgroundColor: "#000000b3",
                              color: "#FFF",
                            }}
                            align="center"
                          >
                            {row.no_interest}
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#000000b3",
                              color: "#FFF",
                            }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.no_interest * 100) / row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{ backgroundColor: "#0000CC4d" }}
                            align="center"
                          >
                            {row.in_negotiation}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#0000CC4d" }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.in_negotiation * 100) / row.interactions ||
                                0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{ backgroundColor: "#D0021B4d" }}
                            align="center"
                          >
                            {row.invalid_contact}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#D0021B4d" }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.invalid_contact * 100) /
                                  row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{ backgroundColor: "#F00", color: "#FFF" }}
                            align="center"
                          >
                            {row.no_profile}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#F00", color: "#FFF" }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.no_profile * 100) / row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>

                          <TableCell
                            style={{ backgroundColor: "#00ff7f" }}
                            align="center"
                          >
                            {row.call_ended}
                          </TableCell>
                          <TableCell
                            style={{ backgroundColor: "#00ff7f" }}
                            align="center"
                          >
                            <small>
                              {(
                                (row.call_ended * 100) / row.interactions || 0
                              ).toFixed(2)}
                              %
                            </small>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowDetail(false)}
                  variant="contained"
                  color="primary"
                >
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      )}
    </Box>
  );
}
const legendStyles = () => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);

const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

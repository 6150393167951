import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FETCH_LOCATIONS } from "../../../../graphql/queries";
import {
  Container,
  Card,
  Typography,
  CardContent,
  Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import List from "./List";
import CreateDialog from "./CreateDialog";
import AddIcon from "@material-ui/icons/Add";
import EditDialog from "./EditDialog";

export default function LocationPage({ history }) {
  const statesLocal = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_LOCATIONS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  return (
    <>
      <Header
        onBack={() => {
          history.goBack();
        }}
      />

      <Container maxWidth="md" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h1">
              Localização
            </Typography>
          </CardContent>

          <List
            onDelete={() => {
              enqueueSnackbar("Localização excluída com sucesso!");
              refetch();
            }}
            onEdit={(item) => setDataEdit(item)}
            {...{
              loading,
              error,
              data,
              networkStatus,
              currentPage,
              setCurrentPage,
              rowsPerPage,
              setRowsPerPage,
            }}
          />
        </Card>
      </Container>

      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        statesLocal={statesLocal}
        onCreate={() => {
          enqueueSnackbar("Localização cadastrada com sucesso!");
          refetch();
          setShowCreate(false);
        }}
      />

      <EditDialog
        open={dataEdit ? true : false}
        handleClose={() => setDataEdit(null)}
        data={dataEdit}
        statesLocal={statesLocal}
        onEdit={() => {
          enqueueSnackbar("Localização editada com sucesso!");
          refetch();
          setDataEdit(null);
        }}
      />

      <Fab
        color="primary"
        onClick={() => setShowCreate(true)}
        style={{ position: "absolute", bottom: 48, right: 48 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

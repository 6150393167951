import { IconButton } from "@material-ui/core";
import React, { forwardRef } from "react";

const iconButton = ({ onClick, children, type = "button", color, disabled, ...rest }, ref) => {
	return (
		<IconButton
			onClick={onClick}
			ref={ref}
			type={type}
			color={color}
			disabled={disabled}
			{...rest}
		>
			{children}
		</IconButton>
	);
};

export default forwardRef(iconButton);
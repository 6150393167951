import { useState } from 'react';
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import InputWithQuerySearch from '../../../../components/InputWithQuerySearch';
import { FETCH_SALE_CONSULTANTS, FETCH_SALE_CONSULTANT_GROUPS } from '../../../../graphql/queries';

export default function Step01(props) {

    const { values, setFieldValue, handleChange, onNext, errors } = props;

    return (
        <>
            <TextField
                variant="outlined"
                margin="normal"
                onChange={handleChange}
                value={values.name}
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoFocus
                error={errors.name && true}
                helperText={errors.name}
            />
            <div style={{ marginBottom: 12 }}>
                <Typography variant="subtitle2" style={{ marginTop: 12 }}>Restrições de acesso</Typography>
                <Typography variant="caption">Selecione abaixo grupos ou consultores que terão acesso aos leads dessa campanha</Typography>
            </div>

            <Grid container>
                <Grid item sm={6} xs={12}>
                    <InputWithQuerySearch
                        title="Consultores"
                        labelBlank="Todos"
                        query={FETCH_SALE_CONSULTANTS}
                        fetchResult="fetchSaleConsultants"
                        value={values.restrictions.sale_consultants}
                        onChange={(value) => setFieldValue('restrictions.sale_consultants', value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <InputWithQuerySearch
                        title="Grupos de consultor"
                        labelBlank="Todos"
                        query={FETCH_SALE_CONSULTANT_GROUPS}
                        fetchResult="fetchSaleConsultantGroups"
                        value={values.restrictions.sale_consultant_groups}
                        onChange={(value) => setFieldValue('restrictions.sale_consultant_groups', value)}
                    />
                </Grid>
            </Grid>

            <Button variant="contained" disabled={!values.name} color="primary" onClick={onNext} style={{ marginTop: 12 }}>
                Avançar
            </Button>
        </>
    )
}

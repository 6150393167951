import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_LEADS_FILES } from "../../../graphql/queries";
import { NetworkStatus } from "@apollo/client";
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import { makeStyles, Link } from "@material-ui/core";

export default function List() {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { loading, error, data, networkStatus } = useQuery(FETCH_LEADS_FILES, {
    fetchPolicy: "network-only",
    variables: {
      limit: rowsPerPage,
      page: currentPage + 1,
    },
  });

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>UF's</TableCell>
              <TableCell>Qtd. linhas</TableCell>
              <TableCell>Linhas processadas</TableCell>
              <TableCell>Leads encontrados</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.fetchLeadsFiles.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link href={"/base-bruta/" + row.id}>{row.name}</Link>
                  </TableCell>
                  <TableCell>
                    {row.ufs && row.ufs != null ? row.ufs.join(", ") : ""}
                  </TableCell>
                  <TableCell>{row.rows}</TableCell>
                  <TableCell>{row.processed}</TableCell>
                  <TableCell>{row.processed_leads}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}

            {data && data.fetchLeadsFiles.data.length == 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  Nenhum arquivo de base bruta foi enviado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
        	showFirstButton
          showLastButton        
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data.fetchLeadsFiles.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import { format, isValid, parseISO } from "date-fns";

export const unmask = (value) => {
  if (value) return Number(value.replace(/\./g, "").replace(/\,/g, "."));
};

export const unmaskCPF = (value) => {
  if (value) return value.replace(/\./g, "").replace(/\-/g, "");
};

export const maskCPF = (value) => {
  if (value) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(.{3})(.{3})(.{3})(.{2})/, "$1.$2.$3-$4");
    return value;
  }
};

export const maskCNPJ = (text) => {
	text = text.replace(/\D/g, "");
	text = text.replace(/^(\d{2})(\d)/, "$1.$2");
	text = text.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
	text = text.replace(/\.(\d{3})(\d)/, ".$1/$2");
	text = text.replace(/(\d{4})(\d)/, "$1-$2");
	return text;
};

export const maskPhone = (value) => {
  if (value) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  }
};

export const maskPhoneMicroSip = (value) => {
  if (value) {
    const matches = value.match(/(\d+)/, "");
    return matches?.[1];
  }
};

export const unmaskMoney = (text) => {
  return text ? parseFloat(text.replace(".", "").replace(",", ".")) : 0;
};

export const maskMoney = (text) => {
  if (!text) return "";

  if (typeof text === "number") text = text.toFixed(2).toString();

  let sig = "";

  if (text < 0) sig = "-";

  text = text.replace(/[\D]+/g, "").replace(/\b0+/g, "").padStart(3, "0");
  var unformatted = text.replace(/([0-9]{2})$/g, ".$1");
  var number = unformatted.split(".");
  number[0] = number[0].split(/(?=(?:...)*$)/).join(".");

  return sig + number.join(",");
};

export const formatMoneyBRL = (value) => {
  return value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
};

export const formatDateBRL = (value) => {
  return new Date(value).toLocaleDateString();
};

export const formatDate = (value) => {
  const date = parseISO(value);
  return isValid(date) ? format(date, 'dd/MM/yyyy') : value;
}

export const isValidCPF = (cpf) => {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("");
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);
  const toValidate = (pop) =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

export const delConfirm = (callback) => {
  if (window.confirm("Deseja realmente excluir esse registro?")) {
    callback();
  }
};

export const formatMoney = (input) => {
  const values = input.split(",");
  const formattedValues = values.map((value) => {
    const cleanValue = value.replace(/[^\d,.]/g, "");
    const [integerPart, decimalPart] = cleanValue.split(".");
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );
    const formattedDecimalPart = decimalPart
      ? "," + decimalPart.padEnd(2, "0")
      : ",00";
    return `R$ ${formattedIntegerPart}${formattedDecimalPart}`;
  });

  return formattedValues.join(", ");
};

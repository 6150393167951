import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import Filter from "./Filter";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { FETCH_INTERACTIONS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import Item from "./Item";
import { Button } from "@mui/material";
import FilterDialog from "./FilterDialog";
import LocalProvider from "../../../components/LocalProvider";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CreateDialogManual from "./CreateDialogManual";
import { useSnackbar } from "notistack";
import { ExportCSV } from "../../../components/ExportCSV";
import useToken from "../../../tokenJWT";

export default function InteractionsPage() {

  const { restrictions } = useToken();

  //Restricitions
  const allowExport = restrictions({ topic: "Interações", name: "Exportar csv" })
  const allowCriarCampanha = restrictions({ topic: "Interações", name: "Criar nova campanha manual" })
  //

  const [status, setStatus] = useState("");
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [saleConsultantId, setSaleConsultantId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [opportunity, setOpportunity] = useState(null);
  const [reason, setReason] = useState([]);
  const [tag, setTag] = useState([]);
  const [entitiesId, setEntitiesId] = useState(null);
  const [saleOriginsId, setSaleOriginsId] = useState(null);
  const [interestLevel, setInterestLevel] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [showCreateManual, setShowCreateManual] = useState(false);

  const { loading, data, refetch } = useQuery(FETCH_INTERACTIONS, {
    fetchPolicy: "network-only",
    variables: {
      status,
      start,
      finish,
      opportunity_id: opportunity,
      sale_consultant_id: saleConsultantId,
      team_id: teamId,
      limit: rowsPerPage,
      page: currentPage,
      sale_origin_id: saleOriginsId,
      entity_id: entitiesId,
      reasons: reason,
      interest_level: interestLevel,
      tag_ids: tag,
    },
  });

  const handleFilter = (data) => {
    setStatus(data.status);
    setStart(data.start);
    setFinish(data.finish);
    setSaleConsultantId(data.saleConsultantId);
    setTeamId(data.teamId);
    setOpportunity(data.opportunity_id);
    setEntitiesId(data.entity_id);
    setSaleOriginsId(data.sale_origin_id);
    setReason(data.reasons);
    setInterestLevel(data.interest_level);
    setTag(data.tag_ids);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [
    status,
    start,
    finish,
    opportunity,
    saleConsultantId,
    teamId,
    saleOriginsId,
    entitiesId,
    reason,
    interestLevel,
  ]);

  return (
    <>
      <Header />
      <Container style={{ marginTop: 86 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 12 }} />
          </div>
        ) : (
          <>
            {data && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                  <Card>
                    <CardContent>
                      <Filter
                        filter={{
                          status,
                          start,
                          finish,
                          saleConsultantId,
                          teamId,
                          saleOriginsId,
                          entitiesId,
                          reason,
                          opportunity,
                          interestLevel,
                          tag,
                        }}
                        onChangeFilter={(filter) => {
                          handleFilter(filter);
                        }}
                      />
                      <FilterDialog
                        onChangeFilter={(filter) => {
                          handleFilter(filter);
                        }}
                        loading={loading}
                        open={isOpen}
                        handleClose={() => setIsOpen(false)}
                        data={{
                          status,
                          start,
                          finish,
                          saleConsultantId,
                          teamId,
                          opportunity,
                          entitiesId,
                          saleOriginsId,
                          reason,
                          interestLevel,
                          tag,
                        }}
                      />
                      <Button
                        onClick={() => setIsOpen(true)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Filtros
                      </Button>
                    </CardContent>
                  </Card>

                  {
                    allowCriarCampanha &&
                    <Button
                      disabled={data?.fetchInteractions.data.length === 0}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 30,
                      }}
                      onClick={() => setShowCreateManual(true)}
                    >
                      <ContactPhoneIcon size={32} round />
                      Nova Campanha Manual
                    </Button>
                  }

                  {
                    allowExport &&
                    <ExportCSV
                      filter={{
                        status,
                        start,
                        finish,
                        saleConsultantId,
                        teamId,
                        saleOriginsId,
                        entitiesId,
                        reason,
                        opportunity,
                        interestLevel,
                        tag,
                      }}
                    />
                  }
                </Grid>

                <CreateDialogManual
                  open={showCreateManual}
                  handleClose={() => setShowCreateManual(false)}
                  onCreate={() => {
                    enqueueSnackbar("Campanha cadastrada com sucesso!");
                    setShowCreateManual(false);
                  }}
                  filter={{
                    status,
                    start,
                    finish,
                    saleConsultantId,
                    teamId,
                    saleOriginsId,
                    entitiesId,
                    reason,
                    opportunity,
                    interestLevel,
                    tag,
                  }}
                />

                <Grid item xs={12} sm={8} md={9}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h1">
                        Interações
                      </Typography>

                      {data.fetchInteractions.data.map((i, k) => {
                        return <Item key={k} data={i} refetch={refetch} />;
                      })}
                      {data.fetchInteractions.data.length === 0 && (
                        <Typography variant="caption">
                          Nenhum resultado encontrado
                        </Typography>
                      )}

                      {data.fetchInteractions.data.length > 0 && (
                        <LocalProvider locale="ptBR">
                          <TablePagination
                            showFirstButton
                            showLastButton
                            rowsPerPageOptions={[15, 50, 100]}
                            component="div"
                            count={data.fetchInteractions.total}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={(event, newPage) => {
                              setCurrentPage(newPage);
                            }}
                            onRowsPerPageChange={(event) => {
                              setRowsPerPage(parseInt(event.target.value, 10));
                              setCurrentPage(0);
                            }}
                          />
                        </LocalProvider>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
}

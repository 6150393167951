import { Box, Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { maskCPF } from "../../../helpers";
import { useHistory } from "react-router-dom";
import { Chip } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";

export default function Item({ data, refetch, isAdmin }) {
  const history = useHistory();

  let statusColor = [];

  statusColor["Em negociação"] = "#00C";
  statusColor["Agendado"] = "#c0c0c0";
  statusColor["Sem Interesse"] = "#000";
  statusColor["Proposta Aberta"] = "#417505";
  statusColor["Contato Inválido"] = "#D0021B";
  statusColor["Outros"] = "#D0021B";
  statusColor["Sem perfil"] = "#D0021B";
  statusColor["Ligação encerrada"] = "#D0021B";
  statusColor["Pré Venda"] = "#417505";

  const handleClick = (id) => {
    history.push("/lead/" + id, {
      id: new URLSearchParams(id).toString(),
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{
        marginTop: 12,
        marginBottom: 12,
        borderBottom: "1px solid #CCC",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={8} lg={10}>
            <Tooltip title="Detalhe do Cliente" placement="right">
              <Button
                style={{ fontSize: "16px", paddingLeft: 0, color: "#5FA018" }}
                onClick={() => handleClick(data.lead_id)}
              >
                {`#${data.id}`}
              </Button>
            </Tooltip>
            <Typography variant="caption" component="div">
              {data.created_at}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <div style={{ flexGrow: 1 }}>
              <div
                style={{
                  backgroundColor: statusColor[data.status],
                  fontSize: 11,
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#FFF",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 8,
                  marginTop: 8,
                }}
              >
                {data.status}
              </div>
            </div>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              CPF
            </Typography>
            <Typography variant="subtitle2" component="div">
              {maskCPF(data.document)}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Cliente
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.customer}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Equipe
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.team ? data.team : "-"}
            </Typography>
          </Grid>

          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Consultor de vendas
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.sale_consultant ? data.sale_consultant : "-"}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Oportunidade
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.opportunity ? data.opportunity : "-"}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Origem de atendimento
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.sale_origin ? data.sale_origin : "-"}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Entidades
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.entity ? data.entity : "-"}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Nível de interesse
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.temperature ? data.temperature : "-"}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={4}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8 }}
            >
              Interação
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.description ? data.description : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              Tags de atendimento
            </Typography>
            <Box style={{ display: "inline-flex", flexWrap: "wrap" }}>
              {data?.tags
                .filter((i) => i.type === "Atendimento")
                .map((i) => (
                  <Tooltip title={i.name} placement="top">
                    <Chip
                      key={i.name}
                      label={i.name}
                      variant="outlined"
                      style={{
                        marginRight: "10px",
                        color: i.color,
                        borderColor: i.color,
                        padding: "5px",
                        width: "150px",
                      }}
                      icon={
                        <BookmarkBorderIcon
                          style={{ fontSize: 15, color: i.color }}
                        />
                      }
                    />
                  </Tooltip>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              Tags de produção
            </Typography>
            <Box style={{ display: "inline-flex", flexWrap: "wrap" }}>
              {data?.tags
                .filter((i) => i.type === "Produto")
                .map((i) => (
                  <Tooltip title={i.name} placement="top">
                    <Chip
                      key={i.name}
                      label={i.name}
                      variant="outlined"
                      style={{
                        backgroundColor: i.color,
                        marginRight: "10px",
                        color: "#FFF",
                        padding: "5px",
                        width: "150px",
                      }}
                      icon={
                        i.required ? (
                          <SdCardAlertIcon
                            style={{ fontSize: 15, color: "#FFF" }}
                          />
                        ) : (
                          <BookmarkBorderIcon
                            style={{ fontSize: 15, color: "#FFF" }}
                          />
                        )
                      }
                    />
                  </Tooltip>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { COUNT_INDICATIONS } from "../../../graphql/queries";

export default function Filter({ onChangeFilter, isAdmin, filter }) {
  const { loading, data } = useQuery(COUNT_INDICATIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChane: true,
    variables: {
      start: filter?.start,
      finish: filter?.finish,
      opportunity_id: filter.opportunity,
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography component="div" variant="caption">
            Total de indicações
          </Typography>
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Typography variant="h5">{data.countIndications.total}</Typography>
          )}
        </Grid>
      </Grid>
      <div style={{ margin: "12px 8px 24px 4px", fontSize: "12px" }}>
        {data &&
          data.countIndications.items.map((i, k) => {
            return (
              <div
                key={k}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginBottom: 8,
                }}
              >
                <div>{i.name}</div>
                <div
                  style={{
                    borderBottom: "2px dotted #aaa",
                    flexGrow: 1,
                    margin: "0 12px",
                  }}
                ></div>
                <div>{i.count}</div>
              </div>
            );
          })}
      </div>
    </>
  );
}

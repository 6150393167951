import { Button, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { useState } from "react";
import { maskCPF, maskPhone } from "../../../helpers";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import DialogManualPhone from "../../sales-consultant/DialogManualPhone"
import { useHistory } from "react-router-dom";

export default function Item({ data, refetch, isAdmin }) {
  const [open, setOpen] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  const [selectedLead, setSelectedLead] = useState(false);
  const history = useHistory();

  let statusColor = [];

  statusColor["Segundo Contrato"] = "#4169E1";
  statusColor["Renovação"] = "#00008B";
  statusColor["Portabilidade Consignada"] = "#363636";
  statusColor["Santander"] = "#8B0000";
  statusColor["Agibank"] = "#228B22";

  const handleClick = (id) => {
    history.push("/lead/" + id, {
      id: new URLSearchParams(id).toString(),
    });
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={3}
        style={{
          marginTop: 12,
          marginBottom: 12,
          borderBottom: "1px solid #CCC",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={8} lg={10}>
            <Tooltip title="Detalhe do Cliente" placement="right">
              <Button style={{ fontSize: "16px", paddingLeft: 0, color: "#5FA018" }} onClick={() => handleClick(data.lead.id)}>
                {`#${data.id}`}
              </Button>
            </Tooltip>
              <Typography variant="caption" component="div">
                {format(new Date(data.created_at), "dd/MM/yyyy HH:mm")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    backgroundColor: statusColor[data.opportunity.name],
                    fontSize: 11,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#FFF",
                    paddingTop: 4,
                    paddingBottom: 4,
                    borderRadius: 8,
                    marginTop: 8,
                  }}
                >
                  {data.opportunity.name}
                </div>
              </div>
            </Grid>
            <Grid item sm={6} lg={6}>
              <Typography
                variant="caption"
                component="div"
                style={{ marginTop: 8 }}
              >
                Cliente
              </Typography>
              <Typography variant="subtitle2" component="div">
                {data.lead.name}
              </Typography>
            </Grid>
            <Grid item sm={6} lg={6}>
              <Typography
                variant="caption"
                component="div"
                style={{ marginTop: 8 }}
              >
                Consultor que indicou
              </Typography>
              <Typography variant="subtitle2" component="div">
                {data?.user?.name ? data?.user?.name : "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="caption"
                component="div"
                style={{ marginTop: 8 }}
              >
                Telefone
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                variant="subtitle2"
                component="div"
              >
                <div>{data.lead.phones[0]?.number ? maskPhone(data.lead.phones[0]?.number) : "-"}</div>
                {data.lead.phones[0]?.number && (
                  <AddIcCallIcon
                    style={{
                      cursor: "pointer",
                      color: "#2196f3",
                      fontSize: 22,
                      marginLeft: 20,
                    }}
                    onClick={() => setSelectedLead(true)}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogManualPhone
        open={selectedLead}
        data={data.lead}
        onClose={() => setSelectedLead(false)}
      />
    </>  
  );
}

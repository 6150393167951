import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Container,
  Button,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../../components/Header";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import PopoverAnnouncement from "../../../components/PopoverAnnouncement";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_ANNOUNCEMENTS } from "../../../graphql/queries";
import { DELETE_ANNOUNCEMENT } from "../../../graphql/mutations";
import { useSnackbar } from "notistack";
import useToken from "../../../tokenJWT";

const formatDate = (datetime) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const profileMapping = {
  manager: "Gerentes",
  supervisor: "Líderes",
  saleConsultant: "Consultores",
};

const formatProfiles = (profileNames) => {
  const translatedProfiles = profileNames.map((name) => profileMapping[name]);
  if (profileNames.length === 2) {
    return profileMapping[profileNames[0]];
  } else if (profileNames.length === 3) {
    return translatedProfiles[0] + ", " + translatedProfiles[1];
  } else {
    return (
      translatedProfiles[0] +
      ", " +
      translatedProfiles[1] +
      ", " +
      translatedProfiles[2]
    );
  }
};

const AnnouncementPage = () => {

  const { restrictions } = useToken();

  //Restricitions
  const allowCriarComunicados = restrictions({ topic: "Comunicados", name: "Criar" })
  const allowExcluirComunicados = restrictions({ topic: "Comunicados", name: "Excluir" })

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, refetch } = useQuery(FETCH_ANNOUNCEMENTS);

  const [deleteAnnouncement, { loading: loadingDelete }] =
    useMutation(DELETE_ANNOUNCEMENT);

  const handleDelete = (id) => {
    deleteAnnouncement({
      variables: {
        id: id,
      },
    })
      .then(() => {
        enqueueSnackbar("Comunicado excluído com sucesso!", {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        console.error("Error deleting announcement:", error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Header />

      <Container style={{ marginTop: 120 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 12 }} />
          </div>
        ) : (
          <>
            {data?.fetchAnnouncements.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Nenhum comunicado encontrado
              </div>
            ) : (
              <>
                <Typography variant="h6">Comunicados</Typography>
                <TableContainer style={{ marginTop: "20px" }} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Cor</TableCell>
                        <TableCell>Para:</TableCell>
                        <TableCell>Assunto</TableCell>
                        <TableCell>Data de envio</TableCell>
                        <TableCell>Prazo de expiração</TableCell>
                        <TableCell
                          style={{ textAlign: "right", width: "50px" }}
                        >
                          &nbsp;
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.fetchAnnouncements.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <div
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  backgroundColor: row.theme,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {formatProfiles(row.profiles)}
                            </TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{formatDate(row.created_at)}</TableCell>
                            <TableCell>{formatDate(row.expire_at)}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(row.id)}
                              >
                                {loadingDelete ? (
                                  <CircularProgress size={21} />
                                ) : (
                                  allowExcluirComunicados ? <DeleteIcon /> : <></>
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </Container>
      {
        allowCriarComunicados &&
        <>
          <Box
            sx={{
              position: "fixed",
              bottom: 60,
              right: 40,
              borderRadius: "12px",
              backgroundColor: "#a4eba4",
              transition:
                "box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0,0.2,1)",
              "&:hover": {
                boxShadow:
                  "0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)",
              },
            }}
          >
            <Button
              onClick={handleOpen}
              color="default"
              startIcon={<CreateOutlinedIcon />}
              style={{
                fontSize: "12px",
                padding: "18px",
              }}
            >
              Comunicado
            </Button>
          </Box>
          <PopoverAnnouncement
            open={open}
            onClose={() => setOpen(false)}
            refetch={refetch}
          />
        </>
      }
    </>
  );
};

export default AnnouncementPage;

import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_REASON } from "../../../../graphql/mutations";
import * as Yup from 'yup';
import { useState } from "react";

export default function CreateDialog({ open, handleClose, onCreate }) {

    const [newValue, setNewValue] = useState(null);

    // Setting mutation
    const [createReason, { loading }] = useMutation(CREATE_REASON);

    // Setting Formik
    const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
        initialValues: { name: "", type: "", entity: "" },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Campo obrigatório')
        }),
        onSubmit: (values) => {
            // Chamando a mutation de criação
            createReason({
                variables: {
                    name: values.name,
                    entity: values.entity,
                    type: newValue,
                }
            }).then(() => {
                resetForm();
                onCreate();
            })
        }
    });

    const handleChangeRadio = (event) => {
        setNewValue(event.target.value);
      };    

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Criar Motivo</DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                onChange={handleChange}
                                value={values.name}
                                required
                                id="name"
                                label="Nome"
                                name="name"
                                error={errors.name && true}
                                helperText={errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                onChange={handleChange}
                                value={values.entity}
                                label="Entidade"
                                name="entity"
                                error={errors.file && true}
                                helperText={errors.file}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                        <Typography>Tipo*</Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group"
                                name="row-radio-buttons-group"
                                value={newValue}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel
                                style={{ marginRight: 50 }}
                                value="Sem interesse"
                                control={<Radio />}
                                label="Sem interesse"
                                />
                                <FormControlLabel
                                value="Sem perfil"
                                control={<Radio />}
                                label="Sem perfil"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

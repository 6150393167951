import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Link,
  Divider,
} from "@material-ui/core";
import ImgList from "./ImgList";

const formatDate = (datetime) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default function ModalStatement({ open, onClose, data }) {
  
  const renderAttachments = () => {
    if (!data || !data.attachments || data.attachments.length === 0) {
      return null;
    }

    return data.attachments.map((attachment) => {
      if (attachment.type === "image") {
        return (
          <Box key={attachment.id} mt={2}>
            <img
              src={attachment.path.replace("http://", "https://")}
              alt={attachment.name}
              style={{ maxWidth: "100%", maxHeight: "300px" }}
            />
          </Box>
        );
      } else {
        return (
          <Box key={attachment.id}>
            <Typography variant="body2" style={{ marginBottom: 4 }}>
              <Link
                href={attachment.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.name}
              </Link>
            </Typography>
          </Box>
        );
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{data?.title}</Typography>
          <Typography variant="subtitle2">
            Enviado: {`${formatDate(data?.created_at)}`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ marginBottom: 20, marginTop: 10 }}>
        <Box sx={{ width: 300, bgcolor: "background.paper", p: 2 }}>
          <Typography variant="subtitle1">{data?.description}</Typography>
        </Box>
        {data?.attachments.length > 0 && (
          <>
            <Divider style={{ marginTop: 40, marginBottom: 20 }} />
            <ImgList itemsData={data?.attachments} />
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <Box>
              <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
                Anexos:
              </Typography>
              {renderAttachments()}
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions style={{ marginBottom: 10, marginRight: 10 }}>
        <Button onClick={onClose} variant="contained" color={"primary"}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import SaveIcon from "@mui/icons-material/Save";
import { Box, Fab, CircularProgress } from "@material-ui/core";

export function SaveFabButton({ isLoading, ...rest }) {
	return (
		<Box position="fixed" bottom={32} right={50}>
			<Fab color="primary" aria-label="add" {...rest} disabled={isLoading}>
				{isLoading ? <CircularProgress color="inherit" /> : <SaveIcon />}
			</Fab>
		</Box>
	);
}
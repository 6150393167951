import {
  Avatar,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import Header from "../../../components/Header";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import DescriptionIcon from "@material-ui/icons/Description";
import StyleIcon from "@material-ui/icons/Style";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HubIcon from "@mui/icons-material/Hub";
import SellIcon from "@mui/icons-material/Sell";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export default function SettingsPage() {
  const items = [
    {
      title: "Perfis",
      url: "/configuracoes/perfis",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <SwitchAccountIcon/>,
    },
    {
      title: "Usuários",
      url: "/configuracoes/usuarios",
      subtitle: "Admin, Consultores, Supervisores, Gerentes",
      icon: <AccountBoxIcon />,
    },
    {
      title: "Empresas",
      url: "/configuracoes/empresas",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <BusinessIcon />,
    },
    {
      title: "Canais de vendas",
      url: "/configuracoes/canais-de-vendas",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <BuildOutlinedIcon />,
    },
    {
      title: "Origem das vendas",
      url: "/configuracoes/origem-das-vendas",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <StyleIcon />,
    },
    {
      title: "Formulários",
      url: "/configuracoes/formularios",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <DynamicFormIcon />,
    },
    {
      title: "Produtos",
      url: "/configuracoes/produtos",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <AppsIcon />,
    },
    {
      title: "Situações de Propostas",
      url: "/configuracoes/propostas",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <RecordVoiceOverIcon />,
    },
    {
      title: "Bancos",
      url: "/configuracoes/bancos",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <AccountBalanceIcon />,
    },
    {
      title: "Entidades",
      url: "/configuracoes/entidades",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <SubtitlesIcon />,
    },
    {
      title: "Espécies",
      url: "/configuracoes/especies",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <DescriptionIcon />,
    },
    {
      title: "Localizações",
      url: "/configuracoes/localizacao",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <LocationOnIcon />,
    },
    {
      title: "Universidade SIM",
      url: "/configuracoes/universidade-sim",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <MenuBookIcon />,
    },
    {
      title: "Motivos",
      url: "/configuracoes/motivos",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <HubIcon />,
    },
    {
      title: "Tags",
      url: "/configuracoes/tags",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <SellIcon />,
    },
    {
      title: "Categorias de Lançamentos",
      url: "/configuracoes/categorias_lancamentos",
      subtitle: "Ver todos, criar, editar, e excluir",
      icon: <RequestQuoteIcon />,
    },
  ];

  return (
    <>
      <Header title="Configurações" />
      <Container maxWidth="xl" style={{ marginTop: 86 }}>
        <Card style={{ marginTop: 12, height: "100%" }}>
          <CardContent>
            <Grid container spacing={3} wrap="wrap">
              {items.map((i) => (
                <Grid item xs={6} sm={3}>
                  <Card style={{ backgroundColor: "#F7F7F7", height: 135 }}>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Avatar style={{ marginBottom: 8 }}>{i.icon}</Avatar>
                      <Typography color="textSecondary" gutterBottom>
                        {<Link href={i.url}>{i.title}</Link>}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
/*
<ListItem>
<ListItemAvatar>
<Avatar>
{i.icon}
</Avatar>
</ListItemAvatar>
<ListItemText
primary={<Link href={i.url}>{i.title}</Link>}
secondary={i.subtitle}
/>
</ListItem>
*/

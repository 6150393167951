import {
	FormControl,
	Grid,
	FormHelperText,
	TextField,
} from "@mui/material";
import IconButton from "../../../components/IconButton";
import { useFormContext, Controller } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { maskCNPJ, maskCPF } from "../../../helpers";
import { useState } from "react";
import { ModalSearchContributor } from "../Modal/ModalSearchContributor";

function FieldCpfOrCnpj({
	name,
	required,
	index,
	groupTitle,
}) {

	const { control, setValue } = useFormContext();

	return (
		<>
			<Grid item xs={12} marginTop={2}>
				<Controller
					control={control}
					name={`groups.${index}.fields.${name}`}
					rules={{
						required: required ? "Campo obrigatório" : false,
						validate: (value) => {
							const valueRegex = value ? value.replace(/\D/g, "") : "";
							if (valueRegex.length < 11 && valueRegex.length > 0) {
								return "CPF inválido";
							}
							if (
								valueRegex.length > 11 &&
								valueRegex.length < 14 &&
								valueRegex.length > 0
							) {
								return "CNPJ inválido";
							}
							if (valueRegex.length > 14 && valueRegex.length > 0) {
								return "CNPJ ou CPF inválido";
							}
						},
					}}
					render={(inputProps) => (
						<>
							<FormControl
								fullWidth
								error={inputProps.fieldState.error && true}
							>
								<TextField
									variant="outlined"
									type="text"
									InputLabelProps={{
										shrink: inputProps.field.value ? true : false,
									}}
									label={`CPF ou CNPJ${required ? "*" : ""}`}
									fullWidth
									value={inputProps.field.value}
									onChange={(e) => {
										const { value } = e.target;
										const valueReplace = value ? value.replace(/\D/g, "") : "";
										inputProps.field.onChange(valueReplace);
									}}
									error={inputProps.fieldState.error && true}
									onBlur={(e) => {
										const { value } = e.target;
										const valueReplace = value.replace(/\D/g, "");
										if (valueReplace.length >= 14) {
											const text = valueReplace;
											const cnpj = maskCNPJ(text);

											e.target.value = cnpj;
											inputProps.field.onChange(e.target.value);
											return;
										}
										if (valueReplace.length >= 11) {
											const text = valueReplace;
											const cpf = maskCPF(text);

											e.target.value = cpf;
											inputProps.field.onChange(e.target.value);
											return;
										}
									}}
								/>
								{inputProps.fieldState.error && (
									<FormHelperText error={inputProps.fieldState.error && true}>
										{inputProps.fieldState.error.message + ""}
									</FormHelperText>
								)}
							</FormControl>
						</>
					)}
				/>
			</Grid>
		</>
	);
}

export default FieldCpfOrCnpj;
import { useState } from "react";
import {
  makeStyles,
  Typography,
  createTheme,
  CircularProgress,
  Button,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import {
  CREATE_CAMPAING_FROM_CUSTOM_FILE,
  CREATE_LEAD_FILE,
} from "../../../../../graphql/mutations";
import { DETAIL_LEAD_FILE } from "../../../../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import useUploadLeadFile from "../../../../../uploadLeadFile";

export default function ManualBase(props) {
  const style = useStyles();
  const [leadFileID, setLeadFileID] = useState(null);
  const [createLeadFile, createLeadFileMutation] =
    useMutation(CREATE_LEAD_FILE);

  // UploadLeadFile
  const { progress, loading, upload, reset, uploaded } = useUploadLeadFile();

  const handleChangeFile = (event) => {
    upload(event.target.files[0], function (file) {
      createLeadFile({
        variables: {
          custom: true,
          filename: file.uniqueIdentifier,
          manual: false,
        },
      }).then(({ data }) => {
        setLeadFileID(data.createLeadFile.id);
      });
    });
  };

  return (
    <>
      <label for="uploadCamp" className={style.sectionStep02Manual}>
        {!uploaded && !loading && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <BackupIcon className={style.uploadIcon}></BackupIcon>
            <Typography className={style.textManualBase}>
              Selecione um arquivo .csv de acordo com o layout pré-estabelecido
            </Typography>
            <input
              id="uploadCamp"
              multiple={false}
              style={{ display: "none" }}
              type="file"
              accept=".csv"
              onChange={handleChangeFile}
            ></input>
          </div>
        )}

        {loading && !leadFileID && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <CircularProgress
              variant="determinate"
              value={Math.round(progress * 100)}
              style={{ marginRight: 6 }}
            />
            <br />
            Enviando: {Math.round(progress * 100)}%
          </div>
        )}

        {createLeadFileMutation.loading && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <CircularProgress />
            <br /> Processando linhas do arquivo ..
          </div>
        )}

        {leadFileID && (
          <ShowLeadFileInfo
            id={leadFileID}
            onCreate={props.onCreate}
            values={props.values}
            handleCancel={() => {
              setLeadFileID(null);
              reset();
            }}
          />
        )}
      </label>
    </>
  );
}

const ShowLeadFileInfo = (params) => {
  const style = useStyles();

  const [createCampaing, createCampaingMutation] = useMutation(
    CREATE_CAMPAING_FROM_CUSTOM_FILE
  );

  const { loading, error, data, stopPolling } = useQuery(DETAIL_LEAD_FILE, {
    variables: { id: params.id },
    pollInterval: 2000,
  });

  if (data && data.detailLeadFile.status == "Processado") stopPolling();

  const handleClickCreateCampaing = () => {
    createCampaing({
      variables: {
        name: params.values.name,
        lead_file_id: params.id,
        restriction: params.values.restrictions && {
          sale_consultants:
            params.values.restrictions.sale_consultants &&
            params.values.restrictions.sale_consultants.map((i) => i.id),
          sale_consultant_groups:
            params.values.restrictions.sale_consultant_groups &&
            params.values.restrictions.sale_consultant_groups.map((i) => i.id),
        },
      },
    }).then(() => {
      params.onCreate();
    });
  };

  return (
    <div style={{ maxWidth: "320px", padding: "0 2%" }}>
      <Typography className={style.textManualBase}>
        {!data ? (
          <CircularProgress />
        ) : (
          <div>
            {data.detailLeadFile.status == "Processando" ? (
              <>
                <CircularProgress />
                <br />
                Processando linhas: {data.detailLeadFile.processed} /{" "}
                {data.detailLeadFile.rows}
                <br />
                Processando leads: {data.detailLeadFile.processed_leads} /{" "}
                {data.detailLeadFile.count_leads}
              </>
            ) : (
              <>
                <Typography className={style.textManualBase}>
                  Total de leads
                  <br />
                  <b style={{ fontSize: "20px" }}>
                    {data.detailLeadFile.processed_leads}
                  </b>
                  <br />
                  <span
                    onClick={() => params.handleCancel()}
                    className={style.textManualBase_href}
                  >
                    Clique aqui
                  </span>{" "}
                  para remover e enviar outro arquivo
                </Typography>

                <Button
                  disabled={
                    data.detailLeadFile.processed_leads == 0 ||
                    createCampaingMutation.loading
                  }
                  variant="contained"
                  style={{ marginTop: 12 }}
                  onClick={handleClickCreateCampaing}
                  color="primary"
                >
                  {createCampaingMutation.loading && (
                    <CircularProgress size={14} style={{ marginRight: 12 }} />
                  )}
                  Criar campanha
                </Button>
              </>
            )}
          </div>
        )}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionStep02Gross: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },

  formGroup: {
    width: "400px",
    overflow: "auto",
    maxHeight: "160px",
    display: "flex",
    flexDirection: "column",
    margin: "-30px 16px -65px 0",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      border: "1px solid #ccc",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.4)",
      outline: "0px solid #fff",
    },
    "@media (max-width: 780px)": {
      margin: "50px 0 50px 0",
    },
  },

  checkboxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "-4px 0px",
  },

  checkBox: {
    fontSize: "14px",
    marginLeft: "-170px",
    color: "#8a8a8a",
  },

  checkBoxValue: {
    fontSize: "14px",
    color: "#8a8a8a",
    paddingRight: theme.spacing(2),
  },

  buttonBack: {
    marginLeft: theme.spacing(2),
  },

  totalText: {
    marginTop: theme.spacing(1),
    fontSize: "14px",
  },

  totalValorText: {
    fontWeight: "bold",
    fontSize: "26px",
  },

  labelStyle: {
    fontSize: "50px",
  },

  /* Base Manual */

  sectionStep02Manual: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "16px 0px",
    padding: "12px 4px 16px 4px",
    border: "1px solid #19857b",
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },

  textManualBase: {
    color: "#1c998d",
    fontSize: "13px",
  },

  textManualBase_href: {
    color: "#556cd6",
    fontSize: "13px",
    borderBottom: "1px solid #556cd6",
  },

  uploadIcon: {
    margin: "8px 0",
    color: "#1c998d",
  },

  label: {
    fontSize: "14px",
    color: "#8a8a8a",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#19857b",
    },
  },

  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
        color: "#8a8a8a",
        paddingLeft: "20px",
      },
    },
  },
});

import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
} from "@material-ui/core";
import Header from "../../../components/Header";
import useToken from "../../../tokenJWT";
import { Card, CardContent, SpeedDial, SpeedDialAction, Typography } from "@mui/material";
import Releases from './Releases'
import RevenueXExpenses from "./RevenueXExpenses";
import TypesGraphic from "./TypesGraphic";
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from "notistack";
import { addMonths, format, subMonths } from "date-fns";
import FilterListIcon from '@mui/icons-material/FilterList';
import ChartFilterDialog from "./ChartFilterDialog";
import { useQuery } from "@apollo/client";
import { COMPARE_REVENUE_AND_EXPENSE_RELEASES, STATISTICS_BY_TYPE_RELEASES } from "../../../graphql/queries";

export default function FinancialPage() {

    const { enqueueSnackbar } = useSnackbar();
    const { restrictions } = useToken();

    //Restricitions
    const allowImportação = restrictions({ topic: "Financeiro", name: "Importação" })
    const allowAddManualmente = restrictions({ topic: "Financeiro", name: "Adicionar Manualmente" })
    //

    const [isRefetch, setIsRefetch] = useState(false)
    const [openCreate, setOpenCreate] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [openSpeed, setOpenSpeed] = useState(false);

    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState({
        startDate: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
        finishDate: format(new Date(), 'yyyy-MM-dd')
    })

    //Querys
    const { data, loading: loadingComparison, refetch: compareRefetch } = useQuery(COMPARE_REVENUE_AND_EXPENSE_RELEASES, {
        variables: {
            start: filters.startDate,
            finish: filters.finishDate
        },
        notifyOnNetworkStatusChange: true
    })
    const dataComparison = data?.compareRevenueAndExpenseReleases.slice(0).reverse() || []

    const { data: dataDespesa, loading: loadingDespesa, refetch: typeDespesaRefetch } = useQuery(STATISTICS_BY_TYPE_RELEASES, {
        variables: {
            type: "Despesa",
            start: filters.startDate,
            finish: filters.finishDate
        },
        notifyOnNetworkStatusChange: true
    })
    const dataTypeDespesa = dataDespesa?.statisticsByTypeReleases || []

    const { data: dataReceita, loading: loadingReceita, refetch: typeReceitaRefetch } = useQuery(STATISTICS_BY_TYPE_RELEASES, {
        variables: {
            type: "Receita",
            start: filters.startDate,
            finish: filters.finishDate
        },
        notifyOnNetworkStatusChange: true
    })
    const dataTypeReceita = dataReceita?.statisticsByTypeReleases || []
    //

    const handleCloseSpeedDial = () => setOpenSpeed(false);

    const handleOpenFilterDialog = () => setOpenFilter(true)
    const handleCloseFilterDialog = () => setOpenFilter(false)

    const actions = [
        {
            icon: <AddIcon size={32} round />,
            name: "Criar novo lançamento",
            operation: "Criar novo lançamento",
            action: () => setOpenCreate(true),
        },
        {
            icon: <PublishIcon size={32} round />,
            name: "Importar lançamentos",
            operation: "Importar lançamentos",
            action: () => setOpenImport(true),
        }

    ];

    const handleGlobalRefresh = () => {
        compareRefetch()
        typeDespesaRefetch()
        typeReceitaRefetch()
    }

    return (
        <>
            <Header />
            <Container style={{ marginTop: 100 }}>
                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            marginRight={4}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<FilterListIcon />}
                                onClick={handleOpenFilterDialog}
                            >
                                Filtros
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item container xs={12} md={9} spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        Receitas x Despesas
                                    </Typography>
                                    <RevenueXExpenses
                                        {...{ dataComparison }}
                                        loading={loadingComparison}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} alignItems="flex-start">
                            <Card>
                                <CardContent>
                                    <Releases
                                        {...{ openCreate, openImport, handleGlobalRefresh }}
                                        handleClose={() => setOpenCreate(false)}
                                        handleCloseImport={() => setOpenImport(false)}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2} xs={12} md={3}>

                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        Receitas
                                    </Typography>
                                    <TypesGraphic
                                        loading={loadingReceita}
                                        dataStatistics={dataTypeReceita}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        Despesas
                                    </Typography>
                                    <TypesGraphic
                                        loading={loadingDespesa}
                                        dataStatistics={dataTypeDespesa}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                </Grid>
            </Container>


            {
                (allowImportação || allowAddManualmente) &&
                <SpeedDial
                    FabProps={{ style: { backgroundColor: "#68bc4f" } }}
                    ariaLabel="SpeedDial Campanhas"
                    sx={{ position: "fixed", bottom: 48, right: 48 }}
                    icon={<MoreVertIcon />}
                    onMouseLeave={handleCloseSpeedDial}
                    onClick={() => setOpenSpeed((x) => !x)}
                    open={openSpeed}
                >
                    {
                        actions.map((action) => {

                            if (action.name === "Importar lançamentos" && !allowImportação) return (<></>)
                            if (action.name === "Criar novo lançamento" && !allowAddManualmente) return (<></>)

                            return (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={action.action}
                                />
                            )
                        })
                    }
                </SpeedDial>
            }

            {
                openFilter &&
                <ChartFilterDialog
                    open={openFilter}
                    handleClose={handleCloseFilterDialog}
                    applyFilters={(obj) => setFilters(obj)}
                    filledFilters={filters}
                />
            }
        </>
    );

}


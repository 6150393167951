import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { maskMoney, unmaskMoney } from "../../../helpers";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import logoSintegra from '../../../assets/logo.png';
import ListSimulator from "./ListSimulator";
import { useFormik } from "formik";
import useToken from "../../../tokenJWT";

export default function Simulator({
  onBack,
  netIncome,
  values,
  setFieldValue,
  errors,
  loading,
}) {
  const { user } = useToken();

  const formik = useFormik({
    initialValues: {
      account_debits: values.account_debits,
      portion_amount: "0,00",
      amount_consigned: maskMoney(netIncome.amount_consigned || 0),
      gross_salary: maskMoney(netIncome.gross_salary),
      reserved: maskMoney(netIncome.reserved),
    },
  });

  let debits_account = formik.values.account_debits.reduce(
    (accumulator, curr) => accumulator + unmaskMoney(curr.portion_amount),
    0
  );

  let actual_salary =
    unmaskMoney(formik.values.gross_salary || 0) -
    unmaskMoney(formik.values.amount_consigned || 0) -
    unmaskMoney(formik.values.reserved || 0) -
    debits_account;
  let new_salary =
    unmaskMoney(formik.values.gross_salary || 0) -
    unmaskMoney(formik.values.amount_consigned || 0) -
    unmaskMoney(formik.values.reserved || 0) -
    unmaskMoney(formik.values.portion_amount);
  let diff = Math.round(((100 * new_salary) / actual_salary - 100) * 100) / 100;

  const [showDialog, setShowDialog] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            shrink: formik.values.portion_amount,
          }}
          label="Valor da parcela"
          name="portion_amount"
          error={formik.errors.portion_amount && true}
          helperText={formik.errors.portion_amount}
          onChange={(e) =>
            formik.setFieldValue("portion_amount", maskMoney(e.target.value))
          }
          value={formik.values.portion_amount}
          fullWidth={true}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            shrink: formik.values.gross_salary,
          }}
          label="Salário bruto"
          name="gross_salary"
          error={formik.errors.gross_salary && true}
          helperText={formik.errors.gross_salary}
          onChange={(e) =>
            formik.setFieldValue("gross_salary", maskMoney(e.target.value))
          }
          value={formik.values.gross_salary}
          fullWidth={true}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            shrink: formik.values.amount_consigned,
          }}
          label="Total de consignados"
          name="amount_consigned"
          error={formik.errors.amount_consigned && true}
          helperText={formik.errors.amount_consigned}
          onChange={(e) =>
            formik.setFieldValue("amount_consigned", maskMoney(e.target.value))
          }
          value={formik.values.amount_consigned}
          fullWidth={true}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          InputLabelProps={{
            shrink: formik.values.amount_consigned,
          }}
          label="RMC"
          name="reserved"
          error={formik.errors.reserved && true}
          helperText={formik.errors.reserved}
          onChange={(e) =>
            formik.setFieldValue("reserved", maskMoney(e.target.value))
          }
          value={formik.values.reserved}
          fullWidth={true}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle2"
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          Simulação de renda mensal
        </Typography>

        <ListSimulator
          account_debits={formik.values.account_debits}
          portion_amount={formik.values.portion_amount}
          gross_salary={formik.values.gross_salary}
          amount_consigned={formik.values.amount_consigned}
          reserved={formik.values.reserved}
          {...{ new_salary, actual_salary, diff }}
        />

        <div style={{ marginTop: 12, textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(true)}
          >
            <WhatsAppIcon style={{ marginRight: 12 }} /> Enviar por WhatsApp
          </Button>

          <Dialog
            open={showDialog}
            maxWidth="sm"
            fullWidth
            onClose={() => setShowDialog(false)}
          >
            <DialogTitle>Enviar por WhatsApp</DialogTitle>
            <DialogContent>
              <Typography>
                Tire um printscreen da imagem abaixo e envie para o cliente
              </Typography>

              <div
                style={{
                  border: "3px dashed #CCC",
                  marginTop: 12,
                  padding: 12,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    backgroundColor: "#132994",
                    padding: "24px 24px",
                  }}
                >
                  <img src={logoSintegra} alt="Logo" style={{ width: 200 }} />
                </div>
                <Typography align="center" variant="h6">
                  Simulação de renda mensal
                </Typography>
                <Typography style={{ marginBottom: 24, marginTop: 12 }}>
                  Consultor: {user.name}
                </Typography>

                <ListSimulator
                  account_debits={formik.values.account_debits}
                  portion_amount={formik.values.portion_amount}
                  gross_salary={formik.values.gross_salary}
                  amount_consigned={formik.values.amount_consigned}
                  reserved={formik.values.reserved}
                  {...{ new_salary, actual_salary, diff }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialog(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
}

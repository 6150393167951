import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { FETCH_RESCHEDULES } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import Item from "./Item";
import Filter from "./Filter";
import LocalProvider from "../../../components/LocalProvider";

export default function ReschedulesPage() {
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const { loading, data } = useQuery(FETCH_RESCHEDULES, {
    variables: {
      ...filter,
      limit: rowsPerPage,
      page: currentPage + 1,
    },
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [filter]);

  return (
    <>
      <Header />

      <Container style={{ marginTop: 86 }}>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} sm={4} md={3}>
            <Filter
              loading={loading}
              total={data?.fetchReschedules.total}
              onChange={(values) => setFilter(values)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h1">
                   Negociações
                </Typography>

                {loading && <CircularProgress style={{ marginTop: 12 }} />}

                {data && (
                  <>
                    {data.fetchReschedules.data.map((i, k) => {
                      return <Item key={k} data={i} />;
                    })}
                    {data.fetchReschedules.data.length == 0 && (
                      <Typography variant="caption">
                        Nenhum resultado encontrado
                      </Typography>
                    )}
                  </>
                )}

                {data && (
                  <LocalProvider locale="ptBR">
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[15, 50, 100]}
                      component="div"
                      count={data.fetchReschedules.total}
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onPageChange={(event, newPage) => {
                        setCurrentPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value));
                        setCurrentPage(0);
                      }}
                    />
                  </LocalProvider>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_PROFILES } from "../../../../graphql/queries";
import {
    Container,
    Card,
    Typography,
    CardContent,
    Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import List from "./List";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";

export default function ProfilesPage({ history }) {

    const { enqueueSnackbar } = useSnackbar();

    const [showCreate, setShowCreate] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const { loading, error, data, networkStatus, refetch } = useQuery(
        FETCH_PROFILES,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                limit: rowsPerPage,
                page: currentPage + 1,
            },
        }
    );

    return (
        <>
            <Header
                onBack={() => {
                    history.goBack();
                }}
            />

            <Container maxWidth="sm" style={{ marginTop: 86 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h1">
                            Perfis
                        </Typography>
                    </CardContent>

                    <List
                        onDelete={() => {
                            enqueueSnackbar("Produto excluído com sucesso!");
                            refetch();
                        }}
                        onEdit={(item) => setDataEdit(item)}
                        {...{
                            loading,
                            error,
                            data,
                            networkStatus,
                            currentPage,
                            setCurrentPage,
                            rowsPerPage,
                            setRowsPerPage,
                        }}
                    />
                </Card>
            </Container>

            <CreateDialog
                open={showCreate}
                handleClose={() => setShowCreate(false)}
                onCreate={() => {
                    enqueueSnackbar("Perfil cadastrado com sucesso!");
                    refetch();
                    setShowCreate(false);
                }}
                onFail={() => {
                    enqueueSnackbar("Erro ao cadastrar perfil", {
                        variant: "error",
                    });
                }}
            />

            <EditDialog
                open={dataEdit ? true : false}
                handleClose={() => setDataEdit(null)}
                data={dataEdit}
                onEdit={() => {
                    enqueueSnackbar("Perfil editado com sucesso!", {
                        variant: "success"
                    });
                    refetch();
                    setDataEdit(null);
                }}
                onFail={() => {
                    enqueueSnackbar("Erro ao atualizar perfil", {
                        variant: "error",
                    });
                }}
            />

            <Fab
                color="primary"
                onClick={() => setShowCreate(true)}
                style={{ position: "absolute", bottom: 48, right: 48 }}
            >
                <AddIcon />
            </Fab>
        </>
    );
}

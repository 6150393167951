import { useRef, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import SearchInputDebounced from "./SearchInputDebounced";
import "../assets/styleSheet/ScrollBar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  Radio,
  InputAdornment,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default function SelectWithQuerySearch({
  title,
  required,
  value,
  error,
  onChange,
  query,
  fetchResult,
  renderLabel = null,
  fullWidth,
  variables,
  style,
  margin,
  errorLabel,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [newValue, setNewValue] = useState(value || {});

  const { loading, data, fetchMore, refetch } = useQuery(query, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
    variables: {
      limit: 20,
      ...variables,
    },
  });

  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        let next = data[fetchResult].current_page + 1;

        if (next <= data[fetchResult].last_page) {
          fetchMore({
            variables: {
              page: next,
            },
          });
        }
      }
    }
  };

  const handleSelect = () => {
    onChange(newValue);
    setShowDialog(false);
  };

  const newValueIsNumber = typeof newValue === "number";

  useEffect(() => {
    if(newValue && newValueIsNumber && data){
      const element = data[fetchResult].data.find(item => item.id == newValue)
      setNewValue(element)
      onChange(element)
    }
  }, [data])

  return (
    <>
      <TextField
        style={style}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon
                style={{ color: "#787878", marginRight: -6 }}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: value && value.name,
        }}
        onClick={() => setShowDialog(true)}
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        value={newValue ? newValue.name : ""}
        label={title}
        margin={margin}
        error={error}
        helperText={errorLabel}
      />
      {
        showDialog &&
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="md"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <SearchInputDebounced
              typeSearch="inDialog"
              onChange={(value) => {
                refetch({
                  keyword: value,
                  limit: 20,
                  page: 1,
                  ...variables,
                });
              }}
            />

            {loading && <CircularProgress style={{ marginTop: 12 }} />}

            {data && data[fetchResult] && (
              <div
                onScroll={onScroll}
                ref={listInnerRef}
                className="scroolbarCustom"
                style={{
                  marginTop: 12,
                  height: 300,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {data[fetchResult].total == 0 && (
                  <div>Nenhum registro encontrado</div>
                )}

                {data[fetchResult].data.map((element) => {
                  return (
                    <div>
                      <Radio
                        checked={
                          newValue && newValueIsNumber
                            ? newValue === element.id
                            : newValue?.id === element.id
                        }
                        onChange={() => setNewValue(element)}
                      />
                      {renderLabel ? renderLabel(element) : element.name}
                    </div>
                  );
                })}
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 22 }}>
            <Button
              color="primary"
              onClick={() => {
                setNewValue(null);
                onChange(null);
                setShowDialog(false);
              }}
            >
              Limpar
            </Button>
            <Button onClick={handleSelect} variant="contained" color="primary">
              Selecionar
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

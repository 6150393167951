import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { DialogActions } from "@mui/material";
import { DELETE_RELEASE_CATEGORY } from "../../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

export default function DialogDelete({
    open,
    handleClose,
    deleteId,
    onDelete
}) {

    const { enqueueSnackbar } = useSnackbar();

    const [deleteReleaseCategory, { loading: loadingDelete }] = useMutation(DELETE_RELEASE_CATEGORY);

    const handleConfirm = async () => {
        try {
            await deleteReleaseCategory({
                variables: {
                    id: deleteId
                }
            })
            enqueueSnackbar("Categoria excluída com sucesso!", {
                variant: "success"
            })
            onDelete()
        } catch (e) {
            console.error(e)
            enqueueSnackbar("[Error]: " + e.message, {
                variant: "error"
            })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Deletar Categoria"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Ao excluir essa categoria você estará removendo juntamente todos os tipos vinculados a ela. Deseja confirmar a exclusão?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    disabled={loadingDelete}
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    autoFocus
                >
                    { loadingDelete && <CircularProgress size={20} color="white" style={{marginRight: 8}}/>}
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import { Typography, CircularProgress } from "@material-ui/core";
import { COUNT_LEADS } from "../../../../../graphql/queries";
import { useQuery } from "@apollo/client";

export default function CountLeads({ filters, lead_file_ids }) {
  const { loading, error, data, networkStatus } = useQuery(COUNT_LEADS, {
    variables: {
      lead_file_ids: lead_file_ids,
      filters: {
        age_group: filters.age_group,
        gender: filters.gender,
        location_id: filters.locations && filters.locations.map((i) => i.id),
        count_loan_range: filters.count_loan_range,
        benefit: {
          salary_range: filters.benefit_salary_range,
          margin_loan_range: filters.benefit_margin_loan_range,
          bank_id:
            filters.benefit_banks && filters.benefit_banks.map((i) => i.id),
          bank_ignore_id:
            filters.benefit_banks_ignore &&
            filters.benefit_banks_ignore.map((i) => i.id),
          start_range: filters.benefit_start_range,
          entity_id:
            filters.benefit_entities &&
            filters.benefit_entities.map((i) => i.id),
          specie_id:
            filters.benefit_species && filters.benefit_species.map((i) => i.id),
        },
        loan: {
          bank_id: filters.loan_banks && filters.loan_banks.map((i) => i.id),
          type_id: filters.loan_types && filters.loan_types.map((i) => i.id),
          amount_range: filters.loan_amount_range,
          portion_amount_range: filters.loan_portion_amount_range,
          debit_balance_range: filters.loan_debit_balance_range,
          tax_range: filters.loan_tax_range,
          portion_count_range: filters.loan_portion_count_range,
          portion_paid_count_range: filters.loan_portion_paid_count_range,
        },
        debit_account: {
          bank_id:
            filters.debit_account_banks &&
            filters.debit_account_banks.map((i) => i.id),
          portion_amount_range: filters.debit_account_portion_amount_range,
          portion_count_range: filters.debit_account_portion_count_range,
          portion_paid_count_range:
            filters.debit_account_portion_paid_count_range,
        },
        target_audience_id:
          filters.target_audience && filters.target_audience.map((i) => i.id),
        contact: {
          is_valid:
            (filters.contact === "is_valid" && true) ||
            (filters.contact === "whatsapp" && true),
          whatsapp: filters.contact === "whatsapp" ? true : false,
        },
      },
    },
  });

  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        border: "1px solid rgba(0,0,0,0.5)",
        borderRadius: 9,
        padding: "12px 12px",
        position: "relative",
      }}
    >
      {loading && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
          <Typography variant="caption">Contando leads..</Typography>
        </div>
      )}

      {data && (
        <>
          <Typography variant="caption">Leads encontrados:</Typography>
          <Typography variant="h5">{data.countLeads}</Typography>
        </>
      )}
    </div>
  );
}

import { TableCell } from "@material-ui/core"
import { Box, Card, CardContent, CircularProgress, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import truncateString from "../../utils/truncateString";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_TAGS } from "../../graphql/queries";
import { useEffect, useState } from "react";
import { CREATE_LEAD_TAG } from "../../graphql/mutations";
import { useSnackbar } from "notistack";

export default function ProductTagsCard({
    data,
    leadId,
    refetch,
    isDisabled = false
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [tagsChoice, setTagsChoice] = useState([])

    const [createLeadTag] = useMutation(CREATE_LEAD_TAG)

    const { data: dataTags, loading } = useQuery(FETCH_TAGS)
    const listTags = dataTags?.fetchTags.data || []

    const rowsAtendimento = data.filter(item => item.type === "Atendimento").map(item => item)
    const rowsProdutos = listTags.filter(item => item.type === "Produto");

    const findExistElementChoice = (id) => {
        const tagEncontrada = tagsChoice.find(tag => tag.id === id);

        if (!tagEncontrada) {
            return "";
        }

        return tagEncontrada.eligible ? "sim" : "nao";
    }

    const handleChange = (element, value) => {
        const formatValue = value === "sim"
        const newArray = tagsChoice.filter(tag => tag.id !== element.id);

        const performeData = [...newArray, { ...element, eligible: formatValue }]
        setTagsChoice(performeData)
        handleSubmitTags(performeData)
    }

    const handleSubmitTags = async (currentData) => {
        const combinedTags = [...rowsAtendimento, ...currentData].map(item => ({ tag_id: item.id, eligible: item.eligible }));

        try {

            createLeadTag({
                variables: {
                    lead_id: leadId,
                    tags: combinedTags,
                },
            })
        } catch (e) {
            enqueueSnackbar(e, {
                variant: "error",
            });
        }
        refetch()
    }

    useEffect(() => {
        if (data.length > 0) {
            const preValues = data.filter(item => item.type === "Produto")
                .map(item => ({
                    id: item.id,
                    nome: item.name,
                    required: item.required,
                    eligible: item.eligible
                }))
            setTagsChoice(preValues)
        }
    }, [])

    return (
        <Card style={{ paddingTop: 16, paddingBottom: 16 }}>

            <Typography
                variant="subtitle1"
                style={{ marginBottom: 12, textAlign: "center" }}
            >
                <b>TAGS PRODUTOS ELEGÍVEIS</b>
            </Typography>

            <CardContent>

                {
                    loading &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginY={3}
                    >
                        <CircularProgress />
                    </Box>
                }

                {
                    !loading &&
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Nome</b></TableCell>
                                    <TableCell align="center"><b>Elegível</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsProdutos.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            <Tooltip title={row.name} aria-label="add">
                                                <Typography style={{ fontSize: 15 }}>{truncateString(row.name, 17)}<b style={{ color: "red" }}>{row.required && " *"}</b></Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            <RadioGroup
                                                onChange={(e) => handleChange(row, e.target.value)}
                                                row
                                                aria-label="tagProduct"
                                                name="tagProduct"
                                                defaultValue={findExistElementChoice(row.id)}
                                                style={{
                                                    width: 140,
                                                    marginRight: 0,
                                                    justifyContent: "center",
                                                    margin: "0 auto"
                                                }}
                                            >
                                                <FormControlLabel

                                                    value={"sim"}
                                                    disabled={isDisabled}
                                                    control={<Radio size="small" color="primary" />}
                                                    label="Sim"
                                                    labelPlacement="top"

                                                />
                                                <FormControlLabel
                                                    value={"nao"}
                                                    disabled={isDisabled}
                                                    control={<Radio size="small" color="primary" />}
                                                    label="Não"
                                                    labelPlacement="top"
                                                    style={{
                                                        fontSize: 10
                                                    }}
                                                />
                                            </RadioGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </CardContent>
        </Card >
    )
}

import * as React from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { FETCH_PROPOSALS_STATISTICS } from "../../../graphql/queries";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { format } from "date-fns";

export default function ProposalSituations({
  teamId,
  startDate,
  finishDate,
  saleConsultantId,
  product_ids,
  company_ids,
}) {
  const classes = useStyles();

  const variables = {
    start: startDate ? format(startDate, "yyyy-MM-dd") : null,
    finish: finishDate ? format(finishDate, "yyyy-MM-dd") : null,
    sale_consultant_id: saleConsultantId ? saleConsultantId : null,
    product_ids: product_ids ? product_ids : null,
    team_id: teamId ? teamId : null,
    company_ids: company_ids ? company_ids : null,
  };

  const { loading, error, data, refetch } = useQuery(
    FETCH_PROPOSALS_STATISTICS,
    {
      variables,
    }
  );

  useEffect(() => {
    refetch();
  }, [
    teamId,
    startDate,
    finishDate,
    saleConsultantId,
    product_ids,
    company_ids,
    refetch,
  ]);

  if (loading) return <CircularProgress style={{ marginTop: 12 }} />;
  if (error) return <div>Ocorreu um erro ao carregar os dados.</div>;

  return (
    <Box>
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={6}>
          <Chart
            data={[
              { status: "Abertas", val: data.fetchProposalStatistics.opened },
              {
                status: "Fechadas",
                val: data.fetchProposalStatistics.closed,
              },
            ]}
            height={190}
          >
            <PieSeries
              valueField="val"
              argumentField="status"
              innerRadius={0.5}
            />
            <Animation />
          </Chart>
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ marginLeft: 18 }}>
            <Grid item xs={6}>
              <Typography variant="caption">Abertas</Typography>
              <Typography variant="h5">
                {data.fetchProposalStatistics.opened}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Fechadas</Typography>
              <Typography variant="h5">
                {data.fetchProposalStatistics.closed}
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginLeft: 18, marginTop: 12, fontSize: "12px" }}>
            {data.fetchProposalStatistics.situations.map((i, k) => {
              return (
                <div
                  key={k}
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginBottom: 8,
                  }}
                >
                  <div>{i.name}</div>
                  <div
                    style={{
                      borderBottom: "2px dotted #aaa",
                      flexGrow: 1,
                      margin: "0 12px",
                    }}
                  ></div>
                  <div>{i.count}</div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.legendBox}>
        <Grid item className={classes.legendStyle} xs={3}>
          <div className={classes.legendOpen}></div>
          <Typography>Abertas</Typography>
        </Grid>

        <Grid item className={classes.legendStyle} xs={3}>
          <div className={classes.legendClosed}></div>
          <Typography>Fechadas</Typography>
        </Grid>
        <Grid xd={6}></Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  buttonsGrid: {
    marginTop: "13px",
  },

  legendBox: {
    marginTop: "25px",
    paddingLeft: "20px",
  },

  legendStyle: {
    display: "flex",
    marginTop: "13px",
    flexDirection: "row",
  },

  legendOpen: {
    marginTop: "7px",
    marginRight: "6px",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    background: "#42a5f5",
  },

  legendClosed: {
    marginTop: "7px",
    marginRight: "6px",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    background: "#ff7043",
  },
});

import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { DETAIL_CAMPAING } from "../../../graphql/queries";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Header from "../../../components/Header";
import DataMetric from "./DataMetric";
import TextCopy from "../../../components/TextCopy";
import { formatDateBRL } from "../../../helpers";
import { useSnackbar } from "notistack";
import List from "./List";

export default function ManualDetailPage() {
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, refetch, data } = useQuery(DETAIL_CAMPAING, {
    variables: {
      id: parseInt(id),
    },
  });

  let restrictionsConsultant = "Nenhuma";
  let restrictionsGroup = "Nenhuma";

  if (data && data.detailCampaing.restrictions.length > 0) {
    let consultant = data.detailCampaing.restrictions.filter(
      (i) => i.type == "Consultor"
    );
    let group = data.detailCampaing.restrictions.filter(
      (i) => i.type == "Grupo"
    );

    if (consultant.length > 0)
      restrictionsConsultant = consultant.map((i) => i.name).join(", ");

    if (group.length > 0)
      restrictionsGroup = group.map((i) => i.name).join(", ");
  }

  const classes = useStyles();

  return (
    <>
      <Header title="Detalhe da campanha" onBack={() => history.goBack()} />

      <Container style={{ marginTop: 86 }}>
        {loading && <CircularProgress style={{ marginTop: 12 }} />}

        {data && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Grid item>
                <DataMetric
                  onUploadedFile={() => {
                    refetch();
                    enqueueSnackbar("Base higienizada com sucesso!");
                  }}
                  data={data.detailCampaing}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Card>
                <CardHeader title="Dados da campanha" />
                <CardContent>
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <TextCopy
                        copy={false}
                        title="Nome"
                        value={data.detailCampaing.name}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextCopy
                        copy={false}
                        title="Data de criação"
                        value={formatDateBRL(data.detailCampaing.created_at)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextCopy
                        copy={false}
                        title="Status"
                        value={data.detailCampaing.status}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextCopy
                        copy={false}
                        title="Restrição por consultor"
                        value={restrictionsConsultant}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextCopy
                        copy={false}
                        title="Restrição por grupos"
                        value={restrictionsGroup}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {data.detailCampaing.lead_files.lenght > 0 && (
                <Card style={{ marginTop: 24 }}>
                  <CardHeader title="Base de pesquisa" />
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Arquivo</TableCell>
                        <TableCell align="right">Qtd. Leads</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.detailCampaing.lead_files.map((i) => {
                        return (
                          <TableRow key={i.id}>
                            <TableCell component="th" scope="row">
                              {i.name}
                            </TableCell>
                            <TableCell align="right">
                              {i.processed_leads}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Card>
              )}

              <Card style={{ marginTop: 24 }}>
                <List data={data} loading={loading} />
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import FilterDialog from "./FilterDialog";

export default function Filter({ total, loading, onChange }) {

    const [open, setOpen] = useState(false);
    
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component="div" variant="caption">Total</Typography>
                        {
                            loading
                                ? <CircularProgress size={18} />
                                : <Typography variant="h5">{total}</Typography>
                        }
                    </Grid>
                </Grid>
                
                <FilterDialog
                    open={open}
                    handleClose={() => setOpen(false)}
                    onFilter={(values) => {
                        setOpen(false);
                        onChange(values);
                    }}
                />

                <Button
                    onClick={() => setOpen(true)}
                    fullWidth
                    style={{ marginTop: 8 }}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    Filtros
                </Button>

            </CardContent>
        </Card>
    );

}

import { Container, Card, CardContent, Button, TextField, Box, Grid, CircularProgress, makeStyles, Typography, CardHeader } from '@material-ui/core';
import { useFormik } from 'formik';
import LogoFastway from '../../assets/fastway.png';
import * as Yup from 'yup';
import { MUTATION_AUTH } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import useToken from '../../tokenJWT';
import { useHistory } from 'react-router';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logoSintegra from '../../assets/logo.png';

export default function Awaiting({ onLogout }) {

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', backgroundColor: '#132994',  padding: 0 }}>
        <img src={logoSintegra} alt="Logo" style={{ width: 200 }} />
        <div style={{ fontSize: 24, color: "#FFF", margin: '0px 20px' }}>+</div> 
        <img src={LogoFastway} alt="Logo Fastway" style={{ width: 200 }} />
      </div>

      <Container maxWidth="sm">
        <Box mt={5}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Card elevation={4}>
                <CardHeader title="Aguarde.." />
                <CardContent>
                  <Typography>Nenhum lead para exibir.<br />Aguarde o redirecionamento automático do Fastway</Typography>
                  <div style={{ margin: '24px 0px' }}>
                    <CircularProgress />
                  </div>
                  <Button variant="outlined" color="primary" onClick={onLogout}>
                    <ExitToAppIcon /> Sair
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

import { useFormik } from "formik";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_INDICATION } from "../../../graphql/mutations";
import useToken from "../../../tokenJWT";

export default function DialogIndication({
  lead_id,
  lead_name,
  user_name,
  opportunity_id,
  open,
  onClose,
  handleClose,
  onIndication,
  campaign_id,
}) {
  const { user } = useToken();

  const queryString = window.location.search;
  const isFastway = queryString.includes("chave=");

  const [createIndication, { loading }] = useMutation(CREATE_INDICATION);

  const { handleSubmit } = useFormik({
    initialValues: {
      lead_id: "",
      user_id: "",
      opportunity_id: "",
    },
    onSubmit: () => {
      createIndication({
        variables: {
          campaign_id: parseInt(campaign_id),
          lead_id: lead_id,
          user_id: user.id,
          opportunity_id: opportunity_id,
          fastway: isFastway,
        },
      }).then(() => {
        onClose();
        onIndication();
        handleClose();
      });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Indicação</DialogTitle>

      <DialogContent style={{ marginBottom: 20 }}>
        Você confirma a indicação do cliente {lead_name} para a oportunidade{" "}
        {user_name} ?
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Indicar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

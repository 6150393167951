import { useState } from "react";
import {
  CircularProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  CardHeader,
  Button,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import { makeStyles, Link } from "@material-ui/core";
import { maskCPF, maskPhone } from "../../../helpers";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import DialogManualPhone from "../../sales-consultant/DialogManualPhone";
import { DETAIL_CAMPAING_LEADS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

export default function List() {
  const [selectedLead, setSelectedLead] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { id } = useParams();

  const { loading, data } = useQuery(DETAIL_CAMPAING_LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: parseInt(id),
      limit: rowsPerPage,
      page: currentPage + 1
    },
  });

  const classes = useStyles();

  const history = useHistory();

  const handleClick = (id) => {
    history.push("/lead/" + id, {
      id: new URLSearchParams(id).toString()
    });
  };

  if (loading) return <CircularProgress style={{ margin: 12 }} />;

  return (
    <>
      <TableContainer>
        <CardHeader title="Clientes" />
        <Table
          size="small"
          className={classes.table}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Histórico de Interações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.detailCampaingLeads.data.map((i) => {
              return (
                <TableRow key={i.id}>
                  <TableCell component="th" scope="row">
                    <Button onClick={() => handleClick(i.id)}>
                      {i.name}
                    </Button>
                  </TableCell>
                  <TableCell>{maskCPF(i.document) ? maskCPF(i.document) : "-"}</TableCell>
                  <TableCell>
                    {maskPhone(i.phones[0]?.number) ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 15,
                        }}
                      >
                        <p>{maskPhone(i.phones[0]?.number)}</p>
                        <AddIcCallIcon
                          style={{
                            cursor: "pointer",
                            color: "#2196f3",
                            fontSize: 20,
                            marginLeft: 20,
                          }}
                          onClick={() => setSelectedLead(i.id)}
                        />
                      </div>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {i.last_interaction
                      ? `${i.last_interaction.created_at} - ${i.last_interaction.status}`
                      : "-"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogManualPhone
        open={selectedLead}
        data={data?.detailCampaingLeads.data.find(
          (lead) => lead.id === selectedLead
        )}
        onClose={() => setSelectedLead(false)}
      />

      <LocalProvider locale="ptBR">
        <TablePagination
        	showFirstButton
          showLastButton        
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data?.detailCampaingLeads.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import { CSVDownload } from "react-csv";

export function CSVDownloader({ rowsCsv, headersCsv }) {
  return (
    <CSVDownload
      data={rowsCsv}
      headers={headersCsv}
      target="_blank"
      separator=";"
    />
  );
}


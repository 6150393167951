import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useState } from "react";
import { UPLOAD_SYNC_PROPOSALS } from "../../../graphql/mutations";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default function SyncProposals({ onUploadedFile }){

    const [file, setFile] = useState();
    const [inputFileKey, setInputFileKey] = useState(0);

    const [uploadSyncProposals, mutation] = useMutation(UPLOAD_SYNC_PROPOSALS);

    const handleSentFilePhones = () => {
        uploadSyncProposals({
            variables: {
                file: file
            }
        }).then(() => {
            setFile(null);
            setInputFileKey(inputFileKey+1);
            onUploadedFile();
        })
    }

    return (
        <>
            <Typography style={{ marginTop: 24, marginBottom: 12 }}>Sincronização de propostas</Typography>
            <Typography variant="caption">Selecione abaixo um arquivo .csv das propostas para realizar a atualização da base</Typography>

            <input 
                style={{ margin: '12px 0px' }} 
                onChange={(e) => setFile(e.target.files[0])} 
                type="file" 
                key={inputFileKey} 
            />

            <Button disabled={!file || mutation.loading} variant="outlined" color="primary" fullWidth style={{ marginTop: 12 }} onClick={handleSentFilePhones}>
                { mutation.loading ? <CircularProgress size={18} style={{ marginRight: 12 }} /> : <CloudUploadIcon style={{ marginRight: 12 }} /> }
                Enviar .csv
            </Button>

            <div style={{ margin: 12 }}><a href="/exemplo_proposals.csv">Clique aqui</a> para baixar um .csv de exemplo</div>
        </>
    )
}
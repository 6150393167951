import { Title } from "../../../components/Title";
import { Stack, Box, Typography } from "@mui/material";
import componentsDinamycForm from "../../../utils/fieldsDynamicForm";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";

function ComponentsDynamicForm() {
	const { getValues } = useFormContext();

	const componentsSelecteds = getValues("groups");

	const allComponents = componentsSelecteds && componentsSelecteds
		.map((component) => {
			return component.components;
		})
		.flat();
	return (
		<Stack spacing={1}>
			<Title title="Componentes" fontSize="1rem" />
			<Stack
				spacing={0.5}
				component="ul"
				sx={{
					padding: 0,
				}}
			>
				{componentsDinamycForm.map((component, index) => (
					<Droppable key={component.label} droppableId={component.label}>
						{(droppableProvided) => (
							<Box
								ref={droppableProvided.innerRef}
								{...droppableProvided.droppableProps}
								component="li"
								sx={{
									listStyle: "none",
								}}
							>
								<Draggable draggableId={`${component.label}`} index={index}>
									{(draggableProvided) => (
										<Box
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps}
											{...draggableProvided.dragHandleProps}
											sx={{
												backgroundColor:
													allComponents.find(
														(value) => value.type === component.type
													) && "#f3f3f3",
											}}
											maxWidth="150px"
											padding={1}
										>
											<Typography variant="body2">{component.label}</Typography>
										</Box>
									)}
								</Draggable>
							</Box>
						)}
					</Droppable>
				))}
			</Stack>
		</Stack>
	);
}

export default ComponentsDynamicForm;
import { useQuery } from "@apollo/client";
import { Card, List, CardContent, CardHeader, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton, Tooltip, Dialog, DialogTitle, ListItem, ListItemText } from "@material-ui/core";
import { useState } from "react";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { FETCH_LEADS_FILES_ROWS } from "../../../graphql/queries";

export default function Records({ lead_file_id }) {

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [detail, setDetail] = useState();

    const { loading, error, data, networkStatus } = useQuery(FETCH_LEADS_FILES_ROWS, {
        fetchPolicy: 'network-only',
        variables: {
            lead_file_id: lead_file_id,
            limit: rowsPerPage,
            page: currentPage + 1
        }
    });

    const handleShowDetail = (detail) => {
        setDetail(JSON.parse(detail));
    }

    return (
        <>
            <Card>
                <CardHeader title="Registros" />
                <CardContent>
                    {loading && <CircularProgress />}
                    {
                        (data && data.fetchLeadsFileRows) &&
                        <>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Documento</TableCell>
                                        <TableCell>Nº benefício</TableCell>
                                        <TableCell>Nº contrato</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.fetchLeadsFileRows.data.map(i => {
                                            return (
                                                <TableRow key={i.id}>
                                                    <TableCell>{i.document}</TableCell>
                                                    <TableCell>{i.benefit}</TableCell>
                                                    <TableCell>{i.loan}</TableCell>
                                                    <TableCell>
                                                        <IconButton style={{ padding: 0, marginRight: 24 }} aria-label="delete" onClick={() => handleShowDetail(i.detail)}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            i.error
                                                                ? <Tooltip title={i.error} arrow>
                                                                    <ErrorIcon style={{ color: "#C00", cursor: 'pointer' }} />
                                                                </Tooltip>
                                                                : <DoneIcon style={{ color: "#0C0" }} />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[8, 32, 100]}
                                component="div"
                                count={data.fetchLeadsFileRows.total}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={(event, newPage) => {
                                    setCurrentPage(newPage);
                                }}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setCurrentPage(0);
                                }}
                            />
                        </>
                    }
                </CardContent>
            </Card>

            <Dialog
                open={detail}
                onClose={() => setDetail(null)}
                scroll="paper"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle>Detalhe</DialogTitle>

                {
                    detail &&
                    <List component="nav">
                        {
                            detail.origin.map((i, index) => (
                                <ListItem>
                                    <ListItemText primary={" Coluna "+(index)+" --> " + i} />
                                </ListItem>
                            ))
                        }
                    </List>
                }

            </Dialog>
        </>
    )

}


import React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { NetworkStatus, useMutation } from "@apollo/client";
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { DELETE_TARGET_AUDIENCE } from "../../../graphql/mutations";
import { FETCH_TARGET_AUDIENCE_PRE_DEFINED } from "../../../graphql/queries";
import { useSnackbar } from "notistack";

export default function List({
  onEdit,
  onDelete,
  dataPreDefined,
  loadingPreDefined,
  errorPreDefined,
  refetchPreDefined,
  networkStatusPreDefined,
  currentPagePreDefined,
  rowsPerPagePreDefined,
  setCurrentPagePreDefined,
  setRowsPerPagePreDefined,
}) {
  // Setup mutation
  const [deleteTargetAudience, { loading: loadingDelete }] = useMutation(
    DELETE_TARGET_AUDIENCE
  );

  if (loadingPreDefined) return <CircularProgress style={{ margin: 12 }} />;
  if (errorPreDefined) return <div>{errorPreDefined}</div>;

  const handleEdit = (item) => {
    var json = JSON.parse(item.filters);

    onEdit({
      id: item.id,
      name: item.name,
      filters: {
        age_group: json.age_group || null,
        gender: json.gender || null,
        locations: json.location_id || null,
        count_loan_range: json.count_loan_range || null,

        benefit_salary_range: json.benefit.salary_range,
        benefit_margin_loan_range: json.benefit?.margin_loan_range || {},
        benefit_banks: json.benefit?.bank_id || null,
        benefit_start_range: json.benefit?.start_range || {},
        benefit_entities: json.benefit?.entity_id || null,
        benefit_species: json.benefit?.specie_id || null,

        loan_banks: json.loan?.bank_id || null,
        loan_types: json.loan?.type_id || null,
        loan_amount_range: json.loan?.amount_range || {},
        loan_portion_amount_range: json.loan?.portion_amount_range || {},
        loan_debit_balance_range: json.loan?.debit_balance_range || {},
        loan_tax_range: json.loan?.tax_range || {},
        loan_portion_count_range: json.loan?.portion_count_range || {},
        loan_portion_paid_count_range:
          json.loan?.portion_paid_count_range || {},
      },
    });
  };

  const handleDelete = (item) => {
    deleteTargetAudience({
      variables: {
        id: item.id,
      },
    }).then(() => {
      onDelete();
    });
  };

  return (
    <>
      <TableContainer>
        {networkStatusPreDefined === NetworkStatus.refetch && (
          <LinearProgress />
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPreDefined.fetchTargetAudiencePreDefined.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <IconButton
                    style={{ padding: 0, marginRight: 24 }}
                    aria-label="edit"
                    onClick={() => handleEdit(row)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ padding: 0 }}
                    aria-label="delete"
                    onClick={() => handleDelete(row)}
                  >
                    {loadingDelete ? (
                      <CircularProgress size={21} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

            {dataPreDefined.fetchTargetAudiencePreDefined.data.total == 0 && (
              <TableRow>
                <TableCell colSpan={2}>Nenhum público cadastrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {dataPreDefined && (
        <LocalProvider locale="ptBR">
          <TablePagination
            showFirstButton
            showLastButton        
            rowsPerPageOptions={[8, 32, 100]}
            component="div"
            count={dataPreDefined.fetchTargetAudiencePreDefined.total}
            rowsPerPage={rowsPerPagePreDefined}
            page={currentPagePreDefined}
            onPageChange={(event, newPage) => {
              setCurrentPagePreDefined(newPage);
            }}
            onRowsPerPageChange={(event) => {
              setRowsPerPagePreDefined(parseInt(event.target.value));
              setCurrentPagePreDefined(0);
            }}
          />
        </LocalProvider>
      )}
    </>
  );
}

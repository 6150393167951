import Flow from "@flowjs/flow.js";
import { useState } from "react";

const chunkSize = 1024 * 1024 * 2; //its 3MB, increase the number measure in mb;

export default function useUploadTargetAudience() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  var flow = new Flow({
    target: process.env.REACT_APP_BACKEND + "/api/upload-target-audience",
    chunkSize: 1024 * 1024 * 10,
    testChunks: false,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });

  if (!flow.support) {
    alert("Browser unsuportted");
    return;
  }

  flow.on("fileProgress", function (file, chunk) {
    setProgress(file.progress());
    setAvgSpeed(file.averageSpeed);
    setTimeRemaining(file.timeRemaining());
    setFile(file);
  });

  flow.on("filesSubmitted", function (file) {
    setLoading(true);
    setFile(file);
  });

  flow.on("error", function (message, file, chunk) {
    setError(message);
    setFile(file);
  });

  return {
    flow: flow,
    progress: progress,
    loading: loading,
    error: error,
    avgSpeed: avgSpeed,
    timeRemaining: timeRemaining,
    uploaded: uploaded,
    upload: function (file, callback) {
      flow.on("fileSuccess", function (file, message, chunk) {
        setLoading(false);
        setFile(file);
        setUploaded(true);
        callback(file);
      });

      setUploaded(false);
      flow.cancel();
      flow.addFile(file);
      flow.resume();
    },
    reset: function () {
      flow.cancel();
      setProgress(0);
      setLoading(false);
      setError(null);
      setAvgSpeed(0);
      setTimeRemaining(0);
      setFile(null);
      setUploaded(false);
    },
  };
}

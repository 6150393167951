import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router";
import { FETCH_NEGOTIATIONS, FETCH_RESCHEDULES } from "../../../graphql/queries";

export default function Negotiations() {

    let history = useHistory();
    const { data, loading } = useQuery(FETCH_NEGOTIATIONS, {
        fetchPolicy: 'network-only',
    });

    const handleClick = (document, lead_id) => {
        history.push("/lead/" + document, {
          document: new URLSearchParams(document).toString(),
          id: new URLSearchParams(lead_id).toString(),
        });
      };

    return (
        <Card>
            <CardHeader title="Em negociação" />
            

                {
                    loading && <CircularProgress style={{ margin: 12, marginTop: -6 }} />
                }

                {
                    data &&
                    <>
                        { 
                            data.fetchNegotiations.length == 0 && 
                            <CardContent> 
                                <Typography variant="body2">Nenhum registro encontrado</Typography>
                            </CardContent>
                        }
                        
                        <div className='scrollbarCustom' style={{ display: 'flex', overflowY: 'hidden', overflowX: 'auto' }}>
                            {
                                data.fetchNegotiations.map(i => {
                                    return (
                                        <>
                                            <CardContent onClick={() => handleClick(i.document, i.lead_id)} style={{ cursor: 'pointer', borderRight: '1px solid #ccc', width: '33.3%' }}>
                                                <Grid container spacing={1}>
                                                    <Grid container item xs={11} spacing={1}>
                                                        <Grid item xs={11}>
                                                            <Typography variant="caption">Cliente</Typography>
                                                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>{i.name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ArrowForwardIosIcon style={{ color: '#666666', cursor: 'pointer' }} />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                }
        </Card>
    );

}


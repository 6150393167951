import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  DialogContent,
  CircularProgress,  
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import InputWithQuerySearch from "../../../../components/InputWithQuerySearch";
import { CREATE_MANUAL_CAMPAIGN_BY_DEBTORS } from "../../../../graphql/mutations";
import { FETCH_SALE_CONSULTANTS, FETCH_SALE_CONSULTANT_GROUPS } from "../../../../graphql/queries";

export default function CreateDialogManual({ open, handleClose, onCreate, filter }) {
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  let history = useHistory();

  const [createManualCampaignByDebtors, { loading } ] = useMutation(CREATE_MANUAL_CAMPAIGN_BY_DEBTORS);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      restrictions: {},
      lead_file_selected: [],
      filters: {},
    },
    onSubmit: (values) => {
      // Chamando a mutation de criação de campanha
      createManualCampaignByDebtors({
        variables: {
          name: values.name,
          keyword: "",
          start: filter.start,
          finish: filter.finish,
          restriction: values.restrictions && {
            sale_consultants:
              values.restrictions.sale_consultants &&
              values.restrictions.sale_consultants.map((i) => i.id),
            sale_consultant_groups:
              values.restrictions.sale_consultant_groups &&
              values.restrictions.sale_consultant_groups.map((i) => i.id),
          },
        },
      }).then(({ data }) => {
        enqueueSnackbar(
          "Campanha Manual criada com sucesso!"
        );
        resetForm();
        if (data.createManualCampaignByDebtors){
          const id = parseInt(data.createManualCampaignByDebtors)
          history.push("/campanhas/manual/" + id);
        }
      });
    },
  });

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          Criar nova campanha manual
        </DialogTitle>

        <DialogContent>
          <TextField
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              value={values.name}
              required
              fullWidth
              id="name"
              label="Nome"
              name="name"
              autoFocus
              error={errors.name && true}
              helperText={errors.name}
          />
          <div style={{ marginBottom: 12 }}>
              <Typography variant="subtitle2" style={{ marginTop: 12 }}>Restrições de acesso</Typography>
              <Typography variant="caption">Selecione abaixo grupos ou consultores que terão acesso aos leads dessa campanha</Typography>
          </div>

          <Grid container>
              <Grid item sm={6} xs={12}>
                  <InputWithQuerySearch
                    title="Consultores"
                    labelBlank="Todos"
                    query={FETCH_SALE_CONSULTANTS}
                    fetchResult="fetchSaleConsultants"
                    value={values.restrictions.sale_consultants}
                    onChange={(value) => setFieldValue('restrictions.sale_consultants', value)}
                  />
              </Grid>
              <Grid item sm={6} xs={12}>
                  <InputWithQuerySearch
                    title="Grupos de consultor"
                    labelBlank="Todos"
                    query={FETCH_SALE_CONSULTANT_GROUPS}
                    fetchResult="fetchSaleConsultantGroups"
                    value={values.restrictions.sale_consultant_groups}
                    onChange={(value) => setFieldValue('restrictions.sale_consultant_groups', value)}
                  />
              </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: "30px", marginBottom: "16px" }}>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading && <CircularProgress size={16} style={{ marginRight: 6 }} />}
            Criar campanha
          </Button>

          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
  );
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useState } from "react";
import SearchInputDebounced from "../../../../../components/SearchInputDebounced";
import { FETCH_TARGET_AUDIENCE_CSV } from "../../../../../graphql/queries";
import { useQuery } from "@apollo/client";

export default function TargetAudienceSavedDialog({ open, onSelect, onClose }) {
  // Search
  const [searchTerm, setSearchTerm] = useState(null);

  // Paginate
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const { loading, error, data, networkStatus } = useQuery(
    FETCH_TARGET_AUDIENCE_CSV,
    {
      variables: {
        keyword: searchTerm,
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>Públicos salvos</DialogTitle>
        <DialogContent>
          <SearchInputDebounced
            typeSearch="inDialog"
            onChange={(value) => {
              setSearchTerm(value);
            }}
          />

          {loading && <CircularProgress style={{ marginTop: 12 }} />}

          {data && data.fetchTargetAudienceCsv && (
            <div
              style={{
                marginTop: 12,
                height: 200,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data.fetchTargetAudienceCsv.total == 0 && (
                <div>Nenhum registro encontrado</div>
              )}

              {data.fetchTargetAudienceCsv.data.map((element) => {
                return (
                  <div
                    onClick={() => onSelect(element)}
                    style={{
                      borderBottom: "1px solid #CCC",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    {element.name}
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

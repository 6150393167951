import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_RELEASE, CREATE_RELEASE_CATEGORY } from "../../../../graphql/mutations";
import { SliderPicker } from "react-color";
import { useSnackbar } from "notistack";
import SelectReleaseCategory from "../../../../components/SelectReleaseCategory";
import SelectReleaseSubCategory from "../../../../components/SelectReleaseSubCategory";
import { applyCurrencyMask, removeCurrencyMask } from "../../../../utils/masks";
import { Grid } from "@mui/material";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { COMPARE_REVENUE_AND_EXPENSE_RELEASES, FETCH_COMPANIES, STATISTICS_BY_TYPE_RELEASES } from "../../../../graphql/queries";
import { useApolloClient } from '@apollo/client';
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";

// ...


export default function CreateDialog({ open, handleClose, onCreate }) {

    const { enqueueSnackbar } = useSnackbar();

    // Setting mutation
    const [createRelease, { loading }] = useMutation(CREATE_RELEASE);

    // Setting Formik
    const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({

        initialValues: {
            categoryId: null,
            subCategoryId: null,
            company: null,
            type: "",
            value: "",
            description: "",
            release_at: null
        },

        onSubmit: async (values) => {

            const {
                company,
                subCategoryId,
                categoryId,
                type,
                value,
                description,
                release_at
            } = values

            if (!categoryId || type === "" || value === "" || !release_at) {
                enqueueSnackbar("Favor preencher todos os campos", {
                    variant: "warning"
                })
                return
            }

            const formattedDate = format(release_at, 'yyyy-MM-dd HH:mm:ss');
            const formatValue = removeCurrencyMask(value)

            try {
                await createRelease({
                    variables: {
                        company_id: company.id,
                        release_subcategory_id: subCategoryId,
                        description,
                        release_category_id: categoryId,
                        value: formatValue,
                        release_at: formattedDate
                    }
                })
                resetForm();
                onCreate();
            } catch (e) {
                enqueueSnackbar(e, {
                    variant: "error"
                })
            }

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">Criar novo lançamento</DialogTitle>

                <DialogContent style={{ paddingBottom: 24 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                value={values.type}
                                label="Origem *"
                                onChange={(e) => setFieldValue("type", e.target.value)}
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value="Receita">Receita</MenuItem>
                                <MenuItem value="Despesa">Despesa</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectReleaseCategory
                                isDisabled={values.type === ""}
                                value={values.categoryId}
                                onChange={(id) => setFieldValue("categoryId", id)}
                                required
                                params={{
                                    type: values.type
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectReleaseSubCategory
                                isDisabled={values.categoryId === null}
                                value={values.subCategoryId}
                                onChange={(id) => setFieldValue("subCategoryId", id)}
                                params={{
                                    category_id: values.categoryId
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectWithQuerySearch
                                title="Empresa"
                                query={FETCH_COMPANIES}
                                fetchResult="fetchCompanies"
                                onChange={(companies) => {
                                    setFieldValue("company", companies);
                                }}
                                fullWidth
                                value={values.company}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Descrição"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.description}
                                onChange={(e) => setFieldValue("description", e.target.value)}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DatePicker
                                inputVariant="outlined"
                                label="Data do lançamento *"
                                format="dd/MM/yyyy"
                                value={values.release_at}
                                fullWidth
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setFieldValue("release_at", date)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Valor *"
                                variant="outlined"
                                name="value"
                                value={values.value}
                                fullWidth
                                onChange={(e) => {
                                    if (e.target.value) {
                                        const formatMoney = applyCurrencyMask(e.target.value)
                                        setFieldValue("value", formatMoney)
                                        return
                                    }
                                    setFieldValue("value", e.target.value)
                                }}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { useEffect } from "react";
import { format } from "date-fns";
import { useQuery } from "@apollo/client";
import { FETCH_CAMPAINGS } from "../../../graphql/queries";
import { NetworkStatus } from "@apollo/client";
import InteractionsProgress from "../../../components/InteractionsProgress";
import {
  CircularProgress,
  LinearProgress,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TablePagination,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import { makeStyles, Link } from "@material-ui/core";

export default function ListManual({
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  onMount,
}) {
  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_CAMPAINGS,
    {
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
        type: "Manual",
      },
    }
  );

  useEffect(() => {
    onMount({ refetch });
  }, []);

  const classes = useStyles();

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Criada em</TableCell>
              <TableCell>Total de leads</TableCell>
              <TableCell>Interações realizadas</TableCell>
              <TableCell>Propostas criadas</TableCell>
              <TableCell>Abertas</TableCell>
              <TableCell>Finalizadas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.fetchCampaings.data.map((row) => {
              const redirect = window.location.href + "/manual/" + row.id;

              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link href={redirect}>{row.name}</Link>
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.created_at), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>
                    {row.total_leads.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    <InteractionsProgress
                      total={row.total_leads}
                      interacted={row.total_interacted}
                      printValue={true}
                    />
                  </TableCell>
                  <TableCell>
                    {(
                      row.total_proposals_opened + row.total_proposals_closed
                    ).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {row.total_proposals_opened.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {row.total_proposals_closed.toLocaleString("pt-BR")}
                  </TableCell>
                </TableRow>
              );
            })}

            {data.fetchCampaings.data.length == 0 && (
              <TableRow>
                <TableCell colSpan={7}>Nenhuma campanha cadastrada</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
          showFirstButton
          showLastButton                
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data.fetchCampaings.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>  
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

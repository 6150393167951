import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_PROFILES, FETCH_SALE_ORIGINS } from "../graphql/queries";

export default function SelectProfile({ value, onChange, style }) {

    const { data, loading } = useQuery(FETCH_PROFILES);

    const profiles = data?.fetchProfiles.data || []

    const handleChange = (id) => {
        if (id === "default") {
            onChange(id)
            return
        }
        const result = profiles.find(profile => profile.id === id)
        onChange(result)
    }

    return (
        <TextField
            select
            value={loading ? "loading" : value?.id}
            disabled={loading}
            label="Perfis"
            onChange={(e) => handleChange(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
        >
            {loading && <MenuItem value="loading">Carregando..</MenuItem>}
            <MenuItem value="default">Nenhum</MenuItem>
            {data &&
                data.fetchProfiles.data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
        </TextField>
    );
}

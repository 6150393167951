import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import SelectWithQuerySearch from "../SelectWithQuerySearch";
import { UPDATE_LEAD_BENEFIT } from "../../graphql/mutations";
import {
  FETCH_BANKS,
  FETCH_ENTITIES,
  FETCH_SPECIES,
} from "../../graphql/queries";
import { useState } from "react";
import { maskMoney } from "../../helpers";

export default function EditDialogBenefitData({
  open,
  handleClose,
  data,
  onEdit,
  id,
}) {
  const { format, parseISO } = require("date-fns");

  const [startDate, setStartDate] = useState(
    data?.start ? parseISO(data?.start) : ""
  );

  const [updateLeadBenefit, { loading }] = useMutation(UPDATE_LEAD_BENEFIT);

  const { handleSubmit, handleChange, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        bank_id: data?.bank?.id ?? null,
        agency: data?.agency ?? "",
        account: data?.account ?? "",
        number: data?.number ?? "",
        start: data?.start ? format(parseISO(data?.start), "yyyy-MM-dd") : "",
        way_of_payment: data?.way_of_payment ?? "",
        entity_id: data?.entity?.id ?? null,
        salary: data?.salary ?? null,
        margin_loan: data?.margin_loan ?? null,
        specie_id: data?.specie?.id ?? null,
      },
      onSubmit: (values) => {
        const floatSalary = parseFloat(
          values.salary.replace(".", "").replace(",", ".")
        );
        const floatMargin = parseFloat(
          values.margin.replace(".", "").replace(",", ".")
        );
        updateLeadBenefit({
          variables: {
            lead_id: id,
            bank_id: values.bank?.id,
            agency: values.agency,
            account: values.account,
            number: values.number,
            start: values.start,
            way_of_payment: values.way_of_payment,
            entity_id: values.entity?.id,
            salary: floatSalary,
            margin_loan: floatMargin,
            specie_id: values.specie?.id,
          },
        }).then(onEdit);
      },
    });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Editar dados do benefício</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Bancos"
              query={FETCH_BANKS}
              fetchResult="fetchBanks"
              onChange={(bank) => {
                setFieldValue("bank", bank);
              }}
              fullWidth
              value={values?.bank}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values?.agency}
              fullWidth
              label="Agência"
              name="agency"
              error={errors?.agency}
              helperText={errors?.agency}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values?.account}
              fullWidth
              label="Conta"
              name="account"
              error={errors?.account}
              helperText={errors?.account}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values?.number}
              fullWidth
              label="Nº Benefício"
              name="number"
              error={errors?.number}
              helperText={errors?.number}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="DIB"
                format="dd/MM/yyyy"
                value={startDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(event) => {
                  if (!isNaN(event.getTime())) {
                    setStartDate(event);
                    setFieldValue("start", format(event, "yyyy-MM-dd"));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values?.way_of_payment}
              fullWidth
              label="Meio de pagamento"
              name="way_of_payment"
              error={errors?.way_of_payment}
              helperText={errors?.way_of_payment}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Entidade"
              query={FETCH_ENTITIES}
              fetchResult="fetchEntities"
              onChange={(entity) => {
                setFieldValue("entity", entity);
              }}
              fullWidth
              value={values?.entity}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              value={values?.salary}
              onChange={(e) =>
                setFieldValue("salary", maskMoney(e.target.value))
              }
              fullWidth
              label="Renda"
              error={errors?.salary && true}
              helperText={errors?.salary}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              value={values?.margin}
              onChange={(e) =>
                setFieldValue("margin", maskMoney(e.target.value))
              }
              fullWidth
              label="Margem"
              error={errors?.margin && true}
              helperText={errors?.margin}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Espécie de Benefício"
              query={FETCH_SPECIES}
              fetchResult="fetchSpecies"
              onChange={(specie) => {
                setFieldValue("specie", specie);
              }}
              fullWidth
              error={errors?.specie && true}
              errorLabel={errors?.specie && "Campo Obrigatório"}
              value={values?.specie}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  createTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { CREATE_TARGET_AUDIENCE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import useUploadTargetAudience from "../../../uploadTargetAudience";
import { useSnackbar } from "notistack";

export default function CreatePublicDialog({
  open,
  handleClose,
  handleUploaded,
  refetch,
}) {
  const style = useStyles();
  const [PublicFileID, setPublicFileID] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [createTargetAudience, createTargetAudienceMutation] = useMutation(
    CREATE_TARGET_AUDIENCE
  );

  // UploadPublicFile
  const { progress, loading, upload, uploaded } = useUploadTargetAudience();

  const handleChangeFile = (event) => {
    upload(event.target.files[0], function (file) {
      createTargetAudience({
        variables: {
          name: file.uniqueIdentifier,
          type: "Lista de clientes",
          filename: file.uniqueIdentifier,
        },
      }).then(({ data }) => {
        setPublicFileID(data.createTargetAudience.id);
        enqueueSnackbar("Arquivo enviado com sucesso!");
        handleClose();
        handleUploaded();
        refetch();
      });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Upload de arquivo</DialogTitle>

      <label for="uploadCamp" className={style.sectionStep02Manual}>
        {!uploaded && !loading && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <BackupIcon className={style.uploadIcon}></BackupIcon>
            <Typography className={style.textManualBase}>
              Selecione um arquivo .csv de acordo com o layout pré-estabelecido
            </Typography>
            <input
              id="uploadCamp"
              multiple={false}
              style={{ display: "none" }}
              type="file"
              accept=".csv"
              onChange={handleChangeFile}
            ></input>
          </div>
        )}

        {loading && !PublicFileID && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <CircularProgress
              variant="determinate"
              value={Math.round(progress * 100)}
              style={{ marginRight: 6 }}
            />
            <br />
            Enviando: {Math.round(progress * 100)}%
          </div>
        )}

        {createTargetAudienceMutation.loading && (
          <div style={{ maxWidth: "320px", padding: "0 2%" }}>
            <CircularProgress />
            <br /> Processando linhas do arquivo ..
          </div>
        )}
      </label>

      <div style={{ margin: 24 }}>
        <a href="/exemploList.csv">Clique aqui</a> para baixar um .csv de
        exemplo
      </div>
    </Dialog>
  );
}

/* <label className={style.checkBox} for={row.id}>{row.name}</label> */

const useStyles = makeStyles((theme) => ({
  /* Base Bruta */

  sectionStep02Gross: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },

  formGroup: {
    width: "400px",
    overflow: "auto",
    maxHeight: "160px",
    display: "flex",
    flexDirection: "column",
    margin: "-30px 16px -65px 0",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      border: "1px solid #ccc",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.4)",
      outline: "0px solid #fff",
    },
    "@media (max-width: 780px)": {
      margin: "50px 0 50px 0",
    },
  },

  checkboxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "-4px 0px",
  },

  checkBox: {
    fontSize: "14px",
    marginLeft: "-170px",
    color: "#8a8a8a",
  },

  checkBoxValue: {
    fontSize: "14px",
    color: "#8a8a8a",
    paddingRight: theme.spacing(2),
  },

  buttonBack: {
    marginLeft: theme.spacing(2),
  },

  totalText: {
    marginTop: theme.spacing(1),
    fontSize: "14px",
  },

  totalValorText: {
    fontWeight: "bold",
    fontSize: "26px",
  },

  labelStyle: {
    fontSize: "50px",
  },

  /* Base Manual */

  sectionStep02Manual: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",
    padding: "12px 4px 16px 4px",
    border: "1px solid #19857b",
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },

  textManualBase: {
    color: "#1c998d",
    fontSize: "13px",
  },

  textManualBase_href: {
    color: "#556cd6",
    fontSize: "13px",
    borderBottom: "1px solid #556cd6",
  },

  uploadIcon: {
    margin: "8px 0",
    color: "#1c998d",
  },

  label: {
    fontSize: "14px",
    color: "#8a8a8a",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#19857b",
    },
  },

  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
        color: "#8a8a8a",
        paddingLeft: "20px",
      },
    },
  },
});

import { useState } from 'react';
import { Container, Card, Typography, CardContent, Fab } from '@material-ui/core';
import Header from '../../../../components/Header';
import { FETCH_ADMINS } from '../../../../graphql/queries';
import { useQuery } from '@apollo/client';
import List from './List';
import CreateDialog from './CreateDialog';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from "notistack";
import EditDialog from './EditDialog';

export default function AdminPage({ history }) {

    const { enqueueSnackbar } = useSnackbar();
    const [showCreate, setShowCreate] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const { loading, error, data, networkStatus, refetch } = useQuery(FETCH_ADMINS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: rowsPerPage,
            page: currentPage + 1
        },
        fetchPolicy: "network-only"
    });

    return (
        <>
            <Header onBack={() => {
                history.goBack()
            }} />

            <Container maxWidth="sm" style={{ marginTop: 86 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h1">Administradores</Typography>
                    </CardContent>

                    <List
                        onDelete={() => {
                            enqueueSnackbar('Administrador excluído com sucesso!');
                            refetch();
                        }}
                        onEdit={(item) => setDataEdit(item)}
                        {...{ loading, error, data, networkStatus, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage }}
                    />

                </Card>
            </Container>

            {
                showCreate &&
                <CreateDialog
                    open={showCreate}
                    handleClose={() => setShowCreate(false)}
                    onCreate={() => {
                        enqueueSnackbar('Administrador cadastrado com sucesso!');
                        refetch();
                        setShowCreate(false);
                    }}
                />
            }

            {
                dataEdit &&
                <EditDialog
                    open={dataEdit ? true : false}
                    handleClose={() => setDataEdit(null)}
                    data={dataEdit}
                    onEdit={() => {
                        enqueueSnackbar('Administrador editado com sucesso!');
                        refetch();
                        setDataEdit(null);
                    }}
                />
            }

            <Fab color="primary" onClick={() => setShowCreate(true)} style={{ position: 'absolute', bottom: 48, right: 48 }}>
                <AddIcon />
            </Fab>
        </>
    )

}
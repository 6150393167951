import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ChatIcon from "@material-ui/icons/Chat";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ListIcon from "@material-ui/icons/List";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useHistory } from "react-router-dom";
import useToken from "../tokenJWT";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import PaidIcon from '@mui/icons-material/Paid';

export default function UserMenu({ handleClickItem }) {
  let history = useHistory();
  const { removeToken, restrictions } = useToken();

  //Restrictions
  const allowProposals = restrictions({ topic: "Propostas", name: "Visualizar" })
  const allowConfig = restrictions({ topic: "Configurações", name: "Visualizar" })
  const allowComunicados = restrictions({ topic: "Comunicados", name: "Visualizar" })
  const allowRelatorios = restrictions({ topic: "Relatórios", name: "Visualizar" })
  const allowGestaoLeads = restrictions({ topic: "Gestão de leads", name: "Visualizar" })
  const allowGestaoLeads_campanha = restrictions({ topic: "Gestão de leads", name: "Campanha" })
  const allowGestaoLeads_baseBruta = restrictions({ topic: "Gestão de leads", name: "Base Bruta" })
  const allowGestaoLeads_publicos = restrictions({ topic: "Gestão de leads", name: "Públicos" })
  const allowAgendamentos = restrictions({ topic: "Agendamentos", name: "Visualizar" })
  const allowIdicacoes = restrictions({ topic: "Indicações", name: "Visualizar" })
  const allowFinanceiro = restrictions({ topic: "Financeiro", name: "Visualizar" })
  const allowInadimplentes = restrictions({ topic: "Inadimplentes", name: "Visualizar" })
  const allowInteracoes = restrictions({ topic: "Interações", name: "Visualizar" })
  const allowPosVenda = restrictions({ topic: "Pós-Venda", name: "Visualizar" })
  const allowCampanhaManual = restrictions({ topic: "Campanha Manual", name: "Visualizar" })
  const allowUnivSim = restrictions({ topic: "Universidade SIM", name: "Visualizar" })
  //

  const [showSubmenu, setShowSubmenu] = useState(false);
  return (
    <>
      <List style={{ color: "#FFF" }}>
        <ListItem
          onClick={() => {
            history.push("/");
            handleClickItem();
          }}
          button={true}
        >
          <ListItemIcon>
            <DashboardIcon style={{ color: "#FFF" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {
          allowProposals &&
          <ListItem
            onClick={() => {
              history.push("/propostas");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <PlaylistAddCheckIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Propostas" />
          </ListItem>
        }

        {
          allowInteracoes &&
          <ListItem
            onClick={() => {
              history.push("/interacoes");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <ChatIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Interações" />
          </ListItem>
        }

        {
          allowInadimplentes &&
          <ListItem
            onClick={() => {
              history.push("/inadimplentes");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <AssignmentIndIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Inadimplentes" />
          </ListItem>
        }

        {
          allowIdicacoes &&
          <ListItem
            onClick={() => {
              history.push("/indicacoes");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <PersonAddAlt1Icon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Indicações" />
          </ListItem>
        }

        {
          allowFinanceiro &&
          <ListItem
            onClick={() => {
              history.push("/financeiro");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <PaidIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Financeiro" />
          </ListItem>
        }

        {
          allowAgendamentos &&
          <ListItem
            onClick={() => {
              history.push("/agendamentos");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <CalendarTodayIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary=" Negociações" />
          </ListItem>
        }

        {
          allowGestaoLeads &&
          <ListItem
            onClick={() => {
              setShowSubmenu(!showSubmenu);
            }}
            button={true}
          >
            <ListItemIcon>
              <GroupIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Gestão de Leads" />
          </ListItem>
        }

        <div style={{ display: showSubmenu ? "block" : "none" }}>
          {
            allowGestaoLeads_campanha &&
            <ListItem
              onClick={() => {
                history.push("/campanhas");
                handleClickItem();
              }}
              button={true}
              style={{ paddingLeft: 72 }}
            >
              <div style={{ fontSize: "10pt" }}>Campanhas</div>
            </ListItem>
          }
          {
            allowGestaoLeads_baseBruta &&
            <ListItem
              onClick={() => {
                history.push("/base-bruta");
                handleClickItem();
              }}
              button={true}
              style={{ paddingLeft: 72 }}
            >
              <div style={{ fontSize: "10pt" }}>Base bruta</div>
            </ListItem>
          }
          {
            allowGestaoLeads_publicos &&
            <ListItem
              onClick={() => {
                history.push("/publicos");
                handleClickItem();
              }}
              button={true}
              style={{ paddingLeft: 72 }}
            >
              <div style={{ fontSize: "10pt" }}>Públicos</div>
            </ListItem>
          }
        </div>

        {
          allowRelatorios &&
          <ListItem
            onClick={() => {
              history.push("/relatorios");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <ListIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
          </ListItem>
        }

        {
          allowComunicados &&
          <ListItem
            onClick={() => {
              history.push("/comunicados");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <CampaignIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Comunicados" />
          </ListItem>
        }

        {/* Sale-Consultant */}

        {
          allowPosVenda &&
          <ListItem
            onClick={() => {
              history.push("/pos-venda");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <RotateRightIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Pós Venda" />
          </ListItem>
        }

        {
          allowCampanhaManual &&
          <ListItem
            onClick={() => {
              history.push("/campanha");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <ContactPhoneIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Campanha Manual" />
          </ListItem>
        }

        {
          allowUnivSim &&
          <ListItem
            onClick={() => {
              history.push("/universidade-sim");
              handleClickItem();
            }}
            button={true}
          >
            <ListItemIcon>
              <MenuBookIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Universidade SIM" />
          </ListItem>
        }

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        {
          allowConfig &&
          <ListItem
            button
            onClick={() => {
              history.push("/configuracoes");
              handleClickItem();
            }}
          >
            <ListItemIcon>
              <SettingsIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText primary="Configurações" />
          </ListItem>
        }

        <ListItem
          button
          onClick={() => {
            removeToken();
            window.location = "/";
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon style={{ color: "#FFF" }} />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </>
  );
}

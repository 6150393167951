import * as React from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation, EventTracker } from "@devexpress/dx-react-chart";
import {
  withStyles,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { FETCH_INTERACTIONS_PERFORMANCE } from "../../../graphql/queries";

export default function InteractionsPerformance() {
  const { loading, error, data } = useQuery(FETCH_INTERACTIONS_PERFORMANCE, {
    fetchPolicy: "network-only",
    variables: {
      period: "week",
      supervisor_id: null,
      company_id: null,
      team_id: null,
      sales_consultant_id: null,
    },
  });

  if (loading) return <CircularProgress style={{ marginTop: 12 }} />;
  if (error) return <div>{error}</div>;

  let point = {
    marginTop: "7px",
    marginRight: "6px",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    background: "#42a5f5",
  };

  return (
    <>
      <Chart data={data.fetchInteractionsPerformance} height={330}>
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries
          name="Interações"
          valueField="interactions"
          argumentField="day"
          color="#ffd700"
        />
        <BarSeries
          name="Reagendadas"
          valueField="scheduled"
          argumentField="day"
          color="#c0c0c0"
        />
        <BarSeries
          name="Sem interesse"
          valueField="no_interest"
          argumentField="day"
          color="#000"
        />
        <BarSeries
          name="Em negociação"
          valueField="in_negotiation"
          argumentField="day"
          color="#00C"
        />
        <BarSeries
          name="Contato inválido"
          valueField="invalid_contact"
          argumentField="day"
          color="#D0021B"
        />
        <BarSeries
          name="Sem perfil"
          valueField="no_profile"
          argumentField="day"
          color="#F08080"
        />
        <BarSeries
          name="Ligação encerrada"
          valueField="call_ended"
          argumentField="day"
          color="#00ff7f"
        />

        <Animation />
        <Stack />
        <EventTracker />
        <Tooltip />
      </Chart>

      <Grid container style={{ marginTop: "25px", paddingLeft: "20px" }}>
        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#ffd700" }}></div>
          <Typography>Interações</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#c0c0c0" }}></div>
          <Typography>Reagendadas</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#000" }}></div>
          <Typography>Sem interesse</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#00C" }}></div>
          <Typography>Em negociação</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#D0021B" }}></div>
          <Typography>Contato inválido</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#F08080" }}></div>
          <Typography>Sem perfil</Typography>
        </Grid>

        <Grid item style={{ display: "flex", marginRight: 24 }}>
          <div style={{ ...point, background: "#00ff7f" }}></div>
          <Typography>Ligação encerrada</Typography>
        </Grid>
      </Grid>
    </>
  );
}
const legendStyles = () => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);

const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

import { format } from "date-fns";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { FETCH_CAMPAINGS } from "../../../graphql/queries";
import { NetworkStatus } from "@apollo/client";
import InteractionsProgress from "../../../components/InteractionsProgress";
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import { makeStyles, Link } from "@material-ui/core";
import useToken from "../../../tokenJWT";
import userEvent from "@testing-library/user-event";

export default function ListManual() {

  const { restrictions } = useToken();

  //Restrictions
  const allowInteraction = restrictions({ topic: "Campanha Manual", name: "Atendimento" })

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { user } = useToken();

  const { loading, error, data, networkStatus } = useQuery(FETCH_CAMPAINGS, {
    variables: {
      limit: rowsPerPage,
      page: currentPage + 1,
      type: "Manual",
      user_id: user.id,
    },
  });

  const classes = useStyles();

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Criada em</TableCell>
              <TableCell>Total de leads</TableCell>
              <TableCell>Interações realizadas</TableCell>
              <TableCell>Propostas criadas</TableCell>
              <TableCell>Abertas</TableCell>
              <TableCell>Finalizadas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.fetchCampaings.data.map((row) => {

              let redirect = window.location.href + "/campanha"
              if(allowInteraction) {
                redirect = window.location.href + "/leads/" + row.id;
              }

              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link href={redirect}>{row.name}</Link>
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.created_at), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>
                    {row.total_leads.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    <InteractionsProgress
                      total={row.total_leads}
                      interacted={row.total_interacted}
                      printValue={true}
                    />
                  </TableCell>
                  <TableCell>
                    {(
                      row.total_proposals_opened + row.total_proposals_closed
                    ).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {row.total_proposals_opened.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {row.total_proposals_closed.toLocaleString("pt-BR")}
                  </TableCell>
                </TableRow>
              );
            })}

            {data.fetchCampaings.data.length == 0 && (
              <TableRow>
                <TableCell colSpan={7}>Nenhuma campanha cadastrada</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
          showFirstButton
          showLastButton                
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data.fetchCampaings.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

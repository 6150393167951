import { useState } from "react";
import {
  makeStyles,
  Typography,
  createTheme,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@material-ui/core";
import { FETCH_LEADS_FILES } from "../../../../../graphql/queries";
import SelectFilterModalUF from "../../../../../components/SelectFilterModalUF";
import { useQuery } from "@apollo/client";

export default function GrossBase(props) {
  const style = useStyles();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [selectAll, setSelectAll] = useState(false);
  const [newValue, setNewValue] = useState([]);
  const [UF, setUF] = useState([]);

  const { loading, data } = useQuery(FETCH_LEADS_FILES, {
    variables: {
      status: "Processado",
      limit: rowsPerPage,
      page: currentPage + 1,
      ufs: newValue,
    },
  });

  const clickCheckbox = (event, element) => {
    let clone = [...props.values.lead_file_selected];

    if (event.target.checked) {
      clone.push(parseInt(event.target.value));
      props.setFieldValue(
        "count_leads",
        props.values.count_leads + element.processed_leads
      );
    } else {
      let index = clone.findIndex((e) => e === parseInt(event.target.value));
      clone.splice(index, 1);
      props.setFieldValue(
        "count_leads",
        props.values.count_leads - element.processed_leads
      );
    }

    props.setFieldValue("lead_file_selected", clone);
  };

  return (
    <section className={style.sectionStep02Gross}>
      <div>
        <Typography className={style.totalText}>Total</Typography>
        <Typography className={style.totalValorText}>
          {props.values.count_leads}
        </Typography>

        <Button
          onClick={props.onNext}
          color="primary"
          variant="contained"
          disabled={props.values.count_leads == 0}
        >
          Avançar
        </Button>
        <Button
          onClick={props.onBack}
          color="primary"
          style={{ marginLeft: 12 }}
          variant="text"
        >
          Voltar
        </Button>
      </div>
      <Box
        sx={{
          "& .MuiOutlinedInput-input": { padding: "8px" },
          width: 150,
          padding: 0,
        }}
        item
        style={{
          position: "absolute",
          right: "5%",
          top: "41%",
          backgroundColor: "white",
          paddingRight: "10px",
        }}
      >
        <SelectFilterModalUF
          newValue={newValue}
          setNewValue={setNewValue}
          title="UF's"
          fullWidth
          onChange={(value) => {
            setNewValue(value);
          }}
        />
      </Box>

      <div>
        {data && data.fetchLeadsFiles.total > 0 && (
          <div style={{ marginLeft: "12px", display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={(event) => {
                    setSelectAll(event.target.checked);
                    props.setFieldValue("lead_file_selected", []);
                    props.setFieldValue("count_leads", 0);

                    if (event.target.checked) {
                      props.setFieldValue(
                        "lead_file_selected",
                        data.fetchLeadsFiles.data.map((element) => element.id)
                      );
                      props.setFieldValue(
                        "count_leads",
                        data.fetchLeadsFiles.data.reduce(
                          (acc, element) => acc + element.processed_leads,
                          0
                        )
                      );
                    }
                  }}
                  value={selectAll}
                />
              }
              label={[
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Selecionar todos</div>
                </div>,
              ]}
            />
          </div>
        )}
        <div className={style.formGroup}>
          {loading && (
            <div>
              <CircularProgress />
              <div>Carregando bases .. </div>
            </div>
          )}
          {data && data.fetchLeadsFiles.total == 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #CCC",
                padding: 12,
                marginTop: 40,
              }}
            >
              <Typography style={{ fontSize: "0.9rem" }}>
                Nenhum arquivo encontrado para as UFs selecionadas.
              </Typography>
            </div>
          )}

          {data &&
            data.fetchLeadsFiles.data.map((element) => {
              var checkBox = props.values.lead_file_selected.findIndex(
                (i) => parseInt(i) === element.id
              );

              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography>
                        {element.name}{" "}
                        <i
                          style={{ fontSize: 12, marginLeft: 24 }}
                        >{` (${element.processed_leads} leads)`}</i>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={checkBox == -1 ? false : true}
                        onChange={(event) => clickCheckbox(event, element)}
                        value={element.id}
                        color="primary"
                        size="medium"
                        id={element.id}
                        name={element.id}
                      />
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  /* Base Bruta */

  sectionStep02Gross: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },

  formGroup: {
    width: "400px",
    overflow: "auto",
    height: "160px",
    border: "1px solid #CCC",
    padding: 12,
    display: "flex",
    flexDirection: "column",
    margin: "0px 16px -65px 0",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      border: "1px solid #ccc",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.4)",
      outline: "0px solid #fff",
    },
    "@media (max-width: 780px)": {
      margin: "50px 0 50px 0",
    },
  },

  checkboxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "-4px 0px",
  },

  checkBox: {
    fontSize: "14px",
    marginLeft: "-170px",
    color: "#8a8a8a",
  },

  checkBoxValue: {
    fontSize: "14px",
    color: "#8a8a8a",
    paddingRight: theme.spacing(2),
  },

  buttonBack: {
    marginLeft: theme.spacing(2),
  },

  totalText: {
    marginTop: theme.spacing(1),
    fontSize: "14px",
  },

  totalValorText: {
    fontWeight: "bold",
    fontSize: "26px",
    marginBottom: 12,
  },

  labelStyle: {
    fontSize: "50px",
  },

  /* Base Manual */

  sectionStep02Manual: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "16px 0px",
    padding: "12px 4px 16px 4px",
    border: "1px solid #19857b",
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },

  textManualBase: {
    color: "#1c998d",
    fontSize: "13px",
  },

  textManualBase_href: {
    color: "#556cd6",
    fontSize: "13px",
    borderBottom: "1px solid #556cd6",
  },

  uploadIcon: {
    margin: "8px 0",
    color: "#1c998d",
  },

  label: {
    fontSize: "14px",
    color: "#8a8a8a",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#19857b",
    },
  },

  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
        color: "#8a8a8a",
        paddingLeft: "20px",
      },
    },
  },
});

import React, { useMemo } from "react";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material";

const LocalProvider = ({
  children,
  locale,
}) => {
  const theme = useMemo(
    () => createTheme(locales[locale]),
    [locale],
  );

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default LocalProvider;

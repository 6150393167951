import {
  Card,
  CardContent,
  Container,
  Fab,
  Typography,
} from "@material-ui/core";
import List from "./List";
import Header from "../../../components/Header";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";
import CreateDialog from "./CreateDialog";

export default function GrossBasePage() {
  const [showCreate, setShowCreate] = useState(false);
  return (
    <>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <Card style={{ marginTop: 12, height: "100%" }}>
          <CardContent>
            <Typography variant="h5" component="h1">
              Bases brutas
            </Typography>
          </CardContent>

          <List />
        </Card>
      </Container>

      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        handleUploaded={() => {
          setShowCreate(false);
          window.location.reload();
        }}
      />

      <Fab
        color="primary"
        onClick={() => setShowCreate(true)}
        style={{ position: "fixed", bottom: 48, right: 48 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

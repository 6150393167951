import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import ModalFilterCsv from "./ModalFilterCsv";

export function ExportCSV({ filter }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        startIcon={<DownloadIcon />}
        onClick={() => setIsOpen(true)}
        style={{marginRight: 8}}
      >
        Exportar
      </Button>

      {isOpen && (
        <ModalFilterCsv isOpen={isOpen} setIsOpen={setIsOpen} filter={filter} />
      )}
    </>
  );
}

import { Box } from "@material-ui/core";
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

function FieldOptionButton({
	label,
	name,
	options,
	index,
	readonly,
}) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12} marginTop={2}>
			<FormControl>
				<FormLabel style={{fontSize: "1.25rem", fontWeight: 500, color: "black"}} id={label}>{label}</FormLabel>
				<RadioGroup row aria-labelledby={label} name={name}>
					<Box paddingX={1} paddingY={2}>
						{options.map((item) => (
							<Controller
								key={item.value}
								name={`groups.${index}.fields.${name}`}
								control={control}
								render={(inputProps) => (
									<FormControlLabel
										value={item.value}
										control={
											<Radio
												value={item.value}
												readOnly={readonly}
												onChange={() => inputProps.field.onChange(item.value)}
											/>
										}
										label={item.label}
									/>
								)}
							/>
						))}
					</Box>
				</RadioGroup>
			</FormControl>
		</Grid>
	);
}

export default FieldOptionButton;
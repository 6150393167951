import { useState } from "react";
import {
  Card,
  Grid,
  Typography,
  makeStyles,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import InteractionsProgress from "../../../components/InteractionsProgress";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/client";
import { UPLOAD_CAMPAING_LEAD_PHONES } from "../../../graphql/mutations";
import { DETAIL_CAMPAING } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import DeleteCampaing from "./DeleteCampaing";
import RestoreCampaing from "./RestoreCampaing";

export default function DataMetric({
  onUploadedFile,
  data: { id, total_leads, total_interacted, onDelete },
}) {
  const [file, setFile] = useState();
  const [showDelete, setShowDelete] = useState();
  const [showRestore, setShowRestore] = useState();

  const classes = useStyles();

  const percentField = ((total_interacted * 100) / total_leads).toFixed(2);

  const [uploadCampaingLeadPhones, mutation, refetch] = useMutation(
    UPLOAD_CAMPAING_LEAD_PHONES
  );

  const { data } = useQuery(DETAIL_CAMPAING, {
    variables: {
      id: parseInt(id),
    },
  });

  const handleSentFilePhones = () => {
    uploadCampaingLeadPhones({
      variables: {
        campaing_id: id,
        file: file,
      },
    }).then(() => {
      onUploadedFile();
    });
  };

  return (
    <>
      <Card>
        <CardHeader title="Interações" />
        <CardContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="caption">Leads:</Typography>
              <Typography variant="h5">{total_leads}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Interações:</Typography>
              <Typography variant="h5">{total_interacted}</Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InteractionsProgress
                total={total_leads}
                interacted={total_interacted}
                printValue={false}
              />
              <Typography variant="caption">({percentField}%)</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        href={
          process.env.REACT_APP_BACKEND +
          "/api/download-lead-campaing-file/" +
          id
        }
        variant="outlined"
        color="primary"
        fullWidth
        style={{ marginTop: 12 }}
      >
        <CloudDownloadIcon style={{ marginRight: 12 }} />
        Baixar .csv Fastway
      </Button>
      <Typography style={{ marginTop: 24 }}>
        Higenização de telefones:
      </Typography>
      <Typography variant="caption">
        Selecione abaixo um arquivo .csv com os telefones para atualizar os
        números de contato dos leads dessa campanha
      </Typography>
      <input
        style={{ margin: "12px 0px" }}
        onChange={(e) => setFile(e.target.files[0])}
        type="file"
      />
      <Button
        disabled={!file || mutation.loading}
        variant="outlined"
        color="primary"
        fullWidth
        style={{ marginTop: 12 }}
        onClick={handleSentFilePhones}
      >
        {mutation.loading ? (
          <CircularProgress size={18} style={{ marginRight: 12 }} />
        ) : (
          <CloudUploadIcon style={{ marginRight: 12 }} />
        )}
        Enviar .csv
      </Button>
      <div style={{ margin: 24 }}>
        <a href="/exemplo_phones.csv">Clique aqui</a> para baixar um .csv de
        exemplo
      </div>
      <Button
        disabled={data.detailCampaing.deleted}
        onClick={() => setShowDelete(true)}
        variant="outlined"
        className={classes.deleteButton}
        fullWidth
        style={{ marginTop: 12 }}
      >
        Excluir Campanha
      </Button>
      <DeleteCampaing
        id={id}
        open={showDelete ? true : false}
        handleClose={() => setShowDelete(null)}
        data={showDelete}
      />

      <Button
        disabled={data.detailCampaing.deleted === false}
        onClick={() => setShowRestore(true)}
        variant="outlined"
        className={classes.RestoreButton}
        fullWidth
        style={{ marginTop: 12 }}
      >
        Restaurar Campanha
      </Button>
      <RestoreCampaing
        id={id}
        open={showRestore ? true : false}
        handleClose={() => setShowRestore(null)}
        data={showRestore}
      />
    </>
  );
}

const useStyles = makeStyles({
  dataField: {
    fontSize: "14px",
  },

  dataFieldResult: {
    fontSize: "20px",
    marginRight: "4px",
  },
  deleteButton: {
    color: "#C00",
    borderColor: "#C00",
  },
  RestoreButton: {
    color: "#68bc4f",
    borderColor: "#68bc4f",
  },
});

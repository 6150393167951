import { useState } from "react";
import {
  Card,
  LinearProgress,
  Typography,
  CardHeader,
  CardContent,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";
import { NetworkStatus } from "@apollo/client";
import { formatMoneyBRL, maskMoney } from "../../../helpers";
import AddIcon from "@material-ui/icons/Add";
import DialogDetail from "./DialogDetails";
import PlaceholderBank from "../../../assets/placeholder_bank.png";

export default function LinkedLoans({ data }) {
  const [open, setOpen] = useState(false);
  const [singleData, setSingleData] = useState(data);

  return (
    <>
      <Card style={{ marginTop: 16, padding: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>EMPRESTIMOS CONSIGNADOS</b>
        </Typography>

        {data.length == 0 && (
          <CardContent>
            <Typography>Nenhum registro encontrado</Typography>
          </CardContent>
        )}

        {data.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Banco</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Parcela</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <>
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.bank && row.bank.name ? row.bank.name : "-"}
                      </TableCell>
                      <TableCell>{row.type || "-"}</TableCell>
                      <TableCell>
                        {row.portion_amount
                          ? formatMoneyBRL(row.portion_amount)
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          // startIcon={<AddIcon />}
                          onClick={() => {
                            setOpen(true);
                            setSingleData(row);
                          }}
                        >
                          Detalhe
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <DialogDetail
          open={open}
          onClose={() => setOpen(false)}
          data={singleData}
          placeholderBank={PlaceholderBank}
        />
      </Card>
    </>
  );
}
import { useHistory } from "react-router";
import { useCallback, useState } from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import ComponentsDynamicForm from "../../../../../components/DynamicForm/ComponentsDynamicForm";
import { Title } from "../../../../../components/Title";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Item } from "../../../../../components/Paper";
import GroupsDynamicForm from "../../../../../components/DynamicForm/GroupsDynamicForm";
import { SaveFabButton } from "../../../../../components/SaveFabButton";
import { VisualizationFabButton } from "../../../../../components/VisualizationFabButton";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext } from "react-beautiful-dnd";
import componentsDinamycForm from "../../../../../utils/fieldsDynamicForm";
import { useMutation } from "@apollo/client";
import { CREATE_DYNAMIC_FORM } from "../../../../../graphql/mutations";
import { useSnackbar } from "notistack";
import InputFormName from "./InputFormName";
import { ModalVisualization } from "../../../../../components/DynamicForm/Modal/VisualizationForm";
import { removeSpecialCharacters } from "../../../../../utils/removeSpecialCharacters";
import Header from "../../../../../components/Header";

export default function DynamicForm() {
  const history = useHistory();

  const [isOpenModalVisualization, setIsOpenModalVisualization] =
    useState(false);
  const [createDynamicForm, { loading }] = useMutation(CREATE_DYNAMIC_FORM);
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues: {
      groups: [
        {
          id: uuidv4(),
          name: "Grupo 1",
          components: [],
        },
      ],
      formName: "",
    },
  });

  const { handleSubmit, control, setValue, getValues } = methods;
  const { fields, update } = useFieldArray({
    control,
    name: "groups",
  });

  const formatedNameComponent = (name) => {
    const nameSplited = name.split(" ");
    if (nameSplited.length > 1) {
      return (
        removeSpecialCharacters(nameSplited[0]).toLowerCase() +
        "." +
        removeSpecialCharacters(nameSplited[1]).toLowerCase()
      );
    } else {
      return name.replace(/\s/g, ".").toLowerCase();
    }
  };

  async function onSubmit(data) {
    const dataFormated = {
      name: data.formName,
      input: JSON.stringify(data.groups[0].components.map((component, index) => ({
        name: formatedNameComponent(component.name) + index,
        type: component.type,
        label: component.name,
        placeholder: component.name,
        required: component.isRequired,
        options:
          (component.options &&
            component.options.map((option) => ({
              id: option.label ?? option.id,
              name: option.label,
            }))) ||
          null,
      }))),
    };

    try {
      await createDynamicForm({
        variables: dataFormated,
      });
      enqueueSnackbar("Formulário registrado com sucesso", {
        variant: "success",
      });
      history.push("/configuracoes/formularios");
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  }

  function onRequestOpenModalVisualization() {
    setIsOpenModalVisualization(true);
  }

  function onRequestCloseModalVisualization() {
    setIsOpenModalVisualization(false);
  }

  const onDragEnd = (result) => {
    const { destination, source } = result;

    const findIndexGroup = fields.findIndex((field) => {
      return field.id === destination.droppableId;
    });

    const findComponent = componentsDinamycForm.find((component) => {
      return component.label === source.droppableId;
    });

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const values = getValues("groups");

    const concatComponents = fields.map((field) => {
      if (field.id === destination.droppableId) {
        return {
          ...field,
          name: values[findIndexGroup].name,
          components: [
            ...field.components,
            {
              id: uuidv4(),
              name: findComponent?.label,
              type: findComponent?.type,
              component: findComponent?.component,
              isEditable: findComponent?.isEditable,
              isRequired: findComponent?.isRequired,
              isOption: findComponent?.isOption,
              options: findComponent?.options,
            },
          ],
        };
      }
      return field;
    });

    setValue("groups", concatComponents);
  };

  const handleRemoveComponent = useCallback(
    (id) => {
      const values = getValues("groups");

      const newComponents = values.map((value) => {
        return {
          ...value,
          components: value.components.filter((component) => {
            return component.id !== id;
          }),
        };
      });
      setValue("groups", newComponents);
    },
    [fields]
  );

  const handleEditComponent = useCallback(
    (indexGroup, indexComponent, componentEdited) => {
      const findIndexField = fields.findIndex(
        (field) => field.id === fields[indexGroup].id
      );
      const findIndexComponent = fields[indexGroup].components.findIndex(
        (component) =>
          component.id === fields[indexGroup].components[indexComponent].id
      );
      const values = getValues("groups");

      update(findIndexField, {
        ...fields[findIndexField],
        name: values[findIndexField].name,
        components: fields[indexGroup].components.map((component, index) => {
          if (index === findIndexComponent) {
            return componentEdited;
          }
          return component;
        }),
      });
    },
    [fields]
  );

  return (
    <FormProvider {...methods}>
      <Header
        title="Formulário"
        onBack={() => {
          history.goBack();
        }}
      />

      <Container style={{ marginTop: 86 }}>
        <Box sx={{ flexGrow: 1 }} marginY="1rem">
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} rowGap={2}>
                <Item>
                  <Stack spacing={4}>
                    <Box>
                      <Title
                        title="Dados do formulário"
                        marginBottom={4}
                        fontSize="1.4rem"
                      />
                      <InputFormName />
                    </Box>
                    <ComponentsDynamicForm />
                  </Stack>
                </Item>
              </Grid>

              <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                  {fields.map((field, index) => (
                    <Item key={field.id}>
                      <GroupsDynamicForm
                        index={index}
                        group={field}
                        handleRemoveComponent={(id) =>
                          handleRemoveComponent(id)
                        }
                        handleEditComponent={(
                          indexGroup,
                          indexComponent,
                          component
                        ) =>
                          handleEditComponent(
                            indexGroup,
                            indexComponent,
                            component
                          )
                        }
                      />
                    </Item>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </DragDropContext>
        </Box>
        <VisualizationFabButton
          onClick={() => onRequestOpenModalVisualization()}
        />
        <SaveFabButton onClick={handleSubmit(onSubmit)} isLoading={loading} />
      </Container>
      <ModalVisualization
        open={isOpenModalVisualization}
        handleClose={() => onRequestCloseModalVisualization()}
        buttonText="Fechar"
      />
    </FormProvider>
  );
}
import { useFormik } from "formik";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import useToken from "../../../tokenJWT";
import { useMutation } from "@apollo/client";
import { CREATE_DEBIT } from "../../../graphql/mutations";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { maskMoney, unmaskMoney } from "../../../helpers";

export default function DialogDebtors({
  lead_id,
  open,
  handleClose,
  onDebit,
}) {
  const { user } = useToken();

  const [createDebit, { loading }] = useMutation(CREATE_DEBIT);

  const { enqueueSnackbar } = useSnackbar();

  const { format } = require("date-fns");

  const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({
    initialValues: { from_date: null, amount: null },
    validationSchema: Yup.object().shape({
      from_date: Yup.string().required("Campo obrigatório").nullable(),
      amount: Yup.string().required("Campo obrigatório").nullable(),
    }),
    onSubmit: (values) => {
      createDebit({
        variables: {
          lead_id: lead_id,
          from_date: format(values.from_date, "yyyy-MM-dd"),
          amount: unmaskMoney(values.amount),
        },
      })
        .then(() => {
          resetForm();
          onDebit();
        })
        .catch((error) => {
          user &&
            user.is_sale_consultant &&
            enqueueSnackbar("Preencher mínimo de 10 caracteres!", {
              variant: "error",
            });
        });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Inadimplente</DialogTitle>

      <DialogContent style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                disableFuture
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                fullWidth
                label="Data"
                value={values.from_date}
                onChange={(event) => {
                  setFieldValue("from_date", event);
                }}
                error={errors.date}
                helperText={errors.date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Valor"
              value={values.amount}
              name="amount"
              onChange={(e) =>
                setFieldValue("amount", maskMoney(e.target.value))
              }
              fullWidth
              error={errors.amount}
              helperText={errors.amount}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import gql from "graphql-tag";

export const SEARCH = gql`
  query ($keyword: String) {
    search(keyword: $keyword) {
      leads {
        id
        document
        name
        email
        phone
      }
      proposals {
        code
        customer {
          document
          name
          birthdate {
            formated
            iso8601
          }
          email
          phone
          phone2
          cellphone
          income
          phone_reference {
            id
            name
            phone
            kinship
          }
        }
      }
    }
  }
`;

export const SEARCH_LEAD = gql`
  query ($document: String, $id: Int) {
    searchLead(document: $document, id: $id) {
      id
      document
      name
      email
      phone
      created_at
      }
  }
`;

export const FETCH_INTERACTIONS_PERFORMANCE = gql`
  query (
    $start: String
    $finish: String
    $sales_consultant_id: Int
    $team_id: Int
    $supervisor_id: Int
    $product_ids:[Int]
    $company_ids: [Int]
    $company_id: Int
  ) {
    fetchInteractionsPerformance(
      start: $start
      finish: $finish
      supervisor_id: $supervisor_id
      sales_consultant_id: $sales_consultant_id
      team_id: $team_id
      product_ids: $product_ids
      company_ids: $company_ids
      company_id: $company_id
    ) {
      day
      interactions
      scheduled
      no_interest
      open_proposals
      in_negotiation
      invalid_contact
      no_profile
      call_ended
    }
  }
`;

export const FETCH_INTERACTIONS_STATISTICS = gql`
  query (
    $start: String
    $finish: String
    $sale_consultant_id: Int
    $sale_origin_id: Int
    $opportunity_id: Int
    $interest_level: String
    $status: String
    $entity_id: Int
    $team_id: Int
    $reasons: [String]
    $tag_ids: [Int]
  ) {
    fetchInteractionsStatistics(
      start: $start
      finish: $finish
      sale_consultant_id: $sale_consultant_id
      sale_origin_id: $sale_origin_id
      opportunity_id: $opportunity_id
      interest_level: $interest_level
      status: $status
      entity_id: $entity_id
      team_id: $team_id
      reasons: $reasons
      tag_ids: $tag_ids
    ) {
      total
      status {
        name
        count
      }
    }
  }
`;

export const FETCH_INTERACTIONS = gql`
  query (
    $start: String
    $finish: String
    $status: String
    $opportunity_id: Int
    $interest_level: String
    $page: Int
    $limit: Int
    $sale_consultant_id: Int
    $entity_id: Int
    $sale_origin_id: Int
    $reasons: [String]
    $team_id: Int
    $tag_ids: [Int]
  ) {
    fetchInteractions(
      start: $start
      finish: $finish
      opportunity_id: $opportunity_id
      interest_level: $interest_level
      limit: $limit
      page: $page
      status: $status
      sale_consultant_id: $sale_consultant_id
      sale_origin_id: $sale_origin_id
      entity_id: $entity_id
      reasons: $reasons
      team_id: $team_id
      tag_ids: $tag_ids
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        customer
        team
        document
        sale_consultant
        description
        temperature
        status
        created_at
        opportunity
        sale_origin
        entity
        lead_id
        tags {
          id
          name
          color
          type
          required
        }
      }
    }
  }
`;

export const FETCH_INDICATIONS = gql`
  query (
    $start: String
    $finish: String
    $user_id: Int
    $lead_id: Int
    $product_id: Int
    $opportunity_id: Int
    $page: Int
    $limit: Int
  ) {
    fetchIndications(
      start: $start
      finish: $finish
      user_id: $user_id
      lead_id: $lead_id
      product_id: $product_id
      opportunity_id: $opportunity_id
      limit: $limit
      page: $page
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        user {
          id
          name
        }
        lead {
          id
          document
          name
          phones {
            id
            number
            is_valid
            is_whatsapp
            verified_at
          }
        }
        product {
          id
          name
        }
        opportunity {
          id
          name
        }
        created_at
      }
    }
  }
`;

export const FETCH_CAMPAINGS = gql`
  query (
    $limit: Int
    $page: Int
    $all: Boolean
    $status: String
    $keyword: String
    $type: String
    $user_id: Int
  ) {
    fetchCampaings(
      limit: $limit
      page: $page
      all: $all
      status: $status
      keyword: $keyword
      type: $type
      user_id: $user_id
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        type
        total_leads
        total_interacted
        total_proposals_opened
        total_proposals_closed
        status
        created_at
      }
    }
  }
`;

export const FETCH_PROPOSALS = gql`
  query (
    $start: String
    $finish: String
    $status: String
    $company_id: Int
    $team_id: Int
    $manager_id: Int
    $supervisor_id: Int
    $sale_consultant_id: Int
    $product_id: Int
    $proposal_situation_id: [Int]
    $inputs: [FetchProposalsInputs]
    $page: Int
    $limit: Int
    $lated: Boolean
    $installments_number: Int
    $sale_origin: String
    $specie_id: Int
  ) {
    fetchProposals(
      start: $start
      finish: $finish
      status: $status
      company_id: $company_id
      team_id: $team_id
      manager_id: $manager_id
      supervisor_id: $supervisor_id
      sale_consultant_id: $sale_consultant_id
      product_id: $product_id
      proposal_situation_id: $proposal_situation_id
      inputs: $inputs
      limit: $limit
      page: $page
      lated: $lated
      installments_number: $installments_number
      sale_origin: $sale_origin
      specie_id: $specie_id
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        code
        status
        situation
        after_sale_first_column
        after_sale_second_column
        after_sale_third_column
        after_sale_fourth_column
        proposal_number
        negotiated_amount
        released_amount
        product
        customer {
          id
          name
          document
        }
        date {
          formated
          days_ago
        }
        sale_consultant {
          id
          name
        }
        lead {
         id
         name
         document 
        }
      }
    }
  }
`;

export const FETCH_PROPOSALS_STATISTICS = gql`
  query (
    $start: String
    $finish: String
    $status: String
    $company_ids: [Int]
    $team_id: Int
    $manager_id: Int
    $supervisor_id: Int
    $sale_consultant_id: Int
    $product_ids: [Int]
    $proposal_situation_id: [Int]
    $installments_number: Int
    $sale_origin: String
    $specie_id: Int
    $inputs: [FetchProposalsStatisticsInputs]
    $company_id: Int
  ) {
    fetchProposalStatistics(
      start: $start
      finish: $finish
      status: $status
      company_ids: $company_ids
      team_id: $team_id
      manager_id: $manager_id
      supervisor_id: $supervisor_id
      sale_consultant_id: $sale_consultant_id
      product_ids: $product_ids
      proposal_situation_id: $proposal_situation_id
      installments_number: $installments_number
      sale_origin: $sale_origin
      specie_id: $specie_id
      inputs: $inputs
      company_id: $company_id
    ) {
      opened
      closed
      situations {
        name
        count
      }
    }
  }
`;

export const FETCH_CUSTOMERS = gql`
  query ($limit: Int, $page: Int) {
    fetchCustomers(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        name
        document
      }
    }
  }
`;

export const FETCH_OPPORTUNITIES = gql`
  query ($lead_id: Int!, $campaign_id: Int) {
    fetchOpportunities(lead_id: $lead_id, campaign_id: $campaign_id) {
      id
      name
      habilited
      checked
    }
  }
`;

export const FETCH_ADMINS = gql`
  query ($limit: Int, $page: Int) {
    fetchAdmins(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        email
        restrictions {
          id 
          topic
          name
        }
      }
    }
  }
`;

export const FETCH_MANAGERS = gql`
  query ($limit: Int, $page: Int) {
    fetchManagers(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        email
        company {
          id
          name
        }
        restrictions {
          id
          topic
          name
        }
      }
    }
  }
`;

export const FETCH_SUPERVISORS = gql`
  query ($limit: Int, $page: Int) {
    fetchSupervisors(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        email
        manager {
          id
          user {
            name
          }
        }
        restrictions {
          id
          topic
          name
        }
      }
    }
  }
`;

export const FETCH_TEAMS = gql`
  query ($limit: Int, $page: Int, $keyword: String, $all: Boolean) {
    fetchTeams(limit: $limit, page: $page, keyword: $keyword, all: $all) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        supervisor {
          id
          user {
            name
          }
        }
      }
    }
  }
`;

export const FETCH_COMPANIES = gql`
  query ($limit: Int, $page: Int) {
    fetchCompanies(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_SALE_CHANNELS = gql`
  query ($limit: Int, $page: Int) {
    fetchSaleChannels(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_SALE_ORIGINS = gql`
  query ($limit: Int, $page: Int) {
    fetchSaleOrigins(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_COMMON_FILES = gql`
  query ($limit: Int, $page: Int) {
    fetchCommonFile(limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        path
        theme
      }
    }
  }
`;

export const FETCH_REASONS = gql`
  query ($keyword: String, $all: Boolean, $limit: Int, $page: Int) {
    fetchReasons(keyword: $keyword, all: $all, limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        type
      }
    }
  }
`;

export const FETCH_PRODUCTS = gql`
  query ($keyword: String, $all: Boolean, $dynamic_form_id: Int, $page: Int, $limit: Int) {
    fetchProducts(keyword: $keyword, all: $all, dynamic_form_id: $dynamic_form_id, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        dynamic_form {
          id
          name
          input
        }
      }
    }
  }
`;

export const FETCH_PROPOSAL_SITUATIONS = gql`
  query ($limit: Int, $page: Int, $all: Boolean) {
    fetchProposalSituations(limit: $limit, page: $page, all: $all) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_SALE_CONSULTANTS = gql`
  query (
    $keyword: String
    $team_id: Int
    $limit: Int
    $page: Int
    $all: Boolean
  ) {
    fetchSaleConsultants(
      keyword: $keyword
      team_id: $team_id
      limit: $limit
      page: $page
      all: $all
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        email
        sale_consultant_group {
          id
          name
        }
        team {
          id
          name
        }
        sale_channel {
          id
          name
        }
        restrictions {
          id
          topic
          name
        }
      }
    }
  }
`;

export const FETCH_LOCATIONS = gql`
  query ($page: Int, $limit: Int, $keyword: String, $only_cities: Boolean) {
    fetchLocations(
      page: $page
      limit: $limit
      keyword: $keyword
      only_cities: $only_cities
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        state
        is_city
      }
    }
  }
`;

export const FETCH_BANKS = gql`
  query ($page: Int, $limit: Int, $keyword: String) {
    fetchBanks(page: $page, limit: $limit, keyword: $keyword) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_TYPES = gql`
  query ($page: Int, $limit: Int, $keyword: String) {
    fetchTypes(page: $page, limit: $limit, keyword: $keyword) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_ENTITIES = gql`
  query ($page: Int, $limit: Int, $keyword: String) {
    fetchEntities(page: $page, limit: $limit, keyword: $keyword) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_SPECIES = gql`
  query ($page: Int, $limit: Int, $keyword: String) {
    fetchSpecies(page: $page, limit: $limit, keyword: $keyword) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_RESCHEDULES = gql`
  query (
    $start: String
    $finish: String
    $sale_consultant_id: Int
    $page: Int
    $limit: Int
  ) {
    fetchReschedules(
      start: $start
      finish: $finish
      sale_consultant_id: $sale_consultant_id
      page: $page
      limit: $limit
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        document
        lead_id
        sale_consultant
        created_at
        return_in {
          formated
          diff_today
        }
      }
    }
  }
`;

export const FETCH_TAGS = gql`
  query ($keyword: String, $type: String, $page: Int, $limit: Int) {
    fetchTags(keyword: $keyword, type: $type, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        color
        created_at
        type
        required
      }
    }
  }
`;

export const FETCH_NEGOTIATIONS = gql`
  query {
    fetchNegotiations {
      name
      document
      lead_id
    }
  }
`;

export const FETCH_LEADS_FILES = gql`
  query ($page: Int, $limit: Int, $status: String, $ufs: [String]) {
    fetchLeadsFiles(page: $page, limit: $limit, status: $status, ufs: $ufs) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        ufs
        created_at
        rows
        processed
        count_leads
        processed_leads
        status
      }
    }
  }
`;

export const FETCH_LEADS_FILES_ROWS = gql`
  query ($lead_file_id: Int!, $page: Int, $limit: Int) {
    fetchLeadsFileRows(
      lead_file_id: $lead_file_id
      page: $page
      limit: $limit
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        document
        benefit
        loan
        detail
        error
      }
    }
  }
`;

export const FETCH_SALE_CONSULTANT_GROUPS = gql`
  query ($keyword: String, $limit: Int, $page: Int, $all: Boolean) {
    fetchSaleConsultantGroups(
      keyword: $keyword
      limit: $limit
      page: $page
      all: $all
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
      }
    }
  }
`;

export const FETCH_ANNOUNCEMENTS = gql`
  query {
    fetchAnnouncements {
      id
      title
      description
      theme
      profiles
      created_at
      expire_at
      attachments {
        id
        name
        path
        created_at
      }
    }
  }
`;

export const DETAIL_CUSTOMER = gql`
  query ($document: String!) {
    detailCustomer(document: $document) {
      name
      document
      mothers_name
      birthdate
      zipcode
      address
      number
      complement
      district
      state
      city
      phones {
        id
        phone
        verified
      }
    }
  }
`;

export const FETCH_DYNAMIC_INTERACTION_REPORT_RESPONSE = gql`
  query (
    $limit: Int!
    $offset: Int!
    $columns: [String]!
    $filters: FetchDynamicInteractionReportFilters
  ) {
    fetchDynamicInteractionReport(
      limit: $limit
      offset: $offset
      columns: $columns
      filters: $filters
    ) {
      total
      items {
        interaction_created_at
        lead_document
        sale_consultant_name
        lead_name
        team_name
        interest_level
        opportunity_name
        interaction_status
      }
    }
  }
`;

export const DETAIL_PROPOSAL = gql`
  query ($code: String!) {
    detailProposal(code: $code) {
      id
      code
      proposal_number
      benefit_salary
      benefit_discount_consigned
      benefit_discount_rmc
      released_amount
      released_gross_amount
      released_portion_amount
      released_portion_count
      released_date
      output
      sale_origin {
        id
        name
      }
      status
      situation {
        id
        name
      }
      product {
        id
        name
      }
      files {
        id
        name
        path
      }
      date {
        formated
        days_ago
      }
      customer {
        id
        name
        document
        mothers_name
        email
        zipcode
        address
        district
        benefit_number
        specie {
          id
          name
        }
        income
        phone
        phone2
        cellphone
        location {
          id
          name
          state
        }
        birthdate {
          formated
          age
          iso8601
        }
        phone_reference {
          id
          name
          phone
          kinship
        }
      }
      bank {
        id
        name
      }
      ported_bank {
        id
        name
      }
      type {
        id
        name
      }
      entity {
        id
        name
      }
      sale_consultant {
        id
        name
      }
    }
  }
`;

export const DETAIL_LEAD_FILE = gql`
  query ($id: Int!) {
    detailLeadFile(id: $id) {
      id
      name
      path
      rows
      processed
      errors
      custom
      count_leads
      processed_leads
      status
      status_detail
      start_process
      finish_process
      created_at
      ufs
    }
  }
`;

export const COUNT_LEADS = gql`
  query ($lead_file_ids: [Int]!, $filters: FilterType!) {
    countLeads(lead_file_ids: $lead_file_ids, filters: $filters)
  }
`;

export const COUNT_INDICATIONS = gql`
  query ($start: String, $finish: String, $opportunity_id: Int) {
    countIndications(
      start: $start
      finish: $finish
      opportunity_id: $opportunity_id
    ) {
      total
      items {
        name
        count
      }
    }
  }
`;

export const CONSULTANT_RANKING = gql`
  query (
    $sale_consultant_id: Int
    $team_id: Int
    $supervisor_id: Int
    $product_ids: [Int]
    $company_ids: [Int]
    $start: String
    $finish: String
    $limit: Int
  ) {
    consultantRanking(
      sale_consultant_id: $sale_consultant_id
      team_id: $team_id
      supervisor_id: $supervisor_id
      product_ids: $product_ids
      company_ids: $company_ids
      start: $start
      finish: $finish
      limit: $limit
    ) {
      id
      name
      goal
      team 
      supervisor
      total_proposals
      total_proposals_by_period
    }
  }
`;

export const FETCH_TARGET_AUDIENCE_PRE_DEFINED = gql`
  query ($page: Int, $limit: Int, $keyword: String, $all: Boolean) {
    fetchTargetAudiencePreDefined(
      page: $page
      limit: $limit
      keyword: $keyword
      all: $all
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        filters
      }
    }
  }
`;

export const FETCH_TARGET_AUDIENCE_CSV = gql`
  query ($page: Int, $limit: Int, $keyword: String, $all: Boolean) {
    fetchTargetAudienceCsv(
      page: $page
      limit: $limit
      keyword: $keyword
      all: $all
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        hiring_date
      }
    }
  }
`;

export const DETAIL_TARGET_AUDIENCE = gql`
  query ($id: Int) {
    detailTargetAudience(id: $id) {
      id
      name
      filters
    }
  }
`;

export const DETAIL_LEAD = gql`
  query ($document: String, $id: Int) {
    detailLead(document: $document, id: $id) {
      id
      document
      name
      email
      gender
      birthdate
      zipcode
      address
      district
      entity_responsible
      service
      product
      negotiation_expire_at
      location {
        id
        name
        state
      }
      phones {
        id
        number
        verified_at
        is_whatsapp
        is_valid
        verified_by
      }
      interactions {
        id
        status
        description
        author
        created_at
        opportunity
        temperature
        dynamic_form_output
      }
      debit_accounts {
        id
        bank {
          id
          name
        }
        portion_amount
        portion_count
        portion_paid_count
        updated_at
      }
      benefits {
        id
        number
        entity {
          id
          name
        }
        specie {
          id
          name
        }
        bank {
          id
          name
          logo
        }
        agency
        account
        way_of_payment
        start
        salary
        margin_loan
        margin_loan_total
        margin_card
        margin_card_used
        net_income {
          gross_salary
          amount_consigned
          reserved
          final_salary
        }
        loans {
          id
          contract_number
          registration
          type
          bank {
            id
            name
            logo
          }
          portion_count
          portion_paid_count
          portion_to_paid_count
          portion_amount
          amount
          start
          finish
          tax_range
          debit_balance
        }
      }
      tags {
        id
        name
        color
        type
        required
        eligible
      }
      debits{
        id
        amount
        from_date
        deleted_at
      }
    }
  }
`;

export const DETAIL_CAMPAING_LEAD = gql`
  query ($code: String!) {
    detailCampaingLead(code: $code) {
      id
      lead_id
      document
      name
      email
      gender
      birthdate
      zipcode
      address
      district
      location {
        id
        name
        state
      }
      phones {
        id
        number
        is_whatsapp
        is_valid
        verified_at
        reason
      }
      interactions {
        id
        status
        description
        author
        created_at
      }
      benefits {
        id
        number
        entity {
          id
          name
        }
        specie {
          id
          name
        }
        bank {
          id
          name
          logo
        }
        agency
        account
        way_of_payment
        start
        salary
        margin_loan
        net_income {
          gross_salary
          amount_consigned
          reserved
          final_salary
        }
        loans {
          id
          contract_number
          registration
          type
          bank {
            id
            name
            logo
          }
          portion_count
          portion_paid_count
          portion_to_paid_count
          portion_amount
          amount
          start
          finish
          tax_range
          debit_balance
        }
      }
    }
  }
`;

export const DETAIL_CAMPAING_LEADS = gql`
  query ($id: Int!, $page: Int, $limit: Int) {
    detailCampaingLeads(id: $id, limit: $limit, page: $page) {
      total
      current_page
      per_page
      last_page
      data {
        id
        document
        name
        zipcode
        phones {
          number
        }
        last_interaction {
          status
          created_at
          sale_consultant
        }
      }
    }
  }
`;

export const DETAIL_CAMPAING = gql`
  query ($id: Int!) {
    detailCampaing(id: $id) {
      id
      name
      total_leads
      total_interacted
      applied_filters
      status
      created_at
      restrictions {
        id
        name
        type
      }
      lead_files {
        id
        name
        processed_leads
      }
      opened
      closed
      deleted
      situations {
        name
        count
      }
    }
  }
`;

export const FETCH_PRODUCTION_REPORT = gql`
  query (
    $sale_consultants: [Int]
    $sale_channels: [Int]
    $teams: [Int]
    $supervisors: [Int]
    $managers: [Int]
    $companies: [Int]
    $status: String
    $situations: [Int]
    $banks: [Int]
    $products: [Int]
    $ported_banks: [Int]
    $created_range: CreatedRangeInput
    $released_range: ReleasedRangeInput
  ) {
    fetchProductionReport(
      sale_consultants: $sale_consultants
      sale_channels: $sale_channels
      teams: $teams
      supervisors: $supervisors
      managers: $managers
      companies: $companies
      status: $status
      situations: $situations
      banks: $banks
      products: $products
      ported_banks: $ported_banks
      created_range: $created_range
      released_range: $released_range
    ) {
      codigo
      consultor_vendas
      status
      situacao
      data_criacao
      cliente
      documento
      nascimento
      mae
      cep
      endereco
      bairro
      cidade
      telefone
      celular
      telefone_referencia
      numero_proposta
      numero_beneficio
      valor_negociado
      parcela_negociada
      valor_aprovado
      parcela_aprovada
      qtd_parcelas
      data_aprovacao
      banco
      produto
    }
  }
`;

export const FETCH_DYNAMIC_INTERACTION_REPORT = gql`
  query (
    $limit: Int!
    $offset: Int!
    $columns: [String]!
    $filters: FetchDynamicInteractionReportFilters
  ) {
    fetchDynamicInteractionReport(
      limit: $limit
      offset: $offset
      columns: $columns
      filters: $filters
    ) {
      total
      items {
        interaction_created_at
        lead_document
        sale_consultant_name
        lead_name
        lead_sale_origin
        lead_entity
        team_name
        interest_level
        interaction_status
        phones {
          id
          number
          is_valid
        }
        benefits {
          id
          number
          salary
        }
        location_state
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const COUNT_PRODUCTS = gql`
  query (
    $start: String
    $finish: String
    $product_ids: [Int]
    $sale_consultant_id: Int
    $supervisor_id: Int
    $team_id: Int
    $company_ids: [Int]
  ) {
    countProducts(
      start: $start
      finish: $finish
      sale_consultant_id: $sale_consultant_id
      supervisor_id: $supervisor_id
      team_id: $team_id
      product_ids: $product_ids
      company_ids: $company_ids
    ) {
      total
      product {
        id
        name
      }
    }
  }
`;

export const FETCH_DEBTORS = gql`
  query (
    $keyword: String
    $finish: String
    $start: String
    $deleted: Boolean
    $page: Int
    $limit: Int
  ) {
    fetchDebtors(
      keyword: $keyword
      finish: $finish
      start: $start
      deleted: $deleted
      page: $page
      limit: $limit
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        amount
        from_date
        deleted_at
        created_at
        lead {
          id
          name
          document
          email
          phones {
            id
            number
            verified_at
            verified_by
            is_valid
            is_whatsapp
          }
          created_at
        }
      }
    }
  }
`;

export const SEARCH_CUSTOM_DATA = gql`
  query (
    $type: String
    $keyword: String
    $limit: Int
    $page: Int
    $filter: [FilterSearchCustomData]
  ) {
    searchCustomData(
      type: $type
      keyword: $keyword
      limit: $limit
      page: $page
      filter: $filter
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        type
        value
      }
    }
  }
`;

export const DETAIL_DYNAMIC_FORM = gql`
  query (
    $id: Int
    $product_id: Int
    ) {
    detailDynamicForm(
      id: $id
      product_id: $product_id
      ) {
      id
      name
      input
      is_after_sale
      days
      created_at
      updated_at
    }
  }
`;

export const FETCH_DYNAMIC_FORMS = gql`
  query(
    $keyword: String 
    $page: Int
    $limit: Int
    $statics: Boolean
  ) {
    fetchDynamicForms(
      keyword: $keyword
      page: $page
      limit: $limit
      statics: $statics
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        created_at
        updated_at
        is_after_sale
        days
      }
    }
  }
`

export const FETCH_RESTRICTIONS = gql`
  query {
    fetchRestrictions {
      id
      name
      topic
      created_at
    }
  }
`;

export const FETCH_PROFILES = gql`
  query ($keyword: String, $page: Int, $limit: Int) {
    fetchProfiles(keyword: $keyword, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        name
        restrictions {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_RELEASE_CATEGORIES = gql`
  query ($keyword: String, $type: String, $order_by: String, $direction: String, $page: Int, $limit: Int) {
    fetchReleaseCategories(keyword: $keyword, type: $type, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        title
        type
        color
        created_at
        subcategories {
          id
          title 
          created_at
        }
      }
    }
  }
`;

export const FETCH_RELEASE_SUBCATEGORIES = gql`
  query ($category_id: Int, $keyword: String,$order_by: String, $direction: String, $page: Int, $limit: Int) {
    fetchReleaseSubcategories(category_id: $category_id, keyword: $keyword, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        title
        created_at
        category {
          id
          title 
          created_at
          type
          color
        }
      }
    }
  }
`;

export const FETCH_RELEASES = gql`
  query ($keyword: String, $type: String, $company_id: Int, $category_id: Int, $subcategory_ids: [Int], $start: String, $finish: String, $page: Int, $limit: Int) {
    fetchReleases(keyword: $keyword, type: $type, company_id: $company_id, category_id: $category_id, subcategory_ids: $subcategory_ids, start: $start, finish: $finish, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        value
        description
        created_at
        company {
          id
          name
        }
        category {
          id
          title
          color
          type
        }
        subcategory{
          id
          title
        }
        release_at
      }
    }
  }
`;

export const COMPARE_REVENUE_AND_EXPENSE_RELEASES = gql`
  query($start: String, $finish: String) {
    compareRevenueAndExpenseReleases(start: $start, finish: $finish) {
      period
      expense
      revenue
    }
  }
`

export const STATISTICS_BY_TYPE_RELEASES = gql`
  query($type: String!, $start: String, $finish: String) {
    statisticsByTypeReleases(type: $type, start: $start, finish: $finish) {
      value
      caption
      color
    }
  }
`

export const FETCH_DYNAMIC_RELEASE_REPORT = gql`
  query(
    $limit: Int!
    $offset: Int!
    $columns: [String]!
    $filters: FetchDynamicReleaseReportFilters
  ) {
    fetchDynamicReleaseReport(
      limit: $limit
      offset: $offset
      columns: $columns
      filters: $filters
    ) {
      total
      items {
        release_id
        release_value
        release_description
        release_release_at
        company_name
        category_title
        category_type
        subcategory_title
      }
    }
  }
`
export const FETCH_DYNAMIC_FORM_INPUTS = gql`
  query($dynamic_form_id: Int) {
    fetchDynamicFormInputs(dynamic_form_id: $dynamic_form_id) {
      dynamic_form {
        id
        name
      }
      label
      name
      type
      options {
        id
        name
      }
    }
  }
`
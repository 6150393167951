import { Card, Link, CardHeader, Grid, TableHead, Table, TableRow, TableCell, TableBody, Button, IconButton, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_PROPOSAL_FILE, UPLOAD_PROPOSAL_FILE } from "../../../../graphql/mutations";
import { delConfirm } from "../../../../helpers";

export default function FilesCard({ data, onUploadedFile, onDeleteFile }) {

    const [uploadProposalFile, uploadProposalFileMutation] = useMutation(UPLOAD_PROPOSAL_FILE);
    const [deleteProposalFile, deleteProposalFileMutation] = useMutation(DELETE_PROPOSAL_FILE);
    const [id, setId] = useState(null);

    const handleChangeFile = (event) => {
        uploadProposalFile({
            variables: {
                proposal_id: data.detailProposal.id,
                file: event.target.files[0]
            }
        }).then(() => {
            onUploadedFile();
        })
    }

    const handleClickDelete = (id) => {
        delConfirm(() => {
            setId(id);
            deleteProposalFile({
                variables: {
                    id: id
                }
            }).then(() => {
                onDeleteFile();
            }).finally(() => setId(null))
        });
    }

    return (
        <Card>
            <CardHeader style={{ backgroundColor: "#ddd" }} title="Arquivos" />
            <Table>
                <TableBody>
                    {
                        data.detailProposal.files.map(el => {
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Link href={el.path}>{el.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton 
                                            onClick={() => handleClickDelete(el.id)} 
                                            aria-label="delete" 
                                            style={{ padding: 0 }}
                                            disabled={(id == el.id && deleteProposalFileMutation.loading)}
                                        >
                                            { (id == el.id && deleteProposalFileMutation.loading) ? <CircularProgress size={21}  /> : <DeleteIcon /> }
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    {
                        data.detailProposal.files.length == 0 &&
                        <TableRow>
                            <TableCell>Nenhum arquivo foi enviado para essa proposta</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>

            <div style={{ textAlign: 'center', padding: 12 }}>
                <input id="inputFile" multiple={false} style={{ display: 'none' }} type="file" accept=".csv" onChange={handleChangeFile}></input>
                <label htmlFor="inputFile">
                    <Button color="primary" startIcon={ uploadProposalFileMutation.loading ? <CircularProgress size={14} /> : <CloudUploadIcon />} component="span" disabled={uploadProposalFileMutation.loading}>
                         Enviar arquivo
                    </Button>
                </label>
            </div>
        </Card>
    )

}
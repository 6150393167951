import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Button,
  IconButton,
  Link,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {
  formatDateBRL,
  maskPhone,
  maskPhoneMicroSip,
} from "../../helpers";
import DialogEditPhone from "./DialogEditPhone";
import { useState } from "react";
import DialogAddPhone from "./DialogAddPhone";
import DialerSipIcon from "@mui/icons-material/DialerSip";

export default function InfoPhone({
  data,
  onEditPhone,
  onCreatePhone,
}) {
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [phoneToEdit, setPhoneToEdit] = useState(null);

  const phones = data.phones;

  return (
    <>
      <Card style={{ marginTop: 16, padding: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>TELEFONES</b>
        </Typography>
        {data.phones.length === 0 && (
          <CardContent>
            <Typography>Nenhum telefone cadastrado</Typography>
          </CardContent>
        )}
        {true && (
          <React.Fragment>
            {phones.map((row) => (
              <React.Fragment key={row.id}>
                <Grid
                  container
                  alignItems="center"
                  spacing={3}
                  style={{
                    marginTop: 12,
                    borderBottom: "1px solid #CCC",
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item sm={5}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ marginTop: 8, fontWeight: "bold" }}
                        >
                          Número
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                          <span
                            style={{
                              textDecoration:
                                row.is_valid === false
                                  ? "line-through"
                                  : "none",
                            }}
                          >
                            {maskPhone(row.number)}
                          </span>

                          {row.is_whatsapp && (
                            <a
                              href={"https://wa.me/55" + row.number}
                              target="_blank"
                              style={{ marginLeft: 12 }}
                              rel="noreferrer"
                            >
                              <WhatsAppIcon
                                style={{ fontSize: 18, color: "#58c24e" }}
                              />
                            </a>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={3}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ marginTop: 8, fontWeight: "bold" }}
                        >
                          É válido?
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                          {row.is_valid === true
                            ? "Sim"
                            : row.is_valid === false
                            ? "Não"
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid item sm={3}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ marginTop: 8, fontWeight: "bold" }}
                        >
                          É WhatsApp?
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                          {row.is_whatsapp === true
                            ? "Sim"
                            : row.is_whatsapp === false
                            ? "Não"
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <Link href={`tel:${maskPhoneMicroSip(row.number)}`}>
                          <DialerSipIcon
                            style={{
                              cursor: "pointer",
                              color: "#2196f3",
                              fontSize: 18,
                            }}
                          />
                        </Link>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            setPhoneToEdit(row);
                          }}
                          aria-label="edit"
                        >
                          <EditIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      </Grid>
                      {row.verified_at !== null && (
                        <Grid item xs={12} md={12} style={{ marginTop: 8 }}>
                          <Typography variant="caption" component="div">
                            Verificado em{" "}
                            {row.verified_at
                              ? formatDateBRL(row.verified_at)
                              : "-"}{" "}
                            por {row.verified_by ? row.verified_by : "-"}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        <Box style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            color="primary"
            onClick={() => {
              setShowAddPhone(true);
            }}
            startIcon={<AddIcon />}
          >
            Adicionar telefone
          </Button>
        </Box>
      </Card>

      <DialogEditPhone
        open={phoneToEdit ? true : false}
        handleClose={() => setPhoneToEdit(null)}
        initialValues={phoneToEdit || {}}
        onEdit={() => {
          setPhoneToEdit(null);
          onEditPhone();
        }}
      />

      <DialogAddPhone
        lead_id={data.lead_id || data.id}
        open={showAddPhone}
        handleClose={() => setShowAddPhone(false)}
        onCreate={() => {
          setShowAddPhone(false);
          onCreatePhone();
        }}
      />
    </>
  );
}
import { Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import Header from '../../../components/Header';
import { useParams } from "react-router-dom";
import CustomerCard from '../../../components/CustomerCard';

export default function CustomerDetailPage({ history }){

    let { document } = useParams ();

    return (
        <>
            <Header 
                title="Detalhe do cliente" 
                onBack={() => {
                    history.goBack()
                }}
            />

            <Container style={{ marginTop: 86 }}>


                <Grid container spacing={3} style={{ marginTop: 12 }}>

                    <Grid item xs={12} md={7}>
                        <CustomerCard document={document} />

                        <Card style={{ marginTop: 24 }}>
                            <CardContent>
                                <Typography variant="h5" component="h1">Propostas</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h1">Interações</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>

            </Container>
        </>
    )
}
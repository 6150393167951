/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_INTERACTION } from "../../graphql/mutations";

export default function DialogCallEnd({
  lead_id,
  open,
  handleClose,
  onCallEnd,
  campaign_id,
}) {
  const [createInteraction, { loading }] = useMutation(CREATE_INTERACTION);

  const { handleSubmit } = useFormik({
    initialValues: { reason: null },
    onSubmit: (values) => {
      createInteraction({
        variables: {
          lead_id: lead_id,
          campaign_id: parseInt(campaign_id),
          status: "Ligação encerrada",
        },
      }).then(() => {
        onCallEnd();
      });
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">Ligação Encerrada</DialogTitle>

        <DialogContent>
          <p>Você confirma que a ligação foi encerrada?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: 12 }} />
            )}
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,  
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { UPDATE_LEAD_BY_CRM } from "../../../../graphql/mutations";


export default function UpdateCrm({ open, onClose, data, refetch }) {
  
  const { enqueueSnackbar } = useSnackbar();

  const [updateLeadByCrm, {loading}] = useMutation(UPDATE_LEAD_BY_CRM);

  function handleUpdate() {
    updateLeadByCrm({ variables: { lead_id: data?.detailLead?.id } })
      .then(() => {
        refetch();
        enqueueSnackbar("Dados atualizados com sucesso!", {variant: "success"});
        onClose();
      })
      .catch((error) => {
        if (error.message.includes('CPF não encontrado')) {
          enqueueSnackbar("Atualização dos dados indisponível: CPF não encontrado.");
        } else {
          console.error("Erro ao atualizar dados do cliente:", error);
        }
        onClose();
      });
  }

  return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          Atualizar dados do cliente
        </DialogTitle>

        <DialogContent>
          <p>Deseja realmente atualizar os dados do cliente?</p>
        </DialogContent>

        <DialogActions style={{ marginTop: "30px", marginBottom: "16px" }}>
          <Button
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading && <CircularProgress size={16} style={{ marginRight: 6 }} />}
            Atualizar
          </Button>

          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
  );
}

import React, { forwardRef, useState } from "react";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "../IconButton";

const InputBase = forwardRef(
  (
    {
      type,
      name,
      placeholder,
      isPassword,
      isRequired,
      shouldUnregister = false,
      readOnly,
      ...rest
    },
    ref
  ) => {
    const { control } = useFormContext();
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        rules={{
          required: isRequired && "Campo obrigatório",
        }}
        render={(inputProps) => (
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              htmlFor={name}
              error={inputProps.fieldState.error && true}
            >
              {placeholder}
            </InputLabel>
            <OutlinedInput
              readOnly={readOnly}
              sx={{
                opacity: readOnly ? 0.5 : 1,
                pointerEvents: readOnly ? "none" : "auto",
              }}
              endAdornment={
                isPassword && (
                  <IconButton
                    onClick={() =>
                      setIsShowPassword((prevState) => !prevState)
                    }
                  >
                    {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                )
              }
              value={inputProps.field.value ?? ""}
              onChange={(e) => inputProps.field.onChange(e.target.value)}
              name={name}
              id={name}
              type={isPassword ? (isShowPassword ? "text" : "password") : type}
              {...rest}
              label={placeholder}
              error={inputProps.fieldState.error && true}
              ref={ref}
              fullWidth
            />
            {inputProps.fieldState.error && (
              <FormHelperText error={inputProps.fieldState.error && true}>
                {inputProps.fieldState.error.message + ""}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  }
);

export default InputBase;
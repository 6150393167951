import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { FETCH_INTERACTIONS_STATISTICS } from "../../../graphql/queries";

export default function Filter({ onChangeFilter, isAdmin, filter }) {
  const { loading, data } = useQuery(FETCH_INTERACTIONS_STATISTICS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      status: filter.status,
      start: filter.start,
      finish: filter.finish,
      opportunity_id: filter.opportunity,
      sale_consultant_id: filter.saleConsultantId,
      team_id: filter.teamId,
      sale_origin_id: filter.saleOriginsId,
      entity_id: filter.entitiesId,
      reasons: filter.reason,
      tag_ids: filter.tag,
      interest_level: filter.interestLevel,
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography component="div" variant="caption">
            Total de interações
          </Typography>
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Typography variant="h5">
              {data?.fetchInteractionsStatistics.total}
            </Typography>
          )}
        </Grid>
      </Grid>
      <div style={{ margin: "12px 8px 24px 4px", fontSize: "12px" }}>
        {data &&
          data?.fetchInteractionsStatistics.status.map((i, k) => {
            return (
              <div
                key={k}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginBottom: 8,
                }}
              >
                <div>{i.name}</div>
                <div
                  style={{
                    borderBottom: "2px dotted #aaa",
                    flexGrow: 1,
                    margin: "0 12px",
                  }}
                ></div>
                <div>{i.count}</div>
              </div>
            );
          })}
      </div>
    </>
  );
}

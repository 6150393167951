import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import ManualBase from "./ManualBase";
import GrossBase from "./GrossBase";

export default function Step02({
  onNext,
  onBack,
  values,
  setFieldValue,
  onCreate,
}) {
  return (
    <>
      <RadioGroup
        row
        aria-label="position"
        value={values.radio}
        onChange={(e) => setFieldValue("radio", parseInt(e.target.value))}
      >
        <FormControlLabel
          value={0}
          control={<Radio color="primary" />}
          label="Base Bruta"
        />
        <FormControlLabel
          value={1}
          control={<Radio color="primary" />}
          label="Base Manual"
        />
      </RadioGroup>

      {values.radio == 0 && (
        <GrossBase
          onBack={onBack}
          onNext={onNext}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}

      {values.radio == 1 && (
        <ManualBase onBack={onBack} values={values} onCreate={onCreate} />
      )}
    </>
  );
}

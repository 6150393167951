import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import SelectWithQuerySearch from "../../../components/SelectWithQuerySearch";
import { UPDATE_PROPOSAL } from "../../../graphql/mutations";
import { FETCH_PROPOSAL_SITUATIONS, DETAIL_PROPOSAL } from "../../../graphql/queries";
import { useEffect } from "react";


export default function EditDialog({ open, handleClose, data, code, onEdit }) {

    const [ updateProposal, {loading} ] = useMutation(UPDATE_PROPOSAL);
    const { data: dataQuery, refetch } = useQuery(DETAIL_PROPOSAL, {
        notifyOnNetworkStatusChange: true,
        variables: {
            code: code
        }
    })

    const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
        initialValues: data,
        onSubmit: (values) => {
            updateProposal({
                variables: {
                    id: data.id,
                    status: values.status,
                    proposal_situation_id: values.situation?.id,
                }
            }).then(onEdit)
        }
    })

    useEffect(() => {
        if(dataQuery){
            setFieldValue('situation', dataQuery.detailProposal.situation);
        }
    }, [dataQuery])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{width: '100%'}}>
                            <InputLabel>Status</InputLabel>
                            <Select
                            label="Status"
                            defaultValue={values.status}
                            onChange={handleChange}
                            name="status"
                            >
                                <MenuItem value={"Aberta"}>Aberta</MenuItem>
                                <MenuItem value={"Fechada"}>Fechada</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectWithQuerySearch
                            title="Situação"
                            query={FETCH_PROPOSAL_SITUATIONS}
                            fetchResult="fetchProposalSituations"
                            onChange={(situations) => { setFieldValue('situation', situations) }}
                            fullWidth
                            value={values.situation}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
                    {loading && <CircularProgress size={14} style={{marginRight: 12}}/>}
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )

}

import { useMutation } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { DELETE_CAMPAIGN } from "../../../graphql/mutations";

export default function DeleteCampaing({ open, handleClose, id }) {
  // Setting mutation
  const [deleteCampaign, { loading }] = useMutation(DELETE_CAMPAIGN);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = (id) => {
    deleteCampaign({
      variables: {
        id: id,
      },
    }).then(() => {
      window.location.href = "/campanhas";
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">Excluir Campanha</DialogTitle>

        <DialogContent>
          <p>Deseja realmente excluir a campanha?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleDelete(id);
              enqueueSnackbar("Campanha excluída com sucesso!");
            }}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: 12 }} />
            )}
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

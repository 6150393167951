import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Chip,
    TextField,
    InputAdornment,
    Checkbox,
    CircularProgress,
    Box,
} from "@material-ui/core";
import { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchInputDebounced from "./SearchInputDebounced";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FETCH_RELEASE_CATEGORIES, FETCH_RELEASE_SUBCATEGORIES, FETCH_TAGS } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import useDebounce from "../debounce";

export default function SelectFilterModalReleaseCategories({
    onChange,
    loading,
    title,
    required,
    error,
    fullWidth,
    style,
    margin,
    errorLabel,
    value,
    preFilledData = [],
    params = {}
}) {
    const [newValueTag, setNewValueTag] = useState(preFilledData || []);
    const [showDialog, setShowDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);

    const { data, loading: loadingQuery } = useQuery(FETCH_RELEASE_SUBCATEGORIES, {
        variables: {
            ...params,
            limit: 999,
            keyword: useDebounce(searchTerm, 1000)
        }
    });

    // // Search

    const handleChangeCheckbox = (e) => {
        const value = parseInt(e.target.value);
        let index = newValueTag.findIndex((id) => id === value);

        if (index >= 0 && e.target.checked === false) {
            deleteItem(index);
        } else {
            let clone = [...newValueTag];
            clone.push(value);
            setNewValueTag(clone);
        }
    };

    const deleteItem = (index) => {
        let clone = [...newValueTag];
        clone.splice(index, 1);
        setNewValueTag(clone);
    };

    const handleSelect = () => {
        onChange(newValueTag);
        setShowDialog(false);
    };

    const handleClearFilter = () => {
        onChange(value);
        setNewValueTag([]);
        setSearchTerm(null);
        setShowDialog(false);
    };

    return (
        <>
            <TextField
                style={style}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <ArrowDropDownIcon
                                style={{ color: "#787878", marginRight: -6 }}
                            />
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    shrink: newValueTag.length > 0,
                }}
                onClick={() => {
                    setShowDialog(true);
                }}
                variant="outlined"
                fullWidth={fullWidth}
                required={required}
                value={newValueTag
                    .map(
                        (tagId) =>
                            data?.fetchReleaseSubcategories.data.find((tag) => tag.id === tagId)?.title
                    )
                    .join(", ")}
                label={title}
                margin={margin}
                error={error}
                helperText={errorLabel}
            />

            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                maxWidth="md"
            >
                <DialogTitle>{"Categorias"}</DialogTitle>
                <DialogContent>
                    <SearchInputDebounced
                        typeSearch="inDialog"
                        onChange={(value) => {
                            setSearchTerm(value);
                        }}
                    />

                    <div style={{ marginTop: 20, width: 300, gap: 4, display: "flex", flexWrap: "wrap" }}>
                        {newValueTag.map((tagId, index) => {
                            const tagName = data?.fetchReleaseSubcategories.data.find(
                                (tag) => tag.id === tagId
                            )?.title;
                            return (
                                <Chip
                                    label={tagName}
                                    onDelete={() => deleteItem(index)}
                                    key={tagId}
                                />
                            );
                        })}
                    </div>

                    <div
                        style={{
                            marginTop: 20,
                            height: 300,
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <FormGroup>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {(data && !loadingQuery) &&
                                    data?.fetchReleaseSubcategories.data.map((tag) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={newValueTag.includes(tag.id)}
                                                    value={tag.id}
                                                    onChange={handleChangeCheckbox}
                                                />
                                            }
                                            label={tag.title}
                                        />
                                    ))}
                                {
                                    loadingQuery &&
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress />
                                    </Box>
                                }
                            </div>
                        </FormGroup>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClearFilter} color="primary">
                        Limpar
                    </Button>
                    <Button
                        onClick={handleSelect}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        Selecionar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

import { Grid, Link, Typography } from "@material-ui/core";
import { useState } from "react";
import { maskCPF, maskMoney } from "../../../helpers";
import EditSituation from "./EditSituation";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";
import TextCopy from "../../../components/TextCopy";
import useToken from "../../../tokenJWT";

export default function Item({ data, refetch, isAdmin }) {

  const { restrictions } = useToken();

  //Restrictions
  const allowEdit = restrictions({ topic: "Propostas", name: "Editar" })

  const [open, setOpen] = useState(false);
  let { enqueueSnackbar } = useSnackbar();

  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{
        marginTop: 12,
        marginBottom: 12,
        borderBottom: "1px solid #CCC",
      }}
    >
      <Grid item xs={12} sm={5} md={3}>
        <Grid container>
          <Grid item xs={6} sm={12}>
            <Link href={`/propostas/${data.code}`}>#{data.code}</Link>
            <Typography variant="caption" component="div">
              {data.date.formated}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={12} style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Typography
                variant="caption"
                component="div"
                style={{ marginTop: 4 }}
              >
                {data.situation}
              </Typography>
            </div>
            {
              allowEdit &&
              < EditIcon
                fontSize="small"
                style={{ cursor: "pointer", fontSize: 16, padding: 10 }}
                onClick={() => setOpen(true)}
              />
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="caption" component="div">
              Nome
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.customer ? data.customer.name : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextCopy
              notForm={true}
              title="CPF"
              value={maskCPF(data.customer.document)}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 12 }}>
          <Grid item xs={6}>
            <Typography variant="caption" component="div">
              Nº da proposta
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.proposal_number || "-"}
            </Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={6}>
              <Typography variant="caption" component="div">
                Consultor de vendas
              </Typography>
              <Typography variant="subtitle2" component="div">
                {data.sale_consultant ? data.sale_consultant.name : "-"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <Grid container justify="space-between">
          <Grid item xs={6} md={12}>
            <Typography variant="caption" component="div">
              Valor liberado
            </Typography>
            <Typography variant="h6" component="div">
              R${maskMoney(data.released_amount || 0)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={12}>
            <Typography variant="caption" component="div">
              Produto
            </Typography>
            <Typography variant="subtitle2" component="div">
              {data.product || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <EditSituation
        code={data.code}
        data={data}
        open={open}
        handleClose={() => setOpen(false)}
        onEdit={() => {
          enqueueSnackbar("Status/Situação alterado(as) com sucesso!");
          setOpen(false);
          refetch();
        }}
      />
    </Grid>
  );
}

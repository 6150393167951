import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { SEARCH } from "../graphql/queries";
import SearchInputDebounced from "./SearchInputDebounced";
import Highlighter from "react-highlight-words";
import DialogOpenProposal from "../pages/sales-consultant/DashboardPage/DialogOpenProposal";
import DialogOpenLead from "./DialogOpenLead";
import { useHistory } from "react-router-dom";

export default function Search({ open, onClose }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialogProposal, setOpenDialogProposal] = useState(false);
  const [openDialogLead, setOpenDialogLead] = useState(false);
  const history = useHistory();

  const { loading, error, data } = useQuery(SEARCH, {
    variables: { keyword: searchTerm },
  });

  useEffect(() => {
    if (searchTerm == null) setSearchTerm("");
  }, [searchTerm]);

  const handleClick = (id) => {
    history.push("/lead/" + id, {
      id: new URLSearchParams(id).toString(),
    });
  };

  return (
    <div
      style={{
        display: open ? "block" : "none",
        zIndex: 1101,
        position: "absolute",
        backgroundColor: "#FFF",
        width: "100vw",
        height: "100%",
        borderBottom: "4px solid #132994",
      }}
    >
      <AppBar position="fixed" color="white">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onClose}
          >
            <ArrowBackIcon />
          </IconButton>
          {open && (
            <SearchInputDebounced onChange={(value) => setSearchTerm(value)} />
          )}
        </Toolbar>
      </AppBar>
      {!loading && searchTerm == "" && (
        <div
          style={{
            color: "#888",
            width: "100%",
            textAlign: "center",
            marginTop: 24,
          }}
        >
          <SearchIcon style={{ fontSize: 60 }} />
          <div>Digite uma palavra chave para encontrar correspondências</div>
          <p>Exemplo: nome de um cliente, CPF ou número de beneficio</p>
        </div>
      )}

      {loading && (
        <div style={{ width: "100%", textAlign: "center", marginTop: 24 }}>
          <CircularProgress />
        </div>
      )}

      {data && data.search.leads.length > 0 && (
        <Container style={{ marginTop: 24 }}>
          <Typography variant="h5">Leads</Typography>
          {data.search.leads.map((i) => {
            return (
              <div
                style={{
                  borderBottom: "1px solid #CCC",
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                <Link onClick={() => handleClick(i.id)}>
                  <Highlighter
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={i.name}
                  />
                </Link>
                <div style={{ marginTop: 6 }}>
                  CPF:{" "}
                  <Highlighter
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={i.document}
                  />
                </div>
              </div>
            );
          })}
        </Container>
      )}

      {data && data.search.proposals.length > 0 && (
        <Container style={{ marginTop: 24 }}>
          <Typography variant="h5">Propostas</Typography>
          {data.search.proposals.map((i) => {
            return (
              <div
                style={{
                  borderBottom: "1px solid #CCC",
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                <Link href={`/propostas/${i.code}`}>
                  #
                  <Highlighter
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={i.code}
                  />
                </Link>
                <div style={{ marginTop: 6 }}>
                  Cliente:{" "}
                  <Highlighter
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={i.customer?.name}
                  />
                </div>
              </div>
            );
          })}
        </Container>
      )}

      {data &&
        data.search.proposals.length === 0 &&
        data &&
        data.search.leads.length === 0 &&
        searchTerm !== "" && (
          <Container
            style={{
              marginTop: 24,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="p">
              Não encontramos nenhum registro com a palavra-chave informada!
            </Typography>

            <Button
              style={{ width: "20%", marginTop: 16 }}
              variant="outlined"
              color="primary"
              onClick={() => setOpenDialogLead(true)}
            >
              Criar Lead
            </Button>
            <DialogOpenLead
              open={openDialogLead}
              handleClose={() => setOpenDialogLead(false)}
            />

            <Button
              style={{ width: "20%", marginTop: 16 }}
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogProposal(true)}
            >
              Abrir proposta
            </Button>
            <DialogOpenProposal
              open={openDialogProposal}
              handleClose={() => setOpenDialogProposal(false)}
            />
          </Container>
        )}
    </div>
  );
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import TextCopy from "../../../components/TextCopy";
import { formatMoneyBRL, formatDateBRL } from "../../../helpers";

export default function DialogDetails({
  open,
  onClose,
  data,
  placeholderBank,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Detalhes Empréstimo</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width={40}
              height={40}
              alt="bank"
              style={{ marginRight: 12, float: "right" }}
              src={
                data.bank && data.bank.logo ? data.bank.logo : placeholderBank
              }
            />
            <TextCopy
              copy={false}
              title="Banco"
              value={data.bank ? data.bank.id + " - " + data.bank.name : "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Nº Contrato"
              value={data.contract_number}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Tipo"
              value={data.type?.name || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Data averbação"
              value={data.registration ? formatDateBRL(data.registration) : "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Valor emprestado"
              value={data.amount ? formatDateBRL(data.amount) : "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Parcelas"
              value={data.portion_count}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Pagas/Pagar"
              value={
                data.portion_paid_count + " / " + data.portion_to_paid_count
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Valor Parcela"
              value={
                data.portion_amount ? formatMoneyBRL(data.portion_amount) : "-"
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Saldo devedor"
              value={
                data.debit_balance ? formatMoneyBRL(data.debit_balance) : "-"
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Início"
              value={data.start ? formatDateBRL(data.start) : "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy
              copy={false}
              title="Fim"
              value={data.finish ? formatDateBRL(data.finish) : "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextCopy copy={false} title="Taxa" value={data.tax_range + "%"} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import { Route } from "react-router";
import CampaingDetailPage from "../pages/admin/CampaingDetailPage";
import ManualDetailPage from "../pages/admin/ManualDetailPage";
import CampaingPage from "../pages/admin/CampaingPage";
import CustomerDetailPage from "../pages/admin/CustomerDetailPage";
import CustomerPage from "../pages/admin/CustomerPage";
import DashboardPage from "../pages/admin/DashboardPage";
import SaleDashboardPage from "../pages/sales-consultant/DashboardPage";
import GrossBasePage from "../pages/admin/GrossBasePage";
import GrossBaseDetailPage from "../pages/admin/GrossBaseDetailPage";
import ProposalDetailPage from "../pages/common/ProposalDetailPage";
import ProposalsPage from "../pages/common/ProposalsPage";
import SettingsPage from "../pages/admin/SettingsPage";
import AdminPage from "../pages/admin/SettingsPage/AdminPage";
import SaleConsultantPage from "../pages/admin/SettingsPage/SaleConsultantPage";
import ProductPage from "../pages/admin/SettingsPage/ProductPage";
import BankPage from "../pages/admin/SettingsPage/BankPage";
import EntitiesPage from "../pages/admin/SettingsPage/EntitiesPage";
import SpeciePage from "../pages/admin/SettingsPage/SpeciePage";
import TypePage from "../pages/admin/SettingsPage/TypePage";
import LocationPage from "../pages/admin/SettingsPage/LocationPage";
import ProposalSituationPage from "../pages/admin/SettingsPage/ProposalSituationPage";
import UniversitySimPage from "../pages/admin/SettingsPage/UniversitySimPage";
import ReasonPage from "../pages/admin/SettingsPage/ReasonPage";
import TargetAudiencePage from "../pages/admin/TargetAudiencePage";
import ProfilesPage from "../pages/admin/SettingsPage/ProfilesPage";
import UsersPage from "../pages/admin/SettingsPage/UsersPage";
import SaleConsultantGroupPage from "../pages/admin/SettingsPage/SaleConsultantGroupPage";
import ManagerPage from "../pages/admin/SettingsPage/ManagerPage";
import CompanyPage from "../pages/admin/SettingsPage/CompanyPage";
import SaleChannelPage from "../pages/admin/SettingsPage/SaleChannelPage";
import SaleOriginPage from "../pages/admin/SettingsPage/SaleOriginPage";
import SupervisorPage from "../pages/admin/SettingsPage/SupervisorPage";
import TeamPage from "../pages/admin/SettingsPage/TeamPage";
import ReportPage from "../pages/admin/ReportPage";
import AnnouncementPage from "../pages/admin/AnnouncementPage";
import LeadDetailPage from "../pages/common/LeadDetailPage";
import InteractionsPage from "../pages/admin/InteractionsPage";
import DebtorsPage from "../pages/admin/DebtorsPage";
import IndicationsPage from "../pages/admin/IndicationsPage";
import ReschedulesPage from "../pages/admin/ReschedulesPage";
import TagsPage from "../pages/admin/SettingsPage/TagsPage";
import FormPage from "../pages/admin/SettingsPage/FormPage";
import DynamicForm from "../pages/admin/SettingsPage/FormPage/DynamicForm";
import EditDynamicForm from "../pages/admin/SettingsPage/FormPage/EditDynamicForm";
import AfterSalesPage from "../pages/sales-consultant/AfterSalesPage";
import ManualCampaing from "../pages/sales-consultant/ManualCampaing";
import ManualLeads from "../pages/sales-consultant/ManualLeads";
import useToken from "../tokenJWT";
import FinancialPage from "../pages/admin/FinancialPage";
import ReleasesCategories from "../pages/admin/SettingsPage/ReleasesCategories";

export default function UserRoutes() {

  const { user } = useToken();
  
  const currentYear = new Date().getFullYear();
  
  const routes = [
    { path: "/", Component: user.is_sale_consultant ? SaleDashboardPage : DashboardPage },
    { path: "/propostas", Component: ProposalsPage },
    { path: "/propostas/:code", Component: ProposalDetailPage },
    { path: "/interacoes", Component: InteractionsPage },
    { path: "/inadimplentes", Component: DebtorsPage },
    { path: "/indicacoes", Component: IndicationsPage },
    { path: "/financeiro", Component: FinancialPage },
    { path: "/agendamentos", Component: ReschedulesPage },
    { path: "/clientes", Component: CustomerPage },
    { path: "/clientes/:document", Component: CustomerDetailPage },
    { path: "/campanhas", Component: CampaingPage },
    { path: "/campanhas/detail/:id", Component: CampaingDetailPage },
    { path: "/campanha", Component: ManualCampaing },
    { path: "/campanha/leads/:id", Component: ManualLeads },
    { path: "/campanha-manual/lead/:id", Component: LeadDetailPage },
    { path: "/pos-venda", Component: AfterSalesPage },
    { path: "/universidade-sim", Component: UniversitySimPage },
    {
      path: "/campanhas/manual/:id",
      Component: ManualDetailPage,
    },
    { path: "/base-bruta", Component: GrossBasePage },
    { path: "/base-bruta/:id", Component: GrossBaseDetailPage },
    { path: "/publicos", Component: TargetAudiencePage },
    { path: "/relatorios", Component: ReportPage },
    { path: "/lead/:id", Component: LeadDetailPage },
    { path: "/comunicados", Component: AnnouncementPage },
    { path: "/configuracoes", Component: SettingsPage },
    { path: "/configuracoes/perfis", Component: ProfilesPage },
    { path: "/configuracoes/usuarios", Component: UsersPage },
    { path: "/configuracoes/administrador", Component: AdminPage },
    { path: "/configuracoes/consultores", Component: SaleConsultantPage },
    { path: "/configuracoes/formularios", Component: FormPage },
    { path: "/configuracoes/criar-formularios", Component: DynamicForm },
    { path: "/configuracoes/editar-formulario/:id", Component: EditDynamicForm },

    {
      path: "/configuracoes/grupo-consultores",
      Component: SaleConsultantGroupPage,
    },
    { path: "/configuracoes/canais-de-vendas", Component: SaleChannelPage },
    { path: "/configuracoes/origem-das-vendas", Component: SaleOriginPage },
    { path: "/configuracoes/empresas", Component: CompanyPage },
    { path: "/configuracoes/gerentes", Component: ManagerPage },
    { path: "/configuracoes/supervisores", Component: SupervisorPage },
    { path: "/configuracoes/equipes", Component: TeamPage },
    { path: "/configuracoes/produtos", Component: ProductPage },
    { path: "/configuracoes/propostas", Component: ProposalSituationPage },
    { path: "/configuracoes/bancos", Component: BankPage },
    { path: "/configuracoes/entidades", Component: EntitiesPage },
    { path: "/configuracoes/especies", Component: SpeciePage },
    { path: "/configuracoes/tipos", Component: TypePage },
    { path: "/configuracoes/localizacao", Component: LocationPage },
    { path: "/configuracoes/universidade-sim", Component: UniversitySimPage },
    { path: "/configuracoes/motivos", Component: ReasonPage },
    { path: "/configuracoes/tags", Component: TagsPage },
    { path: "/configuracoes/categorias_lancamentos", Component: ReleasesCategories },
  ];

  return (
    <>
      {routes.map((i) => {
        return (
          <Route
            path={i.path}
            exact
            render={(props) => <i.Component {...props} />}
          />
        );
      })}

      <div
        style={{
          width: "100%",
          backgroundColor: "#cccccc4d",
          textAlign: "center",
          marginTop: 48,
        }}
      >
        <div
          style={{
            fontSize: 12,
            color: "#333",
            paddingTop: 24,
            paddingBottom: 24,
          }}
        >
          &copy;{currentYear} - Simcrédito
        </div>
      </div>
    </>
  );
}

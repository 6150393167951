import React from "react";
import { NetworkStatus, useMutation } from "@apollo/client";
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@mui/material";
import LocalProvider from "../../../../components/LocalProvider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { DELETE_TAG } from "../../../../graphql/mutations";
import { Chip, Tooltip } from "@material-ui/core";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";

export default function ListProduct({
  loading,
  error,
  data,
  networkStatus,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  onEdit,
  onDelete,
}) {
  // Setup mutation
  const [deleteTag, { loading: loadingDelete }] = useMutation(DELETE_TAG);

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  const handleEdit = (item) => {
    onEdit(item);
  };

  const handleDelete = (item) => {
    deleteTag({
      variables: {
        id: item.id,
      },
    }).then(() => onDelete());
  };

  return (
    <>
      {data?.fetchTags?.data.filter((row) => row.type === "Produto").length ===
      0 ? (
        <TableRow>
          <TableCell colSpan={2}>Nenhuma Tag cadastrada</TableCell>
        </TableRow>
      ) : (
        <TableContainer>
          {networkStatus === NetworkStatus.refetch && <LinearProgress />}
          <Table>
            <TableBody>
              {data?.fetchTags?.data
                .filter((row) => row.type === "Produto")
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title={row.name} placement="right">
                        <Chip
                          label={row.name}
                          variant="outlined"
                          style={{
                            backgroundColor: row.color,
                            marginRight: "10px",
                            color: "#FFF",
                            padding: "5px",
                            width: "150px",
                          }}
                          icon={
                            row.required ? (
                              <SdCardAlertIcon
                                style={{ fontSize: 15, color: "#FFF" }}
                              />
                            ) : (
                              <BookmarkBorderIcon
                                style={{ fontSize: 15, color: "#FFF" }}
                              />
                            )
                          }
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        style={{ padding: 0, marginRight: 24 }}
                        aria-label="delete"
                        onClick={() => handleEdit(row)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                      >
                        {loadingDelete ? (
                          <CircularProgress size={21} />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {data.fetchTags.total > 0 &&
        data.fetchTags.data.some((item) => item.type === "Produto") && (
          <LocalProvider locale="ptBR">
            <TablePagination
              showFirstButton
              showLastButton
              rowsPerPageOptions={[8, 15, 32, 100]}
              component="div"
              count={data?.fetchTags?.data.filter((row) => row.type === "Produto")
              .length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={(event, newPage) => {
                setCurrentPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(0);
              }}
            />
          </LocalProvider>
        )}
    </>
  );
}

import { useState } from "react";
import { useQuery } from "@apollo/client";
import "../assets/styleSheet/ScrollBar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Grid,
  IconButton,
  Chip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FETCH_DYNAMIC_FORM_INPUTS } from "../graphql/queries";

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import DateRangeIcon from '@material-ui/icons/DateRange';

import { formatISO } from 'date-fns';
import { formatDate } from "../helpers";

export default function SelectDynamicForm({
  title,
  required,
  value,
  error,
  onChange,
  fullWidth,
  style,
  errorLabel,
  onDelete,
  dynamicField,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedForm, setSelectedForm] = useState(dynamicField);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [selectedSelect, setSelectedSelect] = useState('');
  const [autocompleteValues, setAutocompleteValues] = useState(dynamicField);
  const { data, loading } = useQuery(FETCH_DYNAMIC_FORM_INPUTS);

  let uniqueData = [];

  if (data && data.fetchDynamicFormInputs) {
    uniqueData = Object.values(data.fetchDynamicFormInputs.reduce((acc, item) => {
      acc[item.label] = acc[item.label] || item;
      return acc;
    }, {}));
  }

  function handleDelete(itemToDelete) {
    setAutocompleteValues((prevValues) => prevValues.filter(item => item.value !== itemToDelete.value));
    onDelete(itemToDelete);
  }

  const getSelectedItem = () => uniqueData.find((item) => item.name === selectedForm);

  const getValueFromItem = (item) => {
    if (item.type === 'date') {
      return formatISO(selectedDate);
    } else if (item.options && item.options.length > 0) {
      const selectedOptionItem = item.options.find(option => option.id === selectedSelect);
      return selectedOptionItem ? selectedOptionItem.name : '';
    } else {
      return selectedText;
    }
  };

  const getValueToSave = (selectedItem) => getValueFromItem(selectedItem);

  const resetForm = () => {
    setSelectedForm('');
    setSelectedText('');
    setSelectedSelect('');
    setSelectedDate(null);
    setShowDialog(false);
  };
  
  const handleSave = () => {
    const selectedItem = getSelectedItem();
    const valueToSave = getValueToSave(selectedItem);
    const labelToSave = selectedItem.label;
  
    const itemsWithSameLabel = data.fetchDynamicFormInputs.filter(item => item.label === labelToSave);
  
    const itemsWithValues = itemsWithSameLabel.map(item => ({ ...item, value: valueToSave }));
  
    setAutocompleteValues(prevValues => {
      const filteredValues = prevValues.filter(item => item.label !== labelToSave);
      return [...filteredValues, ...itemsWithValues];
    });
  
    onChange(prevState => {
      const filteredState = prevState.filter(item => item.label !== labelToSave);
      return [...filteredState, ...itemsWithValues];
    });
  
    resetForm();
  };
  
  const itemsToRender = Object.values(autocompleteValues.reduce((acc, item) => {
    acc[item.label] = acc[item.label] || item;
    return acc;
  }, {}));

  return (
    <>
      <TextField
        style={style}
        InputProps={{
          readOnly: true,
          style: itemsToRender.length > 0 ? { padding: 10 } : {},
          startAdornment: itemsToRender.length > 0 ? (
            <Grid container spacing={1}>
              {itemsToRender.map((item, index) => (
                <Grid item key={index}>
                  <Chip
                    label={`${item.label}: ${item.type === 'date' ? formatDate(item.value) : item.value}`}
                    onDelete={() => handleDelete(item)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon
                style={{ color: "#787878", marginRight: -6 }}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: value && value.name,
        }}
        onClick={() => setShowDialog(true)}
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        label={title}
        error={error}
        helperText={errorLabel}
      />
     
      {
        showDialog &&
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="md"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField 
                  select
                  fullWidth
                  value={loading ? 1 : selectedForm}
                  disabled={loading}
                  label="Campo"
                  onChange={(e) => setSelectedForm(e.target.value)}
                  variant="outlined"
                >
                  {loading && <MenuItem value={1}>Carregando..</MenuItem>}
                  {
                    uniqueData.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={12}>
              {
                loading ? (
                  <TextField 
                    fullWidth
                    disabled
                    value="Carregando..."
                    variant="outlined"
                  />
                ) : (() => {
                  const selectedItem = data.fetchDynamicFormInputs.find((item) => item.name === selectedForm);
                  return selectedItem ? (
                    selectedItem.options && selectedItem.options.length > 0 ? (
                      <TextField
                        select
                        fullWidth
                        value={selectedSelect}
                        label={selectedItem.label}
                        onChange={(e) => setSelectedSelect(e.target.value)}
                        variant="outlined"
                      >
                        {selectedItem.options.map((option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      selectedItem.type === 'date' ? (
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                            <DatePicker
                              fullWidth
                              format="dd/MM/yyyy"
                              value={selectedDate}
                              variant="inline"
                              inputVariant="outlined"
                              onChange={(date) => setSelectedDate(date)}
                              placeholder="Palavra-chave"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <DateRangeIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      ) : (
                        <TextField 
                          fullWidth
                          placeholder="Palavra-chave"
                          variant="outlined"
                          value={selectedText}
                          onChange={(e) => setSelectedText(e.target.value)}
                          type={selectedItem.type === 'number' ? 'number' : 'text'}
                        />
                      )
                    )
                  ) : (
                    <TextField 
                      fullWidth
                      placeholder="Palavra-chave"
                      variant="outlined"
                    />
                  )
                })()
              }
            </Grid>
          </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 22 }}>
            <Button onClick={ () => setShowDialog(false)} color="primary">
              Fechar
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

import { Box, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import DialogCreateSubCategory from "./DialogCreateSubCategory";
import DialogEditSubCategory from "./DialogEditSubCategory";
import { useMutation } from "@apollo/client";
import { DELETE_RELEASE_SUBCATEGORY } from "../../../../../graphql/mutations";
import { useSnackbar } from "notistack";


export default function Row({ row, refetch, handleEdit, handleDelete }) {

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(null)

    const [deleteReleaseSubcategory, { loadingDeleteSub }] = useMutation(DELETE_RELEASE_SUBCATEGORY)

    const handleDeleteSub = async (id) => {

        try {
            await deleteReleaseSubcategory({
                variables: {
                    id
                }
            })
            enqueueSnackbar("Tipo deletado com sucesso!", {
                variant: "success"
            })
            refetch()
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Erro ao deletar tipo", {
                variant: "error"
            })
        }

    }

    return (
        <>
            <TableRow key={row.id}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell><Box width={15} height={15} borderRadius="50%" style={{ backgroundColor: row.color }} /></TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                    <IconButton style={{ padding: 0, marginRight: 24 }} aria-label="delete" onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        style={{ padding: 0 }}
                        aria-label="delete"
                        onClick={() => handleDelete(row)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Box
                                alignItems="center"
                                justifyContent="space-between"
                                display="flex"
                            >
                                <Typography variant="h6" gutterBottom component="div">
                                    Tipos
                                </Typography>
                                <IconButton
                                    onClick={() => setOpenModalCreate(true)}
                                    aria-label="delete"
                                >
                                    <AddIcon
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(104,188,79,0.7)",
                                            borderRadius: "50%",
                                            padding: 3
                                        }}
                                    />
                                </IconButton>
                            </Box>

                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right">&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        row.subcategories.length > 0 &&
                                        <>
                                            {
                                                row.subcategories.map(sub => (
                                                    <TableRow key={sub.id}>
                                                        <TableCell component="th" scope="row">
                                                            {sub.title}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                style={{ padding: 0, marginRight: 24 }}
                                                                aria-label="delete"
                                                                onClick={() => setOpenModalEdit(sub)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                style={{ padding: 0 }}
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteSub(sub.id)}>
                                                                {loadingDeleteSub ? <CircularProgress size={21} /> : <DeleteIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </>
                                    }

                                    {
                                        row.subcategories.length <= 0 &&
                                        <TableRow>
                                            <TableCell colSpan={2}>Nenhuma categoria cadastrada</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {
                openModalCreate &&
                <DialogCreateSubCategory
                    open={openModalCreate}
                    handleClose={() => setOpenModalCreate(false)}
                    fatherId={row.id}
                    onCreate={() => {
                        enqueueSnackbar("Tipo criado com sucesso!", {
                            variant: "success"
                        })
                        setOpenModalCreate(false)
                        refetch()
                    }}
                    onError={() => {
                        enqueueSnackbar("Erro ao criar tipo", {
                            variant: "error"
                        })
                    }}
                />
            }

            {
                openModalEdit &&
                <DialogEditSubCategory
                    open={openModalEdit ? true : false}
                    handleClose={() => setOpenModalEdit(null)}
                    data={openModalEdit}
                    onEdit={() => {
                        enqueueSnackbar("Tipo editado com sucesso!", {
                            variant: "success"
                        })
                        setOpenModalEdit(null)
                        refetch()
                    }}
                    onError={() => {
                        enqueueSnackbar("Erro ao editar tipo", {
                            variant: "error"
                        })
                    }}
                />
            }

        </>
    )
}
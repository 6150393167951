import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import {
	Switch,
	SwitchProps,
	FormGroup,
	FormControlLabel,
} from "@mui/material/";
import { useFormContext } from "react-hook-form";

const GreenSwitchComponent = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: green[600],
		"&:hover": {
			backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: green[600],
	},
}));

export function GreenSwitch({
	label,
	name,
	labelPlacement = "end",
	...rest
}) {
	const { register, watch } = useFormContext();

	const value = watch(name);

	return (
		<FormGroup>
			<FormControlLabel
				control={
					<GreenSwitchComponent {...rest} {...register(name)} checked={value} />
				}
				label={label}
				labelPlacement={labelPlacement}
			/>
		</FormGroup>
	);
}
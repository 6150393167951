import { Box, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import ModalFilterCsv from "../ModalFilterCsv";

export function ExportCSV({ filter }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box marginTop={2} display="flex" justifyContent="center">
        <Button
          color="inherit"
          startIcon={<DownloadIcon />}
          onClick={() => setIsOpen(true)}
        >
          Exportar para Excel
        </Button>
      </Box>
      {isOpen && (
        <ModalFilterCsv isOpen={isOpen} setIsOpen={setIsOpen} filter={filter} />
      )}
    </>
  );
}

import React from 'react';

export default function InteractionsProgress({ interacted, total, printValue }){

    var percent = 100 * interacted / total;



    return (
        <>
            <div style={{ border: '1px #7ED321 solid', height: 6, width: '100%', borderRadius: 6 }}>
                <div style={{ width: percent+'%', height: '100%', backgroundColor: '#B8E986' }} />
            </div>
            {
                printValue === true &&

                <div style={{ fontSize: 11, marginTop: 3 }}> { interacted.toLocaleString('pt-BR') } ({ percent.toFixed(1) }%)</div>
            }
        </>
    )
}
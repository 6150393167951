import { useHistory, useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_UF } from "../../../graphql/mutations";
import { DETAIL_LEAD_FILE } from "../../../graphql/queries";
import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import Header from "../../../components/Header";
import TextCopy from "../../../components/TextCopy";
import Records from "./Records";
import { DELETE_LEAD_FILE } from "../../../graphql/mutations";
import SelectEditModalUF from "../../../components/SelectEditModalUF";
import { useSnackbar } from "notistack";

export default function GrossBaseDetailPage() {
  const { id } = useParams();
  const history = useHistory();

  const [UF, setUF] = useState([]);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, refetch } = useQuery(DETAIL_LEAD_FILE, {
    variables: {
      id: parseInt(id),
    },
  });

  const [deleteLeadFile, { loading: loadingDelete }] =
    useMutation(DELETE_LEAD_FILE);

  const [updateUf, { loading: loadingUf }] = useMutation(UPDATE_UF);

  const handleDelete = (id) => {
    deleteLeadFile({
      variables: {
        id: id,
      },
    }).then(() => (window.location.href = "/base-bruta"));
  };

  const handleEdit = (values) => {
    setUF(values);
    updateUf({
      variables: {
        leadfile_id: parseInt(id),
        ufs: values,
      },
    }).then(() => {
      setTimeout(() => refetch(), 300);
      enqueueSnackbar("UF's editadas com sucesso!");
    });
  };

  return (
    <>
      <Header title="Detalhe da base bruta" onBack={() => history.goBack()} />

      <Container style={{ marginTop: 86 }}>
        {loading && <CircularProgress style={{ marginTop: 12 }} />}

        {data && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Grid item>
                <Card>
                  <CardHeader title="Base bruta" />
                  <CardContent>
                    <TextCopy
                      title="Nome"
                      value={data.detailLeadFile.name}
                      copy={false}
                    />
                    <TextCopy
                      title="UF's"
                      value={
                        loadingUf
                          ? ""
                          : data.detailLeadFile.ufs &&
                            data.detailLeadFile.ufs != null
                          ? data.detailLeadFile.ufs.join(", ")
                          : ""
                      }
                      copy={false}
                      style={{
                        marginTop: 12,
                        position: "relative",
                      }}
                    >
                      <>
                        {loadingUf && (
                          <CircularProgress
                            size="2vh"
                            style={{ marginTop: 0 }}
                          />
                        )}

                        <IconButton
                          style={{
                            padding: 0,
                            position: "absolute",
                            top: "calc(50% - 12px)",
                            right: 10,
                          }}
                          aria-label="edit"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    </TextCopy>
                    <TextCopy
                      title="Quantidade de linhas"
                      value={data.detailLeadFile.rows}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Linhas processadas"
                      value={data.detailLeadFile.processed}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Linhas com erro"
                      value={data.detailLeadFile.errors}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Leads encontrados"
                      value={data.detailLeadFile.processed_leads}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Status"
                      value={data.detailLeadFile.status}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Detalhe"
                      value={data.detailLeadFile.status_detail || "-"}
                      copy={false}
                      style={{ marginTop: 12, marginBottom: 24 }}
                    />
                    <TextCopy
                      title="Inicio do processamento"
                      value={data.detailLeadFile.start_process}
                      copy={false}
                      style={{ marginTop: 12 }}
                    />
                    <TextCopy
                      title="Fim do processamento"
                      value={data.detailLeadFile.finish_process || "-"}
                      copy={false}
                      style={{ marginTop: 12, marginBottom: 24 }}
                    />

                    <SelectEditModalUF
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={handleEdit}
                    />

                    <Button
                      onClick={() => handleDelete(data.detailLeadFile.id)}
                      variant="contained"
                      color="primary"
                      disabled={loadingDelete}
                    >
                      {loadingDelete && (
                        <CircularProgress
                          size="{14}"
                          style={{ marginRight: 12 }}
                        />
                      )}
                      Excluir base
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Records lead_file_id={data.detailLeadFile.id} />
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}

import { useState } from "react";
import { Grid, Button, makeStyles, Typography, Box } from "@material-ui/core";
import ClientData from "../LeadDetail/ClientData";
import BenefitData from "../LeadDetail/BenefitData";
import InteractionsHistory from "../LeadDetail/InteractionsHistory";
import DialogReschedule from "../LeadDetail/DialogReschedule";
import DialogNoInterest from "../LeadDetail/DialogNoInterest";
import DialogNoProfile from "../LeadDetail/DialogNoProfile";
import DialogOnTag from "../LeadDetail/DialogOnTag";
import ResumeInfo from "../LeadDetail/ResumeInfo";
import LinkedLoans from "../LeadDetail/LinkedLoans";
import DialogSimulator from "../LeadDetail/DialogSimulator";
import DialogInvalidContact from "../LeadDetail/DialogInvalidContact";
import DialogInNegotiation from "../LeadDetail/DialogInNegotiation";
import DialogOpenProposal from "../../pages/sales-consultant/DashboardPage/DialogOpenProposal";
import DialogDebtors from "../LeadDetail/DialogDebtors";
import InfoLead from "../LeadDetail/InfoLead";
import ProductTagsCard from "../LeadDetail/ProductTagsCard";

export default function LeadDetailConsultant({
  data,
  tab,
  onRescheduled,
  onUninterested,
  onUnprofile,
  onEditPhone,
  onEditDebitAccount,
  onCreatePhone,
  onInvalidatedContact,
  onInNegotiation,
  onIndication,
  onProposal,
  onCallEnd,
  refetch,
  onTag,
  onDebit,
}) {
  const [showSimulator, setShowSimulator] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showNoInterest, setShowNoInterest] = useState(false);
  const [showNoProfile, setShowNoProfile] = useState(false);
  const [showOnTag, setShowOnTag] = useState(false);
  const [showInvalidContact, setShowInvalidContact] = useState(false);
  const [showInNegotiation, setShowInNegotiation] = useState(false);
  const [showOpenProposal, setShowOpenProposal] = useState(false);
  const [showDebtors, setShowDebtors] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" spacing={2}>

        <Grid item xs={12} md={8} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <InfoLead
                disabled={true}
                data={data}
                name={data.name}
                onUninterested={onUninterested}
                onUnprofile={onUnprofile}
                onInNegotiation={onInNegotiation}
                onIndication={onIndication}
                onProposal={onProposal}
                onCallEnd={onCallEnd}
                location={data.location}
                gender={data.gender}
                birthdate={data.birthdate}
                netIncome={data.benefits[tab]?.net_income || {}}
              />
            </Grid>

            <Grid item xs={12}>
              <ClientData
                {...{ refetch, onEditDebitAccount }}
                debitAccounts={data.debit_accounts}
                data={data}
                tab={tab}
                onEditPhone={onEditPhone}
                onCreatePhone={onCreatePhone}
              />
            </Grid>

            <Grid item xs={12}>
              <BenefitData data={data.benefits[tab] || null} id={data.id} />
            </Grid>

            <Grid item xs={12}>
              <LinkedLoans data={data.benefits[tab]?.loans || []} />
            </Grid>
          </Grid>
        </Grid>



        <Grid item xs={12} md={4}>

          <Box
            marginTop={2}
            marginBottom={4}
          >
            <ProductTagsCard
              {...{ refetch }}
              data={data.tags}
              leadId={data.id}
            />
          </Box>

          <>
            <Typography
              variant="subtitle1"
              fontWeight={500}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                color: "#FFF",
                height: "40px ",
                maxWidth: "570px",
                marginBottom: 22,
                backgroundColor: "blue",
                borderRadius: "20px",
              }}
            >
              <b>STATUS DE ATENDIMENTO</b>
            </Typography>
            <Button
              onClick={() => setShowSimulator(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="contained"
              color="primary"
            >
              Simular Proposta
            </Button>
            <Button
              onClick={() => setShowReschedule(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              color="primary"
            >
               Em negociação
            </Button>
            <Button
              onClick={() => setShowNoInterest(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              color="primary"
            >
              Sem interesse
            </Button>
            <Button
              onClick={() => setShowNoProfile(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              className={classes.deleteButton}
            >
              Sem perfil
            </Button>
            <Button
              onClick={() => setShowInvalidContact(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              className={classes.deleteButton}
            >
              Contato inválido
            </Button>
            <>
              {/* <Button
                onClick={() => setShowInNegotiation(true)}
                size="large"
                style={{ padding: "8px", width: "100%", marginBottom: 12 }}
                variant="outlined"
                color="secondary"
              >
                {data.negotiation_expire_at > 0 ? (
                  <Typography
                    variant="abbr"
                    title={`Tente novamente em ${data.negotiation_expire_at} dias`}
                  >
                    Negociação disponível em {data.negotiation_expire_at} dias
                  </Typography>
                ) : (
                  <Typography>Em negociação</Typography>
                )}
              </Button> */}
            </>
            <Button
              onClick={() => setShowOpenProposal(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              color="primary"
            >
              Abrir Proposta
            </Button>
            <Button
              onClick={() => setShowOnTag(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              color="primary"
            >
              TAGS
            </Button>
            <Button
              onClick={() => setShowDebtors(true)}
              size="large"
              style={{ padding: "8px", width: "100%", marginBottom: 12 }}
              variant="outlined"
              className={classes.deleteButton}
            >
              Inadimplente
            </Button>
          </>

          <Box
            marginTop={2}
            marginBottom={4}
          >
            <ResumeInfo
              data={data}
              onUninterested={onUninterested}
              onUnprofile={onUnprofile}
              onInNegotiation={onInNegotiation}
              onIndication={onIndication}
              onProposal={onProposal}
              onCallEnd={onCallEnd}
              name={data.name}
              location={data.location}
              gender={data.gender}
              birthdate={data.birthdate}
              netIncome={data.benefits[tab]?.net_income || {}}
            />
          </Box>

          <InteractionsHistory data={data.interactions} />
        </Grid>
      </Grid>

      <DialogSimulator
        open={showSimulator}
        refetch={refetch}
        handleClose={() => setShowSimulator(false)}
        netIncome={data.benefits[tab]?.net_income || {}}
        lead_benefit_id={data.benefits[tab]?.id}
      />

      <DialogReschedule
        lead_id={data.id}
        open={showReschedule}
        handleClose={() => setShowReschedule(false)}
        onRescheduled={() => {
          setShowReschedule(false);
          onRescheduled();
        }}
      />

      <DialogNoInterest
        lead_id={data.id}
        open={showNoInterest}
        handleClose={() => {
          setShowNoInterest(false);
        }}
        onUninterested={() => {
          setShowNoInterest(false);
          onUninterested();
        }}
      />

      <DialogNoProfile
        lead_id={data.id}
        open={showNoProfile}
        handleClose={() => {
          setShowNoProfile(false);
        }}
        onUnprofile={() => {
          setShowNoProfile(false);
          onUnprofile();
        }}
      />

      <DialogInvalidContact
        lead_id={data.id}
        open={showInvalidContact}
        handleClose={() => {
          setShowInvalidContact(false);
        }}
        onCreate={() => {
          setShowInvalidContact(false);
          onInvalidatedContact();
        }}
      />

      <DialogInNegotiation
        lead_id={data.id}
        open={showInNegotiation}
        handleClose={() => {
          setShowInNegotiation(false);
        }}
        onCreate={() => {
          setShowInNegotiation(false);
          onInNegotiation();
        }}
      />

      <DialogOpenProposal
        dataPerson={data}
        open={showOpenProposal}
        handleClose={() => {
          setShowOpenProposal(false);
        }}
        onProposal={() => {
          onProposal();
        }}
      />
      <DialogOnTag
        lead_id={data.id}
        open={showOnTag}
        onClose={() => {
          setShowOnTag(false);
        }}
        onTag={() => {
          onTag();
        }}
      />
      <DialogDebtors
        lead_id={data.id}
        open={showDebtors}
        handleClose={() => setShowDebtors(false)}
        onDebit={() => {
          setShowDebtors(false);
          onDebit();
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: "#C00",
    borderColor: "#C00",
  },
}));

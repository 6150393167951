import { useState } from "react";
import { Container, Card, Typography, CardContent } from "@material-ui/core";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { FilterListSharp } from "@material-ui/icons";
import Header from "../../../components/Header";
import List from "./List";
import PublicCsv from "./PublicCsv";
import CreateDialog from "./CreateDialog";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import EditDialog from "./EditDialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CreatePublicDialog from "./CreatePublicDialog";
import { FETCH_TARGET_AUDIENCE_PRE_DEFINED } from "../../../graphql/queries";
import { FETCH_TARGET_AUDIENCE_CSV } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

export default function TargetAudiencePage() {
  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [showPublicUpload, setShowPublicUpload] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [value, setValue] = useState(0);
  const [currentPagePreDefined, setCurrentPagePreDefined] = useState(0);
  const [rowsPerPagePreDefined, setRowsPerPagePreDefined] = useState(8);
  const [currentPageCsv, setCurrentPageCsv] = useState(0);
  const [rowsPerPageCsv, setRowsPerPageCsv] = useState(8);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const {
    loading: loadingPreDefined,
    error: errorPreDefined,
    data: dataPreDefined,
    refetch: refetchPreDefined,
    networkStatus: networkStatusPreDefined,
  } = useQuery(FETCH_TARGET_AUDIENCE_PRE_DEFINED, {
    fetchPolicy: "no-cache",
    variables: {
      limit: rowsPerPagePreDefined,
      page: currentPagePreDefined + 1,
    },
  });

  const {
    loading: loadingCsv,
    error: errorCsv,
    data: dataCsv,
    refetch: refetchCsv,
    networkStatus: networkStatusCsv,
  } = useQuery(FETCH_TARGET_AUDIENCE_CSV, {
    fetchPolicy: "no-cache",
    variables: {
      limit: rowsPerPageCsv,
      page: currentPageCsv + 1,
    },
  });

  const actions = [
    {
      icon: <FileUploadIcon size={32} round />,
      name: "Enviar Lista de Clientes",
      operation: "Enviar Lista de Clientes",
      action: () => setShowPublicUpload(true),
    },
    {
      icon: <FilterListSharp size={32} round />,
      name: "Criar Publico de Campanha Pre Definida",
      operation: "Criar Publico de Campanha Pre Definida",
      action: () => setShowCreate(true),
    },
  ];

  const handleTabs = (e, value) => {
    setValue(value);
  };

  return (
    <>
      <Header />
      <Container maxWidth="sm" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h1">
              Públicos
            </Typography>
          </CardContent>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabs}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#68bc4f",
                },
              }}
            >
              <Tab label="Público de Campanha Predefinida" />
              <Tab label="Lista de Clientes" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <List
              onDelete={() => {
                enqueueSnackbar("Público excluído com sucesso!");
                refetchPreDefined();
              }}
              onEdit={(item, refetch) => {
                setDataEdit(item);
                refetchPreDefined();
              }}
              dataPreDefined={dataPreDefined}
              loadingPreDefined={loadingPreDefined}
              errorPreDefined={errorPreDefined}
              refetchPreDefined={refetchPreDefined}
              networkStatusPreDefined={networkStatusPreDefined}
              currentPagePreDefined={currentPagePreDefined}
              rowsPerPagePreDefined={rowsPerPagePreDefined}
              setCurrentPagePreDefined={setCurrentPagePreDefined}
              setRowsPerPagePreDefined={setRowsPerPagePreDefined}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PublicCsv
              onDelete={() => {
                enqueueSnackbar("Público excluído com sucesso!");
              }}
              dataCsv={dataCsv}
              loadingCsv={loadingCsv}
              errorCsv={errorCsv}
              refetchCsv={refetchCsv}
              networkStatusCsv={networkStatusCsv}
              currentPageCsv={currentPageCsv}
              rowsPerPageCsv={rowsPerPageCsv}
              setCurrentPageCsv={setCurrentPageCsv}
              setRowsPerPageCsv={setRowsPerPageCsv}
            />
          </TabPanel>
        </Card>
      </Container>
      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          enqueueSnackbar("Público cadastrado com sucesso!");
          setShowCreate(false);
          refetchPreDefined();
        }}
      />
      <EditDialog
        open={dataEdit ? true : false}
        handleClose={() => setDataEdit(null)}
        data={dataEdit}
        onEdit={() => {
          enqueueSnackbar("Público editado com sucesso!");
          refetchPreDefined();
          setDataEdit(null);
        }}
      />
      <CreatePublicDialog
        open={showPublicUpload}
        handleClose={() => setShowPublicUpload(false)}
        handleUploaded={() => {
          setShowPublicUpload(false);
          refetchCsv();
        }}
      />
      <SpeedDial
        FabProps={{ style: { backgroundColor: "#68bc4f" } }}
        ariaLabel="SpeedDial Públicos"
        sx={{ position: "absolute", bottom: 48, right: 48 }}
        icon={<SpeedDialIcon />}
        onMouseLeave={handleClose}
        onClick={() => setOpen((x) => !x)}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { CREATE_MANAGER, CREATE_SUPERVISOR, CREATE_TEAM } from "../../../../graphql/mutations";
import { FETCH_COMPANIES, FETCH_MANAGERS, FETCH_SUPERVISORS } from "../../../../graphql/queries";

export default function CreateDialog({ open, handleClose, onCreate }) {

    // Setting mutation
    const [createTeam, { loading }] = useMutation(CREATE_TEAM);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: { name: "", manager: null },
        onSubmit: (values) => {

            // Chamando a mutation de criação
            createTeam({
                variables: {
                    supervisor_id: values.supervisor && values.supervisor.id,
                    name: values.name
                }
            }).then(() => {
                resetForm();
                onCreate();
            })

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Criar nova equipe</DialogTitle>

                <DialogContent>

                    <SelectWithQuerySearch
                        title="Líder"
                        query={FETCH_SUPERVISORS}
                        fetchResult="fetchSupervisors"
                        onChange={(supervisor) => { setFieldValue('supervisor', supervisor) }}
                        fullWidth
                        value={values.supervisor}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar equipe
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_PRODUCTS } from "../graphql/queries";

export default function SelectProductId({ value, onChange, style }) {

    const { data, loading } = useQuery(FETCH_PRODUCTS, {
        variables: {
            all: true
        }
    });

    return (
        <TextField
            select
            value={loading ? 1 : value}
            disabled={loading}
            label="Produtos"
            onChange={(e) => onChange(e.target.value)}
            variant="outlined"
            style={{ width: '100%' }}
        >
            {loading && <MenuItem value={1}>Carregando..</MenuItem>}

            {
                data && data.fetchProducts.data.map((option) => {
                    return (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })
            }
        </TextField>
    )
}

import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button, CircularProgress, MenuItem, DialogActions } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_LEAD_PHONE } from "../../graphql/mutations";
import TextCopy from "../TextCopy";
import { maskPhone } from "../../helpers";

export default function DialogEditPhone({ open, handleClose, initialValues, onEdit }) {

    const [updateLeadPhone, updateLeadPhoneMutation] = useMutation(UPDATE_LEAD_PHONE);

    const { handleSubmit, handleChange, values, errors, setValues } = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            updateLeadPhone({
                variables: {
                    id: values.id,
                    is_whatsapp: values.is_whatsapp,
                    is_valid: values.is_valid,
                    reason: values.reason
                }
            }).then(() => {
                onEdit();
            })
        }
    });

    useEffect(() => {
        setValues(initialValues);
    }, [initialValues]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Editar telefone</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextCopy title="Número" value={maskPhone(values.number)} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    variant="outlined"
                                    label="É válido?"
                                    value={values.is_valid}
                                    name="is_valid"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.is_valid}
                                    helperText={errors.is_valid}
                                >
                                    <MenuItem value={true}>Sim</MenuItem>
                                    <MenuItem value={false}>Não</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    variant="outlined"
                                    label="É WhatsApp?"
                                    value={values.is_whatsapp}
                                    name="is_whatsapp"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.is_whatsapp}
                                    helperText={errors.is_whatsapp}
                                >
                                    <MenuItem value={true}>Sim</MenuItem>
                                    <MenuItem value={false}>Não</MenuItem>
                                </TextField>
                            </Grid>
                            {
                                values.is_valid === false &&
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        label="Motivo da invalidez"
                                        value={values.reason}
                                        name="reason"
                                        onChange={handleChange}
                                        fullWidth
                                        error={errors.reason}
                                        helperText={errors.reason}
                                    >
                                        <MenuItem value="Não pertence ao cliente">Não pertence ao cliente</MenuItem>
                                        <MenuItem value="Caixa Postal">Caixa Postal</MenuItem>
                                        <MenuItem value="Mudo">Mudo</MenuItem>
                                        <MenuItem value="Outros motivos">Outros motivos</MenuItem>
                                    </TextField>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={updateLeadPhoneMutation.loading}>
                    {updateLeadPhoneMutation.loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import { Button, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { maskCPF } from "../../../helpers";

export default function Item({ data }) {

    const history = useHistory();

    const handleClick = (id) => {
        history.push("/lead/" + id, {
          id: new URLSearchParams(id).toString(),
        });
      };    

    return (
        <Grid container alignItems="center" spacing={3} style={{ marginTop: 12, marginBottom: 12, borderBottom: '1px solid #CCC' }}>

            <Grid item xs={12}>

                <Grid container>

                    <Grid item xs={12} sm={8} lg={10}>
                    <Tooltip title="Detalhe do Cliente" placement="right">
                        <Button style={{ fontSize: "16px", paddingLeft: 0, color: "#5FA018" }} onClick={() => handleClick(data.lead_id)}>
                            {`#${data.id}`}
                        </Button>
                    </Tooltip>

                        <Typography variant="caption" component="div">Criado em {data.created_at}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={2}>
                    <div>
                            <Typography variant="caption">Retornar em</Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>{data.return_in.formated}</Typography>
                            <div style={{ backgroundColor: data.return_in.diff_today == 0 ? '#5FA018' : (data.return_in.diff_today > 0 ? '#556cd6' : '#d50029'), fontSize: 10, fontWeight: 'bold', textAlign: 'center', color: '#FFF', paddingTop: 4, paddingBottom: 4, borderRadius: 8, marginTop: 4 }}>
                                {data.return_in.diff_today >= 0 ? 'Ligar' : 'Atrasado'}
                                {data.return_in.diff_today == 0 && <> hoje</>}
                                {data.return_in.diff_today == 1 && <> amanhã</>}
                                {data.return_in.diff_today == -1 && <> 1 dia</>}
                                {data.return_in.diff_today > 1 && <> daqui {data.return_in.diff_today} dias</>}
                                {data.return_in.diff_today < -1 && <> {data.return_in.diff_today * -1} dias</>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={6} lg={3}>
                        <Typography variant="caption" component="div" style={{ marginTop: 8 }}>CPF</Typography>
                        <Typography variant="subtitle2" component="div">{maskCPF(data.document)}</Typography>
                    </Grid>
                    <Grid item sm={6} lg={5}>
                        <Typography variant="caption" component="div" style={{ marginTop: 8 }}>Cliente</Typography>
                        <Typography variant="subtitle2" component="div">{data.name}</Typography>
                    </Grid>

                    <Grid item sm={12} lg={4}>
                        <Typography variant="caption" component="div" style={{ marginTop: 8 }}>Consultor de vendas</Typography>
                        <Typography variant="subtitle2" component="div">{data.sale_consultant}</Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );

}
import { useFormik } from "formik";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_LEAD_TAG } from "../../../graphql/mutations";
import { FETCH_TAGS } from "../../../graphql/queries";
import { useState } from "react";
import SelectFilterModalTags from "../../SelectFilterModalTags";

export default function DialogOnTag({
  lead_id,
  open,
  onClose,
  onTag,
  dataLead,
}) {
  const { data } = useQuery(FETCH_TAGS);

  const [productTags, setProductTags] = useState(
    dataLead?.tags.filter((tag) => tag.type === "Produto").map((i) => i.id) ||
    []
  );

  const [serviceTags, setServiceTags] = useState(
    dataLead?.tags
      .filter((tag) => tag.type === "Atendimento")
      .map((i) => i.id) || []
  );

  const [createLeadTag, { loading }] = useMutation(CREATE_LEAD_TAG);

  const { handleSubmit, resetForm } = useFormik({
    initialValues: { tag: null },
    onSubmit: (values) => {
      const combinedTags = [...productTags, ...serviceTags].map(item => ({ tag_id: item }));

      createLeadTag({
        variables: {
          lead_id: lead_id,
          tags: combinedTags,
        },
      }).then(() => {
        onTag();
        onClose();
        resetForm();
      });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>Tags</DialogTitle>

      <DialogContent style={{ marginBottom: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <SelectFilterModalTags
              dataLead={dataLead}
              newValue={serviceTags}
              setNewValue={setServiceTags}
              tagState={data?.tag}
              title="Tags de Atendimento"
              fullWidth
              onChange={(value) => {
                setServiceTags(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ marginBottom: 20, marginRight: 15 }}>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

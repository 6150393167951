import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { FETCH_PROPOSALS_STATISTICS } from "../../../graphql/queries";
import FilterDialog from "./FilterDialog";

export default function Filter({ onChangeFilter, isAdmin, filter }) {
  const [open, setOpen] = useState(false);

  const { loading, data, error, refetch } = useQuery(FETCH_PROPOSALS_STATISTICS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      start: filter.start ? filter.start : null,
      finish: filter.finish ? filter.finish : null,
      company_id: filter.company_id ? filter.company_id : null,
      team_id: filter.team_id ? filter.team_id : null,
      status: filter.status ? filter.status : null,
      manager_id: filter.manager_id ? filter.manager_id : null,
      supervisor_id: filter.supervisor_id ? filter.supervisor_id : null,
      sale_consultant_id: filter.sale_consultant_id
        ? filter.sale_consultant_id
        : null,
      product_id: filter.productId ? filter.productId : null,
      proposal_situation_id: filter.proposal_situation_id
        ? filter.proposal_situation_id
        : null,
      installments_number: filter.installments_number ? filter.installments_number : null,
      sale_origin: filter.sale_origin ? filter.sale_origin : null,
      specie_id: filter.specie_id ? filter.specie_id : null,
    },
  });

  if (loading) return <CircularProgress />;
  if (error) return  <div> Erro ao carregar os dados</div>

  return (
    <Card>
      <CardContent>
        <Grid container style={{ marginLeft: 18 }}>
          <Grid item xs={6}>
            <Typography component="div" variant="caption">
              Abertas
            </Typography>
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <Typography variant="h5">
                {data.fetchProposalStatistics.opened}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" variant="caption">
              Fechadas
            </Typography>
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <Typography variant="h5">
                {data.fetchProposalStatistics.closed}
              </Typography>
            )}
          </Grid>
        </Grid>
        <div style={{ margin: "12px 8px 24px 4px", fontSize: "12px" }}>
          {data &&
            data.fetchProposalStatistics.situations.map((i, k) => {
              return (
                <div
                  key={k}
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginBottom: 8,
                  }}
                >
                  <div>{i.name}</div>
                  <div
                    style={{
                      borderBottom: "2px dotted #aaa",
                      flexGrow: 1,
                      margin: "0 12px",
                    }}
                  ></div>
                  <div>{i.count}</div>
                </div>
              );
            })}
        </div>

        <FilterDialog
            {...{ isAdmin, refetch, onChangeFilter, loading, filter }}
          open={open}
          handleClose={() => setOpen(false)}
          onEdit={() => {
            setOpen(false);
          }}
        />
        <Button
          onClick={() => setOpen(true)}
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Filtros
        </Button>
      </CardContent>
    </Card>
  );
}

import { useState } from "react";
import { useHistory } from "react-router";
import {
  Container,
  Card,
  Typography,
  CardContent,
  Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import { FETCH_DYNAMIC_FORMS, FETCH_TEAMS } from "../../../../graphql/queries";
import { useQuery } from "@apollo/client";
import List from "./List";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";

export default function FormPage() {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_DYNAMIC_FORMS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
        statics: true
      },
      fetchPolicy: "network-only",
    }
  );

  function handleClick() {
    history.push("/configuracoes/criar-formularios");
  }

  return (
    <>
      <Header
        onBack={() => {
          history.goBack();
        }}
      />

      <Container maxWidth="sm" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h1">
              Formulários
            </Typography>
          </CardContent>

          <List
            {...{
              loading,
              error,
              data,
              networkStatus,
              currentPage,
              setCurrentPage,
              rowsPerPage,
              setRowsPerPage,
            }}
            onDelete={() => {
              enqueueSnackbar("Formulário excluído com sucesso!", {
                variant: "success",
              });
              refetch();
            }}
            onEdit={(item) => setDataEdit(item)}
          />
        </Card>
      </Container>
      <Fab
        color="primary"
        onClick={handleClick}
        style={{ position: "absolute", bottom: 48, right: 48 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
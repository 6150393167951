import { Box, Dialog } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CSVDownloader } from "./CSVDownloader";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FETCH_DYNAMIC_RELEASE_REPORT } from "../../../../../graphql/queries";
import { maskCPF, maskMoney } from "../../../../../helpers";
import { LinearProgressWithLabel } from "../../../../../components/LinearProgress";
import { format, parseISO } from "date-fns";

function formatDate(dataString) {
  const data = new Date(`${dataString}T00:00:00-03:00`);
  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export function ModalExportCSV({ open, handleClose, filter, columns }) {
  const { enqueueSnackbar } = useSnackbar();

  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [dataExport, setDataExport] = useState([]);

  const { data, loading } = useQuery(FETCH_DYNAMIC_RELEASE_REPORT, {
    fetchPolicy: "no-cache",
    variables: {
      offset: offset,
      limit: 500,
      columns: columns,
      filters: {
        keyword: filter.keyword,
        start: filter.startDate,
        finish: filter.finishDate,
        type: filter.type,
        company_id: filter.company,
        category_id: filter.categoryId,
        subcategory_ids: filter.subcategoryIds
      }
    },
  });

  const translationMapping = {
    release_value: "Valor",
    release_description: "Descrição",
    release_release_at: "Lançado em",
    company_name: "Empresa",
    category_title: "Categoria",
    category_type: "Origem",
    subcategory_title: "Tipo",
  };

  const translatedHeaders = columns.flatMap((column) => {
    return translationMapping[column];
  });

  const rowsCsv = dataExport.map((release) => {
    const row = [];

    columns.forEach((column) => {
      switch (column) {
        case "release_value":
          row.push(release.release_value ? `R$ ${maskMoney(release.release_value)}` : "-");
          break;
        case "release_description":
          row.push(release.release_description ? release.release_description : "-");
          break;
        case "release_release_at":
          row.push(release.release_release_at ? format(parseISO(release.release_release_at), 'dd/MM/yyyy HH:mm:ss') : "-");
          break;
        case "company_name":
          row.push(release.company_name ? release.company_name : "-");
          break;
        case "category_title":
          row.push(release.category_title ? release.category_title : "-");
          break;
        case "category_type":
          row.push(release.category_type ? release.category_type : "-");
          break;
        case "subcategory_title":
          row.push(release.subcategory_title ? release.subcategory_title : "-");
          break;
        default:
          row.push("-");
          break;
      }
    });

    return row;
  });

  const percentageDataExport = (dataExport.length * 100) / total;

  if (percentageDataExport === 100) {
    enqueueSnackbar("Exportação concluída com sucesso!", {
      variant: "success",
      preventDuplicate: true,
    });
    setTimeout(() => {
      handleClose();
    }, 2000);
  }

  useEffect(() => {
    if (data) {
      setTotal(data?.fetchDynamicReleaseReport?.total);
      setDataExport([
        ...dataExport,
        ...data?.fetchDynamicReleaseReport?.items,
      ]);
      if (
        data?.fetchDynamicReleaseReport?.total > dataExport.length &&
        offset + 500 < data?.fetchDynamicReleaseReport?.total
      ) {
        setOffset((prev) => prev + 500);
      }
    }
  }, [data]);

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth>
      <DialogTitle>{"Exportar CSV"}</DialogTitle>
      <DialogContent>
        {data && data?.fetchDynamicReleaseReport?.total === dataExport.length && (
          <CSVDownloader rowsCsv={rowsCsv} headersCsv={translatedHeaders} />
        )}
        <Box sx={{ width: "100%" }}>
          {loading && offset === 0 ? (
            <CircularProgress />
          ) : (
            <LinearProgressWithLabel
              value={percentageDataExport}
              current={dataExport.length}
              total={total}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

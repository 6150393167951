import { Box, Button, Card, Divider, Typography } from "@material-ui/core";
import { useState } from "react";
import DialogCallEnd from "./DialogCallEnd";

export default function ServicePanel({
  data,
  user,
  setShowSimulator,
  setShowReschedule,
  setShowNoInterest,
  setShowNoProfile,
  setShowInvalidContact,
  setShowInNegotiation,
  setOpenProposal,
  setShowOnTag,
  setShowDebtors,
  showInNegotiation,
  onCallEnd,
  campaign_id,
}) {
  const [callEnd, setCallEnd] = useState(false);

  return (
    <>
      <Card style={{ padding: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          <b>PAINEL DE ATENDIMENTO</b>
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowSimulator(true)}
            size="large"
            style={{
              color: "rgba(104, 188, 79, 0.75)", 
              borderColor: "rgba(104, 188, 79, 0.75)",
              width: "100%",
              marginRight: 20,
            }}
            variant="outlined"
          >
            Simular Proposta
          </Button>

          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setOpenProposal(true)}
            size="large"
            style={{ 
              color: "rgba(104, 188, 79, 0.75)", 
              borderColor: "rgba(104, 188, 79, 0.75)",
              width: "100%" 
            }}
            variant="outlined"
          >
            Abrir Proposta
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowReschedule(true)}
            size="large"
            style={{
              color: "rgba(104, 188, 79, 0.75)", 
              borderColor: "rgba(104, 188, 79, 0.75)",
              width: "100%",
              marginRight: 20,
            }}
            variant="outlined"
          >
             Em negociação
          </Button>

          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowNoInterest(true)}
            size="large"
            style={{ 
              color: "rgba(104, 188, 79, 0.75)", 
              borderColor: "rgba(104, 188, 79, 0.75)",
              width: "100%" 
            }}
            variant="outlined"
          >
            Sem interesse
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <>
            {/* <Button
              disabled={
                user.is_sale_consultant &&
                (data.negotiation_expire_at > 0)
              }
              onClick={() => setShowInNegotiation(true)}
              size="large"
              style={{
                color: "rgba(19, 41, 148,0.75)",
                borderColor: "rgba(19, 41, 148,0.75)",
                width: "100%",
                marginRight: 20,
              }}
              variant="outlined"
            >
              {data.negotiation_expire_at > 0 ? (
                <Typography
                  variant="abbr"
                  title={`Tente novamente em ${data.negotiation_expire_at} dias`}
                >
                  Negociação disponível em {data.negotiation_expire_at} dias
                </Typography>
              ) : (
                <Typography>Em negociação</Typography>
              )}
            </Button> */}
            {data.habilitacion && showInNegotiation && (
              <Typography
                variant="abbr"
                title={`Tente novamente em ${data.expire_at} dias`}
              >
                Tente novamente em {data.expire_at} dias
              </Typography>
            )}
          </>

          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowNoProfile(true)}
            size="large"
            style={{ 
              color: "rgba(255, 0, 0,0.75)",
              borderColor: "rgba(255, 0, 0,0.75)",
              marginRight: 20,
              width: "100%" 
            }}
            variant="outlined"
          >
            Sem perfil
          </Button>
          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowDebtors(true)}
            size="large"
            style={{ 
              color: "rgba(255, 0, 0,0.75)",
              borderColor: "rgba(255, 0, 0,0.75)",
              width: "100%" 
            }}
            variant="outlined"
          >
            Inadimplente
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowInvalidContact(true)}
            size="large"
            style={{
              color: "rgba(255, 0, 0,0.75)",
              borderColor: "rgba(255, 0, 0,0.75)",
              width: "100%",
            }}
            variant="outlined"
          >
            Contato inválido
          </Button>

          
        </Box>

        <Divider style={{ margin: "30px 0" }} />

        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          <b>TAGS DE ATENDIMENTO</b>
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Button
/*             disabled={
              user.is_sale_consultant &&
              data.phones.every((item) => item.is_valid === null)
            } */
            onClick={() => setShowOnTag(true)}
            size="large"
            variant="contained"
            style={{
              background: "rgba(104, 188, 79, 0.75)",
              color: "#FFF",
              width: "100%",
              marginRight: 20,
            }}
          >
            TAGS
          </Button>
          <Button
            onClick={() => setCallEnd(true)}
            size="small"
            style={{
              width: "100%",
              background: "rgba(255, 0, 0,0.75)",
              color: "#FFF",
              "&:hover": {
                background: "#C00",
              },
            }}
            variant="contained"
          >
            Ligação Encerrada
          </Button>
        </Box>
      </Card>
      {callEnd && (
        <DialogCallEnd
          open={callEnd ? true : false}
          campaign_id={campaign_id}
          data={data}
          handleClose={() => setCallEnd(false)}
          lead_id={data.id}
          onCallEnd={() => {
            setCallEnd(false);
            onCallEnd();
          }}
        />
      )}
    </>
  );
}
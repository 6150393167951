import { Card, CardContent, Container, Typography } from "@material-ui/core";
import List from './List'
import Header from '../../../components/Header';

export default function CustomerPage(){

    return (
        <>
            <Header />
            <Container style={{ marginTop: 86 }}>
                <Card style={{ marginTop: 12, height: '100%' }}>
                    <CardContent>
                        <Typography variant="h5" component="h1">Clientes</Typography>
                    </CardContent>
                    <List />
                </Card>
            </Container>
        </>
    )
}
import { Button } from "@material-ui/core";
import ManualBase from "./ManualBase";
import GrossBase from "./GrossBase";

export default function Step02({ onBack, values, setFieldValue, onCreate }) {
  return (
    <>
      <ManualBase onBack={onBack} values={values} onCreate={onCreate} />
      <div style={{ margin: 24, marginLeft: 0 }}>
        <a href="/exemploManual.csv">Clique aqui</a> para baixar um .csv de
        exemplo
      </div>
      <Button
        onClick={onBack}
        color="primary"
        style={{ marginTop: 12 }}
        variant="text"
      >
        Voltar
      </Button>
    </>
  );
}

import { React } from "react";
import { maskPhone, maskPhoneMicroSip } from "../../helpers";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Link,
} from "@material-ui/core";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import { CenterFocusStrong } from "@material-ui/icons";

export default function DialogManualPhone({ open, onClose, data }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Telefones do(a) Sr(a) {data?.name}</DialogTitle>
      <DialogContent className={classes.phones}>
        {data?.phones.map((i) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>{maskPhone(i.number)}</p>
              <Link href={`tel:${maskPhoneMicroSip(i.number)}`} style={{}}>
                <DialerSipIcon
                  style={{
                    cursor: "pointer",
                    color: "#2196f3",
                    fontSize: 25,
                    marginLeft: 50,
                  }}
                />
              </Link>
            </div>
          );
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  phones: {
    display: "flex",
    flexDirection: "column",
    fontSize: 18,
    lineHeight: 1,
  },
}));

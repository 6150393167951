import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useState } from "react";
import { CREATE_CAMPAING } from "../../../../graphql/mutations";
import Step01 from "./Step01";
import Step02 from "./Step02";

export default function CreateDialogManual({ open, handleClose, onCreate }) {
  const [activeStep, setActiveStep] = useState(0);

  const [createCampaing, createCampaingMutation] = useMutation(CREATE_CAMPAING);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      restrictions: {},
      lead_file_selected: [],
      count_leads: 0,
      filters: {},
      radio: 0,
    },
    onSubmit: (values) => {
      // Chamando a mutation de criação de campanha
      createCampaing({
        variables: {
          name: values.name,
          type: "Manual",
          lead_file_ids: values.lead_file_selected,
          restriction: values.restrictions && {
            sale_consultants:
              values.restrictions.sale_consultants &&
              values.restrictions.sale_consultants.map((i) => i.id),
            sale_consultant_groups:
              values.restrictions.sale_consultant_groups &&
              values.restrictions.sale_consultant_groups.map((i) => i.id),
          },
          filters: {
            age_group: values.filters.age_group,
            gender: values.filters.gender,
            location_id:
              values.filters.locations &&
              values.filters.locations.map((i) => i.id),
            count_loan_range: values.filters.count_loan_range,
            benefit: {
              salary_range: values.filters.benefit_salary_range,
              margin_loan_range: values.filters.benefit_margin_loan_range,
              bank_id:
                values.filters.benefit_banks &&
                values.filters.benefit_banks.map((i) => i.id),
              bank_ignore_id:
                values.filters.benefit_banks_ignore &&
                values.filters.benefit_banks_ignore.map((i) => i.id),
              start_range: values.filters.benefit_start_range,
              entity_id:
                values.filters.benefit_entities &&
                values.filters.benefit_entities.map((i) => i.id),
              specie_id:
                values.filters.benefit_species &&
                values.filters.benefit_species.map((i) => i.id),
            },
            loan: {
              bank_id:
                values.filters.loan_banks &&
                values.filters.loan_banks.map((i) => i.id),
              type_id:
                values.filters.loan_types &&
                values.filters.loan_types.map((i) => i.id),
              amount_range: values.filters.loan_amount_range,
              portion_amount_range: values.filters.loan_portion_amount_range,
              debit_balance_range: values.filters.loan_debit_balance_range,
              tax_range: values.filters.loan_tax_range,
              portion_count_range: values.filters.loan_portion_count_range,
              portion_paid_count_range:
                values.filters.loan_portion_paid_count_range,
            },
            debit_account: {
              bank_id:
                values.filters.debit_account_banks &&
                values.filters.debit_account_banks.map((i) => i.id),
              portion_amount_range:
                values.filters.debit_account_portion_amount_range,
              portion_count_range:
                values.filters.debit_account_portion_count_range,
              portion_paid_count_range:
                values.filters.debit_account_portion_paid_count_range,
            },
            target_audience_id:
              values.filters.target_audience &&
              values.filters.target_audience.map((i) => i.id),
          },
        },
      }).then(() => {
        setActiveStep(0);
        resetForm();
        onCreate();
      });
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          Criar nova campanha manual
        </DialogTitle>

        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Dados da campanha</StepLabel>
            <StepContent>
              <Step01
                onNext={() => setActiveStep(1)}
                {...{ values, errors, setFieldValue, handleChange }}
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Base de pesquisa</StepLabel>
            <StepContent>
              <Step02
                onNext={() => setActiveStep(2)}
                onBack={() => setActiveStep(0)}
                onCreate={() => {
                  setActiveStep(0);
                  resetForm();
                  onCreate();
                }}
                {...{ values, errors, setFieldValue, handleChange }}
              />
            </StepContent>
          </Step>
        </Stepper>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { React, useState, useEffect } from "react";
import { useFormik } from "formik";
import { FormControl, Typography, Radio, FormControlLabel, RadioGroup, InputLabel, MenuItem,Select, Dialog, Grid, DialogTitle, DialogActions, Button, TextField, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import { FETCH_PRODUCTION_REPORT, FETCH_SALE_CONSULTANT_GROUPS } from '../../../graphql/queries';
import { useQuery } from "@apollo/client";

export default function ModalResult({ open, onClose, params }){

    const { loading, error, data, networkStatus, refetch } = useQuery(FETCH_PRODUCTION_REPORT, {
        variables: params
    });

    return(
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="lg"
        >
                <DialogTitle id="scroll-dialog-title">Resultado</DialogTitle>
   
                <DialogContent>
                    { loading && <CircularProgress /> }
                    { (data && data.fetchProductionReport) &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Codigo</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Consultor</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell style={{ minWidth: 100 }}>Situacao</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Cliente</TableCell>
                                    <TableCell style={{ minWidth: 100 }}>Documento</TableCell>
                                    <TableCell>Nascimento</TableCell>
                                    <TableCell>Mãe</TableCell>
                                    <TableCell>Cep</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Endereco</TableCell>
                                    <TableCell style={{ minWidth: 100 }}>Bairro</TableCell>
                                    <TableCell style={{ minWidth: 100 }}>Cidade</TableCell>
                                    <TableCell>Telefone</TableCell>
                                    <TableCell>Celular</TableCell>
                                    <TableCell>Telefone Referencia</TableCell>
                                    <TableCell>Nº proposta</TableCell>
                                    <TableCell>Nº beneficio</TableCell>
                                    <TableCell>Valor liberado</TableCell>
                                    <TableCell>Valor de parcelas</TableCell>
                                    <TableCell>Qtd. parcelas</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Data de aprovacao</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Data de criação</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Banco</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>Produto</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                data.fetchProductionReport.map((row) => {
                                    return(
                                        <TableRow key={row.codigo}>
                                            <TableCell>{row.codigo}</TableCell>
                                            <TableCell>{row.consultor_vendas || '-'}</TableCell>
                                            <TableCell>{row.status || '-'}</TableCell>
                                            <TableCell>{row.situacao || '-'}</TableCell>
                                            <TableCell>{row.cliente || '-'}</TableCell>
                                            <TableCell>{row.documento || '-'}</TableCell>
                                            <TableCell>{row.nascimento || '-'}</TableCell>
                                            <TableCell>{row.mae || '-'}</TableCell>
                                            <TableCell>{row.cep || '-'}</TableCell>
                                            <TableCell>{row.endereco || '-'}</TableCell>
                                            <TableCell>{row.bairro || '-'}</TableCell>
                                            <TableCell>{row.cidade || '-'}</TableCell>
                                            <TableCell>{row.telefone || '-'}</TableCell>
                                            <TableCell>{row.celular || '-'}</TableCell>
                                            <TableCell>{row.telefone_referencia || '-'}</TableCell>
                                            <TableCell>{row.numero_proposta || '-'}</TableCell>
                                            <TableCell>{row.numero_beneficio || '-'}</TableCell>
                                            <TableCell>{row.valor_aprovado || '-'}</TableCell>
                                            <TableCell>{row.parcela_aprovada || '-'}</TableCell>
                                            <TableCell>{row.qtd_parcelas || '-'}</TableCell>
                                            <TableCell>{row.data_aprovacao || '-'}</TableCell>
                                            <TableCell>{row.data_criacao || '-'}</TableCell>
                                            <TableCell>{row.banco || '-'}</TableCell>
                                            <TableCell>{row.produto || '-'}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                            { 
                                data.fetchProductionReport.length == 0 &&
                                <TableRow>
                                    <TableCell colSpan={25}>Nenhum registro encontrado</TableCell>
                                </TableRow>
                            }
                            
                            </TableBody>
                        </Table>
                    }
                </DialogContent>
   
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Fechar
                    </Button>
                    <Button onClick={() => 
                        downloadBlob(arrayToCsv(data.fetchProductionReport), 'download', 'text/csv')
                    } 
                    variant="contained" 
                    color="primary">
                        Baixar .CSV
                    </Button>
                </DialogActions>
         
        </Dialog>
        
    );

}


  

const arrayToCsv = (data) => {
    return [
        [
          "Action",
          "Codigo",
          "Consultor",
          "Status",
          "Situacao",
          "Cliente",
          "Documento",
          "Nascimento",
          "Mãe",
          "Cep",
          "Endereco",
          "Bairro",
          "Cidade",
          "Telefone",
          "Celular",
          "Telefone Referencia",
          "Nº proposta",
          "Nº beneficio",
          "Valor aprovado",
          "Parcela aprovada",
          "Qtd. Parcelas",
          "Data de aprovacao",
          "Data de aprovacao",
          "Banco",
          "Produto"
        ],
        ...data.map(item => Object.values(item))
      ].map(e => e.join(",")) 
      .join("\n");
}

const downloadBlob = (content, filename, contentType) => {
    // Create a blob
    
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}
import { Button, Grid, TextField } from "@material-ui/core";
import { useState } from "react";

import { FETCH_PRODUCTS } from "../../../../graphql/queries";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { maskMoney } from '../../../../helpers'
import SelectSaleOrigins from "../../../../components/SelectSaleOrigins";

export default function Step01({
    setStep,
    productSelected,
    onAdvance,
    selectedFields
}) {

    const [product, setProduct] = useState(productSelected)
    const [releasedAmount, setReleasedAmount] = useState(selectedFields?.released_amount || "0,00")
    const [releasedGrossAmount, setReleasedGrossAmount] = useState(selectedFields?.released_gross_amount || "0,00")
    const [proposalNumber, setProposalNumber] = useState(selectedFields?.proposal_number || "")
    const [releasedPortionCount, setReleasedPortionCount] = useState(selectedFields?.released_portion_count || "")
    const [releasedPortionAmount, setReleasedPortionAmount] = useState(selectedFields?.released_portion_amount || "0,00")
    const [saleOriginId, setSaleOriginId] = useState(selectedFields?.sale_origin_id || "")

    const handleContinue = () => {
        onAdvance(product, releasedAmount, releasedGrossAmount, proposalNumber, releasedPortionCount, releasedPortionAmount, saleOriginId)
        setStep(2)
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} style={{ marginTop: 8 }}>
                <SelectWithQuerySearch
                    title="Produtos *"
                    query={FETCH_PRODUCTS}
                    fetchResult="fetchProducts"
                    onChange={(product) => {
                        setProduct(product);
                    }}
                    fullWidth
                    value={product}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    onChange={(e) =>
                        setReleasedAmount(maskMoney(e.target.value))
                    }
                    value={releasedAmount}
                    fullWidth
                    required
                    label="Valor liberado (líquido)"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    onChange={(e) =>
                        setReleasedGrossAmount(maskMoney(e.target.value))
                    }
                    value={releasedGrossAmount}
                    fullWidth
                    required
                    label="Valor liberado (bruto)"
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    onChange={(e) => setProposalNumber(e.target.value)}
                    value={proposalNumber}
                    fullWidth
                    required
                    label="Nº da proposta"
                    name="proposal_number"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    onChange={(e) => setReleasedPortionCount(e.target.value)}
                    value={releasedPortionCount}
                    fullWidth
                    type="number"
                    required
                    label="Quantidade de Parcelas"
                    name="released_portion_count"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    onChange={(e) =>
                        setReleasedPortionAmount(maskMoney(e.target.value))
                    }
                    value={releasedPortionAmount}
                    fullWidth
                    required
                    label="Valor da Parcela"
                    name="released_portion_amount"
                />
            </Grid>

            <Grid item xs={12}>
                <SelectSaleOrigins
                    title="Origem de venda *"
                    value={saleOriginId}
                    onChange={(id) => setSaleOriginId(id)}
                />
            </Grid>

            <Button
                variant="contained"
                color="primary"
                disabled={!product || proposalNumber === "" || releasedAmount === "0,00" || releasedGrossAmount === "0,00" || releasedPortionCount === "" || releasedPortionAmount === "0,00" || saleOriginId === ""}
                onClick={handleContinue}
            >
                Próximo
            </Button>
            <Button color="primary" onClick={() => setStep(0)}>
                Voltar
            </Button>
        </Grid>
    )
}
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Filter from "./Filter";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { FETCH_DEBTORS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import FilterDialog from "./FilterDialog";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CreateDialogManual from "./CreateDialogManual";
import { useSnackbar } from "notistack";
import { ExportCSV } from "./ExportCSV";
import List from "./List";
import CreateDialog from "./CreateDialog";
import AddIcon from "@material-ui/icons/Add";
import { Button, Fab } from "@material-ui/core";
import useToken from "../../../tokenJWT";

export default function DebtorsPage() {

  const { restrictions } = useToken();

  //Restricitions
  const allowSubirBase = restrictions({ topic: "Inadimplentes", name: "Subir base" })
  const allowExportar = restrictions({ topic: "Inadimplentes", name: "Exportar csv" })
  const allowCriarCampanha = restrictions({ topic: "Inadimplentes", name: "Criar nova campanha" })

  const [deleted, setDeleted] = useState(false);
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [showCreateManual, setShowCreateManual] = useState(false);

  const { data, refetch, loading } = useQuery(FETCH_DEBTORS, {
    fetchPolicy: "network-only",
    variables: {
      start: start ? start : "",
      finish: finish ? finish : "",
      deleted: deleted ? deleted : false,
      limit: rowsPerPage,
      page: currentPage + 1,
    },
  });

  const handleFilter = (data) => {
    setDeleted(data.deleted);
    setStart(data.start);
    setFinish(data.finish);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [deleted, start, finish]);

  return (
    <>
      <Header />
      <Container style={{ marginTop: 86 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 12 }} />
          </div>
        ) : (
          <>
            {data && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3} md={3}>
                  <Card>
                    <CardContent>
                      <Filter data={data?.fetchDebtors} loading={loading} />
                      <FilterDialog
                        onChangeFilter={(filter) => {
                          handleFilter(filter);
                        }}
                        loading={loading}
                        open={isOpen}
                        handleClose={() => setIsOpen(false)}
                        data={{
                          deleted,
                          start,
                          finish,
                        }}
                      />
                      <Button
                        onClick={() => setIsOpen(true)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Filtros
                      </Button>
                    </CardContent>
                  </Card>

                  {
                    allowCriarCampanha &&
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 30,
                      }}
                      onClick={() => setShowCreateManual(true)}
                    >
                      <ContactPhoneIcon size={32} round />
                      Nova Campanha Manual
                    </Button>
                  }

                  {
                    allowExportar &&
                    <ExportCSV
                      filter={{
                        deleted,
                        start,
                        finish,
                      }}
                    />
                  }
                </Grid>

                <CreateDialogManual
                  open={showCreateManual}
                  handleClose={() => setShowCreateManual(false)}
                  onCreate={() => {
                    enqueueSnackbar("Campanha cadastrada com sucesso!");
                    setShowCreateManual(false);
                  }}
                  filter={{
                    deleted,
                    start,
                    finish,
                  }}
                />

                <Grid item xs={12} sm={8} md={9}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h1">
                        Inadimplente
                      </Typography>

                      {data?.fetchDebtors.data.length > 0 && (
                        <List
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          rowsPerPage={rowsPerPage}
                          setRowsPerPage={setRowsPerPage}
                          data={data}
                          loading={loading}
                          refetch={refetch}
                        />
                      )}
                      {data?.fetchDebtors.data.length === 0 && (
                        <Typography variant="caption">
                          Nenhum resultado encontrado
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>

      {
        showCreate &&
        <CreateDialog
          open={showCreate}
          handleClose={() => setShowCreate(false)}
          handleUploaded={() => {
            setShowCreate(false);
            window.location.reload();
          }}
          refetch={refetch}
        />
      }

      {
        allowSubirBase &&
        <Fab
          color="primary"
          onClick={() => setShowCreate(true)}
          style={{ position: "fixed", bottom: 48, right: 48 }}
        >
          <AddIcon />
        </Fab>
      }
    </>
  );
}

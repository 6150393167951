import useToken from "../../tokenJWT";
import Awaiting from "./Awaiting";
import Login from "./Login";
import { useLocation } from "react-router-dom";
import Interaction from "./Interaction";

export default function Fastway() {
  const { user, removeToken } = useToken();

  const chave = new URLSearchParams(useLocation().search).get("chave");

  if (!user) return <Login onLogin={() => window.location.reload()} />;

  if (!chave)
    return (
      <Awaiting
        onLogout={() => {
          removeToken();
          window.location.reload();
        }}
      />
    );

  return <Interaction chave={chave} />;
}

import { Container, Card, Typography, CardContent } from "@material-ui/core";
import ListManual from "./ListManual";
import Header from "../../../components/Header";

export default function CampaingPage() {
  return (
    <>
      <Header isShowBadge />
      <Container style={{ marginTop: 86 }}>
        <Card style={{ marginTop: 12, height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="h1">
              Campanha Manual
            </Typography>
          </CardContent>

          <ListManual />
        </Card>
      </Container>
    </>
  );
}

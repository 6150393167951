import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_COMPANIES, FETCH_PRODUCTS, FETCH_SALE_CHANNELS, FETCH_SALE_CONSULTANTS, FETCH_SALE_ORIGINS } from '../../../../graphql/queries';
import { Container, Card, Typography, CardContent, Fab } from '@material-ui/core';
import Header from '../../../../components/Header';
import List from './List';
import CreateDialog from './CreateDialog';
import AddIcon from '@material-ui/icons/Add';
import EditDialog from './EditDialog';
import { useSnackbar } from "notistack";

export default function SaleOriginPage({ history }) {

    const { enqueueSnackbar } = useSnackbar();

    const [showCreate, setShowCreate] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const { loading, error, data, networkStatus, refetch } = useQuery(FETCH_SALE_ORIGINS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: rowsPerPage,
            page: currentPage + 1
        }
    });


    return (
        <>
            <Header onBack={() => {
                history.goBack()
            }} />

            <Container maxWidth="sm" style={{ marginTop: 86 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h1">Origem das vendas</Typography>
                    </CardContent>

                    <List
                        onDelete={() => {
                            enqueueSnackbar('Origem de venda excluída com sucesso!');
                            refetch();
                        }}
                        onEdit={(item) => setDataEdit(item)}
                        {...{ loading, error, data, networkStatus, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage }}
                    />

                </Card>
            </Container>

            <CreateDialog
                open={showCreate}
                handleClose={() => setShowCreate(false)}
                onCreate={() => {
                    enqueueSnackbar('Origem de venda cadastrada com sucesso!');
                    refetch();
                    setShowCreate(false);
                }}
            />

            <EditDialog
                open={dataEdit ? true : false}
                handleClose={() => setDataEdit(null)}
                data={dataEdit}
                onEdit={() => {
                    enqueueSnackbar('Origem de venda editada com sucesso!');
                    refetch();
                    setDataEdit(null);
                }}
            />

            <Fab color="primary" onClick={() => setShowCreate(true)} style={{ position: 'absolute', bottom: 48, right: 48 }}>
                <AddIcon />
            </Fab>

        </>
    )

}
import { v4 as uuidv4 } from "uuid";
import FieldOptionButton from "../components/DynamicForm/TypeOfComponents/FieldOptionButton";
import FieldCEPInput from "../components/DynamicForm/TypeOfComponents/FieldCEPInput";
import FieldCheckBox from "../components/DynamicForm/TypeOfComponents/FieldCheckBox";
import FieldCpfOrCnpj from "../components/DynamicForm/TypeOfComponents/FieldCpfOrCnpj";
import FieldDate from "../components/DynamicForm/TypeOfComponents/FieldDate";
import FieldDecimalInput from "../components/DynamicForm/TypeOfComponents/FieldDecimalInput";
import FieldEmail from "../components/DynamicForm/TypeOfComponents/FieldEmail";
import FieldNumericInput from "../components/DynamicForm/TypeOfComponents/FieldNumericInput";
import FieldSelectBox from "../components/DynamicForm/TypeOfComponents/FieldSelectBox";
import FieldText from "../components/DynamicForm/TypeOfComponents/FieldText";
import FieldTextEditor from "../components/DynamicForm/TypeOfComponents/FieldTextEditor";

const componentsDinamycForm = [
	{
		id: uuidv4(),
		label: "Texto",
		component: FieldText,
		type: "text",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Botão de opção",
		component: FieldOptionButton,
		type: "radioButton",
		isEditable: true,
		isRequired: true,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "CEP",
		component: FieldCEPInput,
		type: "cep",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Caixa Suspensa",
		component: FieldSelectBox,
		type: "combo",
		isEditable: true,
		isRequired: false,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "Caixa de seleção",
		component: FieldCheckBox,
		type: "checkbox",
		isEditable: true,
		isRequired: false,
		isOption: true,
		options: [
			{
				id: uuidv4(),
				label: "Opção 1",
				value: "Opção 1",
			},
		],
	},
	{
		id: uuidv4(),
		label: "Campo decimal",
		component: FieldDecimalInput,
		type: "decimal",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Campo numérico",
		component: FieldNumericInput,
		type: "number",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Cpf/Cnpj",
		component: FieldCpfOrCnpj,
		type: "cpf",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Data",
		component: FieldDate,
		type: "date",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "E-mail",
		component: FieldEmail,
		type: "email",
		isEditable: true,
		isRequired: false,
	},
	{
		id: uuidv4(),
		label: "Área de texto",
		component: FieldTextEditor,
		type: "textarea",
		isEditable: true,
		isRequired: false,
	},
];

export default componentsDinamycForm;
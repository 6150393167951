import { useMutation } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import InputGender from "../../../components/InputGender";
import InputRangeInterval from "../../../components/InputRangeInterval";
import InputWithQuerySearch from "../../../components/InputWithQuerySearch";
import {
  CREATE_TARGET_AUDIENCE,
  UPDATE_TARGET_AUDIENCE,
} from "../../../graphql/mutations";
import {
  FETCH_BANKS,
  FETCH_ENTITIES,
  FETCH_LOCATIONS,
  FETCH_SPECIES,
  FETCH_TYPES,
} from "../../../graphql/queries";

export default function EditDialog({ open, handleClose, onEdit, data }) {
  // Setting mutation
  const [updateTargetAudience, { loading }] = useMutation(
    UPDATE_TARGET_AUDIENCE
  );

  // Setting Formik
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: data || { filters: {} },
    onSubmit: (values) => {
      // Chamando a mutation de edição
      updateTargetAudience({
        variables: {
          id: values.id,
          name: values.name,
          filters: {
            age_group: values.filters.age_group,
            gender: values.filters.gender,
            location_id:
              values.filters.locations &&
              values.filters.locations.map((i) => i.id),
            count_loan_range: values.filters.count_loan_range,
            benefit: {
              salary_range: values.filters.benefit_salary_range,
              margin_loan_range: values.filters.benefit_margin_loan_range,
              bank_id:
                values.filters.benefit_banks &&
                values.filters.benefit_banks.map((i) => i.id),
              start_range: values.filters.benefit_start_range,
              entity_id:
                values.filters.benefit_entities &&
                values.filters.benefit_entities.map((i) => i.id),
              specie_id:
                values.filters.benefit_species &&
                values.filters.benefit_species.map((i) => i.id),
            },
            loan: {
              bank_id:
                values.filters.loan_banks &&
                values.filters.loan_banks.map((i) => i.id),
              type_id:
                values.filters.loan_types &&
                values.filters.loan_types.map((i) => i.id),
              amount_range: values.filters.loan_amount_range,
              portion_amount_range: values.filters.loan_portion_amount_range,
              debit_balance_range: values.filters.loan_debit_balance_range,
              tax_range: values.filters.loan_tax_range,
              portion_count_range: values.filters.loan_portion_count_range,
              portion_paid_count_range:
                values.filters.loan_portion_paid_count_range,
            },
          },
        },
      }).then(() => {
        onEdit();
      });
    },
  });

  useEffect(
    function () {
      if (data) {
        resetForm();
        setFieldValue("id", data.id);
        setFieldValue("name", data.name);
        setFieldValue("filters", data.filters);
      }
    },
    [data]
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">Editar público</DialogTitle>

        <DialogContent style={{ padding: 16 }}>
          <TextField
            variant="outlined"
            margin="normal"
            onChange={handleChange}
            value={values.name}
            required
            fullWidth
            id="name"
            label="Nome"
            name="name"
            autoFocus
            error={errors.name && true}
            helperText={errors.name}
          />

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginTop: 12 }}
          >
            Dados demográficos
          </Typography>

          <Grid container spacing={3}>
            <Grid item>
              <InputRangeInterval
                title="Faixa Etária"
                labelBlank="Todas"
                value={values.filters.age_group}
                onChange={(value) => setFieldValue("filters.age_group", value)}
              />
            </Grid>
            <Grid item>
              <InputGender
                labelBlank="Todas"
                value={values.filters.gender}
                onChange={(value) => setFieldValue("filters.gender", value)}
              />
            </Grid>
            <Grid item>
              <InputWithQuerySearch
                title="Localização"
                labelBlank="Todas"
                query={FETCH_LOCATIONS}
                fetchResult="fetchLocations"
                renderLabel={(i) =>
                  i.is_city ? i.name + "/" + i.state : i.name + " (estado)"
                }
                value={values.filters.locations}
                onChange={(value) => setFieldValue("filters.locations", value)}
              />
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginTop: 24 }}
          >
            Dados financeiros dos benefícios
          </Typography>

          <Grid container spacing={3}>
            <Grid item>
              <InputRangeInterval
                title="Salario (R$)"
                labelBlank="Todos"
                value={values.filters.benefit_salary_range}
                onChange={(value) =>
                  setFieldValue("filters.benefit_salary_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Margem (R$)"
                labelBlank="Todas"
                value={values.filters.benefit_margin_loan_range}
                onChange={(value) =>
                  setFieldValue("filters.benefit_margin_loan_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputWithQuerySearch
                title="Banco de pagamento"
                labelBlank="Todos"
                query={FETCH_BANKS}
                fetchResult="fetchBanks"
                value={values.filters.benefit_banks}
                onChange={(value) =>
                  setFieldValue("filters.benefit_banks", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="DIB"
                type="date"
                labelBlank="Todas"
                value={values.filters.benefit_start_range}
                onChange={(value) =>
                  setFieldValue("filters.benefit_start_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputWithQuerySearch
                title="Entidade"
                labelBlank="Todas"
                query={FETCH_ENTITIES}
                fetchResult="fetchEntities"
                value={values.filters.benefit_entities}
                onChange={(value) =>
                  setFieldValue("filters.benefit_entities", value)
                }
              />
            </Grid>
            <Grid item>
              <InputWithQuerySearch
                title="Espécie"
                labelBlank="Todas"
                query={FETCH_SPECIES}
                fetchResult="fetchSpecies"
                value={values.filters.benefit_species}
                onChange={(value) =>
                  setFieldValue("filters.benefit_species", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Qtd. emprestimos"
                labelBlank="Todas"
                value={values.filters.count_loan_range}
                onChange={(value) =>
                  setFieldValue("filters.count_loan_range", value)
                }
              />
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginTop: 24 }}
          >
            Dados de empréstimos realizados
          </Typography>

          <Grid container spacing={3}>
            <Grid item>
              <InputWithQuerySearch
                title="Banco"
                labelBlank="Todos"
                query={FETCH_BANKS}
                fetchResult="fetchBanks"
                value={values.filters.loan_banks}
                onChange={(value) => setFieldValue("filters.loan_banks", value)}
              />
            </Grid>
            <Grid item>
              <InputWithQuerySearch
                title="Tipo"
                labelBlank="Todos"
                query={FETCH_TYPES}
                fetchResult="fetchTypes"
                value={values.filters.loan_types}
                onChange={(value) => setFieldValue("filters.loan_types", value)}
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Valor do emprestimo (R$)"
                labelBlank="Todos"
                value={values.filters.loan_amount_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_amount_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Valor da parcela (R$)"
                labelBlank="Todos"
                value={values.filters.loan_portion_amount_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_portion_amount_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Saldo devedor (R$)"
                labelBlank="Todos"
                value={values.filters.loan_debit_balance_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_debit_balance_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Taxa (%)"
                labelBlank="Todas"
                value={values.filters.loan_tax_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_tax_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Qtd. Parcelas"
                labelBlank="Todas"
                value={values.filters.loan_portion_count_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_portion_count_range", value)
                }
              />
            </Grid>
            <Grid item>
              <InputRangeInterval
                title="Parcelas pagas"
                labelBlank="Todas"
                value={values.filters.loan_portion_paid_count_range}
                onChange={(value) =>
                  setFieldValue("filters.loan_portion_paid_count_range", value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={values.name == "" || loading}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: 12 }} />
            )}
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import TextCopy from "./TextCopy";
import { format } from "date-fns";
import Button from "./Button";

export default function ModalOutputs({
    open,
    handleClose,
    formDisplay
}) {

    const dynamicFields = JSON.parse(formDisplay)

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Dados da pós-venda</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {
                        dynamicFields.length <= 0 ?

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height={100}
                                width="100%"
                            >
                                <Typography textAlign="center">Sem dados a serem recuperados</Typography>
                            </Box>

                            :

                            dynamicFields.map((item, index) => {

                                let itemValue = "";

                                if (item.type === "date") itemValue = format(new Date(item.value), 'dd/MM/yyyy');
                                if (item.type === "decimal") itemValue = "R$ " + item.value;
                                if (item.type === "checkbox") itemValue = item.value.map(option => option ? `${option} | ` : "");
                                if (
                                    item.type === "text" ||
                                    item.type === "textarea" ||
                                    item.type === "radioButton" ||
                                    item.type === "email" ||
                                    item.type === "cep" ||
                                    item.type === "number" ||
                                    item.type === "combo" ||
                                    item.type === "cpf"
                                ) itemValue = item.value;

                                return (
                                    <Grid key={index} item xs={12} sm={6}>
                                        <TextCopy copy={false} title={item.label} value={itemValue} />
                                    </Grid>
                                )
                            })
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
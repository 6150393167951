import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { TextField, Grid, FormHelperText, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

function FieldDate({ label, name, required, readonly, index }) {
  const { control } = useFormContext();

  return (
    <Grid item xs={12}>
      <Controller
        rules={{
          required: required ? "Campo obrigatório" : false,
        }}
        defaultValue={new Date()}
        name={`groups.${index}.fields.${name}`}
        control={control}
        render={(inputProps) => (
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                label={label}
                format="dd/MM/yyyy"
                value={inputProps.field.value}
                fullWidth
                onChange={(newValue) => inputProps.field.onChange(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </MuiPickersUtilsProvider>

            {inputProps.fieldState.error && (
              <FormHelperText sx={{ color: "error.main" }}>
                {inputProps.fieldState.error.message + ""}
              </FormHelperText>
            )}
          </Box>
        )}
      />
    </Grid>
  );
}

export default FieldDate;
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, CircularProgress } from "@material-ui/core";
import Header from "../../../components/Header";
import LeadDetail from "../../../components/LeadDetail";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import { DETAIL_LEAD, FETCH_OPPORTUNITIES, FETCH_TAGS } from "../../../graphql/queries";
import { useSnackbar } from "notistack";
import useToken from "../../../tokenJWT";
import MascoteManual from "../../../components/MascoteManual";
import DialogAlert from "../../../components/DialogAlert";
import UpdateCrm from "./UpdateCrm";

export default function LeadDetailPage() {
  const history = useHistory();
  const { user } = useToken();
  const location = useLocation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openManual, setOpenManual] = useState(false);
  const [tab, setTab] = useState(0);
  const [isLeadSubmitted, setIsLeadSubmitted] = useState(false);
  const [message, setMessage] = useState(false);
  const [crm, setCrm] = useState(false);
  const campaign_id = location.state?.campaign_id
    ? location.state?.campaign_id.replace("=", "")
    : null;

  const campaign_name = location.state?.unformated_campaign_name || null;

  const { data, loading, refetch } = useQuery(DETAIL_LEAD, {
    fetchPolicy: "network-only",
    variables: {
      id: location.state
        ? parseInt(location.state.id.replace("=", ""))
        : parseInt(id),
    },
  });

  const { data: dataOpp, refetch: refetchOpp } = useQuery(FETCH_OPPORTUNITIES, {
    variables: {
      lead_id: location.state
        ? parseInt(location.state.id.replace("=", ""))
        : null,
      campaign_id: campaign_id ? parseInt(campaign_id) : null,
    },
  });

  const { data: dataTags, loading: loadingTags } = useQuery(FETCH_TAGS, {
    variables: {
      type: "Produto"
    }
  })

  const benefitsNumbers = !data
    ? []
    : data.detailLead.benefits.map((i) => i.number);

  const handleLeadAction = async (value) => {
    await new Promise((resolve) => setTimeout(resolve, 500))
    await refetch();
    await refetchOpp();
  };

  useEffect(() => {
    setTimeout(() => {
      if (campaign_id !== null) {
        setOpenManual(true);
      }
    }, 60000);
  }, [
    setOpenManual,
    campaign_id,
    user.is_sale_consultant,
    isLeadSubmitted,
  ]);

  useEffect(() => {
    if (data && dataTags) {
      const requiredIds = dataTags.fetchTags.data
        .filter(item => item.required === true)
        .map(item => item.id);

      const isEligible = requiredIds.every(requiredId => {
        const foundTag = data.detailLead.tags.find(tag => tag.id === requiredId);
        return foundTag && foundTag.eligible !== null;
      });

      setIsLeadSubmitted(isEligible)
    }
  }, [data])

  return (
    <>
      <Header
        tab={tab}
        tabs={benefitsNumbers.length > 1 && benefitsNumbers}
        onChangeTab={benefitsNumbers.length > 1 && ((i) => setTab(i))}
        title={
          user && user.is_sale_consultant
            ? campaign_id !== null
              ? `Atendimento Campanha Manual ${campaign_name}`
              : "Atendimento Manual"
            : "Sim Crédito"
        }
        onBack={
          user && user.is_sale_consultant
            ? () => history.push("/campanha")
            : () => history.goBack()
        }
        isNotShowIconButton={campaign_id !== null}
        campaign_id={campaign_id}
        isShowUpdateButton
        onClickUpdate={
          data?.detailLead?.document !== null ? () => setCrm(true) : undefined
        }
        isShowExitButton={Boolean(campaign_id)}
        isDisabledExitButton={!isLeadSubmitted}
        updateButtonTooltip={
          data?.detailLead?.document === null
            ? "É necessário o número do CPF para atualização do dados"
            : "Atualizar os dados do cliente"
        }
        shouldDisableUpdateButton={data?.detailLead?.document === null}
      />

      <Container
        maxWidth="lg"
        style={{
          marginTop: benefitsNumbers.length > 1 ? 150 : 85,
        }}
      >
        {loading && <CircularProgress />}
        {data?.detailLead && (
          <>
            <LeadDetail
              {...{ refetch, campaign_id }}
              data={data.detailLead}
              tab={tab}
              onRescheduled={async (value) => {
                enqueueSnackbar("Reagendamento realizado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onUninterested={async (value) => {
                enqueueSnackbar("Desinteresse registrado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onUnprofile={async (value) => {
                enqueueSnackbar("Lead registrado como sem perfil !", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onInvalidatedContact={async (value) => {
                enqueueSnackbar("Contato invalidado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onInNegotiation={async (value) => {
                enqueueSnackbar("Negociação iniciada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onIndication={async (value) => {
                enqueueSnackbar("Indicação registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onProposal={() => {
                refetch();
                refetchOpp();
                if (campaign_id !== null) {
                  setOpenManual(true);
                } else if (user.is_sale_consultant) {
                  window.location.href = "/";
                }
              }}
              onEditPhone={() => {
                enqueueSnackbar("Telefone editado com sucesso!");
                refetch();
              }}
              onCreatePhone={() => {
                enqueueSnackbar("Telefone cadastrado com sucesso!");
                refetch();
              }}
              onEditDebitAccount={() => {
                enqueueSnackbar("Débito em conta editado com sucesso!");
                refetch();
              }}
              onCallEnd={async () => {
                enqueueSnackbar("Ligação encerrada confirmada!");
                await refetch();
                setTimeout(() => {
                  if (campaign_id !== null) {
                    setOpenManual(true);
                  } else if (user.is_sale_consultant) {
                    window.location.href = "/";
                  }
                }, 500);
              }}
              onTag={async (value) => {
                enqueueSnackbar("Tag registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onDebit={async (value) => {
                enqueueSnackbar("Inadimplência registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
            />
            {user && user.is_sale_consultant && (
              <>
                {openManual && (
                  <MascoteManual
                    open={openManual}
                    setOpenManual={setOpenManual}
                    campaign_id={campaign_id}
                    campaign_name={campaign_name}
                    data={data}
                    tab={tab}
                    refetch={refetch}
                    handleClose={() => setOpenManual(false)}
                    isDisableContinue={!isLeadSubmitted}
                  />
                )}
              </>
            )}
            <DialogAlert
              open={message ? true : false}
              onClose={() => setMessage(false)}
            />
            <UpdateCrm
              open={crm ? true : false}
              onClose={() => setCrm(false)}
              data={data}
              refetch={refetch}
              loading={loading}
            />
          </>
        )}
      </Container>
    </>
  );
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_DEBIT_ACCOUNT } from "../../graphql/mutations";
import SelectWithQuerySearch from "../SelectWithQuerySearch";
import { FETCH_BANKS } from "../../graphql/queries";
import { maskMoney } from "../../helpers";

export default function DialogEditDebitAcc({
  open,
  handleClose,
  initialValues,
  onEdit,
}) {
  const [updateDebitAccount, updateDebitAccountMutation] =
    useMutation(UPDATE_DEBIT_ACCOUNT);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const floatValue = parseFloat(
        values.portion_amount.replace(".", "").replace(",", ".")
      );
      updateDebitAccount({
        variables: {
          id: values.id,
          bank_id: values.bank?.id,
          portion_amount: floatValue,
          portion_count: parseInt(values.portion_count),
          portion_paid_count: parseInt(values.portion_paid_count),
        },
      }).then(() => {
        onEdit();
      });
    },
  });

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Editar débito em conta</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Bancos"
              query={FETCH_BANKS}
              fetchResult="fetchBanks"
              onChange={(bank) => {
                setFieldValue("bank", bank);
              }}
              fullWidth
              value={values.bank}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              value={values.portion_amount}
              onChange={(e) =>
                setFieldValue("portion_amount", maskMoney(e.target.value))
              }
              fullWidth
              label="Valor Parcela"
              error={errors.portion_amount && true}
              helperText={errors.portion_amount}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              variant="outlined"
              label="Qtd. Parcelas"
              value={values.portion_count}
              name="portion_count"
              onChange={handleChange}
              fullWidth
              error={errors.portion_count}
              helperText={errors.portion_count}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              variant="outlined"
              label="Qtd. Pagas"
              value={values.portion_paid_count}
              name="portion_paid_count"
              onChange={handleChange}
              fullWidth
              error={errors.portion_paid_count}
              helperText={errors.portion_paid_count}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={updateDebitAccountMutation.loading}
        >
          {updateDebitAccountMutation.loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

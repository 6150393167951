import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from "@material-ui/core";
import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ModalExportCSV } from "./ModalExportCSV";
import { useSnackbar } from "notistack";
import SearchInputDebounced from "../../../../../components/SearchInputDebounced";

const translationMapping = {
  Valor: "release_value",
  "Descrição": "release_description",
  "Lançado em": "release_release_at",
  "Empresa": "company_name",
  "Categoria": "category_title",
  Origem: "category_type",
  Tipo: "subcategory_title"
};

export default function ModalFilterCsv({ isOpen, setIsOpen, filter }) {
  const [newValue, setNewValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    "Valor",
    "Descrição",
    "Lançado em",
    "Empresa",
    "Categoria",
    "Origem",
    "Tipo"
  ];

  const [searchTerm, setSearchTerm] = useState(null);

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    let index = newValue.findIndex((i) => i === value);

    if (index >= 0 && e.target.checked === false) deleteItem(index);
    else {
      let clone = [...newValue];
      clone.push(value);
      setNewValue(clone);
    }
  };

  const deleteItem = (index) => {
    let clone = [...newValue];
    clone.splice(index, 1);
    setNewValue(clone);
  };

  const handleSelect = () => {
    if (newValue.length > 0) {
      setShowModal(true);
    } else {
      enqueueSnackbar("Selecione pelo menos 1(uma) coluna", {
        variant: "error",
      });
    }
  };

  const handleClearFilter = () => {
    setNewValue([]);
    setSearchTerm(null);
  };

  const filteredColumn = columns.filter((column) => {
    if (searchTerm == null) return column;
    else if (column.toLowerCase().includes(searchTerm.toLowerCase())) {
      return column;
    }
  });

  const translateToEnglish = (portugueseDescription) => {
    return translationMapping[portugueseDescription] || portugueseDescription;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md">
      <DialogTitle>{"Colunas"}</DialogTitle>
      <DialogContent>
        <SearchInputDebounced
          typeSearch="inDialog"
          onChange={(value) => {
            setSearchTerm(value);
          }}
        />

        <div style={{ marginTop: 20, width: 300 }}>
          {newValue.map((element, index) => {
            return <Chip label={element} onDelete={() => deleteItem(index)} />;
          })}
        </div>

        <div
          style={{
            marginTop: 20,
            height: 300,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormGroup>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {filteredColumn.map((column) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newValue.includes(column)}
                      value={column}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label={column}
                />
              ))}
            </div>
          </FormGroup>
        </div>
        {showModal && (
          <ModalExportCSV
            open={showModal}
            handleClose={() => {
              setShowModal(false);
              setIsOpen(false);
            }}
            filter={filter}
            columns={newValue.map(translateToEnglish)}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClearFilter} color="primary">
          Limpar
        </Button>
        <Button onClick={handleSelect} variant="contained" color="primary">
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

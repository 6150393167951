
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import {FETCH_TEAMS, FETCH_SALE_CONSULTANTS} from "../../../../graphql/queries";

export default function FilterModal({ open, onApply, onClose }) {

    const [keyword, setKeyword] = useState();
    const [team, setTeam] = useState();

    const handleClickFilter = () => {
        onApply({
            keyword: keyword,
            team_id: team?.id
        });
    }

    const handleClearFilter = () => {
        setKeyword(null);
        setTeam(null);

        onApply({
            keyword: null,
            team: null,
        });
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Filtro de Consultores</DialogTitle>
            <DialogContent>

                <Grid container spacing={2}>
   
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            label="Nome"
                            fullWidth
                            query={FETCH_SALE_CONSULTANTS}
                            fetchResult="fetchSaleConsultants"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectWithQuerySearch
                            title="Equipe"
                            query={FETCH_TEAMS}
                            fetchResult="fetchTeams"
                            fullWidth
                            value={team}
                            onChange={(event) => { setTeam(event) }}
                        />
                    </Grid>
              
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClearFilter}
                    color="primary"
                >
                    Limpar
                </Button>
                <Button
                    onClick={handleClickFilter}
                    variant="contained"
                    color="primary"
                >
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    )

}
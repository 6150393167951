import React, { useState } from "react";
import {
  Popover,
  Typography,
  TextField,
  Box,
  IconButton,
  Divider,
  TextareaAutosize,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SelectFilterModalAnnouncement from "../SelectFilterModalAnnouncement";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import ColorPicker from "../../components/ColorPicker";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { CREATE_ANNOUNCEMENT } from "../../graphql/mutations";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

function PopoverAnnouncement({ open, onClose, refetch }) {
  const [newValue, setNewValue] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [createAnnouncement, { loading }] = useMutation(CREATE_ANNOUNCEMENT);

  const formikProps = useFormik({
    initialValues: {
      title: "",
      description: "",
      theme: "#68bc4f",
      profiles: [],
      expire_at: "",
      files: [],
    },
    onSubmit: (values) => {
      createAnnouncement({
        variables: {
          title: values.title,
          description: values.description,
          theme: values.color || "#68bc4f",
          profiles: newValue,
          expire_at: format(startDate, "yyyy-MM-dd HH:mm:ss"),
          files: values.files,
        },
      }).then(() => {
        resetForm();
        enqueueSnackbar("Comunicado enviado com sucesso!", {
          variant: "success",
        });
        onClose();
        refetch();
      });
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    resetForm,
    handleChange,
  } = formikProps;
  
  const files = formikProps.values.files;

  const fileTypes = ["SVG", "JPG", "PNG", "GIF", "PDF"];

  const handleAddFiles = (e) => {
    const newFiles = e;
    const filesArray = [newFiles];

    if (
      files &&
      filesArray.filter((fileArray) =>
        files.find((file) => file.name === fileArray.name)
      ).length > 0
    ) {
      enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
      return;
    }

    const concatedFiles = files ? [...files, ...filesArray] : filesArray;

    if (concatedFiles.length > 5) {
      enqueueSnackbar("Você atingiu o limite máximo de 5 arquivos", {
        variant: "error",
      });
      return;
    }

    formikProps.setFieldValue("files", concatedFiles);
  };

  const filePreview = (file) => {
    const fileURL = URL.createObjectURL(file);
    return (
      <Box>
        <Link
          variant="subtitle1"
          fontSize="1rem"
          target="_blank"
          rel="norefferer"
          href={fileURL}
        >
          {file.name}
        </Link>
      </Box>
    );
  };

  const handleDeleteFile = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);

    formikProps.setFieldValue("files", newFiles);
  };

  const handleSizeError = (file) => {
    enqueueSnackbar("O arquivo supera o limite máximo de 5MB", {
      variant: "error",
    });
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Escrever comunicado</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 1, mb: 4 }} />
        <SelectFilterModalAnnouncement
          variant="standard"
          newValue={newValue}
          setNewValue={setNewValue}
          title="Destinatários"
          fullWidth
          onChange={(value) => {
            setNewValue(value);
          }}
        />
        <TextField
          variant="standard"
          label="Assunto"
          fullWidth
          sx={{ mt: 2, mb: 4 }}
          onChange={handleChange}
          value={values.title}
          name="title"
        />
        <TextareaAutosize
          placeholder="Digite seu comunicado aqui..."
          minRows={15}
          maxRows={30}
          style={{ width: "96%", padding: "8px", border: "none" }}
          onChange={handleChange}
          value={values.description}
          name="description"
        />
        <Box spacing={2} marginTop={2}>
          {files && files.length > 0 ? (
            files.map((file) => (
              <Box
                display="flex"
                columnGap={2}
                alignItems="center"
                key={file.name}
              >
                <Box>
                  <AttachFileIcon style={{ fontSize: 16 }}/>
                </Box>
                {filePreview(file)}
                <Box>
                  <IconButton onClick={() => handleDeleteFile(file)}>
                    <DeleteOutlineIcon color="error" style={{ fontSize: 20 }} />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="subtitle2">Nenhum arquivo anexado</Typography>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
            >
              Enviar
            </Button>
          )}
          <FileUploader
            handleChange={(e) => handleAddFiles(e)}
            name="file"
            types={fileTypes}
            onSizeError={(e) => handleSizeError(e)}
            maxSize={5}
          >
            <Box
              sx={{
                cursor: "pointer",
                border: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: "#cfeffd",
                  width: "40px",
                  color: "#2979ff",
                }}
              >
                <AttachFileIcon style={{ fontSize: 26, cursor: "pointer" }} />
              </IconButton>
            </Box>
          </FileUploader>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              disablePast
              inputVariant="outlined"
              label="Prazo de expiração"
              format="dd/MM/yyyy"
              value={startDate}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date) => setStartDate(date)}
            />
          </MuiPickersUtilsProvider>
          <ColorPicker
            selectedColor={values.color}
            onSelectColor={(color) => setFieldValue("color", color)}
          />
        </Box>
      </Box>
    </Popover>
  );
}

export default PopoverAnnouncement;

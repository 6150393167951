import {
  Container,
  Card,
  CardContent,
  Button,
  TextField,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import logoSintegra from "../../assets/logo.png";
import LogoFastway from "../../assets/fastway.png";
import * as Yup from "yup";
import { MUTATION_AUTH } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import useToken from "../../tokenJWT";
import { useSnackbar } from "notistack";

export default function Login({ onLogin }) {
  // Setup GraphQL
  const [authMutation, { loading }] = useMutation(MUTATION_AUTH);
  const { enqueueSnackbar } = useSnackbar();

  // Token
  const { setToken } = useToken();

  // Formik
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      authMutation({ variables: values })
        .then(({ data }) => {
          setToken(data.auth);
          onLogin();
        })
        .catch((error) => {
          enqueueSnackbar(
            "Falha na autenticação. Verifique email e senha informados.",
            { variant: "error" }
          );
        });
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: "#19857b",
        }}
      >
        <img src={logoSintegra} alt="Logo" style={{ width: 200 }} />
        <div style={{ fontSize: 24, color: "#FFF", margin: "0px 20px" }}>+</div>
        <img src={LogoFastway} alt="Logo Fastway" style={{ width: 200 }} />
      </div>
      <Container maxWidth="sm">
        <Box mt={5}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card elevation={4}>
                <CardContent>
                  <form onSubmit={handleSubmit} noValidate>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoFocus
                      error={errors.email && true}
                      helperText={errors.email}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      onChange={handleChange}
                      value={values.password}
                      required
                      fullWidth
                      name="password"
                      label="Senha"
                      type="password"
                      id="password"
                      error={errors.password && true}
                      helperText={errors.password}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{ marginRight: 6 }}
                        />
                      )}
                      Entrar
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

import { Dialog, DialogContent, DialogTitle, Grid, TextField, Button, CircularProgress, MenuItem, DialogActions } from "@material-ui/core";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_LEAD_PHONE } from "../../graphql/mutations";
import { maskPhone, unmask } from "../../helpers";
import * as Yup from 'yup';

export default function DialogAddPhone({ open, lead_id, handleClose, onCreate }) {

    const [createLeadPhone, createLeadPhoneMutation] = useMutation(CREATE_LEAD_PHONE);

    const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
        initialValues: {},
        validationSchema: Yup.object().shape({
            number: Yup.string().required('Campo obrigatório').nullable(),
            is_whatsapp: Yup.bool().required('Campo obrigatório').nullable(),
        }),
        onSubmit: (values) => {
            createLeadPhone({
                variables: {
                    lead_id: lead_id,
                    number: values.number,
                    is_whatsapp: values.is_whatsapp
                }
            }).then(() => {
                onCreate();
            })
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Adicionar telefone</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Número"
                            value={values.number}
                            name="number"
                            onChange={(e) => setFieldValue('number', maskPhone(e.target.value))}
                            fullWidth
                            error={errors.number}
                            helperText={errors.number}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            select
                            variant="outlined"
                            label="É WhatsApp?"
                            value={values.is_whatsapp}
                            name="is_whatsapp"
                            onChange={handleChange}
                            fullWidth
                            error={errors.is_whatsapp}
                            helperText={errors.is_whatsapp}
                        >
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                        </TextField>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={createLeadPhoneMutation.loading}>
                    {createLeadPhoneMutation.loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

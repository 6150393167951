import { Card, Grid, Typography, CardContent, CardHeader } from "@material-ui/core";
import TextCopy from "../../../components/TextCopy";

export default function DefinedPublic({ data: { age_group, gender, benefit, loan } }) {


    return (
        <Card style={{ marginTop: 24 }}>
            <CardHeader title="Público definido" />
            <CardContent>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Dados demográficos</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Faixa Etária" value={formatStartFinish(age_group)} />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Gênero" value={gender || '-'} />
                    </Grid>
                    <Grid item xs={12} xs={6}>
                        <TextCopy copy={false} title="Localização" value="-" />
                    </Grid>
                </Grid>

                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 24 }}>Dados financeiros dos benefícios</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Salário (R$)" value={formatStartFinish(benefit.salary_range)} />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Margem (R$)" value={formatStartFinish(benefit.margin_loan_range)}  />
                    </Grid>
                    <Grid item xs={12} xs={6}>
                        <TextCopy copy={false} title="Banco de pagamento" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="DIB" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Entidade" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={6}>
                        <TextCopy copy={false} title="Espécie" value="-" />
                    </Grid>
                </Grid>

                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 24 }}>Dados de empréstimos realizados</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Banco" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Tipo" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Valor emprestimo (R$)" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Valor parcela (R$)" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Saldo devedor (R$)" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Taxa (%)" value="-" />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Qtd. Parcelas" value={formatStartFinish(loan.portion_count_range)} />
                    </Grid>
                    <Grid item xs={12} xs={3}>
                        <TextCopy copy={false} title="Parcelas pagas" value="-" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

}

const formatStartFinish = (obj) => {
    if (!obj)
        return '-';

    if (obj.start != undefined && obj.finish != undefined) {
        return "De " + obj.start + " até " + obj.finish;
    }

    if (obj.start != undefined && obj.finish == undefined) {
        return "Acima de " + obj.start;
    }

    if (obj.start == undefined && obj.finish != undefined) {
        return "Abaixo de " + obj.finish;
    }

    return '-';
}
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FETCH_SALE_CONSULTANTS } from "../../../../graphql/queries";
import {
  Container,
  Card,
  Typography,
  CardContent,
  Fab,
  IconButton,
  Grid,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import List from "./List";
import CreateDialog from "./CreateDialog";
import AddIcon from "@material-ui/icons/Add";
import EditDialog from "./EditDialog";
import EditPasswordDialog from "./EditPasswordDialog";
import DeleteDialog from "./DeleteDialog";
import { FilterListSharp } from "@material-ui/icons";
import FilterModal from "./FilterModal";

export default function SaleConsultantPage({ history }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [dataEditPassword, setDataEditPassword] = useState();

  const [showDelete, setShowDelete] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_SALE_CONSULTANTS,
    {
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
      },
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      <Header
        onBack={() => {
          history.goBack();
        }}
      />

      <Container maxWidth="md" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography variant="h5" component="h1">
                  Consultores
                </Typography>
              </Grid>

              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton onClick={() => handleOpen(true)}>
                  <FilterListSharp fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>

          {data &&
            <List
              onDelete={(item) => setShowDelete(item)}
              onEdit={(item) => setDataEdit(item)}
              {...{
                loading,
                error,
                data,
                networkStatus,
                currentPage,
                setCurrentPage,
                rowsPerPage,
                setRowsPerPage,
              }}
              onEditPassword={(item) => setDataEditPassword(item)}
            />
          }
        </Card>
      </Container>

      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          enqueueSnackbar("Consultor cadastrado com sucesso!");
          refetch();
          setShowCreate(false);
        }}
      />

      {
        dataEdit &&
        <EditDialog
          open={dataEdit ? true : false}
          handleClose={() => setDataEdit(null)}
          data={dataEdit}
          onEdit={() => {
            enqueueSnackbar("Consultor editado com sucesso!");
            refetch();
            setDataEdit(null);
          }}
        />
      }

      <EditPasswordDialog
        open={dataEditPassword ? true : false}
        handleClose={() => setDataEditPassword(null)}
        data={dataEditPassword}
        onEditPassword={() => {
          enqueueSnackbar("Senha do consultor alterada com sucesso!");
          refetch();
          setDataEditPassword(null);
        }}
      />

      <DeleteDialog
        open={showDelete ? true : false}
        handleClose={() => setShowDelete(null)}
        data={showDelete}
        onDelete={() => {
          enqueueSnackbar("Consultor excluído com sucesso!");
          refetch();
          setShowDelete(null);
        }}
      />

      <Fab
        color="primary"
        onClick={() => setShowCreate(true)}
        style={{ position: "fixed", bottom: 80, right: 48 }}
      >
        <AddIcon />
      </Fab>

      <FilterModal
        open={open}
        onClose={() => setOpen(false)}
        onApply={(values) => {
          refetch(values);
          setOpen(false);
        }}
      />
    </>
  );
}

import { LinearProgressWithLabel } from "../../../../components/LinearProgress";
import { Box, Dialog } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CSVDownloader } from "./CSVDownloader";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FETCH_DEBTORS } from "../../../../graphql/queries";
import { maskCPF, maskMoney, maskPhone } from "../../../../helpers";

function formatDate(dataString) {
  const data = new Date(`${dataString}T00:00:00-03:00`);
  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export function ModalExportCSV({ open, handleClose, filter, columns }) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataExport, setDataExport] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery(FETCH_DEBTORS, {
    fetchPolicy: "no-cache",
    variables: {
      page: page,
      limit: 100,
      start: filter.start ? filter.start : null,
      finish: filter.finish ? filter.finish : null,
      deleted: filter.deleted ? filter.deleted : null,
    },
  });

  const translationMapping = {
    document: "CPF",
    name: "Nome",
    amount: "Débito",
    phones: "Telefone",
    created_at: "Data do débito",
    deleted_at: "Débito excluído",
  };

  const generatePhoneColumnHeaders = () => {
    const phoneHeaders = [];
    for (let i = 1; i <= 5; i++) {
      phoneHeaders.push(`Tel ${i}`);
    }
    return phoneHeaders;
  };

  const generatePhoneColumnCells = (debit) => {
    const phoneCells = [];
    const phones = debit.lead.phones || {};

    for (let i = 0; i < 5; i++) {
      const phone = phones[i] || {};
      if (phone.number !== null && phone.number !== undefined) {
        phoneCells.push(maskPhone(phone.number));
      } else {
        phoneCells.push("-");
      }
    }
    return phoneCells;
  };

  const translatedHeaders = columns.flatMap((column) => {
    switch (column) {
      case "phones":
        return generatePhoneColumnHeaders();
      default:
        return translationMapping[column];
    }
  });

  const rowsCsv = dataExport.map((debit) => {
    const row = [];

    columns.forEach((column) => {
      switch (column) {
        case "document":
          row.push(debit.lead.document ? maskCPF(debit.lead.document) : "-");
          break;
        case "name":
          row.push(debit.lead.name ? debit.lead.name : "-");
          break;
        case "phones":
          row.push(...generatePhoneColumnCells(debit));
          break;
        case "amount":
          row.push(debit.amount ? `R$ ${maskMoney(debit.amount)}` : "-");
          break;
        case "created_at":
          row.push(debit.from_date ? formatDate(debit.from_date) : "-");
          break;
        case "deleted_at":
          row.push(debit.deleted_at ? debit.deleted_at : "-");
          break;
        default:
          row.push("-");
          break;
      }
    });

    return row;
  });

  useEffect(() => {
    if (data) {
      setTotal(data?.fetchDebtors?.total);
      setDataExport([...dataExport, ...data?.fetchDebtors?.data]);
      if (
        data?.fetchDebtors?.total > dataExport.length &&
        page < data?.fetchDebtors?.last_page
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [data]);

  const percentageDataExport = (dataExport.length * 100) / total;

  if (percentageDataExport === 100) {
    enqueueSnackbar("Exportação concluída com sucesso!", {
      variant: "success",
      preventDuplicate: true,
    });
    setTimeout(() => {
      handleClose();
    }, 2000);
  }

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth>
      <DialogTitle>{"Exportar CSV"}</DialogTitle>
      <DialogContent>
        {data && data?.fetchDebtors?.total === dataExport.length && (
          <CSVDownloader rowsCsv={rowsCsv} headersCsv={translatedHeaders} />
        )}
        <Box sx={{ width: "100%" }}>
          {loading && page === 1 ? (
            <CircularProgress />
          ) : (
            <LinearProgressWithLabel
              value={percentageDataExport}
              current={dataExport.length}
              total={total}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

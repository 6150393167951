import { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { formatMoneyBRL } from '../../../helpers';
import EditIcon from '@material-ui/icons/Edit';
import EditInfoResume from "./EditInfoResume";

export default function InfoResume({ data }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '80px', height: '80px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
            <Typography style={{ fontSize: '18px' }}><b>{data.customer.name}</b></Typography>
            <Typography style={{ fontSize: '13px' }}>{data.customer.birthdate?.age == undefined ? "" : data.customer.birthdate?.age + " anos, "} Belo Horizonte/MG</Typography>
        </div>

    )
}
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import DialogNoInterest from "./DialogNoInterest";
import DialogIndication from "./DialogIndication";
import DialogNoProfile from "./DialogNoProfile";
import DialogInNegotiation from "./DialogInNegotiation";
import DialogOpenProposal from "../../pages/sales-consultant/DashboardPage/DialogOpenProposal";

export default function DialogButtonsOportunidads({
  data,
  opportunity_id,
  open,
  onClose,
  handleClose,
  onUninterested,
  onInNegotiation,
  onUnprofile,
  onIndication,
  onProposal,
  title,
  campaign_id,
}) {
  const [showIndicate, setShowIndicate] = useState(false);
  const [showInNegotiation, setShowInNegotiation] = useState(false);
  const [showNoInterest, setShowNoInterest] = useState(false);
  const [showNoProfile, setShowNoProfile] = useState(false);
  const [showOpenProposal, setShowOpenProposal] = useState(false);

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Button
          onClick={() => setShowIndicate(true)}
          size="large"
          style={{ padding: "8px", width: "100%", marginBottom: 12 }}
          variant="outlined"
          className={classes.indicateButton}
        >
          Indicar
        </Button>
        <Button
          onClick={() => setShowNoProfile(true)}
          size="large"
          style={{ padding: "8px", width: "100%", marginBottom: 12 }}
          variant="outlined"
          className={classes.deleteButton}
        >
          Sem perfil
        </Button>
        <Button
          onClick={() => setShowNoInterest(true)}
          size="large"
          style={{ padding: "8px", width: "100%", marginBottom: 12 }}
          variant="outlined"
          color="primary"
        >
          Sem interesse
        </Button>
        <>
          <Button
            disabled={data.negotiation_expire_at > 0 ? true : false}
            onClick={() => setShowInNegotiation(true)}
            size="large"
            style={{ padding: "8px", width: "100%", marginBottom: 12 }}
            variant="outlined"
            color="secondary"
          >
            {data.negotiation_expire_at > 0 ? (
              <Typography
                variant="abbr"
                title={`Tente novamente em ${data.negotiation_expire_at} dias`}
              >
                Negociação disponível em {data.negotiation_expire_at} dias
              </Typography>
            ) : (
              <Typography>Em negociação</Typography>
            )}
          </Button>
        </>
        <Button
          onClick={() => setShowOpenProposal(true)}
          size="large"
          style={{ padding: "8px", width: "100%", marginBottom: 12 }}
          variant="contained"
          color="primary"
        >
          Venda Realizada
        </Button>
      </DialogContent>

      <DialogIndication
        lead_id={data.id}
        campaign_id={campaign_id}
        lead_name={data.name}
        user_name={title}
        opportunity_id={opportunity_id}
        open={showIndicate}
        handleClose={() => {
          setShowIndicate(false);
        }}
        onIndication={() => {
          setShowNoInterest(false);
          onIndication();
        }}
        onClose={onClose}
      />

      <DialogNoInterest
        lead_id={data.id}
        campaign_id={campaign_id}
        opportunity_id={opportunity_id}
        open={showNoInterest}
        handleClose={() => {
          setShowNoInterest(false);
        }}
        onUninterested={() => {
          setShowNoInterest(false);
          onUninterested();
        }}
        onClose={onClose}
      />

      <DialogNoProfile
        lead_id={data.id}
        campaign_id={campaign_id}
        opportunity_id={opportunity_id}
        open={showNoProfile}
        handleClose={() => {
          setShowNoProfile(false);
        }}
        onUnprofile={() => {
          setShowNoProfile(false);
          onUnprofile();
        }}
        onClose={onClose}
      />

      <DialogInNegotiation
        lead_id={data.id}
        campaign_id={campaign_id}
        opportunity_id={opportunity_id}
        open={showInNegotiation}
        handleClose={() => {
          setShowInNegotiation(false);
        }}
        onCreate={() => {
          setShowInNegotiation(false);
          onInNegotiation();
        }}
        onClose={onClose}
      />

      <DialogOpenProposal
        opportunityValue={title}
        campaign_id={campaign_id}
        dataPerson={data}
        open={showOpenProposal}
        handleClose={() => {
          setShowOpenProposal(false);
        }}
        onProposal={() => {
          onProposal();
        }}
      />

      <DialogActions>
        <Button
          size="large"
          onClick={handleClose}
          color="primary"
          style={{ marginRight: 10 }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: "#C00",
    borderColor: "#C00",
  },
  indicateButton: {
    color: "#4169E1",
    borderColor: "#4169E1",
  },
}));

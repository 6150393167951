import { Typography } from '@material-ui/core';
import { formatMoneyBRL, unmaskMoney } from '../../../helpers';

export default function ListSimulator({ account_debits, portion_amount, new_salary, actual_salary, diff, gross_salary, amount_consigned, reserved}) {

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 8 }}>
                <div>Salário Bruto</div>
                <div style={{ borderBottom: '2px dotted #aaa', flexGrow: 1, margin: '0 12px' }}></div>
                <div><b style={{ color: 'green' }}>{formatMoneyBRL(unmaskMoney(gross_salary))}</b></div>
            </div>

            <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 8 }}>
                <div>Total de Consignados</div>
                <div style={{ borderBottom: '2px dotted #aaa', flexGrow: 1, margin: '0 12px' }}></div>
                <div><b style={{ color: 'red' }}>{formatMoneyBRL(unmaskMoney(amount_consigned || 0) * -1)}</b></div>
            </div>

            <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 8 }}>
                <div>RMC</div>
                <div style={{ borderBottom: '2px dotted #aaa', flexGrow: 1, margin: '0 12px' }}></div>
                <div><b style={{ color: 'red' }}>{formatMoneyBRL(unmaskMoney(reserved || 0) * -1)}</b></div>
            </div>

            {
                account_debits.filter(i => unmaskMoney(i.portion_amount) > 0).map(i => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 8, textDecoration: (portion_amount && unmaskMoney(portion_amount) != 0) ? 'line-through' : 'none' }}>
                            <div style={{ maxWidth: 230, whiteSpace: 'nowrap', overflow: 'hidden' }}>{i.bank.name}</div>
                            <div style={{ borderBottom: '2px dotted #aaa', flexGrow: 1, margin: '0 12px' }}></div>
                            <div><b style={{ color: 'red' }}>-R${formatMoneyBRL(i.portion_amount)}</b></div>
                        </div>
                    )
                })
            }

            {
                (portion_amount && unmaskMoney(portion_amount) != 0) &&
                <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 8 }}>
                    <div style={{ fontWeight: 'bold' }}>Nova parcela</div>
                    <div style={{ borderBottom: '2px dotted #aaa', flexGrow: 1, margin: '0 12px' }}></div>
                    <div><b style={{ color: 'red' }}>{formatMoneyBRL(unmaskMoney(portion_amount) * -1)}</b></div>
                </div>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                    (portion_amount && unmaskMoney(portion_amount) != 0) ?
                        <>
                            <div style={{ color: '#AAA', textAlign: 'right' }}>
                                <Typography variant="caption">Salário líquido</Typography>
                                <Typography variant="h6" style={{ textDecoration: 'line-through' }}>{formatMoneyBRL(actual_salary)}</Typography>
                            </div>
                            <div style={{ marginLeft: 12, textAlign: 'right' }}>
                                <Typography variant="caption">Novo salário</Typography>
                                <Typography variant="h6">{formatMoneyBRL(new_salary)}</Typography>
                            </div>
                        </>
                        :
                        <div style={{ textAlign: 'right' }}>
                            <Typography variant="caption">Salário líquido</Typography>
                            <Typography variant="h6">{formatMoneyBRL(actual_salary)}</Typography>
                        </div>
                }
            </div>

            {
                (portion_amount && unmaskMoney(portion_amount) != 0) &&
                <>
                    <div style={{ textAlign: 'right', fontSize: 12 }}>
                        {diff >= 0 && 'Um aumento de ' + diff + '% na renda'}
                        {diff < 0 && 'Uma redução de ' + (diff * -1) + '% na renda'}
                    </div>
                </>
            }
        </>
    )

}
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FETCH_TYPES } from "../../../../graphql/queries";
import {
  Container,
  Card,
  Typography,
  CardContent,
  Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import List from "./List";
import CreateDialog from "./CreateDialog";
import AddIcon from "@material-ui/icons/Add";
import EditDialog from "./EditDialog";

export default function TypePage({ history }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  return (
    <>
      <Header
        onBack={() => {
          history.goBack();
        }}
      />

      <Container maxWidth="md" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h1">
              Tipos
            </Typography>
          </CardContent>

          <List
            onDelete={() => {
              enqueueSnackbar("Tipo excluído com sucesso!");
              refetch();
            }}
            onEdit={(item) => setDataEdit(item)}
            {...{
              loading,
              error,
              data,
              networkStatus,
              currentPage,
              setCurrentPage,
              rowsPerPage,
              setRowsPerPage,
            }}
          />
        </Card>
      </Container>

      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          enqueueSnackbar("Tipo cadastrado com sucesso!");
          refetch();
          setShowCreate(false);
        }}
      />

      <EditDialog
        open={dataEdit ? true : false}
        handleClose={() => setDataEdit(null)}
        data={dataEdit}
        onEdit={() => {
          enqueueSnackbar("Tipo editado com sucesso!");
          refetch();
          setDataEdit(null);
        }}
      />

      <Fab
        color="primary"
        onClick={() => setShowCreate(true)}
        style={{ position: "absolute", bottom: 48, right: 48 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

import * as Yup from "yup";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { maskPhone } from "../../../../helpers";

export default function Step01({ data, setStep, setFormik }) {
  const [schema, setSchema] = useState(null);
  const [clickSubmit, setClickSubmit] = useState(false);

  useEffect(() => {
    if (data) {
      setFieldValue("phone", data.phone ? data.phone : "");
      setFieldValue("is_whatsapp", data.is_whatsapp? data.is_whatsapp : "");
      setSchema(newProposalSchemaCustomer);
    } else {
      setSchema(newProposalSchemaCustomer);
    }
  }, [data]);

  const { handleSubmit, handleChange, setFieldValue, values, errors, isValid } =
    useFormik({
      initialValues: {
        phone: "",
        is_whatsapp: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        if (newProposalSchemaCustomer.isValid(values)) {
          setFormik("pessoal_step", {
            phone: values.phone,
            is_whatsapp: values.is_whatsapp,
          });
          setStep(2);
        }
      },
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
          <TextField
              name="phone"
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              label="Telefone *"
              value={maskPhone(values.phone)}
              fullWidth
          />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
            select
            margin="normal"
            variant="outlined"
            label="É WhatsApp?"
            value={values.is_whatsapp}
            name="is_whatsapp"
            onChange={handleChange}
            fullWidth
            error={errors.is_whatsapp}
            helperText={errors.is_whatsapp}
        >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
        </TextField>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleSubmit();
          setClickSubmit(true);
        }}
      >
        Próximo
      </Button>
      <Button color="primary" onClick={() => setStep(0)}>
        Voltar
      </Button>
    </Grid>
  );
}

const newProposalSchemaCustomer = Yup.object().shape({
  phone: Yup.string().required("Campo obrigatório"),
  is_whatsapp: Yup.string(),
});

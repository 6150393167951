import React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { NetworkStatus, useMutation } from "@apollo/client";
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import DeleteIcon from "@material-ui/icons/Delete";
import { DELETE_TARGET_AUDIENCE } from "../../../graphql/mutations";
import { FETCH_TARGET_AUDIENCE_CSV } from "../../../graphql/queries";

export default function PublicCsv({
  onDelete,
  dataCsv,
  loadingCsv,
  errorCsv,
  refetchCsv,
  networkStatusCsv,
  currentPageCsv,
  rowsPerPageCsv,
  setCurrentPageCsv,
  setRowsPerPageCsv,
}) {
  // Setup mutation
  const [deleteTargetAudience, { loading: loadingDelete }] = useMutation(
    DELETE_TARGET_AUDIENCE
  );

  if (loadingCsv) return <CircularProgress style={{ margin: 12 }} />;
  if (errorCsv) return <div>{errorCsv}</div>;

  const handleDelete = (item) => {
    deleteTargetAudience({
      variables: {
        id: item.id,
      },
    }).then(() => {
      onDelete();
      refetchCsv();
    });
  };

  return (
    <>
      <TableContainer>
        {networkStatusCsv === NetworkStatus.refetch && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataCsv.fetchTargetAudienceCsv.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    aria-label="delete"
                    onClick={() => handleDelete(row)}
                  >
                    {loadingDelete ? (
                      <CircularProgress size={21} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

            {dataCsv.fetchTargetAudienceCsv.data.total == 0 && (
              <TableRow>
                <TableCell colSpan={2}>Nenhuma lista cadastrada</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {dataCsv && (
        <LocalProvider locale="ptBR">
          <TablePagination
            showFirstButton
            showLastButton        
            rowsPerPageOptions={[8, 32, 100]}
            component="div"
            count={dataCsv.fetchTargetAudienceCsv.total}
            rowsPerPage={rowsPerPageCsv}
            page={currentPageCsv}
            onPageChange={(event, newPage) => {
              setCurrentPageCsv(newPage);
            }}
            onRowsPerPageChange={(event) => {
              setRowsPerPageCsv(parseInt(event.target.value));
              setCurrentPageCsv(0);
            }}
          />
        </LocalProvider>
      )}
    </>
  );
}

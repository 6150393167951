import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CircularProgress, FormControl, Select, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_LOCATION } from "../../../../graphql/mutations";

export default function CreateDialog({ open, handleClose, onCreate, statesLocal }) {
    
    // Setting mutation
    const [createLocation, { loading }] = useMutation(CREATE_LOCATION);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        
        initialValues: { name: "", state: "", is_city: true},

        onSubmit: (values) => {

            // Chamando a mutation de criação
            createLocation({
                variables: {
                    name: values.name,
                    state: values.state,
                    is_city: values.is_city
                }
            }).then(() => {
                resetForm();
                onCreate();
            })

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Cadastrar nova Localização</DialogTitle>

                <DialogContent>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Cidade"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                    />
                    <FormControl variant="outlined" fullWidth style={{margin: '8px 0 16px 0'}}>
                        <InputLabel htmlFor="outlined-age-native-simple">Estado</InputLabel>
                        <Select
                            native
                            onChange={(e) => setFieldValue('state', e.target.value)}
                            label="Estado"
                            inputProps={{
                                name: 'state',
                                id: 'outlined-age-native-simple',
                            }}
                            >
                            <option aria-label="None" value="" />
                            {
                                statesLocal.map((row) => <option aria-label="None" value={row}>{row}</option> )
                            }
                            
                        </Select>
                    </FormControl>


                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar Localização
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

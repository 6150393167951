import { SnackbarProvider } from "notistack";
import { createGlobalState } from 'react-use';

export const useGlobalMessage = createGlobalState();

export default function MessageSnackbar({ children }) {

    const MAX_SNACK = 3;

    const AUTO_HIDE_DURATION = 3000;

    const POSITION = {
      vertical: "bottom",
      horizontal: "left"
    };

    return (
        <SnackbarProvider
          maxSnack={MAX_SNACK}
          autoHideDuration={AUTO_HIDE_DURATION}
          anchorOrigin={POSITION}
        >
          {children}
        </SnackbarProvider>
    );
}

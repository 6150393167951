import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { UPDATE_PROPOSAL_SITUATION } from "../../../../graphql/mutations";

export default function EditDialog({ open, handleClose, onEdit, data }) {

    // Setting mutation
    const [updateProposalSituation, { loading }] = useMutation(UPDATE_PROPOSAL_SITUATION);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: { name: "" },
        onSubmit: (values) => {

            // Chamando a mutation de edição
            updateProposalSituation({
                variables: {
                    id: values.id,
                    name: values.name,
                }
            }).then(() => {
                onEdit();
            })

        }
    });
    
    useEffect(function () {
        if (data) {
            resetForm();
            setFieldValue('id', data.id);
            setFieldValue('name', data.name);
        }
    }, [data]);
    
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Editar Situação de Proposta</DialogTitle>

                <DialogContent style={{padding: 16}}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                    />


                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

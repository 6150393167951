import { Button, DialogContent } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Dialog, DialogActions, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import SelectFilterModalReleaseCategories from "../../../../components/SelectFilterModalReleaseCategories";
import SelectReleaseCategory from "../../../../components/SelectReleaseCategory";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { FETCH_COMPANIES } from "../../../../graphql/queries";

export default function FilterDialog({
    open,
    handleClose,
    applyFilters,
    filledFilters
}) {

    const [filters, setFilters] = useState({ ...filledFilters })

    const handleResetFilters = () => {
        const resetFilters = {
            keyword: "",
            startDate: null,
            finishDate: null,
            type: "",
            categoryId: null,
            company: null,
            subcategoryIds: []
        }
        setFilters(resetFilters)
        applyFilters(resetFilters)
        handleClose()
    }

    const handleApplyFilters = () => {
        applyFilters({ ...filters })
        handleClose()
    }

    const handleChange = (prop, value) => {
        setFilters(prevState => ({ ...prevState, [prop]: value }))
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">{"Filtrar lançamentos"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Pesquisar"
                            variant="outlined"
                            value={filters.keyword}
                            onChange={(e) => handleChange("keyword", e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            inputVariant="outlined"
                            label="Data inicial"
                            format="dd/MM/yyyy"
                            value={filters.startDate}
                            fullWidth
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) => handleChange("startDate", date)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            inputVariant="outlined"
                            label="Data final"
                            format="dd/MM/yyyy"
                            value={filters.finishDate}
                            fullWidth
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) => handleChange("finishDate", date)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={filters.type}
                            label="Origem"
                            onChange={(e) => handleChange("type", e.target.value)}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="Receita">Receita</MenuItem>
                            <MenuItem value="Despesa">Despesa</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectReleaseCategory
                            value={filters.categoryId}
                            onChange={(id) => handleChange("categoryId", id)}
                            required
                            params={{
                                type: filters.type
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectFilterModalReleaseCategories
                            preFilledData={filters.subcategoryIds}
                            title="Tipos"
                            fullWidth
                            onChange={(value) => {
                                handleChange("subcategoryIds", value)
                            }}
                            params={{
                                category_id: filters.categoryId
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectWithQuerySearch
                            title="Empresa"
                            query={FETCH_COMPANIES}
                            fetchResult="fetchCompanies"
                            onChange={(companies) => {
                                handleChange("company", companies);
                            }}
                            fullWidth
                            value={filters.company}
                        />
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleResetFilters} color="primary">
                    Resetar Filtros
                </Button>
                <Button onClick={handleApplyFilters} color="primary" autoFocus variant="contained">
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
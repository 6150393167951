import { Avatar, Card, CardContent, Container, Link, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import Header from "../../../../components/Header";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PersonIcon from '@material-ui/icons/Person';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';


export default function UsersPage() {

    const items = [
        { 
            title: "Administradores",
            url: "/configuracoes/administrador",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <AccountBoxIcon />
        },
        { 
            title: "Gerentes",
            url: "/configuracoes/gerentes",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <RecordVoiceOverIcon />
        },
        { 
            title: "Supervisores",
            url: "/configuracoes/supervisores",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <SupervisedUserCircle />
        },
        { 
            title: "Equipes",
            url: "/configuracoes/equipes",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <GroupWorkIcon />
        },
        { 
            title: "Grupos de consultores",
            url: "/configuracoes/grupo-consultores",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <GroupIcon />
        },
        { 
            title: "Consultores",
            url: "/configuracoes/consultores",
            subtitle: "Ver todos, criar, editar, e excluir",
            icon:  <PersonIcon />
        },
    ]

    return (
        <>
            <Header title="Configurações" onBack={() => window.history.go(-1)} />
            <Container maxWidth="sm" style={{ marginTop: 86 }}>

                <Card style={{ marginTop: 12, height: '100%' }}>
                    <CardContent>

                        <List>
                            {
                                items.map(i => <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {i.icon}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Link href={i.url}>{i.title}</Link>}
                                        secondary={i.subtitle}
                                    />
                                </ListItem>)
                            }
                            
                        </List>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}
import React, { useState } from 'react';
import { Avatar, Button, Card, CardContent, CardHeader, Container, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import CloudDownload from '@material-ui/icons/CloudDownload';
import InputRangeInterval from "../../../components/InputRangeInterval";
import { useFormik } from "formik";
import InputWithQuerySearch from "../../../components/InputWithQuerySearch";
import { FETCH_BANKS, FETCH_COMPANIES, FETCH_ENTITIES, FETCH_MANAGERS, FETCH_PRODUCTS, FETCH_PROPOSAL_SITUATIONS, FETCH_SALE_CHANNELS, FETCH_SALE_CONSULTANTS, FETCH_SUPERVISORS, FETCH_TEAMS, FETCH_TYPES } from "../../../graphql/queries";
import InputProposalStatus from "../../../components/InputProposalStatus";
import { useHistory } from "react-router-dom";
import ModalResult from "./ModalResult";

export default function ReportPage() {

    const formStyle = {
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: "4px 12px",
        margin: "8px 4px"
    };

    const [params, setParams] = useState();
    const [open, setOpen] = useState(false);

    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: {},
        onSubmit: (values) => {

            setOpen(true);
            setParams({
                sale_consultants: values.sale_consultants?.map(i => i.id),
                sale_channels: values.sale_channels?.map(i => i.id),
                teams: values.teams?.map(i => i.id),
                supervisors: values.supervisors?.map(i => i.id),
                managers: values.managers?.map(i => i.id),
                companies: values.companies?.map(i => i.id),
                status: values.status,
                situations: values.situations?.map(i => i.id),
                banks: values.banks?.map(i => i.id),
                products: values.products?.map(i => i.id),
                ported_banks: values.ported_banks?.map(i => i.id),
                created_range: values.created_range,
                released_range: values.released_range,
            });

        }
    });

    return (
        <>
            <Header title="Relatórios" />
            <Container style={{ marginTop: 86 }} maxWidth="md">

                <Card style={{ marginTop: 12, height: '100%' }}>
                    <CardHeader title="Relatório de produção" />
                    <CardContent>
                        <Grid container spacing={2} style={{margin: "-12px 0 4px 0"}}>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Consultor"
                                    labelBlank="Todos"
                                    query={FETCH_SALE_CONSULTANTS}
                                    fetchResult="fetchSaleConsultants"
                                    value={values.sale_consultants}
                                    onChange={(value) => setFieldValue('sale_consultants', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Canal de venda"
                                    labelBlank="Todos"
                                    query={FETCH_SALE_CHANNELS}
                                    fetchResult="fetchSaleChannels"
                                    value={values.sale_channels}
                                    onChange={(value) => setFieldValue('sale_channels', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Equipe"
                                    labelBlank="Todas"
                                    query={FETCH_TEAMS}
                                    fetchResult="fetchTeams"
                                    value={values.teams}
                                    onChange={(value) => setFieldValue('teams', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Líder"
                                    labelBlank="Todos"
                                    query={FETCH_SUPERVISORS}
                                    fetchResult="fetchSupervisors"
                                    value={values.supervisors}
                                    onChange={(value) => setFieldValue('supervisors', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Gerente"
                                    labelBlank="Todos"
                                    query={FETCH_MANAGERS}
                                    fetchResult="fetchManagers"
                                    value={values.managers}
                                    onChange={(value) => setFieldValue('managers', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Empresa"
                                    labelBlank="Todas"
                                    query={FETCH_COMPANIES}
                                    fetchResult="fetchCompanies"
                                    value={values.companies}
                                    onChange={(value) => setFieldValue('companies', value)}
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container spacing={2} style={{margin: "4px 0"}}>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Instituição bancária"
                                    labelBlank="Todas"
                                    query={FETCH_BANKS}
                                    fetchResult="fetchBanks"
                                    value={values.banks}
                                    onChange={(value) => setFieldValue('banks', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Produto"
                                    labelBlank="Todos"
                                    query={FETCH_PRODUCTS}
                                    fetchResult="fetchProducts"
                                    value={values.products}
                                    onChange={(value) => setFieldValue('products', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Tipo"
                                    labelBlank="Todos"
                                    query={FETCH_TYPES}
                                    fetchResult="fetchTypes"
                                    value={values.types}
                                    onChange={(value) => setFieldValue('types', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Entidade"
                                    labelBlank="Todos"
                                    query={FETCH_ENTITIES}
                                    fetchResult="fetchEntities"
                                    value={values.entities}
                                    onChange={(value) => setFieldValue('entities', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Banco portado"
                                    labelBlank="Todos"
                                    query={FETCH_BANKS}
                                    fetchResult="fetchBanks"
                                    value={values.ported_banks}
                                    onChange={(value) => setFieldValue('ported_banks', value)}
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container spacing={2} style={{margin: "4px 0"}}>
                            <Grid item style={formStyle}>
                                <InputProposalStatus
                                    labelBlank="Todos"
                                    value={values.status}
                                    onChange={(value) => setFieldValue('status', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputWithQuerySearch
                                    title="Situação"
                                    labelBlank="Todas"
                                    query={FETCH_PROPOSAL_SITUATIONS}
                                    fetchResult="fetchProposalSituations"
                                    value={values.situations}
                                    onChange={(value) => setFieldValue('situations', value)}
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container spacing={2} style={{margin: "4px 0"}}>
                            <Grid item style={formStyle}>
                                <InputRangeInterval
                                    title="Data de criação"
                                    type="date"
                                    labelBlank="Todo o período"
                                    value={values.created_range}
                                    onChange={(value) => setFieldValue('created_range', value)}
                                />
                            </Grid>
                            <Grid item style={formStyle}>
                                <InputRangeInterval
                                    title="Data de aprovação"
                                    type="date"
                                    labelBlank="Todo o período"
                                    value={values.released_range}
                                    onChange={(value) => setFieldValue('released_range', value)}
                                />
                            </Grid>
                        </Grid>

                        <Button style={{ marginTop: 12, marginLeft: 4 }} onClick={handleSubmit} variant="contained" color="primary">
                            Pesquisar
                        </Button>

                    </CardContent>


                </Card>

            </Container>

            {params &&
                <ModalResult
                    open={open}
                    params={params}
                    onClose={() => setOpen(false)}
                />
            }
        </>
    )
}

import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import Filter from "./Filter";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { FETCH_INDICATIONS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import Item from "./Item";
import { Button } from "@mui/material";
import FilterDialog from "./FilterDialog";
import useToken from "../../../tokenJWT";
import LocalProvider from "../../../components/LocalProvider";

export default function IndicationsPage() {
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [productId, setProductId] = useState(null);
  const [opportunity, setOpportunity] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const { user } = useToken();

  const { loading, data, refetch } = useQuery(FETCH_INDICATIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      start,
      finish,
      opportunity_id: opportunity,
      limit: rowsPerPage,
      page: currentPage + 1,
    },
  });

  const handleFilter = (data) => {
    setStart(data.start);
    setFinish(data.finish);
    setOpportunity(data.opportunity_id);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [start, finish, opportunity, productId, opportunity]);

  return (
    <>
      <Header />
      <Container style={{ marginTop: 86 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 12 }} />
          </div>
        ) : (
          <>
            {data && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                  <Card>
                    <CardContent>
                      <Filter
                        filter={{
                          start,
                          finish,
                          opportunity,
                        }}
                        onChangeFilter={(filter) => {
                          handleFilter(filter);
                        }}
                      />
                      <FilterDialog
                        onChangeFilter={(filter) => {
                          handleFilter(filter);
                        }}
                        data={{ opportunity, start, finish }}
                        loading={loading}
                        open={isOpen}
                        handleClose={() => setIsOpen(false)}
                      />
                      <Button
                        onClick={() => setIsOpen(true)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Filtros
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                  <Card>
                    <CardContent>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress style={{ marginTop: 12 }} />
                        </div>
                      ) : (
                        <>
                          <Typography variant="h5" component="h1">
                            Indicações
                          </Typography>

                          {data.fetchIndications.data.map((i, k) => {
                            return <Item key={k} data={i} refetch={refetch} />;
                          })}
                          {data.fetchIndications.data.length === 0 && (
                            <Typography variant="caption">
                              Nenhum resultado encontrado
                            </Typography>
                          )}

                          <LocalProvider locale="ptBR">
                            <TablePagination
                              showFirstButton
                              showLastButton
                              rowsPerPageOptions={[15, 50, 100]}
                              component="div"
                              count={data.fetchIndications.total}
                              rowsPerPage={rowsPerPage}
                              page={currentPage}
                              onPageChange={(event, newPage) => {
                                setCurrentPage(newPage);
                              }}
                              onRowsPerPageChange={(event) => {
                                setRowsPerPage(
                                  parseInt(event.target.value, 10)
                                );
                                setCurrentPage(0);
                              }}
                            />
                          </LocalProvider>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export const applyCurrencyMask = (value) => {
    var formtValue = value;
    formtValue = formtValue.replace(/\D/g, "");
    formtValue = formtValue.replace(/(\d+)(\d{2})$/, "$1,$2");
    formtValue = formtValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    formtValue = "R$ " + formtValue;
    return formtValue;
};

export const removeCurrencyMask = (formattedValue) => {
    const numericValue = formattedValue.replace(/[^0-9,]/g, '');

    const floatValue = parseFloat(numericValue.replace(',', '.'));

    return !isNaN(floatValue) ? floatValue : null;
};

export const isFormatedDateFormat = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

    return regex.test(dateString);
};
import { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_MANUAL_SERVICE, UPDATE_LEAD_BY_CRM } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { unmaskCPF } from "../../../../helpers";
import SelectEntities from "../../../../components/SelectEntities";
import SelectSaleOrigins from "../../../../components/SelectSaleOrigins";

export default function Step02({
  data,
  setStep,
  step,
  setFormik,
  pessoalValues,
  documentValue,
  nameValue,
  onNewService,
  opportunityValue,
}) {

  const [entitiesId, setEntitiesId] = useState(null);
  const [saleOriginsId, setSaleOriginsId] = useState(null);

  const errorMsg = "Campo Obrigatório";

  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [createManualService, createManualServiceMutation] = useMutation(CREATE_MANUAL_SERVICE);

  const [updateLeadByCrm, {loading}] = useMutation(UPDATE_LEAD_BY_CRM);

  const { handleSubmit, handleChange, setFieldValue, values, errors, isValid, resetForm,  } =
    useFormik({
      initialValues: {
        sale_origin_id: "",
        entity_id: "",
      },
      validationSchema: newProposalSchema,
      onSubmit: (values, {resetForm}) => {
        if (newProposalSchema.isValid(values)) {
          createManualService({
            variables: {
              document: unmaskCPF(documentValue),
              name: nameValue,
              phone: pessoalValues.phone,
              is_whatsapp: pessoalValues.is_whatsapp,
              sale_origin_id: saleOriginsId,
              entity_id: entitiesId,
            },
          }).then(({ data }) => {
            enqueueSnackbar(
              "Novo atendimento criado com sucesso!"
            );
            resetForm();
            onNewService();
            history.push("/lead/" + data.createManualService, {
              id: new URLSearchParams(data.createManualService).toString()
            });

            updateLeadByCrm({ variables: { lead_id: parseInt(data.createManualService), errorPolicy: "ignore" } })
            .then(() => {
              enqueueSnackbar("Dados do cliente atualizados com sucesso!", {variant: "success"});
            })
            .catch((error) => {
              if (error.message.includes('CPF não encontrado')) {
                enqueueSnackbar("Atualização dos dados indisponível: CPF não encontrado.");
              } else {
                console.error("Erro ao atualizar dados do cliente:", error);
              }
            });
          });
        }
      },
    });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectSaleOrigins value={saleOriginsId} onChange={(id) => setSaleOriginsId(id)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectEntities value={entitiesId} onChange={(id) => setEntitiesId(id)} />
        </Grid>
      </Grid>

      <div style={{ marginTop: 12 }}></div>

      <Button
        onClick={handleSubmit}
        disabled={!isValid}
        color="primary"
        variant="contained"
      >
        {createManualServiceMutation.loading && (
          <CircularProgress size={14} style={{ marginRight: 12, color: "#FFF" }} />
        )}
        Avançar
      </Button>

      <Button color="primary" onClick={() => setStep(1)}>
        Voltar
      </Button>
    </>
  );
}

const newProposalSchema = Yup.object().shape({
  originService: Yup.string(),
  employer: Yup.string(),
});

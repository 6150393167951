import { Typography, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import CreateIcon from '@material-ui/icons/Create';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR';

export default function InputRangeInterval({ title, labelBlank, value, onChange, type = null }) {

    const [showDialog, setShowDialog] = useState(false);
    const [newValues, setNewValues] = useState(value || null);

    useEffect(() =>{
        setNewValues(value);
    }, [value]);

    // Display
    let display = generateDisplay(value) || labelBlank || "Todos";

    const handleChange = (e) => {
        let clone = {...newValues};
        clone[e.target.name] = type == "date" ? e.target.value : (type == "float" ? parseFloat(e.target.value) : parseInt(e.target.value));
        
        if(isNaN(clone[e.target.name]))
            delete clone[e.target.name];

        setNewValues(clone);
    }

    const handleSelect = () => {
        onChange(newValues); 
        setShowDialog(false);
    }

    let inputStart = <TextField id="outlined-basic" type="number" label="De" variant="outlined" name="start" value={newValues && newValues.start} onChange={handleChange} />
    let inputFinish = <TextField id="outlined-basic" type="number" label="Até" variant="outlined" name="finish" value={newValues && newValues.finish} onChange={handleChange} />

    if (type == "date") {
        inputStart = (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker clearable
                    invalidDateMessage="Data inválida"
                    onChange={(event) => event != "Invalid Date" && handleChange({ target: { name: 'start', value: event } })}
                    format="dd/MM/yyyy"
                    value={newValues ? newValues.start : null}
                    label="De"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "end" }}
                />
            </MuiPickersUtilsProvider>
        )

        inputFinish = (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker clearable
                    invalidDateMessage="Data inválida"
                    onChange={(event) => event != "Invalid Date" && handleChange({ target: { name: 'finish', value: event } })}
                    format="dd/MM/yyyy"
                    value={newValues ? newValues.finish : null}
                    label="Até"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "end" }}
                />
            </MuiPickersUtilsProvider>
        )
    }

    return (
        <>
            <div style={{cursor: 'pointer'}} onClick={() => setShowDialog(true)}>
                <Typography variant="subtitle2">{title}</Typography>
                <Typography variant="caption">{display}</Typography>
                <CreateIcon style={{fontSize: '15px', paddingLeft: '8px', position: 'relative', top: '2px'}}/>
            </div>

            <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="md">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent style={{padding: '16px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>{inputStart}</Grid>
                        <Grid item xs={6}>{inputFinish}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding: '16px'}}>
                    <Button onClick={() => { setNewValues({}); onChange({}); setShowDialog(false)} } color="primary">
                        Limpar
                    </Button>
                    <Button onClick={handleSelect} variant="contained" color="primary">
                        Selecionar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

const generateDisplay = (value) => {

    if(!value) return null;

    if(value.start != undefined && value.finish != undefined){
        return "De " + formatValue(value.start) + " até " + formatValue(value.finish);
    }

    if(value.start != undefined && value.finish == undefined){
        return "Acima de " + formatValue(value.start);
    }

    if(value.start == undefined && value.finish != undefined){
        return "Abaixo de " + formatValue(value.finish);
    }

    return null;
}

const formatValue = (value) => {

    if(!(value instanceof Date))
        return value;
    
    let day = value.getDate().toString();
    let month = (value.getMonth()+1).toString();
    let year = value.getFullYear().toString();

    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`
}
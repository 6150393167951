import {
  Card,
  CardContent,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import { useState } from "react";
import { useGlobalMessage } from "../App";

export default function TextCopy({
  title,
  value,
  copy,
  style = {},
  notForm,
  children,
}) {
  const [message, setMessage] = useState();

  const handleClick = () => {
    if (value != "-" && copy !== false) {
      navigator.clipboard.writeText(value);
      setMessage('"' + value + '" copiado');
    }
  };

  return (
    <div
      style={{
        cursor: value != "-" && copy !== false ? "pointer" : "auto",
        ...style,
        border: notForm ? "0px" : "1px solid #ccc",
        borderRadius: notForm ? 0 : 5,
        padding: notForm ? "0xp 0px" : "4px 8px",
      }}
      onClick={handleClick}
    >
      <Typography variant="caption">{title}</Typography>

      <div style={{ fontSize: 14, fontWeight: 600 }}>
        {value}{" "}
        {value != "-" && copy !== false && (
          <FileCopyIcon style={{ color: "#333", fontSize: 14 }} />
        )}
      </div>

      <Snackbar
        open={message ? true : false}
        onClose={() => setMessage(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1000}
        message={<span id="message-id">{message}</span>}
      />

      {children}
    </div>
  );
}

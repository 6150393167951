import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import { DETAIL_CAMPAING, DETAIL_CAMPAING_LEADS } from "../../graphql/queries";
import {
  CircularProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Container,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import LocalProvider from "../../components/LocalProvider";
import { makeStyles, Link } from "@material-ui/core";
import { maskCPF, maskPhone } from "../../helpers";
import Header from "../../components/Header";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import DialogManualPhone from "./DialogManualPhone";

export default function ManualLeads() {
  const { id: campaign_id } = useParams();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedLead, setSelectedLead] = useState(undefined);

  const { loading, data } = useQuery(DETAIL_CAMPAING, {
    variables: {
      id: parseInt(campaign_id),
    },
  });

  const { loading: loadingLeads, data: dataLeads } = useQuery(
    DETAIL_CAMPAING_LEADS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(campaign_id),
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  const { data: dataLeadsId } = useQuery(DETAIL_CAMPAING_LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: parseInt(campaign_id),
      limit: 1000000,
    },
  });

  const classes = useStyles();

  if (loadingLeads) return <CircularProgress style={{ margin: 12 }} />;

  const campaign_name = data?.detailCampaing.name;

  const firstUnusedId = dataLeadsId?.detailCampaingLeads.data.find(
    (item) => item.last_interaction === null
  );

  const handleClick = () => {
    const id = firstUnusedId.id;

    history.push("/campanha-manual/lead/" + id, {
      id: new URLSearchParams(id).toString(),
      campaign_id: new URLSearchParams(campaign_id).toString(),
      campaign_name: new URLSearchParams(campaign_name).toString(),
      unformated_campaign_name: campaign_name,
    });
  };

  const handleClickPersonal = (id) => {
    history.push("/campanha-manual/lead/" + id, {
      id: new URLSearchParams(id).toString(),
      campaign_id: new URLSearchParams(campaign_id).toString(),
      campaign_name: new URLSearchParams(campaign_name).toString(),
      unformated_campaign_name: campaign_name,
    });
  };

  return (
    <>
      <Header
        title={`Detalhe da campanha ${data?.detailCampaing.name}`}
        onBack={() => history.push("/campanha")}
        isShowBadge
      />
      <Container style={{ marginTop: 100 }}>
        <Card style={{ marginTop: 12, height: "100%" }}>
          <CardContent
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h5" component="h1">
              Clientes
            </Typography>
            <Button
              disabled={!firstUnusedId}
              variant="outlined"
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontSize: 14,
                width: "220px",
              }}
              onClick={handleClick}
            >
              <SmartDisplayIcon
                className={classes.play}
                style={{
                  cursor: "pointer",
                  color: "primary",
                  fontSize: 20,
                }}
              />
              Iniciar Atendimento
            </Button>
          </CardContent>
          <Table
            size="small"
            className={classes.table}
            aria-label="a dense table"
          >
            
            <TableHead>
              <TableRow>
                <TableCell>CPF</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Consultor</TableCell>
                <TableCell style={{ width: 250 }}>
                  Histórico de Interações
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLeads?.detailCampaingLeads.data.map((i) => {
                return (
                  <TableRow key={i.id}>
                    <TableCell component="th" scope="row">
                      {maskCPF(i.document)}
                    </TableCell>
                    <TableCell>{i.name}</TableCell>
                    <TableCell style={{ width: 200 }}>
                      {i.phones.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 15,
                          }}
                        >
                          <p>{maskPhone(i.phones[0].number)}</p>

                          <AddIcCallIcon
                            style={{
                              cursor: "pointer",
                              color: "#2196f3",
                              fontSize: 22,
                              marginLeft: 20,
                            }}
                            onClick={() => setSelectedLead(i.id)}
                          />
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell style={{ maxWidth: 100 }}>
                      {i.last_interaction?.sale_consultant
                        ? i.last_interaction?.sale_consultant
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {i.last_interaction
                        ? `${i.last_interaction.created_at} - ${i.last_interaction.status}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Iniciar Atendimento individual"
                        placement="right"
                      >
                        <SmartDisplayIcon
                          className={classes.play}
                          onClick={() => handleClickPersonal(i.id)}
                          style={{
                            cursor: "pointer",
                            color: "#2196f3",
                            fontSize: 26,
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <DialogManualPhone
            open={selectedLead}
            data={dataLeads?.detailCampaingLeads.data.find(
              (lead) => lead.id === selectedLead
            )}
            onClose={() => setSelectedLead(false)}
          />

          <LocalProvider locale="ptBR">
            <TablePagination
              showFirstButton
              showLastButton
              rowsPerPageOptions={[8, 32, 100]}
              component="div"
              count={dataLeads?.detailCampaingLeads.total}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={(event, newPage) => {
                setCurrentPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value));
                setCurrentPage(0);
              }}
            />
          </LocalProvider>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useState } from "react";
import Simulator from "./Simulator";
import "../../../assets/styleSheet/ScrollBar.css";

export default function DialogSimulator({ open, handleClose, netIncome }) {
  const [activeStep, setActiveStep] = useState(0);

  const { setFieldValue, values, errors } = useFormik({
    initialValues: {
      account_debits: [{}],
      portion_amount: "0,00",
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth={true}
      className="scroolbarCustom"
    >
      <DialogTitle id="scroll-dialog-title" style={{ marginBottom: "-16px" }}>
        Simular proposta
      </DialogTitle>

      <DialogContent style={{ overflow: "hidden" }}>
        <p style={{ marginBottom: 5, fontSize: 14, fontWeight: 500 }}>
          Proposta SIM Crédito
        </p>
        {
          <Typography variant="caption">
            Informe o valor da parcela para simular a renda líquida do cliente
            em um novo banco
          </Typography>
        }
        <Simulator
          onBack={() => setActiveStep(0)}
          netIncome={netIncome}
          {...{ values, errors, setFieldValue }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

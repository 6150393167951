import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { maskPhone } from "../../../../helpers";

export default function LineAddReferences({
  values,
  setFieldValue,
  error,
  validReference,
}) {
  let references = values?.phone_reference;

  if (values?.phone_reference.length === 0) references = [{}];

  const updateInfo = (index, id, name, phone, kinship) => {
    let clone = [...references];
    clone[index] = { id: id, name: name, phone, kinship };
    setFieldValue("phone_reference", clone);
  };

  const addLine = () => {
    let clone = [...references];
    clone.push({});
    setFieldValue("phone_reference", clone);
  };

  const removeLine = (i) => {
    let clone = [...references];
    clone.splice(i, 1);
    setFieldValue("phone_reference", clone);
  };

  return (
    <>
      {error && (
        <div style={{ fontSize: 14, color: "red", marginBottom: 8 }}>
          *{error}
        </div>
      )}
      {validReference && (
        <div style={{ fontSize: 14, color: "red", marginBottom: 8 }}>
          *Favor preencher todos os campos
        </div>
      )}
      {references.map((element, index) => {
        return (
          <div key={index}>
            <Line
              index={index}
              id={element.id}
              data={element}
              onChange={updateInfo}
              onAdd={addLine}
              onRemove={removeLine}
            />
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          </div>
        );
      })}
    </>
  );
}
const Line = ({ index, id, onChange, data, onAdd, onRemove }) => {
  const kinshipArr = [
    "Pai",
    "Mãe",
    "Avô(ó)",
    "Filho(a)",
    "Neto(a)",
    "Irmão(ã)",
    "Tio(a)",
    "Vizinho(a)",
    "Amigo(a)",
    "Outros",
  ];

  let indexKinship = kinshipArr.findIndex((el) => {
    return el == data?.kinship;
  });

  let valueKinshipSelect = (keySelect) => {
    return kinshipArr.find((el, index) => index == keySelect);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(index, id, e.target.value, data?.phone, data?.kinship);
          }}
          label="Nome *"
          value={data && data.name}
        />
      </Grid>

      <Grid item xs={5} sm={3}>
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(index, id, data?.name, e.target.value, data?.kinship);
          }}
          label="Telefone *"
          inputProps={{ maxLength: 15 }}
          value={data && maskPhone(data.phone)}
        />
      </Grid>

      <Grid item xs={5} sm={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            Parent.*
          </InputLabel>
          <Select
            label="Parent. *"
            variant="outlined"
            labelId="demo-simple-select-outlined-label"
            value={indexKinship == -1 ? "" : indexKinship}
            onChange={(e) => {
              onChange(
                index,
                id,
                data?.name,
                data?.phone,
                valueKinshipSelect(e.target.value)
              );
            }}
          >
            {kinshipArr.map((elemnt, key) => {
              return (
                <MenuItem key={key} value={key}>
                  {elemnt}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {index == 0 && (
        <Grid item xs={2}>
          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            style={{ height: 55 }}
            onClick={onAdd}
          >
            <AddIcon />
          </Button>
        </Grid>
      )}

      {index > 0 && (
        <Grid item xs={2}>
          <Button
            color="default"
            fullWidth={true}
            style={{ height: 55 }}
            onClick={() => onRemove(index)}
          >
            <DeleteIcon />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

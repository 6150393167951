import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import SelectSaleConsultant from "../../../components/SelectSaleConsultant";
import SelectWithQuerySearch from "../../../components/SelectWithQuerySearch";
import { FETCH_TEAMS } from "../../../graphql/queries";
import { useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { format, parseISO } from "date-fns";
import SelectFilterModalCompany from "../../../components/SelectFilterModalCompany";
import SelectFilterModalProduct from "../../../components/SelectFilterModalProduct";

export default function FilterDialog({ open, onClose, onChangeFilter, data }) {
  const [period, setPeriod] = useState("dia");
  const [teamId, setTeamId] = useState(data.teamId ? data.teamId : null);
  const [newValueCompany, setNewValueCompany] = useState(
    data?.company_ids || []
  );
  const [newValueProduct, setNewValueProduct] = useState(
    data?.product_ids || []
  );
  const [saleConsultantId, setSaleConsultantId] = useState(
    data.saleConsultantId ? data.saleConsultantId : null
  );
  const [startDate, setStartDate] = useState(
    data.startDate ? parseISO(format(data.startDate, "yyyy-MM-dd")) : null
  );
  const [finishDate, setFinishDate] = useState(
    data.finishDate ? parseISO(format(data.finishDate, "yyyy-MM-dd")) : null
  );

  useEffect(() => {
    let periodStartDate;
    let periodFinishDate;

    switch (period) {
      case "dia":
        periodStartDate = new Date(); // Current date
        periodFinishDate = new Date(); // Current date
        break;
      case "semana":
        periodStartDate = new Date();
        periodStartDate.setDate(periodStartDate.getDate() - 7);
        periodFinishDate = new Date();
        break;
      case "mês":
        periodStartDate = new Date();
        periodStartDate.setDate(periodStartDate.getDate() - 30);
        periodFinishDate = new Date();
        break;
      default:
        periodStartDate = startDate;
        periodFinishDate = finishDate;
    }

    setStartDate(periodStartDate);
    setFinishDate(periodFinishDate);
  }, [period]);

  const handleClickFilter = () => {
    const obj = {
      teamId: teamId?.id,
      company_ids: newValueCompany,
      product_ids: newValueProduct,
      saleConsultantId,
      startDate,
      finishDate,
    };

    onChangeFilter(obj);
  };

  const handleClearFilter = () => {
    setTeamId(null);
    setNewValueCompany(null);
    setNewValueProduct(null);
    setSaleConsultantId(null);
    setStartDate(null);
    setFinishDate(null);

    var obj = {
      team_id: null,
      company_ids: null,
      product_ids: null,
      sale_consultant_id: null,
      startDate: null,
      finishDate: null,
    };

    onChangeFilter(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Filtros</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              label="Período"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="dia">Hoje</MenuItem>
              <MenuItem value="semana">à uma semana</MenuItem>
              <MenuItem value="mês">à um mês</MenuItem>
              <MenuItem value="personalizado">Personalizado</MenuItem>
            </TextField>
          </Grid>

          {period === "personalizado" && (
            <>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    disableFuture
                    variant="inline"
                    inputVariant="outlined"
                    label="Início"
                    format="dd/MM/yyyy"
                    value={startDate}
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => setStartDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    disableFuture
                    variant="inline"
                    inputVariant="outlined"
                    label="Fim"
                    format="dd/MM/yyyy"
                    value={finishDate}
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => setFinishDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <SelectSaleConsultant
              value={saleConsultantId}
              onChange={(id) => setSaleConsultantId(id)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Equipe"
              query={FETCH_TEAMS}
              fetchResult="fetchTeams"
              onChange={(teams) => {
                setTeamId(teams);
              }}
              fullWidth
              value={teamId}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectFilterModalProduct
              newValue={newValueProduct}
              setNewValue={setNewValueProduct}
              productState={data?.product_ids}
              title="Produtos"
              fullWidth
              onChange={(value) => {
                setNewValueProduct(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectFilterModalCompany
              newValue={newValueCompany}
              setNewValue={setNewValueCompany}
              companyState={data?.company_ids}
              title="Empresa"
              fullWidth
              onChange={(value) => {
                setNewValueCompany(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ marginTop: 30, marginBottom: 20 }}>
        <Button
          onClick={() => {
            handleClearFilter();
            onClose();
          }}
          color="primary"
        >
          Limpar filtros
        </Button>
        <Button
          onClick={() => {
            handleClickFilter();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Button, CircularProgress, Grid } from "@material-ui/core";


import { CREATE_PROPOSAL } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { unmaskCPF, unmaskMoney } from "../../../../helpers";
import { format } from "date-fns";
import componentsDinamycForm from "../../../../utils/fieldsDynamicForm";
import { Fragment, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

export default function Step03({
  setStep,
  pessoalValues,
  documentValue,
  onProposal,
  campaign_id,
  handleClose,
  dynamicForm,
  productId
}) {
  const queryString = window.location.search;
  const isFastway = queryString.includes("chave=");

  const { enqueueSnackbar } = useSnackbar();

  const [createProposal, { loading }] = useMutation(CREATE_PROPOSAL);

  const defForm = dynamicForm?.input ? JSON.parse(dynamicForm.input) : null;

  const methods = useForm({
    defaultValues: {
      dynamicForm: defForm
    }
  });
  const { handleSubmit, getValues, reset } = methods;

  const encontrarObjeto = useCallback((chaveDinamica) => {

    const { groups } = getValues()

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      if (group.fields && chaveDinamica in group.fields) {
        return group;
      }
    }

    return null;
  })


  const onSubmit = async (data) => {

    const outputFormat = data.dynamicForm ?
      data.dynamicForm.map((input, index) => {
        const groupInputRef = encontrarObjeto(input.name)

        const formatValue = {
          ...input,
          value: groupInputRef?.fields[input.name]
        }
        return formatValue
      })
      : null

    try {
      const { data: dataResponse } = await createProposal({
        variables: {
          campaign_id: parseInt(campaign_id),
          document: unmaskCPF(documentValue),
          name: pessoalValues.name,
          email: pessoalValues.email,
          birthdate:
            pessoalValues.birthdate?.iso8601 &&
            format(new Date(pessoalValues.birthdate.iso8601), "yyyy-MM-dd"),
          phone: pessoalValues.phone,
          phone2: pessoalValues.phone2,
          cellphone: pessoalValues.cellphone,
          benefit_number: pessoalValues.benefit_number,
          specie_id: pessoalValues.specie_id,
          income: unmaskMoney(pessoalValues.income),
          phone_reference: pessoalValues.phone_reference.map((i) => ({
            id: i.id == undefined ? null : i.id,
            name: i.name,
            phone: i.phone,
            kinship: i.kinship,
          })),
          proposal_number: pessoalValues.proposal_number,
          released_amount: unmaskMoney(pessoalValues.released_amount),
          released_gross_amount: unmaskMoney(pessoalValues.released_gross_amount),
          released_portion_amount: unmaskMoney(pessoalValues.released_portion_amount),
          released_portion_count: parseFloat(pessoalValues.released_portion_count),
          sale_origin_id: pessoalValues.sale_origin_id,
          product_id: productId || null,
          output: JSON.stringify(outputFormat),
          fastway: isFastway,
        },
      })

      enqueueSnackbar("Proposta #" + dataResponse.createProposal + " criada com sucesso!", {
        variant: "success",
      });

      reset();
      setStep(0);
      handleClose();
      setTimeout(() => {
        window.open("/propostas/" + dataResponse.createProposal, "_blank");
      }, 3000);
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>

        { 
          Array.isArray(defForm) && defForm.map((input, index) => {

            const component = componentsDinamycForm.find(
              (value) => value.type === input.type
            )

            return (
              <Fragment key={index}>
                {
                  component && (
                    <Fragment>
                      {
                        <component.component
                          required={input.required}
                          label={input.label}
                          placeholder={input.label}
                          name={input.name}
                          options={
                            input.options &&
                            input.options.map((option) => ({
                              label: option.name,
                              value: option.name,
                            }))
                          }
                          index={index}
                        />
                      }
                    </Fragment>
                  )
                }
              </Fragment>
            )
          })
        }

      </Grid>

      <div style={{ marginTop: 12 }}></div>

      <Button
        onClick={handleSubmit(onSubmit)}
        color="primary"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress size={14} style={{ marginRight: 12 }} color="white"/>
        )}
        Abrir proposta
      </Button>

      <Button color="primary" onClick={() => setStep(2)} disabled={loading}>
        Voltar
      </Button>
    </FormProvider>
  );
}


import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@material-ui/core";

import { maskCPF, maskMoney } from "../../../../helpers";
import TextCopy from "../../../../components/TextCopy";
import EditIcon from "@material-ui/icons/Edit";
import EditDialog from "./EditDialog";
import { useState } from "react";
import ListPhoneReferences from "./ListPhoneReferences";

export default function PessoalCard({ data, style, onEdit }) {
  const [open, setOpen] = useState(false);
  return (
    <Card style={style}>
      <CardHeader
        style={{ backgroundColor: "#bdcdbd" }}
        title="Dados pessoais"
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <TextCopy title="CPF" value={maskCPF(data.customer.document)} />
          </Grid>

          <Grid item xs={12} sm={7}>
            <TextCopy title="Nome" value={data.customer.name} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextCopy
              title="Data de nascimento"
              value={data.customer.birthdate?.formated || "-"}
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <TextCopy
              title="Nome da mãe"
              value={data.customer.mothers_name || "-"}
            />
          </Grid>

          <Grid item xs={4} sm={5}>
            <TextCopy title="CEP" value={data.customer.zipcode || "-"} />
          </Grid>

          <Grid item xs={8} sm={7}>
            <TextCopy title="Endereço" value={data.customer.address || "-"} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextCopy title="Bairro" value={data.customer.district || "-"} />
          </Grid>

          <Grid item xs={12} sm={7}>
            <TextCopy
              title="Cidade"
              value={
                data.customer.location
                  ? data.customer.location.name +
                  "/" +
                  data.customer.location.state
                  : "-"
              }
            />
          </Grid>


          <Grid item xs={6} sm={5}>
            <TextCopy copy={false} title="Nº da proposta" value={data.proposal_number || "-"} />
          </Grid>

          <Grid item xs={6} sm={7}>
            <TextCopy copy={false} title="Valor liberado (R$)" value={maskMoney(data.released_amount) || "-"} />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextCopy title="Renda" value={data.customer.income || "-"} />
          </Grid>

          <Grid item xs={12} sm={7}>
            <TextCopy title="Email" value={data.customer.email || "-"} />
          </Grid>

          <Grid item xs={6} sm={5}>
            <TextCopy title="Celular 01" value={data.customer.phone || "-"} />
          </Grid>

          <Grid item xs={6} sm={7}>
            <TextCopy title="Celular 02" value={data.customer.phone2 || "-"} />
          </Grid>

          <Grid item xs={6} sm={5}>
            <TextCopy title="Número do benefício" value={data.customer.benefit_number || "-"} />
          </Grid>

          <Grid item xs={6} sm={7}>
            <TextCopy
              title="Telefone Fixo"
              value={data.customer.cellphone || "-"}
            />
          </Grid>

          <Grid item xs={12}>
            <TextCopy title="Espécie" value={data.customer.specie.name || "-"} />
          </Grid>

          <Grid item xs={12}>
            <ListPhoneReferences
              title="Referências"
              value={data.customer.phone_reference || "-"}
            />
          </Grid>
        </Grid>

        <div style={{ textAlign: "center", marginTop: 12 }}>
          <Button
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setOpen(true)}
          >
            Editar dados
          </Button>
        </div>

        <EditDialog
          data={data.customer}
          code={data.code}
          open={open}
          handleClose={() => setOpen(false)}
          onEdit={() => {
            setOpen(false);
            onEdit();
          }}
        />
      </CardContent>
    </Card>
  );
}

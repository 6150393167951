import { Stack, Box, Typography } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import React, { useState } from "react";
import { Chips } from "../../../components/Chips";
import ModalEditField from "../../../pages/admin/SettingsPage/FormPage/DynamicForm/Modal/ModalEditField";

function GroupsDynamicForm({
  index,
  group,
  handleRemoveComponent,
  handleEditComponent,
}) {
  const [isOpenModalEditField, setIsOpenModalEditField] = useState(false);
  const [componentSelected, setComponentSelected] = useState(null);

  const onRequestCloseModalEditField = () => {
    setIsOpenModalEditField(false);
    setComponentSelected(null);
  };

  const handleOpenModalEditField = (component) => {
    setComponentSelected(component);
    setIsOpenModalEditField(true);
  };

  const handleEditComponentSelected = (component) => {
    handleEditComponent(
      index,
      group.components.indexOf(componentSelected),
      component
    );
    onRequestCloseModalEditField();
  };

  return (
    <>
      <Stack spacing={2}>
        <Droppable droppableId={group.id}>
          {(droppableProvided, droppableSnapshot) => (
            <Box
              border={`1px dashed ${
                droppableSnapshot.isDraggingOver ? "#000" : "#ccc"
              }`}
              padding={2}
              minHeight="150px"
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              position="relative"
              flex={1}
            >
              {group.components.length === 0 && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  fontWeight={600}
                  position="absolute"
                  top="45%"
                  left="30%"
                >
                  Arraste os componentes ao lado para essa área
                </Typography>
              )}
              <Stack
                direction="row"
                spacing={2}
                flex={1}
                flexWrap="wrap"
                rowGap={2}
              >
                {group.components.map((component) => (
                  <Chips
                    variant="filled"
                    label={component.name}
                    textColor="#000"
                    key={component.id}
                    isDelete
                    handleDelete={() => {
                      handleRemoveComponent(component.id);
                    }}
                    isEditable={component.isEditable}
                    handleEdit={() => handleOpenModalEditField(component)}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </Droppable>
      </Stack>

      {componentSelected && (
        <ModalEditField
          open={isOpenModalEditField}
          handleClose={() => onRequestCloseModalEditField()}
          title={componentSelected.name}
          handleEditComponent={(component) =>
            handleEditComponentSelected(component)
          }
          component={componentSelected}
        />
      )}
    </>
  );
}

export default GroupsDynamicForm;
import { useState } from "react";
import { useQuery } from "@apollo/client";
import CreateIcon from "@material-ui/icons/Create";
import SearchInputDebounced from "./SearchInputDebounced";
import "../assets/styleSheet/ScrollBar.css";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default function InputWithQuerySearchDelete({
  title,
  labelBlank,
  value,
  onChange,
  query,
  fetchResult,
  renderLabel = null,
  styleInput,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [newValue, setNewValue] = useState(value || []);

  // Search
  const [searchTerm, setSearchTerm] = useState(null);

  // Paginate
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const { loading, error, data, networkStatus } = useQuery(query, {
    variables: {
      keyword: searchTerm,
      limit: rowsPerPage,
      page: currentPage + 1,
    },
  });

  // Setting display
  let display = labelBlank || "Todos";

  if (value && value.length > 0) {
    display = value
      .map((i) =>
        i.name.length > 15 ? i.name.substring(0, 15) + ".." : i.name
      )
      .join(", ");

    if (value.length > 2) {
      display = value
        .slice(0, 2)
        .map((i) =>
          i.name.length > 10 ? i.name.substring(0, 10) + ".." : i.name
        )
        .join(", ");
      display += " (+" + (value.length - 2) + ")";
    }
  }

  const handleChangeCheckbox = (e, item) => {
    let index = newValue.findIndex((i) => parseInt(i.id) === parseInt(item.id));

    if (index >= 0 && e.target.checked === false) deleteItem(index);
    else {
      let clone = [...newValue];
      clone.push(item);
      setNewValue(clone);
    }
  };

  const deleteItem = (index) => {
    let clone = [...newValue];
    clone.splice(index, 1);
    setNewValue(clone);
  };

  const handleSelect = () => {
    onChange(newValue);
    setShowDialog(false);
  };

  return (
    <>
      {styleInput == "outlined" ? (
        <TextField
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon
                  style={{ color: "#787878", marginRight: -6 }}
                />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: value && value.name,
          }}
          onClick={() => setShowDialog(true)}
          variant="outlined"
          fullWidth
          value={display === "Todos" ? "" : display}
          label={title}
        />
      ) : (
        <div style={{ cursor: "pointer" }} onClick={() => setShowDialog(true)}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="caption">{display}</Typography>
          <CreateIcon
            style={{
              fontSize: "15px",
              paddingLeft: "8px",
              position: "relative",
              top: "2px",
            }}
          />
        </div>
      )}

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <SearchInputDebounced
            typeSearch="inDialog"
            onChange={(value) => {
              setSearchTerm(value);
            }}
          />

          <div style={{ marginTop: 12, width: 300 }}>
            {newValue.map((element, index) => {
              return (
                <Chip
                  label={renderLabel ? renderLabel(element) : element.name}
                  onDelete={() => deleteItem(index)}
                />
              );
            })}
          </div>

          {loading && <CircularProgress style={{ marginTop: 12 }} />}

          {data && (
            <div
              className="scroolbarCustom"
              style={{
                marginTop: 12,
                height: 300,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data[fetchResult].total == 0 && (
                <div>Nenhum registro encontrado</div>
              )}

              {data[fetchResult].data.map((element) => {
                let checked = newValue.findIndex(
                  (i) => parseInt(i.id) === parseInt(element.id)
                );

                return (
                  <div>
                    <Checkbox
                      checked={checked >= 0}
                      onChange={(e) => handleChangeCheckbox(e, element)}
                      name="gilad"
                    />
                    {renderLabel ? renderLabel(element) : element.name}
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 22 }}>
          <Button
            color="primary"
            onClick={() => {
              setNewValue([]);
              onChange([]);
              setShowDialog(false);
            }}
          >
            Limpar
          </Button>
          <Button onClick={handleSelect} variant="contained" color="primary">
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useFormik } from "formik";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_INTERACTION } from "../../../graphql/mutations";
import * as Yup from "yup";
import SelectReason from "../../../components/SelectReason";
import { useSnackbar } from "notistack";

export default function DialogNoInterest({
  lead_id,
  opportunity_id,
  open,
  onClose,
  handleClose,
  onUninterested,
  campaign_id,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const queryString = window.location.search;
  const isFastway = queryString.includes("chave=");

  const [createInteraction, { loading }] = useMutation(CREATE_INTERACTION);

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: { reason: null },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Campo obrigatório").nullable(),
      description: Yup.string().required("Campo obrigatório").nullable(),
    }),
    onSubmit: (values) => {
      createInteraction({
        variables: {
          lead_id: lead_id,
          campaign_id: parseInt(campaign_id),
          opportunity_id: opportunity_id || null,
          status: "Sem Interesse",
          reason: values.reason,
          description: values.description,
          temperature: null,
          fastway: isFastway,
        },
      })
        .then(() => {
          if (onClose) {
            onClose();
          }
          resetForm();
          onUninterested();
        })
        .catch((error) => {
          enqueueSnackbar("Preencher mínimo de 10 caracteres!", {
            variant: "error",
          });
        });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Sem interesse</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectReason value={values.reason} onChange={handleChange} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              label="Observação"
              value={values.description}
              name="description"
              onChange={handleChange}
              fullWidth
              error={errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Grid style={{ marginLeft: 26, marginTop: 10 }}>
        *Preencher mínimo de 10 caracteres.
      </Grid>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box } from "@material-ui/core";
import { Checkbox } from "../../../components/Checkbox";
import { Grid, Typography } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

function FieldCheckBox({
    options,
    label,
    name,
    index,
    readonly,
}) {
    const { control } = useFormContext();
    return (
        <Grid item xs={12}>
            <Typography variant="h6" marginBottom={1} color="black">
                {label}
            </Typography>
            <Box paddingX={1} paddingY={2}>
                {options.map((option, indexOption) => (
                    <Controller
                        key={option.value}
                        name={`groups.${index}.fields.${name}.${indexOption}`}
                        control={control}
                        render={(inputProps) => (
                            <Checkbox
                                readOnly={readonly}
                                label={option.label}
                                onChange={(e) => inputProps.field.onChange(e.target.value)}
                                value={option.value}
                            />
                        )}
                    />
                ))}
            </Box>
        </Grid>
    );
}

export default FieldCheckBox;
import { FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

function FieldEmail({ name, placeholder, required, index }) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				name={`groups.${index}.fields.${name}`}
				control={control}
				rules={{
					required: required ? "Campo obrigatório" : false,
					pattern: {
						value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
						message: "Email inválido",
					},
				}}
				render={(inputProps) => (
					<FormControl
						variant="outlined"
						fullWidth
						error={inputProps.fieldState.error && true}
					>
						<TextField
							id={name}
							type={"email"}
							label={`${placeholder}${required ? "*" : ""}`}
							fullWidth
							value={inputProps.field.value}
							onChange={(e) => {
								inputProps.field.onChange(e.target.value);
							}}
							InputLabelProps={{
								shrink: inputProps.field.value ? true : false,
							}}
						/>
						{inputProps.fieldState.error && (
							<FormHelperText error={inputProps.fieldState.error && true}>
								{inputProps.fieldState.error.message + ""}
							</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</Grid>
	);
}

export default FieldEmail;
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useState } from "react";
import SelectSaleConsultant from "../../../components/SelectSaleConsultant";
import SelectTeam from "../../../components/SelectTeam";
import SelectEntities from "../../../components/SelectEntities";
import SelectSaleOrigins from "../../../components/SelectSaleOrigins";
import SelectFilterModalReason from "../../../components/SelectFilterModalReason";
import SelectFilterModalTag from "../../../components/SelectFilterModalTag";
import { parseISO } from "date-fns";

export default function FilterDialog({
  open,
  handleClose,
  onChangeFilter,
  loading,
  data,
}) {
  const [startDate, setStartDate] = useState(
    data.start ? parseISO(data.start) : null
  );
  const [finishDate, setFinishDate] = useState(
    data.finish ? parseISO(data.finish) : null
  );
  const [status, setStatus] = useState(data?.status || null);
  const [opportunity, setOpportunity] = useState(data?.opportunity || null);
  const [saleConsultantId, setSaleConsultantId] = useState(
    data?.saleConsultantId || null
  );
  const [teamId, setTeamId] = useState(data?.teamId || null);
  const [entitiesId, setEntitiesId] = useState(data?.entitiesId || null);
  const [interestLevel, setInterestLevel] = useState(
    data?.interestLevel || null
  );
  const [saleOriginsId, setSaleOriginsId] = useState(
    data?.saleOriginsId || null
  );
  const [newValue, setNewValue] = useState(data?.reason || []);
  const [newValueTag, setNewValueTag] = useState(data?.tag || []);

  const handleClickFilter = () => {
    let { format, parseISO } = require("date-fns");

    const values = {
      status: status || "",
      opportunity_id: opportunity || "",
      start: (startDate && format(startDate, "yyyy-MM-dd")) || "",
      finish: (finishDate && format(finishDate, "yyyy-MM-dd")) || "",
      sale_origin_id: saleOriginsId || "",
      entity_id: entitiesId || "",
      interest_level: interestLevel || "",
      reasons: newValue,
      saleConsultantId,
      teamId,
      tag_ids: newValueTag,
    };

    onChangeFilter(values);
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setFinishDate(null);
    setStatus(null);
    setSaleConsultantId(null);
    setTeamId(null);
    setOpportunity(null);
    setEntitiesId(null);
    setSaleOriginsId(null);
    setNewValue([]);
    setNewValueTag([]);
    setInterestLevel(null);

    const obj = {
      status: null,
      start: null,
      finish: null,
      sale_consultant_id: null,
      team_id: null,
      opportunity_id: null,
      entity_id: null,
      sale_origin_id: null,
      reasons: null,
      interest_level: null,
      tag_ids: null,
    };

    onChangeFilter(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Filtrar interações</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label="Início"
                format="dd/MM/yyyy"
                value={startDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setStartDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label="Fim"
                format="dd/MM/yyyy"
                value={finishDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setFinishDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status de atendimento"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Em negociação">Em negociação</MenuItem>
              <MenuItem value="Agendado">Agendado</MenuItem>
              <MenuItem value="Sem Interesse">Sem Interesse</MenuItem>
              <MenuItem value="Proposta Aberta">Proposta Aberta</MenuItem>
              <MenuItem value="Contato Inválido">Contato Inválido</MenuItem>
              <MenuItem value="Sem perfil">Sem perfil</MenuItem>
              <MenuItem value="Ligação encerrada">Ligação encerrada</MenuItem>
              <MenuItem value="Pós Venda">Pós Venda</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectTeam value={teamId} onChange={(id) => setTeamId(id)} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectSaleConsultant
              value={saleConsultantId}
              onChange={(id) => setSaleConsultantId(id)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Oportunidades"
              value={opportunity}
              onChange={(e) => setOpportunity(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={1}>Seg. contrato</MenuItem>
              <MenuItem value={2}>Renovação</MenuItem>
              <MenuItem value={3}>Portab. consig.</MenuItem>
              <MenuItem value={4}>Santander</MenuItem>
              <MenuItem value={5}>Agibank</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectSaleOrigins
              value={saleOriginsId}
              onChange={(id) => setSaleOriginsId(id)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectEntities
              value={entitiesId}
              onChange={(id) => setEntitiesId(id)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectFilterModalReason
              newValue={newValue}
              setNewValue={setNewValue}
              reasonState={data?.reason}
              title="Motivos"
              fullWidth
              onChange={(value) => {
                setNewValue(value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Nível de interesse"
              value={interestLevel}
              onChange={(e) => setInterestLevel(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={"Baixo"}>Baixo</MenuItem>
              <MenuItem value={"Médio"}>Médio</MenuItem>
              <MenuItem value={"Alto"}>Alto</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectFilterModalTag
              newValue={newValueTag}
              setNewValue={setNewValueTag}
              tagState={data?.tag}
              title="Tags"
              fullWidth
              onChange={(value) => {
                setNewValueTag(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClickFilter();
            handleClose();
          }}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Filtrar
        </Button>

        <Button
          onClick={() => {
            handleClearFilter();
            handleClose();
          }}
          color="primary"
        >
          Limpar filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { FormControl, Grid, FormHelperText, TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

function FieldTextEditor({
	name,
	index,
	readonly,
	required,
	placeholder,
}) {
	const { control } = useFormContext();
	return (
		<Grid item xs={12}>
			<Controller
				name={`groups.${index}.fields.${name}`}
				rules={{
					required: required ? "Campo obrigatório" : false,
				}}
				control={control}
				render={(inputProps) => {
					return (
						<FormControl
							variant="contained"
							fullWidth
							error={inputProps.fieldState.error && true}
						>
							<TextField
								id={name}
								type={"text"}
								label={`${placeholder}${required ? "*" : ""}`}
								defaultValue={inputProps.field.value}
								fullWidth
								value={inputProps.field.value}
								onChange={(e) => {
									inputProps.field.onChange(e.target.value);
								}}
								InputLabelProps={{
									shrink: inputProps.field.value ? true : false,
								}}
								multiline
								rows={5}
							/>
							{inputProps.fieldState.error && (
								<FormHelperText error={inputProps.fieldState.error && true}>
									{inputProps.fieldState.error.message + ""}
								</FormHelperText>
							)}
						</FormControl>
					);
				}}
			/>
		</Grid>
	);
}

export default FieldTextEditor;
import { Box, Chip, CircularProgress, Divider, Paper, Typography } from "@material-ui/core";
import Items from "./Items";
import { useQuery } from "@apollo/client";
import { FETCH_DYNAMIC_FORMS, FETCH_PROPOSALS } from "../../../../graphql/queries";
import { useEffect, useState } from "react";
import { format, subDays } from "date-fns";
import truncateString from "../../../../utils/truncateString"

export default function KanbanCard({ title, days, handleClick, doneCardAccess, refetchValue, handleAbortRefetch }) {

    const convertDays = () => {
        const actualDate = new Date();
        const dateAfter30days = subDays(actualDate, days);
        const formatDate = format(dateAfter30days, 'yyyy-MM-dd');

        return formatDate
    }

    const [filterDate] = useState(convertDays())

    const isLatedProposals = title === "Atrasadas"

    const { data, loading, refetch } = useQuery(FETCH_PROPOSALS, {
        fetchPolicy: "network-only",
        variables: {
            start: isLatedProposals ? null : filterDate,
            finish: isLatedProposals ? null : filterDate,
            lated: isLatedProposals
        },
    })


    const { data: dataForms, loading: loadingForms } = useQuery(FETCH_DYNAMIC_FORMS, {
        variables: {
            keyword: title
        }
    })

    const dynamicFormId = dataForms?.fetchDynamicForms.data[0]?.id || 0

    const dataProposals = data?.fetchProposals.data || []
    const totalCount = data?.fetchProposals.total || 0

    let bgColor;
    let borderColor;
    switch (title) {
        case "Qualidade":
            bgColor = "rgba(56, 139, 253, 0.1)";
            borderColor = "rgb(31, 111, 235)";
            break;
        case "Pós venda - Parc. 1":
            bgColor = "rgba(187, 128, 9, 0.15)";
            borderColor = "rgb(158, 106, 3)";
            break;
        case "Pós Venda - Parc. 2 - Segundo Contrato":
            bgColor = "rgba(163, 113, 247, 0.1)";
            borderColor = "rgb(137, 87, 229)";
            break;
        case "Pós Venda - Parc.3 - Renovação":
            bgColor = "rgba(46, 160, 67, 0.15)";
            borderColor = "rgb(35, 134, 54)";
            break;
        default:
            bgColor = "rgba(248, 81, 73, 0.1)";
            borderColor = "rgb(218, 54, 51)";
            break;
    }

    useEffect(() => {
        if (refetchValue) {
            refetch()
            handleAbortRefetch()
        }
    }, [refetchValue])

    return (
        <>
            <Paper
                elevation={3}
                style={{
                    padding: 8
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ gap: 8 }}>
                    <Box width={15} height={15} borderRadius={"50%"} bgcolor={bgColor} border={`2px solid ${borderColor}`} />
                    <Chip size="small" variant="outlined" label={totalCount} />
                    <Typography variant="subtitle1" component="h2" style={{ fontWeight: "bold" }}>
                        {truncateString(title, 24)}
                    </Typography>
                </Box>

                <Divider style={{ marginTop: 4, marginBottom: 4 }} />

                {
                    (loading || loadingForms) ?
                        <Box display="flex" marginY={4} justifyContent="center">
                            <CircularProgress />
                        </Box>
                        :

                        <Box
                            display="flex"
                            flexDirection="column"
                            height={700}
                            overflow="auto"
                        >
                            {
                                dataProposals.map(item => {

                                    let doneCard = false
                                    if (doneCardAccess) doneCard = item[doneCardAccess]

                                    return (
                                        <Items
                                            handleClick={() => handleClick(dynamicFormId, item.lead?.id || null, item.id)}
                                            key={item.code}
                                            code={item.code}
                                            name={item.customer.name}
                                            cpf={item.customer.document}
                                            createdAt={item.date.formated}
                                            isDone={doneCard}
                                        />
                                    )
                                })
                            }
                        </Box>
                }
            </Paper>
        </>
    )
}
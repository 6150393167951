import React, { useState } from "react";
import {
  CircularProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
} from "@mui/material";
import LocalProvider from "../../../components/LocalProvider";
import { makeStyles, Tooltip, Button } from "@material-ui/core";
import { maskCPF, maskMoney, maskPhone } from "../../../helpers";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DialogManualPhone from "./DialogManualPhone";
import { useMutation } from "@apollo/client";
import { DELETE_DEBIT } from "../../../graphql/mutations";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

function formatDate(dataString) {
  const data = new Date(`${dataString}T00:00:00-03:00`);
  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export default function List({
  data,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  loading,
  refetch,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [selectedLead, setSelectedLead] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDebit, { loading: loadingDelete }] = useMutation(DELETE_DEBIT);

  const handleDelete = (id) => {
    deleteDebit({
      variables: {
        id: id,
      },
    })
      .then(() => {
        enqueueSnackbar("Débito excluído com sucesso!", {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        console.error("Error deleting debit:", error);
      });
  };

  if (loading) return <CircularProgress style={{ margin: 12 }} />;

  const handleClick = (id) => {
    history.push("/lead/" + id, {
      id: new URLSearchParams(id).toString(),
    });
  };

  return (
    <>
      <TableContainer>
        {data && data?.fetchDebtors.data.length === 0 ? (
          <Typography style={{ marginTop: 24 }}>
            Nenhum lead inadimplente encontrado.
          </Typography>
        ) : (
          <Table className={classes.table} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Inadimplente desde</TableCell>
                <TableCell>Débito</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.fetchDebtors.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Tooltip title="Detalhe do Cliente" placement="right">
                      <Button
                        style={{
                          fontSize: "14px",
                          paddingLeft: 0,
                          color: "#5FA018",
                          display: "contents",
                        }}
                        onClick={() => handleClick(row.lead.id)}
                      >
                        {row.lead.name}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{maskCPF(row.lead.document)}</TableCell>
                  <TableCell>
                    {row.lead.phones.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 15,
                        }}
                      >
                        <p>{maskPhone(row.lead.phones[0].number)}</p>

                        <AddIcCallIcon
                          style={{
                            cursor: "pointer",
                            color: "#2196f3",
                            fontSize: 22,
                          }}
                          onClick={() => setSelectedLead(row.id)}
                        />
                      </div>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>{formatDate(row.from_date)}</TableCell>
                  <TableCell> R$ {maskMoney(row.amount)}</TableCell>
                  {row.deleted_at === null && (
                    <TableCell>
                      {loadingDelete ? (
                        <CircularProgress size={22} />
                      ) : (
                        <CreditScoreIcon
                          style={{
                            cursor: "pointer",
                            color: "#2196f3",
                            fontSize: 22,
                          }}
                          onClick={() => handleDelete(row.id)}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <DialogManualPhone
        open={selectedLead !== undefined}
        data={data?.fetchDebtors.data.find((lead) => lead.id === selectedLead)}
        onClose={() => setSelectedLead(undefined)}
      />

      {data && data?.fetchDebtors.data.length > 0 && (
        <LocalProvider locale="ptBR">
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={[8, 32, 100]}
            component="div"
            count={data.fetchDebtors.total}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => {
              setCurrentPage(newPage);
            }}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </LocalProvider>
      )}
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FETCH_ANNOUNCEMENTS } from "../../../graphql/queries";
import ModalStatement from "../../../components/ModalStatement";
import { useState } from "react";
import ImgList from "../../../components/ImgList";
import { at } from "lodash";
import { Backdrop } from "@mui/material";

const formatDate = (datetime) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const isDarkColor = (color) => {
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
};

const secureUrl = (url) => {
  return url.startsWith("http://") ? url.replace("http://", "https://") : url;
};

export default function Statement() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [openImg, setOpenImg] = useState({
    state: false,
    url: null,
  });

  const { data, loading } = useQuery(FETCH_ANNOUNCEMENTS);

  const handleOpen = (item, e) => {
    if (e.target.id === "showListImg") return
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseImg = () => {
    setOpenImg({ state: false, url: null });
  };

  const handleToggleImg = (itemUrl) => {
    setOpenImg({ state: !open.state, url: secureUrl(itemUrl) });
  };

  const truncateString = (str, maxLen) => {
    if (str.length <= maxLen) return str;
    return str.substr(0, maxLen) + "...";
  };

  return (
    <Card style={{ paddingBottom: 20 }}>
      <CardHeader title="Comunicados" />
      {loading && <CircularProgress style={{ margin: 12, marginTop: -6 }} />}

      {data && (
        <>
          {data.fetchAnnouncements.length === 0 && (
            <CardContent>
              <Typography variant="body2">
                Nenhum registro encontrado
              </Typography>
            </CardContent>
          )}

          <div
            className="scrollbarCustom"
            style={{ display: "flex", overflowY: "hidden", overflowX: "auto" }}
          >
            {data.fetchAnnouncements.map((i, index) => {
              return (
                <CardContent
                  key={index}
                  style={{
                    backgroundColor: i.theme,
                    cursor: "pointer",
                    borderRight: "1px solid #ccc",
                    borderRadius: "10px",
                    width: "20%",
                    marginLeft: "20px",
                  }}
                  onClick={(e) => handleOpen(i, e)}
                >
                  <Grid container spacing={1}>
                    <Grid container item xs={11} spacing={1}>
                      <Grid item xs={11}>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: isDarkColor(i?.theme) ? "#FFF" : "#000",
                              marginBottom: 10,
                              fontWeight: "bold",
                            }}
                          >
                            {i?.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              color: isDarkColor(i?.theme) ? "#FFF" : "#000",
                            }}
                          >
                            {truncateString(i?.description, 24)}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              color: isDarkColor(i?.theme) ? "#FFF" : "#000",
                            }}
                          >
                            Enviado: {`${formatDate(i?.created_at)}`}
                          </Typography>
                          {
                            i.attachments.length > 0 &&
                            <Box
                              borderTop={`1px solid ${isDarkColor(i?.theme) ? "#FFF" : "#000"}`}
                              marginTop={1}
                              paddingY={1}
                            >
                              {
                                i.attachments.map((att, index) => {
                                  if (att.name.endsWith(".png") || att.name.endsWith(".jpg")) {
                                    return (
                                      <Box
                                        onClick={() => handleToggleImg(att.path)}
                                      >
                                        <img
                                          id="showListImg"
                                          key={index}
                                          width="100%"
                                          alt={att.name}
                                          src={att.path}
                                        />
                                      </Box>
                                    )
                                  }
                                  return <></>
                                })
                              }
                            </Box>
                          }
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ display: "flex", alignItems: "start" }}
                    >
                      <ArrowForwardIosIcon
                        style={{ color: "#666666", cursor: "pointer" }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              );
            })}
          </div>
        </>
      )}

      {openImg.state && <BackdropImg open={openImg} handleClose={handleCloseImg} />}

      <ModalStatement open={open} onClose={handleClose} data={selectedItem} />
    </Card>
  );
}

const BackdropImg = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open.state}
      onClick={handleClose}
    >
      <img
        style={{ maxWidth: 600 }}
        src={`${open.url}`}
        srcSet={`${open.url}`}
        alt=""
        loading="lazy"
      />
    </Backdrop>
  );
};
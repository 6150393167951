import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_RELEASE_CATEGORIES, FETCH_RELEASE_SUBCATEGORIES } from "../graphql/queries";

export default function SelectReleaseSubCategory({ value, onChange, required = false, params = {}, isDisabled = false }) {

    const { data, loading } = useQuery(FETCH_RELEASE_SUBCATEGORIES, {
        variables: {
            ...params
        }
    });
    
    return (
        <TextField
            select
            value={loading ? "loading" : value}
            disabled={loading || isDisabled}
            label={`Tipos ${required ? "*" : ""}`}
            onChange={(e) => onChange(e.target.value)}
            variant="outlined"
            fullWidth
        >
            {loading && <MenuItem value="loading">Carregando..</MenuItem>}
            {data &&
                data.fetchReleaseSubcategories.data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.title}
                    </MenuItem>
                ))}
        </TextField>
    );
}

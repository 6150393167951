import { Snackbar, Typography } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { Tooltip } from "@material-ui/core";
import { useGlobalMessage } from "../App";
import { phoneMask } from "../masks";
import { useState } from "react";

export default function CustomerPhones({ values }){
    
    const [message, setMessage] = useState();

    return (
        <div>
            <Typography variant="caption">Telefones</Typography>
            {
                values.map((i) => {

                    let phone = phoneMask(i.phone);

                    return (
                        <div onClick={() => { navigator.clipboard.writeText(phone); setMessage('"'+phone+'" copiado.') }} key={i.id} style={{  fontSize: 14, fontWeight: 600, display: 'flex', flexDirection: 'row', marginBottom: 3, cursor: 'pointer' }}>
                            <div style={{ width: 14, height: 14, marginRight: 5 }}>
                                {
                                    i.verified &&
                                    <Tooltip title={i.verified}>
                                        <VerifiedUser style={{ color: '#0C0', fontSize: 14 }} />
                                    </Tooltip>
                                }
                            </div>
                            { phone }
                            <FileCopyIcon style={{ color: '#333', fontSize: 14, marginLeft: 5 }} />
                        </div>
                    )
                })
            }
            <Snackbar
                open={message ? true : false}
                onClose={() => setMessage(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={1000}
                message={<span id="message-id">{message}</span>}
            />
        </div>
    )

}
import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_ADMIN, UPLOAD_COMMON_FILE } from "../../../../graphql/mutations";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import * as Yup from 'yup';

export default function CreateDialog({ open, handleClose, onCreate }) {

    // Setting mutation
    const [uploadCommonFile, { loading }] = useMutation(UPLOAD_COMMON_FILE);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: { theme: "", file: null },
        validationSchema: Yup.object().shape({
            theme: Yup.string().required('Campo obrigatório').nullable()
        }),
        onSubmit: (values) => {

            if(!values.file){
                alert("Selecione um arquivo");
                return;
            }

            // Chamando a mutation de criação
            uploadCommonFile({
                variables: values
            }).then(() => {
                resetForm();
                onCreate();
            })

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Enviar novo conteúdo</DialogTitle>

                <DialogContent>

                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={values.theme}
                        required
                        id="theme"
                        label="Tema"
                        name="theme"
                        error={errors.theme && true}
                        helperText={errors.theme}
                    />

                    <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={values.file?.name}
                        InputLabelProps={{ shrink: values.file?.name }}
                        required
                        label="Arquivo"
                        error={errors.file && true}
                        helperText={errors.file}
                    />

                    <input
                        accept="image/*, audio/*, video/*, application/pdf, application/msword, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => setFieldValue('file', e.target.files[0])}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="outlined" color="primary" component="span">
                            <CloudUploadIcon style={{ marginRight: 12 }} /> Selecionar arquivo
                        </Button>
                    </label>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

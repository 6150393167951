import {
  Container,
  Card,
  Typography,
  CardContent,
  IconButton,
  Grid,
} from "@material-ui/core";
import List from "./List";
import ListManual from "./ListManual";
import Header from "../../../components/Header";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import CreateDialog from "./CreateDialog";
import CreateDialogManual from "./CreateDialogManual";
import { useState } from "react";
import { FETCH_CAMPAINGS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { FilterListSharp } from "@material-ui/icons";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FilterModalCampaing from "./FilterModalCampaing";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function CampaingPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [showCreate, setShowCreate] = useState(false);
  const [showCreateManual, setShowCreateManual] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSpeed, setOpenSpeed] = useState(false);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_CAMPAINGS,
    {
      variables: {
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  const actions = [
    {
      icon: <ContactPhoneIcon size={32} round />,
      name: "Criar nova campanha manual",
      operation: "Criar nova campanha manual",
      action: () => setShowCreateManual(true),
    },
    {
      icon: <DialerSipIcon size={32} round />,
      name: "Criar nova campanha discador",
      operation: "Criar nova campanha discador",
      action: () => setShowCreate(true),
    },
  ];

  const handleTabs = (e, value) => {
    setValue(value);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpenSpeed(false);

  const listRef = useRef();

  return (
    <>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <Card style={{ marginTop: 12, height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="h1">
              Campanhas
            </Typography>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={() => handleOpen(true)}>
                <FilterListSharp fontSize="large" />
              </IconButton>
            </Grid>
          </CardContent>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabs}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#68bc4f",
                },
              }}
            >
              <Tab label="Campanha Discador" />
              <Tab label="Campanha Manual" />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <List
              onMount={(ref) => (listRef.current = ref)}
              {...{
                loading,
                error,
                data,
                networkStatus,
                currentPage,
                setCurrentPage,
                rowsPerPage,
                setRowsPerPage,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListManual
              onMount={(ref) => (listRef.current = ref)}
              {...{
                loading,
                error,
                data,
                networkStatus,
                currentPage,
                setCurrentPage,
                rowsPerPage,
                setRowsPerPage,
              }}
            />
          </TabPanel>
        </Card>
      </Container>
      <CreateDialog
        open={showCreate}
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          enqueueSnackbar("Campanha cadastrada com sucesso!");
          setShowCreate(false);
          listRef.current.refetch();
        }}
      />
      <CreateDialogManual
        open={showCreateManual}
        handleClose={() => setShowCreateManual(false)}
        onCreate={() => {
          enqueueSnackbar("Campanha cadastrada com sucesso!");
          setShowCreateManual(false);
          listRef.current.refetch();
        }}
      />
      <SpeedDial
        FabProps={{ style: { backgroundColor: "#68bc4f" } }}
        ariaLabel="SpeedDial Campanhas"
        sx={{ position: "fixed", bottom: 48, right: 48 }}
        icon={<SpeedDialIcon />}
        onMouseLeave={handleClose}
        onClick={() => setOpenSpeed((x) => !x)}
        open={openSpeed}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>

      <FilterModalCampaing
        open={open}
        onClose={() => setOpen(false)}
        onChangeFilter={(values) => {
          refetch(values);
          setOpen(false);
        }}
      />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

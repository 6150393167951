import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardHeader,
  Button,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Link,
} from "@material-ui/core";
import TextCopy from "../../components/TextCopy";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {
  formatDateBRL,
  maskCPF,
  maskPhone,
  maskPhoneMicroSip,
  maskMoney,
} from "../../helpers";
import DialogEditPhone from "./DialogEditPhone";
import { useState } from "react";
import DialogAddPhone from "./DialogAddPhone";
import EditDialogClientData from "./EditDialogClientData";
import DialogEditDebitAcc from "./DialogEditDebitAcc";
import DialogDebitAccount from "../LeadDetail/DialogDebitAccount";
import DialerSipIcon from "@mui/icons-material/DialerSip";

export default function ClientData({
  data,
  onEditPhone,
  onCreatePhone,
  debitAccounts,
  onEditDebitAccount,
  refetch,
  tab,
}) {
  const [open, setOpen] = useState(false);
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [phoneToEdit, setPhoneToEdit] = useState(null);
  const [debitAccToEdit, setDebitAccToEdit] = useState();
  const [showDebitAccount, setShowDebitAccount] = useState(false);

  const phones = data.phones;

  return (
    <>
      <Card style={{ marginTop: 16, padding: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>DÉBITOS EM CONTA</b>
        </Typography>

        {debitAccounts.length === 0 ? (
          <CardContent>
            <Typography>Nenhum débito em conta cadastrado</Typography>
          </CardContent>
        ) : (
          <>
            <CreditScoreIcon
              style={{
                position: "absolute",
                top: 20,
                left: 250,
                color: "#68bc4f",
              }}
            />

            <TableContainer style={{ margin: "16px 0 12px 0" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>Valor Parcela</TableCell>
                    <TableCell>Qtd. Parcelas</TableCell>
                    <TableCell>Qtd. Paga</TableCell>
                    <TableCell>Atualização</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {debitAccounts.map((row) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell>{row.bank.name}</TableCell>
                        <TableCell>
                          R$ {maskMoney(row.portion_amount)}
                        </TableCell>
                        <TableCell>{row.portion_count}</TableCell>
                        <TableCell>{row.portion_paid_count}</TableCell>
                        <TableCell>{row.updated_at}</TableCell>
                        <TableCell>
                          <IconButton
                            style={{ padding: 0, marginRight: 24 }}
                            onClick={() => setDebitAccToEdit(row)}
                            aria-label="edit"
                          >
                            <EditIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <DialogEditDebitAcc
              open={debitAccToEdit ? true : false}
              handleClose={() => setDebitAccToEdit(null)}
              initialValues={debitAccToEdit || {}}
              onEdit={() => {
                setDebitAccToEdit(null);
                onEditDebitAccount();
              }}
            />
          </>
        )}

        <div style={{ textAlign: "center", marginTop: 12 }}>
          <Button
            color="primary"
            onClick={() => {
              setShowDebitAccount(true);
            }}
            startIcon={<AddIcon />}
          >
            Débitos em conta
          </Button>
        </div>

        <DialogDebitAccount
          open={showDebitAccount}
          lead_benefit_id={data?.benefits[tab]?.id}
          lead_id={data?.id}
          refetch={refetch}
          handleClose={() => {
            setShowDebitAccount(false);
          }}
          netIncome={data.benefits[tab]?.net_income || {}}
        />
      </Card>

      <Card style={{ marginTop: 16, paddingTop: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>DADOS DO CLIENTE</b>
        </Typography>

        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <TextCopy title="CPF" value={maskCPF(data.document)} />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextCopy
                    title="Data de Nascimento"
                    value={data.birthdate ? formatDateBRL(data.birthdate) : "-"}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextCopy title="CEP" value={data.zipcode || "-"} />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextCopy title="Endereço" value={data.address || "-"} />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextCopy title="Bairro" value={data.district || "-"} />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextCopy
                    title="Cidade"
                    value={
                      !data.location
                        ? "-"
                        : data.location.name + "/" + data.location.state
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ textAlign: "center", marginTop: 12 }}>
            <Button
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setOpen(true)}
            >
              Editar dados
            </Button>
          </div>

          <EditDialogClientData
            data={data}
            open={open}
            handleClose={() => setOpen(false)}
            onEdit={() => {
              setOpen(false);
              refetch();
            }}
          />
        </CardContent>
      </Card>

      {data.service !== null && (
        <Card style={{ marginTop: 24 }}>
          <CardHeader title="Origem do Cliente"></CardHeader>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <TextCopy title="Serviço" value={data.service || "-"} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextCopy
                      title="Entidade"
                      value={data.entity_responsible || "-"}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextCopy title="Produto" value={data.product || "-"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
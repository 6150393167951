import { ModalLayout } from "../../../components/ModalLayout";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import React, { Fragment } from "react";

export function ModalVisualization({ open, handleClose, buttonText }) {
  const { getValues } = useFormContext();
  const { groups } = getValues();
  return (
    <ModalLayout
      title="Pré visualização"
      open={open}
      handleClose={handleClose}
      buttonText={buttonText}
      isNotShowButtonSubmit={true}
      variantButtonClose="contained"
    >
      <Grid container spacing={2} marginTop={0}>
        {groups.map((group, indexGroup) => (
          <Fragment key={group.id}>
            {group.components.map((component) => (
              <Fragment key={component.id}>
                {component && component.component && (
                  <component.component
                    label={component.name}
                    name={component.name}
                    placeholder={component.name}
                    options={component.options}
                    fullWidth
                    index={indexGroup}
                  />
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </Grid>
    </ModalLayout>
  );
}
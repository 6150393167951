import { Typography, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { useState } from "react";
import CreateIcon from '@material-ui/icons/Create';

export default function InputProposalStatus({ labelBlank, value, onChange }) {

    const [showDialog, setShowDialog] = useState(false);
    const [newValue, setNewValue] = useState(value);

    let display = value || labelBlank || "Todos";

    const handleChangeRadio = (e) => {
        setNewValue(e.target.value);
    }

    const handleSelect = () => {
        onChange(newValue); 
        setShowDialog(false);
    }

    return (
        <>
            <div style={{cursor: 'pointer'}} onClick={() => setShowDialog(true)}>
                <Typography variant="subtitle2">Status</Typography>
                <Typography variant="caption">{display}</Typography>
                <CreateIcon style={{fontSize: '15px', paddingLeft: '8px', position: 'relative', top: '2px'}}/>
            </div>

            <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="md">
                <DialogTitle>Status</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender" value={newValue} onChange={handleChangeRadio}>
                                <FormControlLabel value="Aberta" control={<Radio />} label="Aberta" />
                                <FormControlLabel value="Fechada" control={<Radio />} label="Fechada" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{padding: '16px'}}>
                    <Button 
                        color="primary"
                        onClick={() => { 
                            setNewValue(null); 
                            onChange(null); 
                            setShowDialog(false)} 
                        }>
                        Limpar
                    </Button>
                    <Button onClick={handleSelect} variant="contained" color="primary">
                        Selecionar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
import React, { useState } from "react";
import {
  List,
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
  Paper,
} from "@mui/material";
import ModalStatement from "./ModalStatement";

const formatDate = (datetime) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const isDarkColor = (color) => {
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
};

export default function DialogNotification({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        position: "absolute",
        top: "67px",
        right: "0",
        width: "20%",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 30px",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "16px", fontWeight: "700" }}
        >
          Comunicados
        </Typography>
      </Box>
      <div style={{ maxHeight: "100vh", overflowY: "scroll" }}>
        <List>
          {data?.fetchAnnouncements.map((notification, index) => (
            <Card
              key={`${index}`}
              sx={{
                marginBottom: "5px",
                marginX: "20px",
                backgroundColor: notification?.theme,
              }}
              onClick={() => handleOpen(notification)}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  sx={{
                    color: isDarkColor(notification?.theme) ? "#FFF" : "#000",
                  }}
                >
                  {notification?.description}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: isDarkColor(notification?.theme) ? "#FFF" : "#000",
                  }}
                >
                  Enviado: {`${formatDate(notification?.created_at)}`}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </List>
      </div>
      <Divider />
      <ModalStatement open={open} onClose={handleClose} data={selectedItem} />
    </Paper>
  );
}

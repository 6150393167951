import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from "@material-ui/core";
import { useState } from "react";
import SearchInputDebounced from "./SearchInputDebounced";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function SelectEditModalUF({
  open,
  onClose,
  onChange,
  loading,
  value,
}) {
  const [newValue, setNewValue] = useState([]);

  const ufs = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MT",
    "MS",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  // // Search
  const [searchTerm, setSearchTerm] = useState(null);

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    let index = newValue.findIndex((i) => i === value);

    if (index >= 0 && e.target.checked === false) deleteItem(index);
    else {
      let clone = [...newValue];
      clone.push(value);
      setNewValue(clone);
    }
  };

  const deleteItem = (index) => {
    let clone = [...newValue];
    clone.splice(index, 1);
    setNewValue(clone);
  };

  const handleSelect = () => {
    onChange(newValue);
    setNewValue(newValue);
    onClose();
  };

  const handleClearFilter = () => {
    setNewValue([]);
    setSearchTerm(null);
    onClose();
  };

  const filteredUfs = ufs.filter((uf) => {
    if (searchTerm == null) return uf;
    else if (uf.toLowerCase().includes(searchTerm.toLowerCase())) {
      return uf;
    }
  });

  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth="md">
        <DialogTitle>{"UF's"}</DialogTitle>
        <DialogContent>
          <SearchInputDebounced
            typeSearch="inDialog"
            onChange={(value) => {
              setSearchTerm(value);
            }}
          />

          <div style={{ marginTop: 20, width: 300 }}>
            {newValue.map((element, index) => {
              return (
                <Chip label={element} onDelete={() => deleteItem(index)} />
              );
            })}
          </div>

          <div
            style={{
              marginTop: 20,
              height: 300,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormGroup>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {filteredUfs.map((uf) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newValue.includes(uf)}
                        value={uf}
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label={uf}
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClearFilter} color="primary">
            Limpar
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_SALE_ORIGINS } from "../graphql/queries";

export default function SelectSaleOrigins({ 
    value, 
    onChange, 
    title = "Origem de atendimento"
  }) {
  const { data, loading } = useQuery(FETCH_SALE_ORIGINS, {
    variables: {
      all: true,
    },
  });

  return (
    <TextField
      select
      value={loading ? 1 : value}
      disabled={loading}
      label={title}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      fullWidth
    >
      {loading && <MenuItem value={1}>Carregando..</MenuItem>}

      {data &&
        data.fetchSaleOrigins.data.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </TextField>
  );
}

import { useFormik } from "formik";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { maskCPF, isValidCPF } from "../../../../helpers";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_LEAD } from "../../../../graphql/queries";
import Step02 from "./Step02";
import Step03 from "./Step03";
import Step01 from "./Step01";

export default function DialogOpenProposal({
  open,
  handleClose,
  dataPerson,
  onProposal,
  opportunityValue,
  campaign_id,
}) {

  const [step, setStep] = useState(0);
  const [schema, setSchema] = useState(null);
  const [productSelected, setProductSelected] = useState(null)

    const [searchLead, { data, loading, error }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: "network-only",
  });

  const { setFieldValue, values } = useFormik({
    initialValues: {
      document: dataPerson?.document || "",
      pessoal_step: {},
    },
  });
  
  
  useEffect(() => {
    if (data && data.searchLead) {
      setFieldValue("pessoal_step", data.searchLead || {});
      setStep(1);
    }

    if (error) {
      setFieldValue("name", "");
      setFieldValue("phone", "");
      setFieldValue("email", "");
      setStep(1);
    }
  }, [data, error]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{ marginBottom: "-16px" }}>
        Informe os dados do cliente
      </DialogTitle>

      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Pesquisar cliente</StepLabel>
          <StepContent>
            <TextField
              variant="outlined"
              margin="normal"
              id="reason"
              label="Informe o CPF"
              value={maskCPF(values.document)}
              onChange={(e) => {
                setFieldValue("document", maskCPF(e.target.value));
                setSchema(null);
              }}
              autoFocus
              fullWidth
              required
            />

            <Button
              variant="contained"
              color="primary"
              disabled={loading || !isValidCPF(values.document)}
              onClick={() =>
                searchLead({ variables: { document: values.document } })
              }
            >
              {loading && (
                <CircularProgress size={14} style={{ marginRight: 12 }} />
              )}
              Próximo
            </Button>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Selecione o produto</StepLabel>
          <StepContent>
            <Step01
              {...{
                setStep,
                productSelected
              }}
              selectedFields={values.pessoal_step}
              onAdvance={(
                  prodId, 
                  releasedAmount, 
                  releasedGrossAmount, 
                  proposalNumber, 
                  releasedPortionCount, 
                  releasedPortionAmount,
                  saleOriginId,
                ) => {
                setProductSelected(prodId)
                setFieldValue("pessoal_step.released_amount", releasedAmount)
                setFieldValue("pessoal_step.released_gross_amount", releasedGrossAmount)
                setFieldValue("pessoal_step.proposal_number", proposalNumber)
                setFieldValue("pessoal_step.released_portion_count", releasedPortionCount)
                setFieldValue("pessoal_step.released_portion_amount", releasedPortionAmount)
                setFieldValue("pessoal_step.sale_origin_id", saleOriginId)
              }}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Dados do cliente</StepLabel>
          <StepContent>
            <Step02
              {...{ setStep }}
              data={values.pessoal_step}
              dataPerson={dataPerson}
              dataSearch={data}
              setFormik={setFieldValue}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Dados da proposta</StepLabel>
          <StepContent>
            <Step03
              {...{ setStep, step, campaign_id, productSelected }}
              setFormik={setFieldValue}
              pessoalValues={values.pessoal_step}
              documentValue={values.document}
              handleClose={handleClose}
              opportunityValue={opportunityValue}
              dynamicForm={productSelected?.dynamic_form}
              productId={productSelected?.id}
            />
          </StepContent>
        </Step>
      </Stepper>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

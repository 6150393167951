import { ApolloProvider } from "@apollo/client";
import { ApolloClient, InMemoryCache, from, createHttpLink  } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import useToken from '../tokenJWT';
import { useSnackbar } from "notistack";
import { createUploadLink } from 'apollo-upload-client';

export default function ApolloCustomProvider({ children }) {

    const { getSchema, token } = useToken();
    const { enqueueSnackbar } = useSnackbar();

    /*
     * HTTP Link
     */
    const link = createUploadLink({
        uri: process.env.REACT_APP_BACKEND + '/graphql?schema=' + getSchema(),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
        }
    });

    /*
     * Auth Link
     */
    const authLink = setContext((_, { headers }) => {
        
        if(!token)
            return headers;

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    /*
     * Error Link
     */

    const errorLink = onError(({ graphQLErrors, networkError }) => {

        if (networkError && (networkError.statusCode === 500 || networkError.statusCode === 404)) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }

        if (graphQLErrors){

            if(graphQLErrors[0].message == 'Signature verification failed'){
                localStorage.removeItem('token');
                window.location.href = '/login';
            }

            graphQLErrors.map(({ message, locations, path }) => {
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });
        }

        if (networkError) 
            console.log(`[Network error]: ${networkError}`);
    });

    const mergePagination = {
        keyArgs: ['keyword'],
        merge(existing, incoming) {
      
          if (!incoming) return existing
          if (!existing) return incoming
      
          if(incoming.current_page < existing.current_page)
            return existing;
      
          const { data, ...rest } = incoming;
          let result = rest;
          result.data = [...existing.data, ...data];
      
          return result
        }
      };

    /**
     * Create Client
     */
    const client = new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        // fetchCompanies: mergePagination,
                        // fetchTeams: mergePagination,
                        // fetchSaleConsultants: mergePagination
                    }
                }
            }
        }),
        link: from([errorLink, authLink, link])
    });

    return (
        <ApolloProvider client={client}>
            { children }
        </ApolloProvider>
    );

}
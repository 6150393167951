import {
	FormControl,
	InputLabel,
	Select as SelectMaterial,
	OutlinedInput,
	SelectProps as SelectPropsMaterial,
	MenuItem,
	Grid,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

function FieldSelectBox({
	label,
	name,
	options,
	fullWidth,
	index,
	readonly,
	...rest
}) {
	const { register } = useFormContext();
	return (
		<Grid item xs={12}>
			<FormControl sx={{ minWidth: 120 }} fullWidth>
				<InputLabel htmlFor={`dialog-${label}`}>{label}</InputLabel>
				<SelectMaterial
					readOnly={readonly}
					{...rest}
					fullWidth
					input={<OutlinedInput label={label} id={`dialog-${label}`} />}
					defaultValue={label}
					{...register(`groups.${index}.fields.${name}`)}
				>
					<MenuItem
						value=""
						style={{
							width: "100%",
							paddingTop: 4,
							paddingBottom: 4,
							paddingLeft: 8,
							justifyContent: "flex-start"
						}}
					>
						<em>Nenhum(a)</em>
					</MenuItem>
					{options.map((option) => (
						<MenuItem
							key={option.value ?? option.id}
							value={option.value ?? option.id}
							style={{
								width: "100%",
								paddingTop: 4,
								paddingBottom: 4,
								paddingLeft: 8,
								justifyContent: "flex-start"
							}}
						>
							{option.title ?? option.label}
						</MenuItem>
					))}
				</SelectMaterial>
			</FormControl>
		</Grid>
	);
}

export default FieldSelectBox;
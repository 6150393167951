import { useQuery } from "@apollo/client";
import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DETAIL_LEAD, FETCH_OPPORTUNITIES, FETCH_TAGS } from "../../graphql/queries";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import LeadDetailConsultant from "../../components/LeadDetailConsultant";
import UpdateCrm from "../common/LeadDetailPage/UpdateCrm";
import CachedIcon from "@mui/icons-material/Cached";

export default function Interaction({ chave }) {
  const [tab, setTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [crm, setCrm] = useState(false);
  const [isLeadSubmitted, setIsLeadSubmitted] = useState(false);

  const { data, loading, refetch } = useQuery(DETAIL_LEAD, {
    notifyOnNetworkStatusChange: true,
    variables: {
      document: chave,
    },
    fetchPolicy: "network-only"
  });

  const { data: dataTags, loading: loadingTags } = useQuery(FETCH_TAGS, {
    variables: {
      type: "Produto"
    }
  })

  const {
    data: dataOpp,
    refetch: refetchOpp,
    loading: loadingOpp,
  } = useQuery(FETCH_OPPORTUNITIES, {
    variables: {
      lead_id: data?.detailLead?.id,
    },
  });

  const handleClick = () => {
    if (data?.detailLead?.document !== null || undefined) {
      setCrm(true);
    }
  };

  const handleLeadAction = async (value) => {
    await new Promise((resolve) => setTimeout(resolve, 500))
    await refetch();
    await refetchOpp();
  };

  useEffect(() => {
    if (data && dataTags) {
      const requiredIds = dataTags.fetchTags.data
        .filter(item => item.required === true)
        .map(item => item.id);

      const isEligible = requiredIds.every(requiredId => {
        const foundTag = data.detailLead.tags.find(tag => tag.id === requiredId);
        return foundTag && foundTag.eligible !== null;
      });

      setIsLeadSubmitted(isEligible)
    }
  }, [data])

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6">SIM Crédito</Typography>
          <Tooltip
            title={
              data?.detailLead?.document === null
                ? "É necessário o número do CPF para atualização dos dados"
                : "Atualizar os dados do cliente"
            }
            placement="botton"
          >
            <CachedIcon
              style={{
                position: "absolute",
                right: "220px",
                fontSize: "33px",
                cursor: !data?.detailLead?.document ? "default" : "pointer",
                color: !data?.detailLead?.document ? "#CCC" : "#FFF",
              }}
              onClick={handleClick}
            />
          </Tooltip>
        </Toolbar>

        {data?.detailLead && data.detailLead.benefits.length > 1 && (
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            TabIndicatorProps={{ style: { backgroundColor: "yellow" } }}
          >
            {data.detailLead.benefits.map((i) => (
              <Tab label={i.number} />
            ))}
          </Tabs>
        )}
      </AppBar>

      <Container
        maxWidth="lg"
        style={{
          marginTop:
            data?.detailLead && data.detailLead.benefits.length > 1 ? 130 : 85,
        }}
      >
        {loading && <CircularProgress />}
        {data?.detailLead && (
          <>
            <LeadDetailConsultant
              refetch={refetch}
              data={data.detailLead}
              tab={tab}
              onRescheduled={(value) => {
                enqueueSnackbar("Reagendamento realizado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onUninterested={(value) => {
                enqueueSnackbar("Desinteresse registrado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onUnprofile={(value) => {
                enqueueSnackbar("Lead registrado como sem perfil !", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onInvalidatedContact={(value) => {
                enqueueSnackbar("Contato invalidado com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onInNegotiation={(value) => {
                enqueueSnackbar("Negociação iniciada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onIndication={(value) => {
                enqueueSnackbar("Indicação registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onProposal={() => {
                refetchOpp();
                refetch();
              }}
              onEditPhone={() => {
                enqueueSnackbar("Telefone editado com sucesso!");
                refetch();
              }}
              onCreatePhone={() => {
                enqueueSnackbar("Telefone cadastrado com sucesso!");
                refetch();
              }}
              onCallEnd={() => {
                window.location.href = "/fastway";
              }}
              onTag={async (value) => {
                enqueueSnackbar("Tag registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
              onDebit={async (value) => {
                enqueueSnackbar("Inadimplência registrada com sucesso!", {
                  variant: "success",
                });
                handleLeadAction(value);
              }}
            />

            <UpdateCrm
              open={crm ? true : false}
              onClose={() => setCrm(false)}
              data={data}
              refetch={refetch}
              loading={loading}
            />
          </>
        )}
      </Container>

      <Dialog
        open={isLeadSubmitted}
        scroll="paper"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Finalização de atendimento</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = "/fastway";
            }}
            variant="contained"
            color="primary"
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from 'react';
import { NetworkStatus, useMutation } from '@apollo/client';
import { 
  CircularProgress, 
  LinearProgress, 
  TablePagination, 
  TableRow, 
  TableHead, 
  TableContainer, 
  TableCell, 
  TableBody, 
  Table, 
  IconButton 
} from "@mui/material";
import LocalProvider from "../../../../components/LocalProvider";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DELETE_PROFILE } from '../../../../graphql/mutations'

export default function List({ loading, error, data, networkStatus, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, onEdit, onDelete }) {

  // Setup mutation
  const [deleteProduct, { loading: loadingDelete }] = useMutation(DELETE_PROFILE);

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  const handleEdit = (item) => {
    onEdit(item)
  }

  const handleDelete = (item) => {

    deleteProduct({ 
      variables: { 
        id: item.id 
      } 
    })
    .then(() => onDelete())
  }

  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.fetchProfiles.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    <IconButton style={{ padding: 0, marginRight: 24 }} aria-label="delete" onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      style={{ padding: 0 }} 
                      aria-label="delete" 
                      onClick={() => handleDelete(row)}>
                      { loadingDelete ? <CircularProgress size={21} /> : <DeleteIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }

            {
              data.fetchProfiles.data.length == 0 &&
              <TableRow>
                <TableCell colSpan={2}>Nenhum perfil cadastrado</TableCell>
              </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
        	showFirstButton
          showLastButton        
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data.fetchProfiles.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}
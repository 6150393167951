import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useState } from "react";
import Debit from "./Debit";
import "../../../assets/styleSheet/ScrollBar.css";

export default function DialogDebitAccount({
  open,
  handleClose,
  netIncome,
  lead_benefit_id,
  refetch,
  setShowDebitAccount,
  lead_id,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const { setFieldValue, values, errors, resetForm } = useFormik({
    initialValues: {
      account_debits: [{}],
      portion_amount: "0,00",
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth={true}
      className="scroolbarCustom"
    >
      <DialogTitle id="scroll-dialog-title" style={{ marginBottom: "-16px" }}>
        Débitos em conta
      </DialogTitle>

      <DialogContent style={{ overflow: "hidden" }}>
        {
          <Typography variant="caption">
            Informe as parcelas que debitam atualmente na conta corrente do
            cliente
          </Typography>
        }

        <Debit
          handleClose={handleClose}
          lead_id={lead_id}
          refetch={refetch}
          lead_benefit_id={lead_benefit_id}
          {...{ values, errors, setFieldValue, resetForm }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

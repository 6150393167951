import Input from "../../../../../components/Input";
import { memo } from "react";

function InputFormName() {
    return (
        <Input
            name="formName"
            placeholder="Nome do formulário"
            autoComplete="off"
        />
    );
}

export default memo(InputFormName);
import { useMutation } from "@apollo/client";
import {
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  ThemeProvider,
  createTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_TAG } from "../../../../graphql/mutations";
import ColorPicker from "../../../../components/ColorPicker";
import { Box, Grid, Typography } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import { useState } from "react";

export default function CreateDialog({ open, handleClose, onCreate, title }) {
  const [createTag, { loading }] = useMutation(CREATE_TAG);
  const [type, setType] = useState("Produto");

  const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({
    initialValues: { name: "", color: "#68bc4f", required: false },
    onSubmit: (values) => {
      createTag({
        variables: {
          name: values.name,
          color: values.color,
          type: values.type || "Produto",
          required: values.isTagRequired || false,
        },
      }).then(() => {
        resetForm();
        onCreate();
      });
    },
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#68bc4f",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <Grid container marginTop={2}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" style={{ width: "80%" }}>
                <InputLabel>Tipo da tag</InputLabel>
                <Select
                  label="Tipo da tag"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    setFieldValue("type", e.target.value);
                  }}
                >
                  <MenuItem value={"Produto"}>Produto</MenuItem>
                  <MenuItem value={"Atendimento"}>Atendimento</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} sm={10}>
              <TextField
                variant="outlined"
                margin="normal"
                onChange={(e) => setFieldValue("name", e.target.value)}
                value={values.name}
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoFocus
                error={errors.name && true}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ColorPicker
                selectedColor={values.color}
                onSelectColor={(color) => setFieldValue("color", color)}
              />
            </Grid>
          </Grid>
          <Box
            marginTop="20px"
            marginBottom="20px"
            display="flex"
            alignItems="center"
          >
            <Typography variant="body2" marginRight="10px">
              Pré-visualização:
            </Typography>
            {type === "Atendimento" ? (
              <Chip
                label={values.name ? values.name : "Visualizar"}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  color: values.color || "#68bc4f",
                  borderColor: values.color || "#68bc4f",
                  padding: "5px",
                }}
                icon={
                  <BookmarkBorderIcon
                    style={{ fontSize: 15, color: values.color || "#68bc4f" }}
                  />
                }
              />
            ) : (
              <Chip
                label={values.name ? values.name : "Visualizar"}
                variant="outlined"
                style={{
                  backgroundColor: values.color || "#68bc4f",
                  marginRight: "10px",
                  color: "#FFF",
                  padding: "5px",
                }}
                icon={
                  values.isTagRequired ? (
                    <SdCardAlertIcon style={{ fontSize: 15, color: "#FFF" }} />
                  ) : (
                    <BookmarkBorderIcon
                      style={{ fontSize: 15, color: "#FFF" }}
                    />
                  )
                }
              />
            )}
          </Box>
          {type === "Produto" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isTagRequired}
                  onChange={(e) =>
                    setFieldValue("isTagRequired", e.target.checked)
                  }
                  name="isTagRequired"
                  style={{ color: values.isTagRequired ? "#68bc4f" : "" }}
                />
              }
              label="Tag obrigatória"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={values.name === "" || loading}
            style={{ color: "white" }}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: 12 }} />
            )}
            Criar Tag
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

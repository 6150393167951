import * as Yup from 'yup';
import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Select, TextField, Typography } from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from "formik";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { UPDATE_PROPOSAL_CUSTOMER } from "../../../../graphql/mutations";
import { FETCH_LOCATIONS, FETCH_SPECIES } from "../../../../graphql/queries";
import LineAddReferences from "./LineAddReferences";
import { useEffect, useState } from 'react';

export default function EditDialog({ open, code, handleClose, data, onEdit }) {

    const { format } = require("date-fns");

    const [validReference, setValidReference] = useState(false);
    const [clickSubmit, setClickSubmit] = useState(false);

    const [updateProposalCustomer, { loading }] = useMutation(UPDATE_PROPOSAL_CUSTOMER);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, isValid } = useFormik({
        initialValues: {
            ...data,
        },
        validationSchema: updateProposalCustomerSchema,
        onSubmit: (values) => {

            updateProposalCustomer({
                variables: {
                    id: data.id,
                    proposal_code: code,
                    name: values.name,
                    document: values.document,
                    birthdate: values.birthdate?.iso8601 && format(new Date(values.birthdate.iso8601), 'yyyy-MM-dd'),
                    mothers_name: values.mothers_name,
                    email: values.email,
                    zipcode: values.zipcode,
                    address: values.address,
                    district: values.district,
                    location_id: values.location && values.location.id,
                    benefit_number: values.benefit_number,
                    specie_id: values.specie.id,
                    income: values.income,
                    phone: values.phone,
                    phone2: values.phone2,
                    cellphone: values.cellphone,
                    phone_reference: values.phone_reference.map(i => ({
                        id: i.id == undefined ? null : i.id,
                        name: i.name,
                        phone: i.phone,
                        kinship: i.kinship
                    })),
                }
            }).then(onEdit)
        }
    });

    useEffect(() => {
        let filledReference = [];
        values?.phone_reference.map((el) => {
            filledReference.push(Boolean(el.name && el.phone && el.kinship))
        })

        let issetFalse = filledReference.findIndex((el) => el == false)

        if (issetFalse === -1) {
            setValidReference(false);
        } else {
            setValidReference(true);
        }

        setClickSubmit(false)
    }, [clickSubmit, values.phone_reference])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Editar dados pessoais</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.document}
                            required
                            fullWidth
                            label="CPF"
                            name="document"
                            error={errors.document}
                            helperText={errors.document}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.name}
                            required
                            fullWidth
                            label="Nome"
                            name="name"
                            error={errors.name}
                            helperText={errors.name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.email}
                            required
                            fullWidth
                            label="E-mail"
                            name="email"
                            error={errors.email}
                            helperText={errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker
                                autoOk
                                fullWidth
                                variant="inline"
                                inputVariant="outlined"
                                label="Nascimento"
                                format="dd/MM/yyyy"
                                value={values.birthdate?.iso8601}
                                InputAdornmentProps={{ position: "end" }}
                                onChange={(event) => {
                                    if (event != "Invalid Date") {
                                        setFieldValue('birthdate.iso8601', event);
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.mothers_name}
                            fullWidth
                            label="Nome da mãe"
                            name="mothers_name"
                            error={errors.mothers_name}
                            helperText={errors.mothers_name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.zipcode}
                            fullWidth
                            label="CEP"
                            name="zipcode"
                            error={errors.zipcode}
                            helperText={errors.zipcode}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.address}
                            fullWidth
                            label="Endereço"
                            name="address"
                            error={errors.address}
                            helperText={errors.address}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.district}
                            fullWidth
                            label="Bairro"
                            name="district"
                            error={errors.district}
                            helperText={errors.district}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SelectWithQuerySearch
                            title="Cidade"
                            query={FETCH_LOCATIONS}
                            fetchResult="fetchLocations"
                            variables={{ only_cities: true }}
                            onChange={(bank) => { setFieldValue('location', bank) }}
                            fullWidth
                            value={values.location}
                            renderLabel={(i) => i.name + '/' + i.state}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.benefit_number}
                            fullWidth
                            label="Nº Benefício"
                            name="benefit_number"
                            error={errors.benefit_number}
                            helperText={errors.benefit_number}
                        />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.income}
                            fullWidth
                            label="Renda"
                            name="income"
                            error={errors.income}
                            helperText={errors.income}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <SelectWithQuerySearch 
                            title="Espécie"
                            query={FETCH_SPECIES}
                            fetchResult="fetchSpecies"
                            onChange={(specie) => { setFieldValue('specie', specie) }}
                            fullWidth
                            value={values.specie}
                            renderLabel={(i) => i.name}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ marginBottom: 8 }}>
                            Contato
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.phone}
                            fullWidth
                            label="Celular 01"
                            name="phone"
                            error={errors.phone}
                            helperText={errors.phone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.phone2}
                            fullWidth
                            label="Celular 02"
                            name="phone2"
                            error={errors.phone2}
                            helperText={errors.phone2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            onChange={handleChange}
                            value={values.cellphone}
                            fullWidth
                            label="Telefone Fixo"
                            name="cellphone"
                            error={errors.cellphone}
                            helperText={errors.cellphone}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ marginBottom: 8 }}>
                            Referências
                        </Typography>
                        <LineAddReferences {...{ values, setFieldValue }} error={errors.phone_reference} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => {handleSubmit();setClickSubmit(true)}} variant="contained" color="primary" disabled={loading || !isValid || validReference ? true : false}>
                    {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )

}

const updateProposalCustomerSchema = Yup.object().shape({
    phone_reference: Yup.array().min(1, "Favor informe no mínimo uma referência"),
});
import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Backdrop, useMediaQuery } from "@mui/material";

const secureUrl = (url) => {
  return url.startsWith("http://") ? url.replace("http://", "https://") : url;
};

const ImgList = ({ itemsData }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [open, setOpen] = useState({
    state: false,
    url: null,
  });

  const handleClose = () => {
    setOpen({ state: false, url: null });
  };
  const handleToggle = (itemUrl) => {
    setOpen({ state: !open.state, url: secureUrl(itemUrl) });
  };

  const data = itemsData.filter((file) => {
    const split = file.name.split(".")[1];
    if (split === "jpg" || split === "png" || split === "jpeg" || split === "gif") {
      return file;
    }
  });

  return (
    <>
      <ImageList sx={{ width: "100%" }} gap={10} cols={isSmallScreen ? 1 : 5}>
        {data.map((item) => (
          <ImageListItem
            key={item.path}
            style={{ cursor: "pointer" }}
            onClick={() => handleToggle(item.path)}
          >
            <img
              style={{
                maxWidth: 75,
                maxHeight: 75,
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
              }}
              src={`${secureUrl(item.path)}?w=180&h=180&fit=crop&auto=format`}
              srcSet={`${secureUrl(item.path)}?w=180&h=180&fit=crop&auto=format&dpr=2 2x`}
              alt={item.name || null}
              loading="lazy"
            />
          </ImageListItem>
        ))}
        {open.state && <BackdropImg {...{ open, handleClose }} />}
      </ImageList>
    </>
  );
};

const BackdropImg = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open.state}
      onClick={handleClose}
    >
      <img
        style={{ maxWidth: 600 }}
        src={`${open.url}`}
        srcSet={`${open.url}`}
        alt=""
        loading="lazy"
      />
    </Backdrop>
  );
};

export default ImgList;

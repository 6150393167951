import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { 
  CircularProgress,
  LinearProgress, 
  TablePagination, 
  TableRow, 
  TableHead, 
  TableContainer, 
  TableCell, 
  TableBody, 
  Table, 
  IconButton 
} from "@mui/material";
import LocalProvider from "../../../../components/LocalProvider";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

export default function List({ 
  loading, 
  error, 
  data, 
  networkStatus, 
  currentPage, 
  setCurrentPage, 
  rowsPerPage, 
  setRowsPerPage, 
  onEdit, 
  onEditPassword, 
  onDelete 
}) {

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  const handleEdit = (item) => {
    onEdit(item)
  }

  const handleEditPassword = (item) => {
    onEditPassword(item)
  }

  const handleDelete = (item) => {
    onDelete(item)
  }
  
  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Equipe</TableCell>
              <TableCell>Canal de venda</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.fetchSaleConsultants.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.sale_consultant_group ? row.sale_consultant_group.name : '-'}</TableCell>
                  <TableCell>{row.team ? row.team.name : '-'}</TableCell>
                  <TableCell>{row.sale_channel ? row.sale_channel.name : '-'}</TableCell>
                  <TableCell style={{ textAlign: 'right', display:'flex', alignItems:'center', columnGap:'1rem' }}>
                    <IconButton style={{ padding: 0 }} aria-label="delete" onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton style={{ padding: 0 }} aria-label="delete" onClick={() => handleEditPassword(row)}>
                      <LockIcon />
                    </IconButton>
                    <IconButton 
                      style={{ padding: 0 }} 
                      aria-label="delete" 
                      onClick={() => handleDelete(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }

            {
              data.fetchSaleConsultants.data.length == 0 &&
              <TableRow>
                <TableCell colSpan={2}>Nenhum público cadastrado</TableCell>
              </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
        	showFirstButton
          showLastButton        
          rowsPerPageOptions={[8, 15, 32, 100]}
          component="div"
          count={data.fetchSaleConsultants.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "../../../../components/Button";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { Fragment, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { DETAIL_DYNAMIC_FORM } from "../../../../graphql/queries";
import { Box, CircularProgress, Grid } from "@mui/material";
import componentsDinamycForm from "../../../../utils/fieldsDynamicForm";
import { CREATE_INTERACTION } from "../../../../graphql/mutations";

export default function ModalService({
    open,
    handleClose,
    handleRefetch,
    formId,
    leadId,
    proposalId
}) {

    const { enqueueSnackbar } = useSnackbar();

    const { data, loading } = useQuery(DETAIL_DYNAMIC_FORM, {
        variables: {
            id: formId
        }
    })
    const [createInteraction, { loading: loadingMutation }] = useMutation(CREATE_INTERACTION)

    const methods = useForm({
        defaultValues: {
            dynamicForm: []
        }
    });
    const { handleSubmit, getValues, watch, setValue } = methods;

    const encontrarObjeto = useCallback((chaveDinamica) => {

        const { groups } = getValues()

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];

            if (group.fields && chaveDinamica in group.fields) {
                return group;
            }
        }

        return null;
    })

    const onSubmit = async (data) => {

        const outputFormat = data.dynamicForm ?
            data.dynamicForm.map((input, index) => {
                const groupInputRef = encontrarObjeto(input.name)

                const formatValue = {
                    ...input,
                    value: groupInputRef.fields[input.name]
                }
                return formatValue
            })
            : null

        try {

            await createInteraction({
                variables: {
                    lead_id: leadId,
                    status: "Pós Venda",
                    dynamic_form_output: JSON.stringify(outputFormat),
                    proposal_id: proposalId
                },
            })

            enqueueSnackbar("Pós venda realizada com sucesso", {
                variant: "success",
            });
            handleClose();
            handleRefetch();
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: "error",
            });
        }
    }

    useEffect(() => {
        if (data) setValue("dynamicForm", JSON.parse(data?.detailDynamicForm.input))
    }, [data])

    return (
        <Dialog
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Atendimento"}</DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <Box display="flex" marginY={4} justifyContent="center">
                            <CircularProgress />
                        </Box>
                        :
                        <FormProvider {...methods}>
                            <Grid container spacing={2} style={{ minWidth: 400 }}>

                                {
                                    watch("dynamicForm").map((input, index) => {

                                        const component = componentsDinamycForm.find(
                                            (value) => value.type === input.type
                                        )

                                        return (
                                            <Fragment key={index}>
                                                {
                                                    component && (
                                                        <Fragment>
                                                            {
                                                                <component.component
                                                                    required={input.required}
                                                                    label={input.label}
                                                                    placeholder={input.label}
                                                                    name={input.name}
                                                                    options={
                                                                        input.options &&
                                                                        input.options.map((option) => ({
                                                                            label: option.name,
                                                                            value: option.name,
                                                                        }))
                                                                    }
                                                                    index={index}
                                                                />
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    })
                                }

                            </Grid>
                        </FormProvider>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Fechar
                </Button>

                <Button
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                    variant="contained"
                >
                    {loadingMutation && (
                        <CircularProgress size={14} style={{ marginRight: 12 }} />
                    )}
                    Registrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import { useState } from "react";
import { useFormik } from "formik";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import useToken from "../../../tokenJWT";
import { useMutation } from "@apollo/client";
import { CREATE_INTERACTION } from "../../../graphql/mutations";
import * as Yup from "yup";
import TimerIcon from "@material-ui/icons/Timer";
import { useSnackbar } from "notistack";

export default function DialogReschedule({
  lead_id,
  open,
  handleClose,
  onRescheduled,
  campaign_id,
}) {
  const { user } = useToken();

  const queryString = window.location.search;
  const isFastway = queryString.includes("chave=");

  const [createInteraction, { loading }] = useMutation(CREATE_INTERACTION);
  const [newValue, setNewValue] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeRadio = (e) => {
    setNewValue(e.target.value);
  };

  const { format } = require("date-fns");

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: { date: null, hour: null, description: null },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("Campo obrigatório").nullable(),
      hour: Yup.string().required("Campo obrigatório").nullable(),
      description: Yup.string().required("Campo obrigatório").nullable(),
    }),
    onSubmit: (values) => {
      createInteraction({
        variables: {
          lead_id: lead_id,
          campaign_id: parseInt(campaign_id),
          status: "Agendado",
          return_in:
            format(values.date, "yyyy-MM-dd") +
            " " +
            format(values.hour, "HH:mm"),
          description: values.description,
          temperature: newValue,
          fastway: isFastway,
        },
      })
        .then(() => {
          resetForm();
          onRescheduled();
        })
        .catch((error) => {
          user && user.is_sale_consultant
            ? enqueueSnackbar("Preencher mínimo de 10 caracteres!", {
                variant: "error",
              })
            : enqueueSnackbar(
                "Somente usuários com perfil de Consultor de Vendas pode realizar agendamento.",
                { variant: "error" }
              );
        });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle> Em negociação</DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                fullWidth
                label="Data"
                value={values.date}
                onChange={(event) => {
                  setFieldValue("date", event);
                }}
                error={errors.date}
                helperText={errors.date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardTimePicker
              clearable
              fullWidth
              variant="inline"
              inputVariant="outlined"
              ampm={false}
              label="Hora"
              name="hour"
              value={values.hour}
              onChange={(event) => {
                setFieldValue("hour", event);
              }}
              error={errors.hour}
              helperText={errors.hour}
              keyboardIcon={<TimerIcon />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              label="Observação"
              value={values.description}
              name="description"
              onChange={handleChange}
              fullWidth
              error={errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Grid style={{ marginLeft: 26, marginTop: 5, color: "#808080" }}>
        *Preencher a observação com mínimo de 10 caracteres.
      </Grid>

      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #D3D3D3",
            borderRadius: "5px",
            padding: "15px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Qual o nível de interesse do cliente?
          <FormControl>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={newValue}
              onChange={handleChangeRadio}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <FormControlLabel
                value="Alto"
                control={<Radio />}
                label="Alto"
                sx={{
                  color: "pink",
                  "&.Mui-checked": {
                    color: "pink",
                  },
                }}
              />
              <FormControlLabel
                value="Médio"
                control={<Radio />}
                label="Médio"
              />
              <FormControlLabel
                value="Baixo"
                control={<Radio />}
                label="Baixo"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, Container, Divider, Grid, Paper, Typography } from "@material-ui/core";
import Header from "../../../components/Header";
import KanbanCard from "./KanbanCard";
import ModalService from "./ModalService";
import { useState } from "react";
import useToken from "../../../tokenJWT";

export default function AfterSalesPage() {

    const { restrictions } = useToken();

    //Restrictions
    const allowInteraction = restrictions({ topic: "Pós-Venda", name: "Realizar" })

    const [openModal, setOpenModal] = useState(false)
    const [formInfo, setFormInfo] = useState({
        formId: null,
        leadId: null,
        proposalId: null
    })
    const [refetchValue, setRefetchValue] = useState(false)

    return (
        <>
            <Header isShowBadge />

            <Container maxWidth="lg" style={{ marginTop: "86px" }}>

                <Paper
                    elevation={1}
                    style={{
                        marginBottom: 16,
                        padding: 8,
                        textAlign: "center"
                    }}
                >
                    <Typography variant="h4">Pós-Venda</Typography>
                </Paper>

                <Box
                    display="flex"
                    flexWrap="nowrap"
                    style={{
                        marginBottom: "16px",
                        width: "100%",
                        overflow: "auto",
                        padding: "8px"
                    }}
                >
                    {
                        gridItems.map((item, index) => {
                            return (
                                <Box key={index} minWidth="312px" marginRight="16px">
                                    <KanbanCard
                                        {...{ refetchValue }}
                                        handleClick={(form_id, lead_id, proposal_id) => {
                                            setOpenModal(true)
                                            setFormInfo({
                                                formId: form_id,
                                                leadId: lead_id,
                                                proposalId: proposal_id
                                            })
                                        }}
                                        handleAbortRefetch={() => setRefetchValue(false)}
                                        title={item.title}
                                        days={item.days}
                                        doneCardAccess={item.doneCard}
                                    />
                                </Box>
                            )
                        })
                    }
                </Box>
            </Container>

            {
                (openModal && formInfo.formId && formInfo.leadId && allowInteraction) &&
                <ModalService
                    formId={formInfo.formId}
                    leadId={formInfo.leadId}
                    proposalId={formInfo.proposalId}
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    handleRefetch={() => setRefetchValue(true)}
                />
            }
        </>
    )
}

const gridItems = [
    {
        title: "Qualidade",
        days: 1,
        doneCard: "after_sale_first_column"
    },
    {
        title: "Pós venda - Parc. 1",
        days: 30,
        doneCard: "after_sale_second_column"
    },
    {
        title: "Pós Venda - Parc. 2 - Segundo Contrato",
        days: 60,
        doneCard: "after_sale_third_column"
    },
    {
        title: "Pós Venda - Parc.3 - Renovação",
        days: 120,
        doneCard: "after_sale_fourth_column"
    },
    {
        title: "Atrasadas",
        days: 200,
        doneCard: null
    }
]


import { useHistory, useParams } from "react-router";
import Header from "../../../components/Header";
import { Container, Grid, Typography, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { DETAIL_PROPOSAL } from "../../../graphql/queries";
import InfoResume from "./InfoResume";
import PessoalCard from "./PessoalCard";
import ProposalCard from "./ProposalCard";
import FilesCard from "./FilesCard";
import { useSnackbar } from "notistack";
import ProductCard from "./ProductCard";

export default function ProposalDetailPage() {

    const history = useHistory();
    let { code } = useParams();
    let { enqueueSnackbar } = useSnackbar();

    const { data, loading, refetch } = useQuery(DETAIL_PROPOSAL, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            code: code
        }
    })

    const goBackOrRedirect = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/propostas');
        }
    }
    
    return (
        <>
            <Header
                title="Detalhe da proposta"
                onBack={goBackOrRedirect}
            />

            <Container style={{ marginTop: 86 }}>
                { loading && <CircularProgress style={{ marginTop: 12 }} /> }

                {
                    data &&
                
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <InfoResume 
                                data={data.detailProposal}
                            />
                        </Grid>

                        <Grid item xs={12} sm={8}>

                            <Grid container spacing={1} style={{ marginBottom: 12 }}>
                                <Grid item xs={3} sm={2}>
                                    <Typography variant="caption">Código</Typography>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>#{ data.detailProposal.code }</Typography>
                                </Grid>
                                <Grid item xs={5} sm={7}>
                                    <Typography variant="caption">Consultor de vendas</Typography>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>{ data.detailProposal.sale_consultant?.name || '-' }</Typography>
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <div style={{ backgroundColor: data.detailProposal.status == 'Aberta' ? '#5FA018' : '#AB0015', fontSize: 11, fontWeight: 'bold', textAlign: 'center', color: '#FFF', paddingTop: 4, paddingBottom: 4, borderRadius: 8, marginTop: 8 }}>
                                        { data.detailProposal.status } 
                                        { data.detailProposal.days_ago == 0 && <> hoje</>}
                                        { data.detailProposal.days_ago == 1 && <> ontem</>}
                                        { data.detailProposal.days_ago >= 1 && <> à {data.detailProposal.days_ago} dias</>}
                                    </div>
                                    <Typography variant="caption" component="div" style={{ textAlign: 'center' }}>{ data.detailProposal.situation?.name || 'Sem situação' }</Typography>
                                </Grid>
                            </Grid>

                            <PessoalCard 
                                data={data.detailProposal} 
                                style={{ marginBottom: 12 }} 
                                onEdit={() => {
                                    enqueueSnackbar("Dados pessoais alterados com sucesso!");
                                    refetch();
                                }}
                            />
                            
                            <ProductCard 
                                data={data.detailProposal} 
                                style={{ marginBottom: 12 }} 
                            />

                            <ProposalCard 
                                data={data.detailProposal} 
                                style={{ marginBottom: 12 }} 
                                onEdit={() => {
                                    enqueueSnackbar("Dados da proposta alterados com sucesso!");
                                    refetch();
                                }}
                            />
                            
                            <FilesCard 
                                data={data} 
                                onUploadedFile={() => {
                                    enqueueSnackbar("Arquivo enviado com sucesso!");
                                    refetch();
                                }}
                                onDeleteFile={() => {
                                    enqueueSnackbar("Arquivo excluido com sucesso!");
                                    refetch();
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            </Container>
        </>
    )

}
import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { UPDATE_PRODUCT } from "../../../../graphql/mutations";
import SelectDynamicFormId from "../../../../components/SelectDynamicFormId";

export default function EditDialog({ open, handleClose, onEdit, data }) {

    // Setting mutation
    const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: { name: "", formId: null },
        onSubmit: (values) => {

            // Chamando a mutation de edição
            updateProduct({
                variables: {
                    id: values.id,
                    name: values.name,
                    dynamic_form_id: values.formId
                }
            }).then(() => {
                onEdit();
            })

        }
    });

    useEffect(function () {
        if (data) {
            resetForm();
            setFieldValue('id', data.id);
            setFieldValue('name', data.name);
            setFieldValue('formId', data.dynamic_form?.id || null)
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Editar Produto</DialogTitle>

                <DialogContent style={{ padding: 16 }}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                        style={{marginBottom: 16}}
                    />

                    <SelectDynamicFormId
                        value={values.formId}
                        inputName="formId"
                        onChange={(id) => setFieldValue("formId", id)}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { DELETE_SALE_CONSULTANT } from "../../../../graphql/mutations";

export default function DeleteDialog({ open, handleClose, onDelete, data }) {

    // Setting mutation
    const [deleteSaleConsultant, { loading }] = useMutation(DELETE_SALE_CONSULTANT);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
        
        initialValues: { id: ""},

        onSubmit: (values) => {

            // Chamando a mutation de remoção
            deleteSaleConsultant({
                variables: {
                    id: values.id,
                }
            }).then(() => {
                onDelete();
            })

        }
    });

    useEffect(function () {
        if (data) {
            setFieldValue('id', data.id);
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Excluir consultor</DialogTitle>

                <DialogContent>

                    <p>Deseja realmente excluir o consultante selecionado?</p>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import React, { useEffect } from 'react';
import {
    Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export,
} from 'devextreme-react/chart';
import { useQuery } from '@apollo/client';
import { COMPARE_REVENUE_AND_EXPENSE_RELEASES } from '../../../../graphql/queries';
import { Box } from '@material-ui/core';
import { CircularProgress } from '@mui/material';


function onPointClick(e) {
    e.target.select();
}

export default function RevenueXExpenses({
    loading,
    dataComparison
}) {

    if (loading) return (
        <Box
            marginY={12}
            display="flex"
            justifyContent="center"
        >
            <CircularProgress size={40} />
        </Box>
    )

    return (
        <Chart id="chart"
            dataSource={dataComparison}
            onPointClick={onPointClick}
        >
            <CommonSeriesSettings
                argumentField="period"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
                <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                </Label>
            </CommonSeriesSettings>
            <Series
                argumentField="period"
                valueField="revenue"
                name="Receitas"
                color="#97c95c"
            />
            <Series
                argumentField="period"
                valueField="expense"
                name="Despesas"
                color="#f5564a"
            />
            <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
            <Export enabled={false} />
        </Chart>
    )
}
import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectWithQuerySearch from "../../../components/SelectWithQuerySearch";
import { ADVANCE_SIMULATOR } from "../../../graphql/mutations";
import { FETCH_BANKS } from "../../../graphql/queries";
import { maskMoney, unmaskMoney } from "../../../helpers";

export default function Debit({
  values,
  setFieldValue,
  lead_benefit_id,
  handleClose,
  refetch,
  resetForm,
  lead_id
}) {
  const [advanceSimulator, { loading }] = useMutation(ADVANCE_SIMULATOR);

  const handleSubmit = () => {
    advanceSimulator({
      variables: {
        lead_id: lead_id,
        lead_benefit_id: lead_benefit_id || null,
        debit_accounts: values.account_debits
          .filter((i) => unmaskMoney(i.portion_amount) > 0)
          .map((i) => ({
            bank_id: i.bank?.id || null,
            portion_amount: unmaskMoney(i.portion_amount),
            portion_count: parseInt(i.portion_count),
            portion_paid_count: parseInt(i.portion_paid_count),
          })),
      },
    }).then(() => {
      handleClose();
      refetch();
      resetForm();
    });
  };

  const updateInfo = (
    index,
    bank,
    portion_amount,
    portion_count,
    portion_paid_count
  ) => {
    let clone = [...values.account_debits];
    clone[index] = {
      bank: bank,
      portion_amount,
      portion_count,
      portion_paid_count,
    };
    setFieldValue("account_debits", clone);
  };

  const addLine = () => {
    let clone = [...values.account_debits];
    clone.push({});
    setFieldValue("account_debits", clone);
  };

  const removeLine = (i) => {
    let clone = [...values.account_debits];
    clone.splice(i, 1);
    setFieldValue("account_debits", clone);
  };

  return (
    <>
      {values.account_debits.map((element, i) => {
        return (
          <>
            <Line
              style={{ marginTop: 12 }}
              index={i}
              data={element}
              onChange={updateInfo}
              onAdd={addLine}
              onRemove={removeLine}
            />
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          </>
        );
      })}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: 12 }}
        disabled={loading}
      >
        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
        Salvar
      </Button>

      <div
        style={{
          marginTop: 12,
          fontSize: 14,
          backgroundColor: "#ffdf00",
          padding: "10px",
          border: "1px solid #ffc800",
        }}
      >
        Certifique-se que as informações cadastradas acima estão corretas. Elas
        irão alimentar o nosso banco de dados.
      </div>
    </>
  );
}

const Line = ({ index, onChange, data, onAdd, onRemove }) => {
  return (
    <Grid style={{ marginTop: 15 }} container spacing={1}>
      <Grid item xs={5} sm={4}>
        <SelectWithQuerySearch
          title="Banco"
          query={FETCH_BANKS}
          onChange={(bank) => {
            onChange(
              index,
              bank,
              data?.portion_amount,
              data?.portion_count,
              data?.portion_paid_count
            );
          }}
          fetchResult="fetchBanks"
          fullWidth
          value={data && data.bank}
        />
      </Grid>

      <Grid item xs={5} sm={2}>
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(
              index,
              data?.bank,
              maskMoney(e.target.value),
              data?.portion_count,
              data?.portion_paid_count
            );
          }}
          label="Valor Parcela"
          value={data && data.portion_amount}
        />
      </Grid>

      <Grid item xs={5} sm={2}>
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(
              index,
              data?.bank,
              data?.portion_amount,
              e.target.value,
              data?.portion_paid_count
            );
          }}
          label="Qtd. Parcelas"
          value={data && data.portion_count}
        />
      </Grid>

      <Grid item xs={5} sm={2}>
        <TextField
          variant="outlined"
          onChange={(e) => {
            onChange(
              index,
              data?.bank,
              data?.portion_amount,
              data?.portion_count,
              e.target.value
            );
          }}
          label="Qtd. Paga"
          value={data && data.portion_paid_count}
        />
      </Grid>

      {index == 0 && (
        <Grid item xs={2} sm={2}>
          <Button
            variant="contained"
            color="primary"
            style={{ height: 55 }}
            onClick={onAdd}
          >
            <AddIcon />
          </Button>
        </Grid>
      )}

      {index > 0 && (
        <Grid item xs={2} sm={2}>
          <Button
            color="default"
            style={{ height: 55 }}
            onClick={() => onRemove(index)}
          >
            <DeleteIcon />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

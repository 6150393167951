import { Button, DialogContent } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Alert, Dialog, DialogActions, DialogContentText, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { addMonths, format, subMonths } from "date-fns";
import { useState } from "react";
import { isFormatedDateFormat } from "../../../utils/masks";

const isDateFormat = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    return regex.test(dateString);
};

export default function ChartFilterDialog({
    open,
    handleClose,
    applyFilters,
    filledFilters
}) {

    const [filters, setFilters] = useState({ ...filledFilters })

    const handleResetFilters = () => {
        const resetFilters = {
            startDate: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
            finishDate: format(new Date(), 'yyyy-MM-dd')
        }
        setFilters(resetFilters)
        applyFilters(resetFilters)
        handleClose()
    }

    const handleApplyFilters = () => {

        const {
            startDate,
            finishDate
        } = filters

        let formatStart = startDate
        let formatFinish = finishDate
        if (!isDateFormat(startDate)) {
            formatStart = format(startDate, 'yyyy-MM-dd')
        }
        if (!isDateFormat(finishDate)) {
            formatFinish = format(finishDate, 'yyyy-MM-dd')
        }

        applyFilters({
            startDate: formatStart,
            finishDate: formatFinish
        })
        handleClose()
    }

    const handleChange = (prop, value) => {
        setFilters(prevState => ({ ...prevState, [prop]: value }))
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">{"Filtros"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Alert severity="info">Os filtros abaixos serão aplicados somente aos gráficos</Alert>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            inputVariant="outlined"
                            label="Data inicial"
                            format="dd/MM/yyyy"
                            value={filters.startDate}
                            fullWidth
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) => handleChange("startDate", date)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            inputVariant="outlined"
                            label="Data final"
                            format="dd/MM/yyyy"
                            value={filters.finishDate}
                            fullWidth
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) => handleChange("finishDate", date)}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleResetFilters} color="primary">
                    Resetar Filtros
                </Button>
                <Button onClick={handleApplyFilters} color="primary" autoFocus variant="contained">
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
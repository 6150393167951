import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect } from "react";
import { UPDATE_CONSULTANT_PASSWORD } from "../../../../graphql/mutations";

export default function EditPasswordDialog({ open, handleClose, onEditPassword, data }) {

    // Setting mutation
    const [updateConsultantPassword, { loading }] = useMutation(UPDATE_CONSULTANT_PASSWORD);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: { id: "", password: "" },
        onSubmit: (values) => {

            // Chamando a mutation de edição
            updateConsultantPassword({
                variables: {
                    id: values.id,
                    password: values.password,
                }
            }).then(() => {
                onEditPassword();
            })

        }
    });

    useEffect(function () {
        if (data) {
            resetForm();
            setFieldValue('id', data.id);
            setFieldValue('password', data.password);
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Editar senha do consultor</DialogTitle>

                <DialogContent style={{padding: 16}}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        required
                        fullWidth
                        id="password"
                        label="Nova senha"
                        name="password"
                        autoFocus
                        error={errors.password && true}
                        helperText={errors.password}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.password == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Alterar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
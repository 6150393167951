import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "@material-ui/core";

export default function DialogAlert({ open, onClose }) {

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">Aviso</DialogTitle>

        <DialogContent>
          <p>Cliente tabulado com sucesso. Você possui OPORTUNIDADES disponíveis. Trate elas, antes de finalizar o atendimento.</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

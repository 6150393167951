import React, { useState } from "react";
import { SketchPicker } from "react-color";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box } from "@mui/system";

export default function ColorPicker({ selectedColor, onSelectColor }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChange = (color) => {
    onSelectColor(color);
  };

  return (
    <Box>
      <Box
        width={["30px", "40px"]}
        height={["30px", "40px"]}
        borderRadius="100%"
        bgcolor={!selectedColor ? "#68bc4f" : selectedColor}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setDisplayColorPicker((prevState) => !prevState)}
      >
        <ModeEditIcon
          sx={{
            color: "#fff",
          }}
        />
      </Box>
      {displayColorPicker && (
        <Box position="fixed" zIndex={1} right="18%" top="62%">
          <SketchPicker
            color={selectedColor}
            onChange={(e) => handleChange(e.hex)}
            disableAlpha
            onSwatchHover={(e) => handleChange(e.hex)}
          />
        </Box>
      )}
    </Box>
  );
}

import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useQuery } from "@apollo/client";
import { COUNT_PRODUCTS } from "../../../graphql/queries";
import MercantilImage from "../../../assets/mercantil.png";
import SantanderImage from "../../../assets/santander_logo.png";
import AgibankImage from "../../../assets/agibank_logo.png";
import TrocaCreditoImage from "../../../assets/trocacredito.png";
import ConsignadoImage from "../../../assets/consig.png";
import FgtsImage from "../../../assets/fgts.png";
import FilterDialog from "./FilterDialog";
import { format } from "date-fns";

export default function AccountProposal({
  handleFilter,
  loadingSubmit,
  teamId,
  startDate,
  finishDate,
  saleConsultantId,
  product_ids,
  company_ids,
}) {
  const [showDialog, setShowDialog] = useState(false);

  const { data, loading } = useQuery(COUNT_PRODUCTS, {
    variables: {
      start: startDate ? format(startDate, "yyyy-MM-dd") : null,
      finish: finishDate ? format(finishDate, "yyyy-MM-dd") : null,
      sale_consultant_id: saleConsultantId ? saleConsultantId : null,
      product_ids: product_ids ? product_ids : null,
      team_id: teamId ? teamId : null,
      company_ids: company_ids ? company_ids : null,
    },
    fetchPolicy: "network-only"
  });

  function getImageUrl(productName) {
    switch (productName) {
      case "Santander":
        return SantanderImage;
      case "Agibank":
        return AgibankImage;
      case "CP Mercantil":
        return MercantilImage;
      case "Troca Crédito":
        return TrocaCreditoImage;
      case "Consignado":
        return ConsignadoImage;
      case "Fgts":
        return FgtsImage;
      default:
        return null;
    }
  }

  return (
    <Grid container spacing={1} style={{ marginTop: 10 }}>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="h1">
          Propostas por produto
        </Typography>
        <Button
          variant="outlined"
          style={{
            borderRadius: 0,
            background: "transparent",
            borderColor: "black",
            padding: "8px 16px",
            display: "flex",
            alignItems: "center",
          }}
          endIcon={<FilterListIcon />}
          onClick={() => setShowDialog(true)}
        >
          Filtros
        </Button>
        {showDialog && (
          <FilterDialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            loading={loadingSubmit}
            onChangeFilter={(filter) => {
              handleFilter(filter);
            }}
            data={{
              teamId,
              saleConsultantId,
              startDate,
              finishDate,
              product_ids,
              company_ids,
            }}
          />
        )}
      </Grid>
      {data?.countProducts.map((item, index) => (
        <Grid item xs={3} key={index}>
          <Card style={{ height: "100%" }}>
            <CardContent
              style={{
                height: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {getImageUrl(item.product.name) ? (
                  <img
                    src={getImageUrl(item.product.name)}
                    alt={item.product.name}
                    width="90px"
                    height={item.product.name === "Fgts" ? "24px" : "auto"}
                  />
                ) : (
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#132994", width: "60%" }}
                  >
                    {item.product.name}
                  </Typography>
                )}
                <Box style={{ width: "40%", textAlign: "end" }}>
                  <Typography variant="h6">{item.total}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

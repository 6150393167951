import { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";

export default function DialogRadioContact({
  open,
  onClose,
  value,
  onChange,
  setFieldValue,
}) {
  const [newValue, setNewValue] = useState(value || null);

  const handleSelect = () => {
    onChange(newValue);
    onClose();
  };

  const handleChange = (event) => {
    setNewValue(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Contato</DialogTitle>
      <DialogContent
        style={{ fontSize: 18, lineHeight: 2, marginTop: 20, marginBottom: 20 }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group"
            name="row-radio-buttons-group"
            value={newValue}
            onChange={handleChange}
          >
            <FormControlLabel
              style={{ marginRight: 50 }}
              value="is_valid"
              control={<Radio />}
              label="Telefone válido"
            />
            <FormControlLabel
              value="whatsapp"
              control={<Radio />}
              label="Telefone válido com WhatsApp"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions style={{ padding: 22 }}>
        <Button
          color="primary"
          onClick={() => {
            setNewValue(null);
            onChange(null);
            onClose();
          }}
        >
          Limpar
        </Button>
        <Button onClick={handleSelect} variant="contained" color="primary">
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import * as Yup from "yup";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import LineAddReferences from "../../../common/ProposalDetailPage/PessoalCard/LineAddReferences";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import SelectWithQuerySearch from "../../../../components/SelectWithQuerySearch";
import { FETCH_SPECIES } from "../../../../graphql/queries";
import { maskMoney, maskPhone } from "../../../../helpers";

export default function Step02({ data, dataPerson, setStep, setFormik }) {
  const [schema, setSchema] = useState(null);
  const [validReference, setValidReference] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);

  useEffect(() => {
    if (data) {
      setFieldValue("name", data.name ? data.name : "");
      setFieldValue("email", data.email ? data.email : "");
      setFieldValue(
        "birthdate",
        data.birthdate ? data.birthdate : { iso8601: new Date() }
      );
      setFieldValue("phone", data.phone ? data.phone : "");
      setFieldValue("phone2", data.phone2 ? data.phone2 : "");
      setFieldValue("cellphone", data.cellphone ? data.cellphone : "");
      setFieldValue("income", data.income ? maskMoney(data.income) : 0);
      setFieldValue(
        "phone_reference",
        data.phone_reference ? data.phone_reference : []
      );
      setSchema(newProposalSchemaCustomer);
    } else {
      setSchema(newProposalSchemaCustomer);
    }
      setFieldValue("benefit_number", data.benefit_number ? data.benefit_number : "");
  }, [data]);

  const { handleSubmit, handleChange, setFieldValue, values, errors, isValid } =
    useFormik({
      initialValues: {
        name: dataPerson?.name || "",
        email: dataPerson?.email || "",
        birthdate: {
          iso8601: new Date(),
        },
        phone: maskPhone(dataPerson?.phones[0]?.number) || "",
        phone2: maskPhone(dataPerson?.phones[1]?.number) || "",
        cellphone: "",
        income: 0,
        phone_reference: [],
        benefit_number: "",
        specie: dataPerson?.specie ? dataPerson.specie : null,
      },
      validationSchema: schema,
      onSubmit: (values) => {
        if (newProposalSchemaCustomer.isValid(values)) {
          setFormik("pessoal_step", {
            ...data,
            name: values.name,
            email: values.email,
            birthdate: values.birthdate,
            phone: values.phone,
            phone2: values.phone2,
            cellphone: values.cellphone,
            income: values.income,
            phone_reference: values.phone_reference,
            benefit_number: values.benefit_number,
            specie_id: values.specie.id,
          });
          setStep(3);
        }
      },
    });

  useEffect(() => {
    let filledReference = [];
    values?.phone_reference.map((el) => {
      filledReference.push(Boolean(el.name && el.phone && el.kinship));
    });

    let issetFalse = filledReference.findIndex((el) => el == false);

    if (issetFalse === -1) {
      setValidReference(false);
    } else {
      setValidReference(true);
    }

    setClickSubmit(false);
  }, [clickSubmit, values.phone_reference]);

  console.log(errors.specie)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: -20 }}>
        <TextField
          variant="outlined"
          margin="normal"
          label="Nome"
          name="name"
          onChange={handleChange}
          value={values.name}
          error={errors.name && true}
          helperText={errors.name}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          label="Email"
          type="email"
          name="email"
          onChange={handleChange}
          value={values.email}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant="inline"
            inputVariant="outlined"
            label="Nascimento"
            format="dd/MM/yyyy"
            value={values.birthdate?.iso8601}
            InputAdornmentProps={{ position: "end" }}
            onChange={(event) => {
              if (event != "Invalid Date") {
                setFieldValue("birthdate.iso8601", event);
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          onChange={handleChange}
          value={maskPhone(values.phone)}
          fullWidth
          label="Celular 01"
          name="phone"
          inputProps={{ maxLength: 15 }}
          required
          error={errors.phone && true}
          helperText={errors.phone}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          onChange={handleChange}
          value={maskPhone(values.phone2)}
          fullWidth
          label="Celular 02"
          name="phone2"
          error={errors.phone2 && true}
          helperText={errors.phone2}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          onChange={handleChange}
          value={values.cellphone}
          fullWidth
          label="Telefone Fixo"
          name="cellphone"
          error={errors.cellphone && true}
          helperText={errors.cellphone}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          value={values.income}
          onChange={(e) => setFieldValue("income", maskMoney(e.target.value))}
          fullWidth
          label="Renda"
          error={errors.income && true}
          helperText={errors.income}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField 
          variant="outlined"
          label="Número do benefício"
          inputProps={{ maxLength: 10 }}
          name="benefit_number"
          onChange={handleChange}
          value={values.benefit_number}
          error={errors.benefit_number && true}
          helperText={errors.benefit_number}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <SelectWithQuerySearch 
          title="Espécie"
          query={FETCH_SPECIES}
          fetchResult="fetchSpecies"
          onChange={(specie) => setFieldValue("specie", specie)}
          fullWidth
          value={values.specie}
          errorLabel={errors.specie}
          error={!!errors.specie}
          required
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Typography variant="h6" style={{ marginBottom: 8, fontSize: 19 }}>
          Referências
        </Typography>
        <LineAddReferences
          {...{ values, setFieldValue, validReference }}
          error={errors.phone_reference}
        />
      </Grid>

      <Button
        variant="contained"
        color="primary"
        disabled={validReference || !isValid ? true : false}
        onClick={() => {
          handleSubmit();
          setClickSubmit(true);
        }}
      >
        Próximo
      </Button>
      <Button color="primary" onClick={() => setStep(1)}>
        Voltar
      </Button>
    </Grid>
  );
}

const newProposalSchemaCustomer = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Email inválido"),
  birthdate: Yup.object({ iso8601: Yup.date() }).required("Campo obrigatório"),
  phone: Yup.string().required("Campo obrigatório"),
  income: Yup.string().required("Campo obrigatório"),
  phone_reference: Yup.array().min(1, "Favor informe no mínimo uma referência"),
  benefit_number: Yup.string().required("Campo obrigatório"),
  specie: Yup.object({
    id: Yup.number().typeError("Selecione a espécie"),
  }).nullable().required("Campo obrigatório"),
});
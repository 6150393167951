import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useState } from "react";
import InputWithQuerySearch from "../../../components/InputWithQuerySearch";
import SelectProductId from "../../../components/SelectProductId";
import SelectSaleConsultant from "../../../components/SelectSaleConsultant";
import SelectWithQuerySearch from "../../../components/SelectWithQuerySearch";
import {
  FETCH_PROPOSAL_SITUATIONS,
  FETCH_COMPANIES,
  FETCH_TEAMS,
  FETCH_MANAGERS,
  FETCH_SUPERVISORS,
  FETCH_SPECIES,
} from "../../../graphql/queries";
import SelectDynamicForm from "../../../components/SelectDynamicForm";
import SelectSaleOrigins from "../../../components/SelectSaleOrigins";

export default function FilterDialog({
  open,
  handleClose,
  isAdmin,
  refetch,
  onChangeFilter,
  loading,
  filter,
}) {  
  const [startDate, setStartDate] = useState(filter.start ? filter.start : null);
  const [finishDate, setFinishDate] = useState(filter.finish ? filter.finish : null);
  const [companyId, setCompanyId] = useState(filter.company_id ? filter.company_id : null);
  const [teamId, setTeamId] = useState(filter.team_id ? filter.team_id : null);
  const [managerId, setManagerId] = useState(filter.manager_id ? filter.manager_id : null);
  const [supervisorId, setSupervisorId] = useState(filter.supervisor_id ? filter.supervisor_id : null);
  const [status, setStatus] = useState(filter.status ? filter.status : "Todas");
  const [saleConsultantId, setSaleConsultantId] = useState(filter.sale_consultant_id ? filter.sale_consultant_id : null);
  const [productId, setProductId] = useState(filter.productId ? filter.productId : null);
  const [proposalSituationId, setProposalSituationId] = useState(filter.proposal_situation_id ? filter.proposal_situation_id : null);
  const [dynamicField, setDynamicField] = useState(
    filter.inputs && filter.inputs.length > 0
      ? filter.inputs.map((input) => ({
          name: input.name,
          value: input.value,
          label: input.label,
        }))
      : []
  );
  const [installmentsNumber, setInstallmentsNumber] = useState(filter.installments_number ? filter.installments_number : null);
  const [saleOrigin, setSaleOrigin] = useState(filter.sale_origin ? filter.sale_origin : null);
  const [specie, setSpecie] = useState(filter.specie_id ? filter.specie_id : null);
  
  const handleDeleteDynamicField = (itemToDelete) => {
    setDynamicField((prevValues) => prevValues.filter(item => item.value !== itemToDelete.value));
  };

  const handleClickFilter = () => {
    let { format } = require("date-fns");

    const inputs = dynamicField.map(field => ({
      name: field.name,
      value: field.value,
      label: field.label
    }));

    var obj = {
      status: status === "Todas" ? null : status,
      start: startDate && format(startDate, "yyyy-MM-dd"),
      finish: finishDate && format(finishDate, "yyyy-MM-dd"),
      company_id: companyId?.id,
      team_id: teamId?.id,
      manager_id: managerId?.id,
      supervisor_id: supervisorId?.id,
      productId: productId,
      sale_consultant_id: saleConsultantId,
      proposal_situation_id:
        proposalSituationId && proposalSituationId.map((i) => i.id),
      inputs: inputs,
      installments_number: installmentsNumber,
      sale_origin: saleOrigin === null ? null : String(saleOrigin),
      specie_id: specie?.id,
    };

    refetch(obj);

    onChangeFilter(obj);
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setFinishDate(null);
    setStatus("Todas");
    setCompanyId(null);
    setTeamId(null);
    setManagerId(null);
    setSupervisorId(null);
    setSaleConsultantId(null);
    setProductId(null);
    setProposalSituationId(null);
    setInstallmentsNumber(null);
    setSaleOrigin(null);
    setSpecie(null);
    setDynamicField([]);

    var obj = {
      status: null,
      start: null,
      finish: null,
      company_id: null,
      team_id: null,
      manager_id: null,
      supervisor_id: null,
      productId: null,
      sale_consultant_id: null,
      proposal_situation_id: null,
      installments_number: null,
      sale_origin: null,
      inputs: [],
    };

    refetch(obj);

    onChangeFilter(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Filtrar Propostas</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label="Início"
                format="dd/MM/yyyy"
                value={startDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setStartDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label="Fim"
                format="dd/MM/yyyy"
                value={finishDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setFinishDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {isAdmin && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectWithQuerySearch
                  title="Empresa"
                  query={FETCH_COMPANIES}
                  fetchResult="fetchCompanies"
                  onChange={(companies) => {
                    setCompanyId(companies);
                  }}
                  fullWidth
                  value={companyId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectWithQuerySearch
                  title="Equipe"
                  query={FETCH_TEAMS}
                  fetchResult="fetchTeams"
                  onChange={(teams) => {
                    setTeamId(teams);
                  }}
                  fullWidth
                  value={teamId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectWithQuerySearch
                  title="Gerente"
                  query={FETCH_MANAGERS}
                  fetchResult="fetchManagers"
                  onChange={(manager) => {
                    setManagerId(manager);
                  }}
                  fullWidth
                  value={managerId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectWithQuerySearch
                  title="Líder"
                  query={FETCH_SUPERVISORS}
                  fetchResult="fetchSupervisors"
                  onChange={(supervisor) => {
                    setSupervisorId(supervisor);
                  }}
                  fullWidth
                  value={supervisorId}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectSaleConsultant
                  value={saleConsultantId}
                  onChange={(id) => setSaleConsultantId(id)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="Todas">Todas</MenuItem>
              <MenuItem value="Aberta">Aberta</MenuItem>
              <MenuItem value="Fechada">Fechada</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputWithQuerySearch
              styleInput="outlined"
              title="Situação"
              query={FETCH_PROPOSAL_SITUATIONS}
              fetchResult="fetchProposalSituations"
              value={proposalSituationId}
              onChange={(situations) => {
                if (situations.length == 0) {
                  setProposalSituationId(null);
                } else {
                  setProposalSituationId(situations);
                }
              }}
            />
          </Grid>



          <Grid item xs={12} sm={6}>
            <TextField
              label="Quantidade de parcelas"
              variant="outlined"
              fullWidth
              type="number"
              value={installmentsNumber}
              onChange={(e) => setInstallmentsNumber(e.target.value ? Number(e.target.value) : null)}
            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <SelectSaleOrigins 
              title="Origem de venda"
              value={saleOrigin}
              onChange={setSaleOrigin}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectProductId
              value={productId}
              onChange={(id) => setProductId(id)}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWithQuerySearch
              title="Espécie de benefícios"
              query={FETCH_SPECIES}
              fetchResult="fetchSpecies"
              onChange={(species) => {
                setSpecie(species);
              }}
              fullWidth
              value={specie}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectDynamicForm
              fullWidth={true}
              title={"Selecione o campo"}
              onChange={setDynamicField}
              onDelete={handleDeleteDynamicField}
              dynamicField={dynamicField}
            />
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClickFilter();
            handleClose();
          }}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Filtrar
        </Button>

        <Button
          onClick={() => {
            handleClearFilter();
            handleClose();
          }}
          color="primary"
        >
          Limpar filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}
import { Box, Grid, Link, Paper, Typography } from "@material-ui/core";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { maskCPF } from "../../../../helpers";

export default function Items({
    code,
    name,
    cpf,
    createdAt,
    handleClick,
    isDone
}) {

    return (
        <Paper
            elevation={1}
            style={{
                padding: 8,
                marginTop: 8,
                marginBottom: 8,
                border: "0.5px solid #e3e3e3",
                backgroundColor: isDone ? "rgba(50, 168, 82, 0.15)" : "white"
            }}
        >
            <Box>
                <Link style={{cursor: "pointer"}} onClick={!isDone ? handleClick : () => {}}>#{code}</Link>

                <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="caption"><b>Nome:</b> {name}</Typography>
                        <br />
                        <Typography variant="caption"><b>CPF:</b> {maskCPF(cpf)}</Typography>
                        <br />
                        <Typography variant="caption"><b>Data Criação:</b> {createdAt}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}


import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from "formik";
import SelectWithQuerySearch from "../SelectWithQuerySearch";
import { UPDATE_LEAD} from "../../graphql/mutations";
import { FETCH_LOCATIONS } from "../../graphql/queries";


export default function EditDialogClientData({
  open,
  handleClose,
  data,
  onEdit,
}) {
  const { format } = require("date-fns");

  const [updateLead, { loading }] = useMutation(UPDATE_LEAD);

  // Setting Formik
  const { handleSubmit, handleChange, setFieldValue, values, errors, isValid } =
    useFormik({
      initialValues: {
        ...data,
      },
      onSubmit: (values) => {
        updateLead({
          variables: {
            id: data.id,
            document: values.document,
            birthdate:
              values.birthdate?.iso8601 &&
              format(new Date(values.birthdate.iso8601), "yyyy-MM-dd"),
            zipcode: values.zipcode,
            address: values.address,
            district: values.district,
            location_id: values.location && values.location.id,
          },
        }).then(onEdit);
      },
    });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Editar dados do cliente</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.document}
              required
              fullWidth
              label="CPF"
              name="document"
              error={errors.document}
              helperText={errors.document}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Nascimento"
                format="dd/MM/yyyy"
                value={values.birthdate?.iso8601}
                InputAdornmentProps={{ position: "end" }}
                onChange={(event) => {
                  if (event != "Invalid Date") {
                    setFieldValue("birthdate.iso8601", event);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.zipcode}
              fullWidth
              label="CEP"
              name="zipcode"
              error={errors.zipcode}
              helperText={errors.zipcode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.address}
              fullWidth
              label="Endereço"
              name="address"
              error={errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              onChange={handleChange}
              value={values.district}
              fullWidth
              label="Bairro"
              name="district"
              error={errors.district}
              helperText={errors.district}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectWithQuerySearch
              title="Cidade"
              query={FETCH_LOCATIONS}
              fetchResult="fetchLocations"
              variables={{ only_cities: true }}
              onChange={(bank) => {
                setFieldValue("location", bank);
              }}
              fullWidth
              value={values.location}
              renderLabel={(i) => i.name + "/" + i.state}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
          color="primary"
          disabled={loading || !isValid ? true : false}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useQuery } from '@apollo/client';
/* import PieChart, {
    Legend,
    Series,
    Tooltip,
    Format,
    Export
} from 'devextreme-react/pie-chart';
import { STATISTICS_BY_TYPE_RELEASES } from '../../../../graphql/queries';*/
import { Box } from '@mui/material';
import { CircularProgress } from '@material-ui/core';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { maskMoney } from '../../../../helpers';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function TypesGraphic({
    dataStatistics,
    loading
}) {

    const data = {
        labels: dataStatistics.map((item) => item.caption),
        datasets: [
            {
                label: "Valor(R$)",
                data: dataStatistics.map((item) => item.value),
                backgroundColor: dataStatistics.map((item) => !item.color ? "#ccc" : item.color)
            }
        ]

    }

    if (loading) return (
        <Box
            marginY={12}
            display="flex"
            justifyContent="center"
        >
            <CircularProgress size={40} />
        </Box>
    )

    return (
        <Doughnut
            data={data}
            options={{
                plugins: {
                    legend: {
                        maxWidth: 50,
                        position: "bottom",
                        align: "start",
                    }
                },
                layout: {
                    padding: 23
                }
            }}
        />
    )
}

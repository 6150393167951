import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import {
  FETCH_BANKS,
  FETCH_ENTITIES,
  FETCH_LOCATIONS,
  FETCH_SPECIES,
  FETCH_TARGET_AUDIENCE_CSV,
} from "../../../../../graphql/queries";
import CountLeads from "./CountLeads";
import InputGender from "../../../../../components/InputGender";
import InputRangeInterval from "../../../../../components/InputRangeInterval";
import InputWithQuerySearch from "../../../../../components/InputWithQuerySearch";
import InputWithQuerySearchDelete from "../../../../../components/InputWithQuerySearchDelete";
import TargetAudienceSavedDialog from "./TargetAudienceSavedDialog";
import DialogRadioContact from "./DialogRadioContact";
import { separateOperations } from "graphql";
import CreateIcon from "@material-ui/icons/Create";

export default function Step03({
  onBack,
  handleSubmit,
  values,
  setFieldValue,
  loading,
}) {
  const [publicType, setPublicType] = useState(0);
  const [showDialogSelectPublic, setShowDialogSelectPublic] = useState(false);
  const [open, setOpen] = useState(false);
  const [contactValue, setContactValue] = useState("Todos");

  const handleOnSelectTargetAudience = (i) => {
    var json = JSON.parse(i.filters);

    setFieldValue("filters", {
      age_group: json.age_group || null,
      gender: json.gender || null,
      locations: json.location_id || null,
      count_loan_range: json.count_loan_range || {},

      benefit_salary_range: json.benefit?.salary_range || {},
      benefit_margin_loan_range: json.benefit?.margin_loan_range || {},
      benefit_banks: json.benefit?.bank_id || null,
      benefit_banks_ignore: json.benefit?.bank_ignore_id || null,
      benefit_start_range: json.benefit?.start_range || {},
      benefit_entities: json.benefit?.entity_id || null,
      benefit_species: json.benefit?.specie_id || null,
      benefit_species_ignore: json.benefit?.specie_ignore_id || null,

      loan_banks: json.loan?.bank_id || null,
      loan_amount_range: json.loan?.amount_range || {},
      loan_portion_amount_range: json.loan?.portion_amount_range || {},
      loan_debit_balance_range: json.loan?.debit_balance_range || {},
      loan_tax_range: json.loan?.tax_range || {},
      loan_portion_count_range: json.loan?.portion_count_range || {},
      loan_portion_paid_count_range: json.loan?.portion_paid_count_range || {},

      debit_account_banks: json.debit_account?.bank_id || null,
      debit_account_portion_amount_range:
        json.debit_account?.portion_amount_range || {},
      debit_account_portion_count_range:
        json.debit_account?.portion_count_range || {},
      debit_account_portion_paid_count_range:
        json.debit_account?.portion_paid_count_range || {},

      target_audience: json.target_audience_id || null,

      contact: json.contact
        ? {
            [json.contact]: true,
          }
        : null,
    });

    setShowDialogSelectPublic(false);
  };

  const contactMap = {
    is_valid: "Telefone válido",
    whatsapp: "Telefone válido com WhatsApp",
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue={0}
            value={publicType}
            onChange={(e) => {
              setPublicType(parseInt(e.target.value));
              setShowDialogSelectPublic(parseInt(e.target.value));
            }}
          >
            <FormControlLabel
              value={0}
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFieldValue("filters", {});
                  }}
                />
              }
              label="Novo público"
            />
            <FormControlLabel
              value={1}
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setShowDialogSelectPublic(true);
                  }}
                />
              }
              label="Utilizar público salvo"
            />

            <TargetAudienceSavedDialog
              open={showDialogSelectPublic}
              onClose={() => setShowDialogSelectPublic(false)}
              onSelect={handleOnSelectTargetAudience}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountLeads
            filters={values.filters}
            lead_file_ids={values.lead_file_selected}
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: 12 }}
      >
        Dados demográficos
      </Typography>

      <Grid container spacing={3}>
        <Grid item>
          <InputRangeInterval
            title="Faixa Etária"
            labelBlank="Todas"
            value={values.filters.age_group}
            onChange={(value) => setFieldValue("filters.age_group", value)}
          />
        </Grid>
        <Grid item>
          <InputGender
            labelBlank="Todas"
            value={values.filters.gender}
            onChange={(value) => setFieldValue("filters.gender", value)}
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Localização"
            labelBlank="Todas"
            query={FETCH_LOCATIONS}
            fetchResult="fetchLocations"
            renderLabel={(i) =>
              i.is_city ? i.name + "/" + i.state : i.name + " (estado)"
            }
            value={values.filters.locations}
            onChange={(value) => setFieldValue("filters.locations", value)}
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: 24 }}
      >
        Dados financeiros dos benefícios
      </Typography>

      <Grid container spacing={3}>
        <Grid item>
          <InputRangeInterval
            title="Salario (R$)"
            labelBlank="Todos"
            value={values.filters.benefit_salary_range}
            onChange={(value) =>
              setFieldValue("filters.benefit_salary_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Margem (R$)"
            labelBlank="Todas"
            value={values.filters.benefit_margin_loan_range}
            onChange={(value) =>
              setFieldValue("filters.benefit_margin_loan_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Banco de pagamento"
            type="bank"
            labelBlank="Todos"
            query={FETCH_BANKS}
            fetchResult="fetchBanks"
            value={values.filters.benefit_banks}
            onChange={(value) => setFieldValue("filters.benefit_banks", value)}
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Desconsiderar bancos"
            type="bank"
            labelBlank="Nenhum"
            query={FETCH_BANKS}
            fetchResult="fetchBanks"
            value={values.filters.benefit_banks_ignore}
            onChange={(value) =>
              setFieldValue("filters.benefit_banks_ignore", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="DIB"
            type="date"
            labelBlank="Todas"
            value={values.filters.benefit_start_range}
            onChange={(value) =>
              setFieldValue("filters.benefit_start_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Entidade"
            labelBlank="Todas"
            query={FETCH_ENTITIES}
            fetchResult="fetchEntities"
            value={values.filters.benefit_entities}
            onChange={(value) =>
              setFieldValue("filters.benefit_entities", value)
            }
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Espécie"
            labelBlank="Todas"
            query={FETCH_SPECIES}
            fetchResult="fetchSpecies"
            value={values.filters.benefit_species}
            onChange={(value) =>
              setFieldValue("filters.benefit_species", value)
            }
          />
        </Grid>
        <Grid item>
          <InputWithQuerySearch
            title="Desconsiderar espécies"
            labelBlank="Todas"
            query={FETCH_SPECIES}
            fetchResult="fetchSpecies"
            value={values.filters.benefit_species_ignore}
            onChange={(value) =>
              setFieldValue("filters.benefit_species_ignore", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Qtd. emprestimos"
            labelBlank="Todas"
            value={values.filters.count_loan_range}
            onChange={(value) =>
              setFieldValue("filters.count_loan_range", value)
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: 24 }}
      >
        Dados de empréstimos consignados
      </Typography>

      <Grid container spacing={3}>
        <Grid item>
          <InputWithQuerySearch
            title="Banco"
            type="bank"
            labelBlank="Todos"
            query={FETCH_BANKS}
            fetchResult="fetchBanks"
            value={values.filters.loan_banks}
            onChange={(value) => setFieldValue("filters.loan_banks", value)}
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Valor do emprestimo (R$)"
            labelBlank="Todos"
            value={values.filters.loan_amount_range}
            onChange={(value) =>
              setFieldValue("filters.loan_amount_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Valor da parcela (R$)"
            labelBlank="Todos"
            value={values.filters.loan_portion_amount_range}
            onChange={(value) =>
              setFieldValue("filters.loan_portion_amount_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Saldo devedor (R$)"
            labelBlank="Todos"
            value={values.filters.loan_debit_balance_range}
            onChange={(value) =>
              setFieldValue("filters.loan_debit_balance_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Taxa (%)"
            labelBlank="Todas"
            value={values.filters.loan_tax_range}
            onChange={(value) => setFieldValue("filters.loan_tax_range", value)}
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Qtd. Parcelas"
            labelBlank="Todas"
            value={values.filters.loan_portion_count_range}
            onChange={(value) =>
              setFieldValue("filters.loan_portion_count_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Parcelas pagas"
            labelBlank="Todas"
            value={values.filters.loan_portion_paid_count_range}
            onChange={(value) =>
              setFieldValue("filters.loan_portion_paid_count_range", value)
            }
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: 24 }}
      >
        Dados de débitos em conta
      </Typography>

      <Grid container spacing={3}>
        <Grid item>
          <InputWithQuerySearch
            title="Banco"
            type="bank"
            labelBlank="Todos"
            query={FETCH_BANKS}
            fetchResult="fetchBanks"
            value={values.filters.debit_account_banks}
            onChange={(value) =>
              setFieldValue("filters.debit_account_banks", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Valor da parcela (R$)"
            labelBlank="Todos"
            value={values.filters.debit_account_portion_amount_range}
            onChange={(value) =>
              setFieldValue("filters.debit_account_portion_amount_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Qtd. Parcelas"
            labelBlank="Todas"
            value={values.filters.debit_account_portion_count_range}
            onChange={(value) =>
              setFieldValue("filters.debit_account_portion_count_range", value)
            }
          />
        </Grid>
        <Grid item>
          <InputRangeInterval
            title="Parcelas pagas"
            labelBlank="Todas"
            value={values.filters.debit_account_portion_paid_count_range}
            onChange={(value) =>
              setFieldValue(
                "filters.debit_account_portion_paid_count_range",
                value
              )
            }
          />
        </Grid>
      </Grid>

      <Grid>
        <h6
          style={{
            fontWeight: "bold",
            marginTop: 24,
            fontSize: 16,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            marginBottom: 0,
          }}
        >
          Dados de contato
        </h6>
      </Grid>

      <Grid container spacing={3}>
        <h4
          style={{
            fontWeight: 500,
            marginTop: 20,
            marginLeft: 12,
            fontSize: 14,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            marginBottom: 0,
          }}
        >
          Contato
        </h4>
      </Grid>

      <Grid container spacing={3}>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <p
            style={{
              fontSize: 13,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              marginBottom: 0,
              marginTop: 7,
            }}
          >
            {contactValue === "Todos" ? "Todos" : contactMap[contactValue]}
          </p>
          <CreateIcon
            onClick={() => setOpen(true)}
            style={{
              fontSize: "15px",
              paddingLeft: "8px",
              position: "relative",
              top: "2px",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", marginTop: 24 }}
      >
        Excluir Público
      </Typography>

      <Grid container spacing={3}>
        <Grid item>
          <InputWithQuerySearchDelete
            title="Lista .csv"
            labelBlank="Nenhum"
            query={FETCH_TARGET_AUDIENCE_CSV}
            fetchResult="fetchTargetAudienceCsv"
            value={values.filters.target_audience}
            onChange={(value) =>
              setFieldValue("filters.target_audience", value)
            }
          />
        </Grid>
      </Grid>

      <DialogRadioContact
        open={open}
        onClose={() => setOpen(false)}
        value={values.filters.contact}
        onChange={(value) => {
          setContactValue(value);
          setFieldValue("filters.contact", value);
        }}
      />

      <div style={{ marginTop: 24 }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={values.count_leads == 0 || loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Criar campanha
        </Button>
        <Button
          onClick={onBack}
          color="primary"
          style={{ marginLeft: 12 }}
          variant="text"
        >
          Voltar
        </Button>
      </div>
    </>
  );
}

import { useState } from "react";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import ClientData from "./ClientData";
import BenefitData from "./BenefitData";
import TagHistory from "./TagHistory";
import InteractionsHistory from "./InteractionsHistory";
import DialogReschedule from "./DialogReschedule";
import DialogNoInterest from "./DialogNoInterest";
import DialogOnTag from "./DialogOnTag";
import DialogNoProfile from "./DialogNoProfile";
import ResumeInfo from "./ResumeInfo";
import LinkedLoans from "./LinkedLoans";
import DialogSimulator from "./DialogSimulator";
import DialogInvalidContact from "./DialogInvalidContact";
import DialogInNegotiation from "./DialogInNegotiation";
import DialogOpenProposal from "../../pages/sales-consultant/DashboardPage/DialogOpenProposal";
import useToken from "../../tokenJWT";
import DialogDebtors from "./DialogDebtors";
import InfoLead from "./InfoLead";
import InfoPhone from "./InfoPhone";
import ServicePanel from "./ServicePanel";
import ProductTagsCard from "./ProductTagsCard";

export default function LeadDetail({
  data,
  tab,
  onRescheduled,
  onUninterested,
  onUnprofile,
  onEditPhone,
  onEditDebitAccount,
  onCreatePhone,
  onInvalidatedContact,
  onInNegotiation,
  onIndication,
  onProposal,
  onCallEnd,
  onTag,
  onDebit,
  refetch,
  campaign_id,
}) {
  
  const [showSimulator, setShowSimulator] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showNoInterest, setShowNoInterest] = useState(false);
  const [showNoProfile, setShowNoProfile] = useState(false);
  const [showOnTag, setShowOnTag] = useState(false);
  const [showInvalidContact, setShowInvalidContact] = useState(false);
  const [showInNegotiation, setShowInNegotiation] = useState(false);
  const [openProposal, setOpenProposal] = useState(false);
  const [showDebtors, setShowDebtors] = useState(false);

  const { user } = useToken();

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={4} spacing={2}>
          <InfoLead
            {...{ campaign_id }}
            disabled={true}
            data={data}
            name={data.name}
            onUninterested={onUninterested}
            onUnprofile={onUnprofile}
            onInNegotiation={onInNegotiation}
            onIndication={onIndication}
            onProposal={onProposal}
            onCallEnd={onCallEnd}
            location={data.location}
            gender={data.gender}
            birthdate={data.birthdate}
            netIncome={data.benefits[tab]?.net_income || {}}
          />

          <InfoPhone
            {...{ refetch, onEditDebitAccount }}
            debitAccounts={data.debit_accounts}
            data={data}
            tab={tab}
            onEditPhone={onEditPhone}
            onCreatePhone={onCreatePhone}
          />
          
          <ResumeInfo
            {...{ campaign_id }}
            disabled={true}
            data={data}
            onUninterested={onUninterested}
            onUnprofile={onUnprofile}
            onInNegotiation={onInNegotiation}
            onIndication={onIndication}
            onProposal={onProposal}
            onCallEnd={onCallEnd}
          />

        </Grid>
        <Grid item xs={12} md={5} spacing={2}>
          <ServicePanel
            {...{ campaign_id }}
            data={data}
            user={user}
            setShowSimulator={setShowSimulator}
            setShowReschedule={setShowReschedule}
            setShowNoInterest={setShowNoInterest}
            setShowNoProfile={setShowNoProfile}
            setShowInvalidContact={setShowInvalidContact}
            setShowInNegotiation={setShowInNegotiation}
            setOpenProposal={setOpenProposal}
            setShowOnTag={setShowOnTag}
            setShowDebtors={setShowDebtors}
            showInNegotiation={showInNegotiation}
            onCallEnd={onCallEnd}
          />
          <ClientData
            {...{ refetch, onEditDebitAccount }}
            debitAccounts={data.debit_accounts}
            data={data}
            tab={tab}
            onEditPhone={onEditPhone}
            onCreatePhone={onCreatePhone}
          />
          <BenefitData
            data={data.benefits[tab] || null}
            id={data.id}
            refetch={refetch}
          />
          <LinkedLoans data={data.benefits[tab]?.loans || []} />
        </Grid>
        <Grid item xs={12} md={3} spacing={2}>
          <ProductTagsCard {...{ refetch }} data={data.tags} leadId={data.id} />

          <TagHistory data={data.tags} />

          <InteractionsHistory data={data.interactions} />
        </Grid>
      </Grid>
      <DialogSimulator
        open={showSimulator}
        refetch={refetch}
        handleClose={() => setShowSimulator(false)}
        netIncome={data.benefits[tab]?.net_income || {}}
        lead_benefit_id={data.benefits[tab]?.id}
      />

      <DialogReschedule
        lead_id={data.id}
        campaign_id={campaign_id}
        open={showReschedule}
        handleClose={() => setShowReschedule(false)}
        onRescheduled={() => {
          setShowReschedule(false);
          onRescheduled();
        }}
      />

      <DialogNoInterest
        lead_id={data.id}
        campaign_id={campaign_id}
        open={showNoInterest}
        handleClose={() => {
          setShowNoInterest(false);
        }}
        onUninterested={() => {
          setShowNoInterest(false);
          onUninterested();
        }}
      />

      <DialogNoProfile
        lead_id={data.id}
        campaign_id={campaign_id}
        open={showNoProfile}
        handleClose={() => {
          setShowNoProfile(false);
        }}
        onUnprofile={() => {
          setShowNoProfile(false);
          onUnprofile();
        }}
      />

      <DialogInvalidContact
        lead_id={data.id}
        campaign_id={campaign_id}
        open={showInvalidContact}
        handleClose={() => {
          setShowInvalidContact(false);
        }}
        onCreate={() => {
          setShowInvalidContact(false);
          onInvalidatedContact();
        }}
      />

      <DialogInNegotiation
        lead_id={data.id}
        open={showInNegotiation}
        handleClose={() => {
          setShowInNegotiation(false);
        }}
        onCreate={() => {
          setShowInNegotiation(false);
          onInNegotiation();
        }}
      />

      <DialogOpenProposal
        dataPerson={data}
        campaign_id={campaign_id}
        open={openProposal}
        handleClose={() => {
          setOpenProposal(false);
        }}
        onProposal={() => {
          onProposal();
        }}
      />

      <DialogOnTag
        lead_id={data.id}
        dataLead={data}
        campaign_id={campaign_id}
        open={showOnTag}
        onClose={() => {
          setShowOnTag(false);
        }}
        onTag={() => {
          onTag();
        }}
      />

      <DialogDebtors
        lead_id={data.id}
        campaign_id={campaign_id}
        open={showDebtors}
        handleClose={() => setShowDebtors(false)}
        onDebit={() => {
          setShowDebtors(false);
          onDebit();
        }}
      />
    </>
  );
}


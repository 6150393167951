import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";
import CustomerPhones from "./CustomerPhones";
import TextCopy from "./TextCopy";
import EditIcon from '@material-ui/icons/Edit';
import { useQuery } from "@apollo/client";
import { DETAIL_CUSTOMER } from "../graphql/queries";
import { cpfMask } from "../masks";

export default function CustomerCard({ document }){
    
    const { data, loading } = useQuery(DETAIL_CUSTOMER, {
        variables: {
            document: document
        }
    })

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h1">Dados do cliente</Typography>

                { loading && <CircularProgress style={{ marginTop: 12 }} /> }

                {
                    data &&
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={8} style={{ marginTop: 8 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={5}>
                                        <TextCopy title="CPF" value={cpfMask(data.detailCustomer.document)} />
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextCopy title="Nome" value={data.detailCustomer.name} />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextCopy title="Nascimento" value={data.detailCustomer.birthdate} />
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextCopy title="Nome da mãe" value={data.detailCustomer.mothers_name} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextCopy title="Endereço" value={data.detailCustomer.address + ', ' + data.detailCustomer.number + ' ' + (data.detailCustomer.complement ?? '') + ' - ' + data.detailCustomer.district  + ' - ' + data.detailCustomer.city + '/' + data.detailCustomer.state + ' CEP: ' + data.detailCustomer.zipcode} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ marginTop: 8 }}>
                                <CustomerPhones values={data.detailCustomer.phones} />
                            </Grid>
                        </Grid>

                        <div style={{ textAlign: 'center', marginTop: 12 }}>
                            <Button color="primary" startIcon={<EditIcon />}>
                                Editar dados
                            </Button>
                        </div>
                    </>
                }
            </CardContent>
        </Card>
    )

}
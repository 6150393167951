import {
  Card,
  Typography,
  makeStyles,
  CardContent,
  Chip,
  Tooltip,
} from "@material-ui/core";
import "../../assets/styleSheet/ScrollBar.css";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";

export default function TagHistory({ data }) {
  return (
    <Card style={{ marginTop: 16, padding: 16 }}>
      <Typography
        variant="subtitle1"
        style={{ marginBottom: 12, textAlign: "center" }}
      >
        <b>TAGS</b>
      </Typography>

      {data.length === 0 && (
        <CardContent>
          <Typography>Nenhuma Tag encontrada</Typography>
        </CardContent>
      )}
      {data.map((el) => {
        return (
          <>
            <CardContent
              key={el.id}
              style={{
                display: "inline-block",
                maxWidth: "100%",
                whiteSpace: "nowrap",
                padding: "5px 0px 16px 16px",
              }}
            >
              {el.type === "Atendimento" ? (
                <Tooltip title={el.name} placement="top">
                  <Chip
                    label={el.name}
                    variant="outlined"
                    style={{
                      marginRight: "10px",
                      color: el.color,
                      borderColor: el.color,
                      padding: "5px",
                      width: "150px",
                    }}
                    icon={
                      <BookmarkBorderIcon
                        style={{ fontSize: 15, color: el.color }}
                      />
                    }
                  />
                </Tooltip>
              ) : el.type === "Produto" ? (
                <Tooltip title={`[${el.eligible ? "Elegível" : "Não elegível"}] ` + el.name} placement="top">
                  <Chip
                    label={`[${el.eligible ? "Elegível" : "Não elegível"}] ` + el.name}
                    variant="outlined"
                    style={{
                      backgroundColor: el.eligible ? el.color : "#ff0000bf",
                      marginRight: "10px",
                      color: "#FFF",
                      padding: "5px",
                      maxWidth: "200px",
                    }}
                    icon={
                      el.required ? (
                        <SdCardAlertIcon
                          style={{ fontSize: 15, color: "#FFF" }}
                        />
                      ) : (
                        <BookmarkBorderIcon
                          style={{ fontSize: 15, color: "#FFF" }}
                        />
                      )
                    }
                  />
                </Tooltip>
              ) : null}
            </CardContent>
          </>
        );
      })}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  red: {
    color: "#C00",
    borderColor: "#C00",
  },
}));
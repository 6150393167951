import { useCallback, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Button } from "@material-ui/core";
import { formatDateBRL, formatMoneyBRL, maskMoney } from '../../../../helpers';
import TextCopy from "../../../../components/TextCopy";
import EditIcon from '@material-ui/icons/Edit';
import EditDialog from "./EditDialog";
import { string } from "yup";
import { format } from "date-fns";

export default function ProposalCard({ data, style, onEdit }) {

    const [open, setOpen] = useState(false);

    const dynamicFields = JSON.parse(data.output)

    return (

        <Card style={style}>
            <CardHeader style={{ backgroundColor: "#c3c9e5" }} title="Dados da proposta" />
            <CardContent>

                <Grid container spacing={1}>
                    <Grid item xs={6} sm={3}>
                        <TextCopy title="Código" value={data.code} />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextCopy copy={false} title="Data de criação" value={data.date.formated} />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextCopy copy={false} title="Status" value={data.status} />
                    </Grid>

                    {
                        dynamicFields && dynamicFields.map((item, index) => {

                            let itemValue = "";

                            if (item.type === "date" && item.value) {
                                const parsedDate = new Date(item.value);

                                if (!isNaN(parsedDate.getTime())) {
                                    itemValue = format(parsedDate, 'dd/MM/yyyy');
                                } else {
                                    console.error('Invalid date format:', item.value);
                                }
                            }
                            if (item.type === "decimal") itemValue = "R$ " + item.value;
                            if (item.type === "checkbox") itemValue = item.value.map(option => option ? `${option} | ` : "");
                            if (
                                item.type === "text" ||
                                item.type === "textarea" ||
                                item.type === "radioButton" ||
                                item.type === "email" ||
                                item.type === "cep" ||
                                item.type === "number" ||
                                item.type === "combo" ||
                                item.type === "cpf"
                            ) itemValue = item.value;

                            return (
                                <Grid key={index} item xs={6} sm={3}>
                                    <TextCopy copy={false} title={item.label || "-"} value={itemValue || "-"} />
                                </Grid>
                            )
                        })
                    }

                </Grid>

            </CardContent>
        </Card>

    )

}

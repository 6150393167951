import { useState } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_RELEASE_CATEGORIES } from "../../../../graphql/queries";
import {
    Container,
    Card,
    Typography,
    CardContent,
    Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import List from "./List";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";
import DialogDelete from "./List/DialogDelete";

export default function ReleaseCategories({ history }) {

    const { enqueueSnackbar } = useSnackbar();

    const [showCreate, setShowCreate] = useState(false);
    const [dataEdit, setDataEdit] = useState();
    const [openDelete, setOpenDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const { loading, error, data, networkStatus, refetch } = useQuery(
        FETCH_RELEASE_CATEGORIES,
        {
            variables: {
                limit: rowsPerPage,
                page: currentPage + 1,
                order_by: "title",
                direction: "asc"
            },
            fetchPolicy: "network-only"
        }
    );

    return (
        <>
            <Header
                onBack={() => {
                    history.goBack();
                }}
            />

            <Container maxWidth="sm" style={{ marginTop: 86 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h1">
                            Categorias de lançamento
                        </Typography>
                    </CardContent>

                    <List
                        onDelete={() => {
                            enqueueSnackbar("Categoria excluída com sucesso!");
                            refetch();
                        }}
                        onEdit={(item) => setDataEdit(item)}
                        {...{
                            refetch,
                            loading,
                            error,
                            data,
                            networkStatus,
                            currentPage,
                            setCurrentPage,
                            rowsPerPage,
                            setRowsPerPage,
                            setOpenDelete
                        }}
                    />
                </Card>
            </Container>

            {
                showCreate &&
                <CreateDialog
                    open={showCreate}
                    handleClose={() => setShowCreate(false)}
                    onCreate={() => {
                        enqueueSnackbar("Categoria cadastrada com sucesso!");
                        refetch();
                        setShowCreate(false);
                    }}
                />
            }

            {
                dataEdit &&
                <EditDialog
                    open={dataEdit ? true : false}
                    handleClose={() => setDataEdit(null)}
                    data={dataEdit}
                    onEdit={() => {
                        enqueueSnackbar("Categoria editada com sucesso!");
                        refetch();
                        setDataEdit(null);
                    }}
                />
            }

            {
                openDelete &&
                <DialogDelete
                    open={openDelete ? true : false}
                    handleClose={() => setOpenDelete(null)}
                    deleteId={openDelete}
                    onDelete={() => {
                        refetch()
                        setOpenDelete(null)
                    }}
                />
            }

            <Fab
                color="primary"
                onClick={() => setShowCreate(true)}
                style={{ position: "absolute", bottom: 48, right: 48 }}
            >
                <AddIcon />
            </Fab>
        </>
    );
}

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "../../components/Button";
import IconButton from "../../components/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export function ModalLayout({
	handleClose,
	open,
	title,
	children,
	handleSubmit,
	buttonText = "Salvar",
	isLoading,
	isDisabled,
	isNotShowButtonSubmit = false,
	isNotShowButtonClose = false,
	variantButtonSubmit = "contained",
	variantButtonClose = "text",
	buttonTextClose = "Fechar",
	isShowAdditionalButton,
	icon,
	onClickAdditionalButton,
	additionalButtonIsComponent,
	componentAdditionalButton,
	additionalFuntion,
	isFullScreen,
	centerButtons,
	...rest
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			{...rest}
			sx={{
				"& .MuiDialog-paper": {
					width: "100%",
				},
			}}
		>
			{!isFullScreen && (
				<DialogTitle id="responsive-dialog-title">
					<Stack
						direction="row"
						flex={1}
						justifyContent="space-between"
						alignItems="center"
					>
						{title}
						{isShowAdditionalButton && (
							<>
								{additionalButtonIsComponent ? (
									<>{componentAdditionalButton}</>
								) : (
									<IconButton onClick={onClickAdditionalButton}>
										{icon ?? <DeleteIcon color="error" />}
									</IconButton>
								)}
							</>
						)}
					</Stack>
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					padding: isFullScreen ? 0 : "20px 24px",
					display: isFullScreen ? "flex" : "block",
					flexDirection: isFullScreen ? "column" : "row",
				}}
			>
				{children}
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: centerButtons ? "center" : "flex-end",
				}}
			>
				{!isNotShowButtonClose && (
					<Button
						autoFocus
						onClick={() => {
							if (additionalFuntion) {
								additionalFuntion();
							} else {
								handleClose();
							}
						}}
						disabled={isLoading}
						variant={variantButtonClose}
					>
						{buttonTextClose}
					</Button>
				)}
				{!isNotShowButtonSubmit && (
					<Button
						onClick={handleSubmit}
						autoFocus
						variant={variantButtonSubmit}
						loading={isLoading}
						disabled={isDisabled}
					>
						{buttonText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
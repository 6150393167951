import { useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FETCH_TAGS } from "../../../../graphql/queries";
import {
  Container,
  Card,
  Typography,
  CardContent,
  Box,
  Fab,
} from "@material-ui/core";
import Header from "../../../../components/Header";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListProduct from "./ListProduct";
import ListService from "./ListService";
import AddIcon from "@material-ui/icons/Add";

export default function TagsPage({ history }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showCreate, setShowCreate] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [value, setValue] = useState(0);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    FETCH_TAGS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        keyword: "",
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    }
  );

  const handleTabs = (e, value) => {
    setValue(value);
  };
  const listRef = useRef();

  return (
    <>
      <Header
        onBack={() => {
          history.goBack();
        }}
      />

      <Container maxWidth="sm" style={{ marginTop: 86 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h1">
              Tags
            </Typography>
          </CardContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 20,
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabs}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#68bc4f",
                },
              }}
            >
              <Tab label="Produto" />
              <Tab label="Atendimento" />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <ListProduct
              onDelete={() => {
                enqueueSnackbar("Tag excluída com sucesso!");
                refetch();
              }}
              onEdit={(item) => setDataEdit(item)}
              onMount={(ref) => (listRef.current = ref)}
              {...{
                loading,
                error,
                data,
                networkStatus,
                currentPage,
                setCurrentPage,
                rowsPerPage,
                setRowsPerPage,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListService
              onDelete={() => {
                enqueueSnackbar("Tag excluída com sucesso!");
                refetch();
              }}
              onEdit={(item) => setDataEdit(item)}
              onMount={(ref) => (listRef.current = ref)}
              {...{
                loading,
                error,
                data,
                networkStatus,
                currentPage,
                setCurrentPage,
                rowsPerPage,
                setRowsPerPage,
              }}
            />
          </TabPanel>
        </Card>
      </Container>

      <CreateDialog
        open={showCreate}
        title="Cadastrar tag"
        handleClose={() => setShowCreate(false)}
        onCreate={() => {
          enqueueSnackbar("Tag cadastrada com sucesso!");
          refetch();
          setShowCreate(false);
        }}
      />

      {dataEdit && (
        <EditDialog
          open={dataEdit ? true : false}
          handleClose={() => setDataEdit(null)}
          data={dataEdit}
          onEdit={() => {
            enqueueSnackbar("Tag editada com sucesso!");
            refetch();
            setDataEdit(null);
          }}
        />
      )}

      <Fab
        color="primary"
        onClick={() => setShowCreate(true)}
        style={{ position: "absolute", bottom: 48, right: 48 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_RELEASE_CATEGORY } from "../../../../graphql/mutations";
import { ChromePicker } from "react-color";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";

export default function CreateDialog({ open, handleClose, onCreate }) {

    const { enqueueSnackbar } = useSnackbar();

    // Setting mutation
    const [createReleaseCategory, { loading }] = useMutation(CREATE_RELEASE_CATEGORY);

    // Setting Formik
    const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({

        initialValues: {
            title: "",
            type: "",
            color: "#244d19"
        },

        onSubmit: (values) => {

            const {
                title,
                type,
                color
            } = values

            if (title === "" || type === "" || color === "") {
                enqueueSnackbar("Favor preencher todos os campos", {
                    variant: "warning"
                })
                return
            }

            try {
                createReleaseCategory({
                    variables: {
                        title,
                        type,
                        color
                    }
                })
                resetForm();
                onCreate();
            } catch (e) {
                enqueueSnackbar(e, {
                    variant: "error"
                })
            }

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
            >
                <DialogTitle id="scroll-dialog-title">Criar nova categoria</DialogTitle>

                <DialogContent style={{ paddingBottom: 24 }}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setFieldValue('title', e.target.value)}
                        value={values.title}
                        required
                        fullWidth
                        id="title"
                        label="Nome"
                        name="title"
                        autoFocus
                        error={errors.title && true}
                        helperText={errors.title}
                        style={{ marginBottom: 16 }}
                    />

                    <TextField
                        select
                        value={values.type}
                        disabled={loading}
                        label="Origem *"
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        variant="outlined"
                        style={{ marginBottom: 24, width: '100%' }}
                        name="type"
                    >
                        <MenuItem value="Receita">Receita</MenuItem>
                        <MenuItem value="Despesa">Despesa</MenuItem>
                    </TextField>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChromePicker 
                        styles={{width: "100%"}} 
                        color={values.color} 
                        onChange={(color) => setFieldValue("color", color.hex)} 
                        />
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { TextField, MenuItem } from "@material-ui/core";
import { FETCH_SALE_CONSULTANTS } from "../graphql/queries";
import useDebounce from "../debounce";

export default function SelectSaleConsultant({ value, onChange, style }) {
  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const [keyword, setKeyword] = useState("");
  const debouncedValue = useDebounce(keyword, 1000);

  const { data, loading } = useQuery(FETCH_SALE_CONSULTANTS, {
    variables: {
      all: true,
      keyword: debouncedValue || null,
    },
  });

  const renderResults = () => {
    if (loading) {
      return <MenuItem value={1}>Carregando..</MenuItem>;
    } else if (data && data.fetchSaleConsultants.data.length === 0) {
      return (
        <p style={{ display: "flex", justifyContent: "center" }}>
          Nenhum resultado...
        </p>
      );
    } else {
      return data.fetchSaleConsultants.data.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ));
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label="Consultor de vendas"
        variant="outlined"
        style={style}
        inputProps={{ autoComplete: "off" }}
      >
        <MenuItem
          value={""}
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ backgroundColor: "#FFF" }}
        >
          <TextField
            fullWidth
            variant="standard"
            name="keyword"
            type="text"
            label="Pesquisar..."
            value={keyword}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
          />
        </MenuItem>
        {renderResults()}
      </TextField>
    </div>
  );
}

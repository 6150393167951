import { useMutation } from "@apollo/client";
import {
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { UPDATE_TAG } from "../../../../graphql/mutations";
import ColorPicker from "../../../../components/ColorPicker";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Box } from "@mui/material";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";

export default function EditDialog({ open, handleClose, onEdit, data }) {
  // Setting mutation
  const [updateTag, { loading }] = useMutation(UPDATE_TAG);
  const [type, setType] = useState(data?.type || "Produto");

  // Setting Formik
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: { name: "", id: 0, code: 0 },
    onSubmit: (values) => {
      // Chamando a mutation de edição
      updateTag({
        variables: {
          id: values.id,
          name: values.name,
          color: values.color,
          required: values.isTagRequired || false,
        },
      }).then(() => {
        onEdit();
      });
    },
  });

  useEffect(
    function () {
      if (data) {
        resetForm();
        setFieldValue("id", data.id);
        setFieldValue("color", data.color);
        setFieldValue("name", data.name);
        setFieldValue("isTagRequired", data.required);
      }
    },
    [data]
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">Editar Tag</DialogTitle>

        <DialogContent style={{ padding: 16 }}>
          <Grid container marginTop={4}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" style={{ width: "80%" }}>
                <InputLabel>Tipo da tag</InputLabel>
                <Select
                  label="Tipo da tag"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    setFieldValue("type", e.target.value);
                  }}
                >
                  <MenuItem value={"Produto"}>Produto</MenuItem>
                  <MenuItem value={"Atendimento"}>Atendimento</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} sm={10}>
              <TextField
                variant="outlined"
                margin="normal"
                onChange={handleChange}
                value={values.name}
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoFocus
                error={errors.name && true}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ColorPicker
                selectedColor={values.color}
                onSelectColor={(color) => setFieldValue("color", color)}
              />
            </Grid>
          </Grid>
          <Box
            marginTop="20px"
            marginBottom="20px"
            display="flex"
            alignItems="center"
          >
            <Typography variant="body2" style={{ marginRight: "10px" }}>
              Pré-visualização:
            </Typography>
            {type === "Atendimento" ? (
              <Chip
                label={values.name ? values.name : "Visualizar"}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  color: values.color || "#68bc4f",
                  borderColor: values.color || "#68bc4f",
                  padding: "5px",
                }}
                icon={
                  <BookmarkBorderIcon
                    style={{ fontSize: 15, color: values.color || "#68bc4f" }}
                  />
                }
              />
            ) : (
              <Chip
                label={values.name ? values.name : "Visualizar"}
                variant="outlined"
                style={{
                  backgroundColor: values.color || "#68bc4f",
                  marginRight: "10px",
                  color: "#FFF",
                  padding: "5px",
                }}
                icon={
                  values.isTagRequired ? (
                    <SdCardAlertIcon style={{ fontSize: 15, color: "#FFF" }} />
                  ) : (
                    <BookmarkBorderIcon
                      style={{ fontSize: 15, color: "#FFF" }}
                    />
                  )
                }
              />
            )}
          </Box>
          {type === "Produto" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isTagRequired}
                  onChange={(e) =>
                    setFieldValue("isTagRequired", e.target.checked)
                  }
                  name="isTagRequired"
                  style={{ color: values.isTagRequired ? "#68bc4f" : "" }}
                />
              }
              label="Tag obrigatória"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={values.name === "" || loading}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: 12 }} />
            )}
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

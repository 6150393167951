import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { useState } from "react";
import { parseISO } from "date-fns"

export default function FilterDialog({
  open,
  handleClose,
  onChangeFilter,
  loading,
  data,
}) {
  const [startDate, setStartDate] = useState(data.start ? parseISO(data.start) : null);
  const [finishDate, setFinishDate] = useState(data.finish ? parseISO(data.finish) : null);
  const [opportunity, setOpportunity] = useState(data?.opportunity || null);

  const handleClickFilter = () => {
    let { format } = require("date-fns");

    const values = {
      opportunity_id: opportunity || "",
      start: (startDate && format(startDate, "yyyy-MM-dd")) || "",
      finish: (finishDate && format(finishDate, "yyyy-MM-dd")) || "",
    };

    onChangeFilter(values);
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setFinishDate(null);
    setOpportunity(null);

    const obj = {
      start: null,
      finish: null,
      opportunity_id: null,
    };

    onChangeFilter(obj);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Filtrar indicações</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                label="Início"
                format="dd/MM/yyyy"
                value={startDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setStartDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                label="Fim"
                format="dd/MM/yyyy"
                value={finishDate}
                fullWidth
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => setFinishDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Oportunidades"
              value={opportunity}
              onChange={(e) => setOpportunity(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={1}>Seg. contrato</MenuItem>
              <MenuItem value={2}>Renovação</MenuItem>
              <MenuItem value={3}>Portab. consig.</MenuItem>
              <MenuItem value={4}>Santander</MenuItem>
              <MenuItem value={5}>Agibank</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClickFilter();
            handleClose();
          }}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Filtrar
        </Button>

        <Button
          onClick={() => {
            handleClearFilter();
            handleClose();
          }}
          color="primary"
        >
          Limpar filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  Card,
  Grid,
  Typography,
  makeStyles,
  Divider,
  CardHeader,
  CardContent,
  Chip,
} from "@material-ui/core";
import "../../assets/styleSheet/ScrollBar.css";
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";
import ModalOutputs from "../ModalOutputs";

export default function InteractionsHistory({ data }) {
  const classes = useStyles();

  const [openModalAfterSale, setOpenModalAfterSale] = useState(false)
  const [formDynamic, setformDynamic] = useState(null)

  return (
    <>
      <Card style={{ marginTop: 16, padding: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>HISTÓRICO DE INTERAÇÕES</b>
        </Typography>
        {data.length == 0 && (
          <CardContent>
            <Typography>Nenhuma registro encontrado</Typography>
          </CardContent>
        )}

        {data.map((el) => {
          
          return (
            <>
              <CardContent>
                <Typography variant="caption">
                  {el.created_at} - {el.author || "Administrador"}
                </Typography>
                <Typography variant="body2">{el.description}</Typography>
                <div style={{ marginTop: 6 }}>
                  <Chip
                    size="small"
                    variant="outlined"
                    color={
                      el.status == "Em negociação" ? "secondary" : "primary"
                    }
                    label={el.status}
                    className={
                      (el.status == "Contato Inválido" ||
                        el.status === "Sem perfil" ||
                        el.status === "Ligação encerrada") &&
                      classes.red
                    }
                  />
                  {
                    el.status === "Pós Venda" &&
                    <IconButton
                      aria-label="delete"
                      style={{ marginLeft: 4 }}
                      onClick={() => {
                        setOpenModalAfterSale(true)
                        setformDynamic(el.dynamic_form_output)
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  }
                  {el.opportunity !== null && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={el.opportunity}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </div>
              </CardContent>
              <Divider />
            </>
          );
        })}
      </Card>

      {
        (openModalAfterSale && formDynamic) &&
        <ModalOutputs
          open={openModalAfterSale}
          handleClose={() => setOpenModalAfterSale(false)}
          formDisplay={formDynamic}
        />
      }
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  red: {
    color: "#C00",
    borderColor: "#C00",
  },
}));
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import TextCopy from "../../components/TextCopy";
import PlaceholderBank from "../../assets/placeholder_bank.png";
import { formatDateBRL, formatMoneyBRL } from "../../helpers";
import EditIcon from "@material-ui/icons/Edit";
import { useState } from "react";
import EditDialogBenefitData from "./EditDialogBenefitData";

export default function BenefitData({ data, refetch, id }) {
  const [open, setOpen] = useState();

  const { format, parseISO } = require("date-fns");

  return (
    <>
      <Card style={{ marginTop: 16, paddingTop: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>DADOS DO BENEFÍCIO</b>
        </Typography>
        <CardContent>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <img
                  alt=""
                  width={40}
                  height={40}
                  style={{ marginRight: 12, float: "right" }}
                  src={
                    data?.bank && data?.bank.logo
                      ? data?.bank.logo
                      : PlaceholderBank
                  }
                />
                <TextCopy
                  copy={false}
                  title="Banco de Pagamento"
                  value={
                    data?.bank ? data?.bank.id + " - " + data?.bank.name : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Agência / Conta:"
                  value={
                    data?.agency && data?.account
                      ? data?.agency + " / " + data?.account
                      : data?.agency
                      ? data?.agency
                      : data?.account
                      ? data?.account
                      : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy title="Nº Benefício" value={data?.number || "-"} />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="DIB"
                  value={
                    data?.start
                      ? format(parseISO(data?.start), "dd/MM/yyyy")
                      : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Meio de Pagamento"
                  value={data?.way_of_payment || "-"}
                />
              </Grid>
              <Grid item xs={6} sm={8}>
                <TextCopy title="Entidade" value={data?.entity?.name || "-"} />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Salário"
                  value={data?.salary ? formatMoneyBRL(data?.salary) : "-"}
                />
              </Grid>
              <Grid item xs={6} sm={8}>
                <TextCopy
                  title="Margem disponível"
                  value={
                    data?.margin_loan ? formatMoneyBRL(data?.margin_loan) : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Margem total"
                  value={
                    data?.margin_loan_total
                      ? formatMoneyBRL(data?.margin_loan_total)
                      : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Margem cartão disponível"
                  value={
                    data?.margin_card ? formatMoneyBRL(data?.margin_card) : "-"
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextCopy
                  title="Margem cartão utilizada"
                  value={
                    data?.margin_card_used
                      ? formatMoneyBRL(data?.margin_card_used)
                      : "-"
                  }
                />
              </Grid>
              {(!data?.margin_card_used || data?.margin_card_used == 0) && (
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#0C0",
                      color: "#FFF",
                      textAlign: "center",
                      padding: "12px 0px",
                    }}
                  >
                    Cartão disponível!
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextCopy title="Espécie" value={data?.specie?.name || "-"} />
              </Grid>
            </Grid>
          </Grid>

          <div style={{ textAlign: "center", marginTop: 12 }}>
            <Button
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setOpen(true)}
            >
              Editar dados
            </Button>
          </div>

          <EditDialogBenefitData
            id={id}
            data={data}
            open={open}
            handleClose={() => setOpen(false)}
            onEdit={() => {
              setOpen(false);
              refetch();
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}
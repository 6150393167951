import { useMutation } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { CREATE_COMPANY, CREATE_PRODUCT, CREATE_SALE_CHANNEL, CREATE_SALE_ORIGIN } from "../../../../graphql/mutations";

export default function CreateDialog({ open, handleClose, onCreate }) {

    // Setting mutation
    const [createSaleOrigin, { loading }] = useMutation(CREATE_SALE_ORIGIN);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        
        initialValues: { name: "", email: "", password: "" },

        onSubmit: (values) => {

            // Chamando a mutation de criação
            createSaleOrigin({
                variables: {
                    name: values.name,
                }
            }).then(() => {
                resetForm();
                onCreate();
            })

        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Criar nova origem de venda</DialogTitle>

                <DialogContent>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        value={values.name}
                        required
                        fullWidth
                        id="name"
                        label="Nome"
                        name="name"
                        autoFocus
                        error={errors.name && true}
                        helperText={errors.name}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Criar origem de venda
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Card,
  Box,
} from "@material-ui/core";
import DialogButtonsOportunidads from "./DialogButtonsOportunidads";
import DialogCallEnd from "./DialogCallEnd";
import { FETCH_OPPORTUNITIES } from "../../graphql/queries";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useToken from "../../tokenJWT";

export default function ResumeInfo({
  data,
  onUninterested,
  onInNegotiation,
  onIndication,
  onProposal,
  onCallEnd,
  onUnprofile,
  campaign_id,
}) {
  const [buttonsOportunidads, setButtonsOportunidads] = useState(false);

  const { data: dataOpp, refetch } = useQuery(FETCH_OPPORTUNITIES, {
    variables: {
      lead_id: data.id,
      campaign_id: parseInt(campaign_id),
    },
  });

  const classes = useStyles();

  const handleClose = () => {
    setButtonsOportunidads(false);
    refetch();
  };

  const titleMap = {
    second_contract: "Seg. Contrato",
    renovation: "Renovação",
    consigned_portability: "Portab. Consig.",
    santander: "Santander",
    agibank: "Agibank",
  };

  const opportunity = (buttonsOportunidads) => {
    let opportunityId;
    if (buttonsOportunidads === "second_contract") {
      opportunityId = 1;
    } else if (buttonsOportunidads === "renovation") {
      opportunityId = 2;
    } else if (buttonsOportunidads === "consigned_portability") {
      opportunityId = 3;
    } else if (buttonsOportunidads === "santander") {
      opportunityId = 4;
    } else if (buttonsOportunidads === "agibank") {
      opportunityId = 5;
    } else {
      opportunityId = null;
    }
    return opportunityId;
  };

  return (
    <>
      <Card style={{ padding: 16, marginTop: 16 }}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          <b>OPORTUNIDADES</b>
        </Typography>

        <Box>
          <Button
            disabled={dataOpp?.fetchOpportunities[0]?.habilited === false}
            onClick={() => setButtonsOportunidads("second_contract")}
            size="small"
            style={{
              padding: "10px 5px 10px 5px",
              width: "100%",
              marginBottom: 12,
            }}
            variant="outlined"
            className={classes.contractButton}
            endIcon={
              dataOpp?.fetchOpportunities[0]?.checked ? (
                <CheckCircleIcon style={{ color: "#68bc4f" }} />
              ) : null
            }
          >
            Seg. Contrato
          </Button>
          <Button
            disabled={dataOpp?.fetchOpportunities[1]?.habilited === false}
            onClick={() => setButtonsOportunidads("renovation")}
            size="small"
            style={{
              padding: "10px 5px 10px 5px",
              width: "100%",
              marginBottom: 12,
            }}
            variant="outlined"
            className={classes.renovationButton}
            endIcon={
              dataOpp?.fetchOpportunities[1]?.checked ? (
                <CheckCircleIcon style={{ color: "#68bc4f" }} />
              ) : null
            }
          >
            Renovação
          </Button>
          <Button
            disabled={dataOpp?.fetchOpportunities[2]?.habilited === false}
            onClick={() => setButtonsOportunidads("consigned_portability")}
            size="small"
            style={{
              padding: "10px 5px 10px 5px",
              width: "100%",
              marginBottom: 12,
            }}
            variant="outlined"
            className={classes.portabilityButton}
            endIcon={
              dataOpp?.fetchOpportunities[2]?.checked ? (
                <CheckCircleIcon style={{ color: "#68bc4f" }} />
              ) : null
            }
          >
            Portab. Consig.
          </Button>
          <Button
            disabled={dataOpp?.fetchOpportunities[3]?.habilited === false}
            onClick={() => setButtonsOportunidads("santander")}
            size="small"
            style={{
              padding: "10px 5px 10px 5px",
              width: "100%",
              marginBottom: 12,
            }}
            variant="outlined"
            className={classes.santanderButton}
            endIcon={
              dataOpp?.fetchOpportunities[3]?.checked ? (
                <CheckCircleIcon style={{ color: "#68bc4f" }} />
              ) : null
            }
          >
            Santander
          </Button>
          <Button
            disabled={dataOpp?.fetchOpportunities[4]?.habilited === false}
            onClick={() => setButtonsOportunidads("agibank")}
            size="small"
            style={{
              padding: "10px 5px 10px 5px",
              width: "100%",
              marginBottom: 12,
            }}
            variant="outlined"
            className={classes.agibankButton}
            endIcon={
              dataOpp?.fetchOpportunities[4]?.checked ? (
                <CheckCircleIcon style={{ color: "#68bc4f" }} />
              ) : null
            }
          >
            Agibank
          </Button>
        </Box>
      </Card>

      <DialogButtonsOportunidads
        title={titleMap[buttonsOportunidads]}
        campaign_id={campaign_id}
        data={data}
        opportunity_id={opportunity(buttonsOportunidads)}
        open={buttonsOportunidads}
        onClose={() => setButtonsOportunidads(false)}
        refetch={refetch}
        handleClose={handleClose}
        onUninterested={() => onUninterested(opportunity(buttonsOportunidads))}
        onInNegotiation={() =>
          onInNegotiation(opportunity(buttonsOportunidads))
        }
        onUnprofile={() => onUnprofile(opportunity(buttonsOportunidads))}
        onIndication={() => onIndication(opportunity(buttonsOportunidads))}
        onProposal={() => {
          refetch();
          onProposal();
          setButtonsOportunidads(false);
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  callEndButton: {
    background: "#F00",
    color: "#FFF",
    "&:hover": {
      background: "#C00",
    },
  },
  santanderButton: {
    color: "#8B0000",
    borderColor: "#8B0000",
  },
  contractButton: {
    color: "#4169E1",
    borderColor: "#4169E1",
  },
  renovationButton: {
    color: "#00008B",
    borderColor: "#00008B",
  },
  portabilityButton: {
    color: "#363636",
    borderColor: "#363636",
  },
  agibankButton: {
    color: "#228B22",
    borderColor: "#228B22",
  },
}));
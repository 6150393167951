import '../../../assets/styleSheet/ScrollBar.css';
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router";
import { FETCH_RESCHEDULES } from "../../../graphql/queries";

export default function Reschedules() {

    let history = useHistory();
    const { data, loading } = useQuery(FETCH_RESCHEDULES, {
        fetchPolicy: 'network-only',
    });

    const handleClick = (document, lead_id) => {
        history.push("/lead/" + document, {
          document: new URLSearchParams(document).toString(),
          id: new URLSearchParams(lead_id).toString(),
        });
      };

    return (
        <Card>
            <CardHeader title="Agendamentos" />


            {
                loading && <CircularProgress style={{ margin: 12, marginTop: -6 }} />
            }

            {
                data &&
                <>
                    {
                        data.fetchReschedules.total == 0 &&
                        <CardContent>
                            <Typography variant="body2">Nenhum registro encontrado</Typography>
                        </CardContent>
                    }

                    <div className='scrollbarCustom' style={{ display: 'flex', overflowY: 'hidden', overflowX: 'auto' }}>
                        {
                            data.fetchReschedules.data.map(i => {
                                return (
                                    <>
                                        <CardContent onClick={() => handleClick(i.document, i.lead_id)} style={{ cursor: 'pointer', borderRight: '1px solid #ccc' }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div >
                                                    <Typography variant="caption">Retornar em</Typography>
                                                    <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>{i.return_in.formated}</Typography>
                                                    <div style={{ backgroundColor: i.return_in.diff_today == 0 ? '#5FA018' : (i.return_in.diff_today > 0 ? '#556cd6' : '#d50029'), fontSize: 10, fontWeight: 'bold', textAlign: 'center', color: '#FFF', paddingTop: 4, paddingBottom: 4, borderRadius: 8, marginTop: 4 }}>
                                                        {i.return_in.diff_today >= 0 ? 'Ligar' : 'Atrasado'}
                                                        {i.return_in.diff_today == 0 && <> hoje</>}
                                                        {i.return_in.diff_today == 1 && <> amanhã</>}
                                                        {i.return_in.diff_today == -1 && <> 1 dia</>}
                                                        {i.return_in.diff_today > 1 && <> daqui {i.return_in.diff_today} dias</>}
                                                        {i.return_in.diff_today < -1 && <> {i.return_in.diff_today * -1} dias</>}
                                                    </div>
                                                </div>

                                                <div style={{margin: '0px 24px'}}>
                                                    <Typography variant="caption">Cliente</Typography>
                                                    <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>{i.name}</Typography>
                                                </div>

                                                <div >
                                                    <ArrowForwardIosIcon style={{ color: '#666666', cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </>
                                )
                            })
                        }
                    </div>
                </>
            }
        </Card>
    );

}


import React, { useState } from "react";
import {
  Typography,
  Container,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  Box,
  Button,
} from "@material-ui/core";
import InteractionsPerformance from "./InteractionsPerformance";
import ProposalSituations from "./ProposalSituations";
import Header from "../../../components/Header";
import Ranking from "./Ranking";
import AccountProposal from "./AccountProposal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopoverAnnouncement from "../../../components/PopoverAnnouncement";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import useToken from "../../../tokenJWT";
import Statement from "../../sales-consultant/DashboardPage/Statement";

export default function DashboardPage() {

  const { restrictions } = useToken();

  //Restricitions
  const allowComunicados = restrictions({ topic: "Dashboard", name: "Criar comunicado" })
  const allowRanking = restrictions({ topic: "Dashboard", name: "Ranking" })
  const allowContadores = restrictions({ topic: "Dashboard", name: "Contadores e Análise" })
  const allowVisComunicados = restrictions({ topic: "Dashboard", name: "Contadores e Análise" })

  const [teamId, setTeamId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [companyIds, setCompanyIds] = useState(null);
  const [productIds, setProductIds] = useState(null);
  const [saleConsultantId, setSaleConsultantId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleFilter = (data) => {
    setTeamId(data.teamId);
    setStartDate(data.startDate);
    setFinishDate(data.finishDate);
    setCompanyIds(data.company_ids);
    setProductIds(data.product_ids);
    setSaleConsultantId(data.saleConsultantId);
  };

  return (
    <>
      <Header />
      <Container style={{ marginTop: 100 }}>
        <Grid container spacing={3}>

          {
            allowContadores &&
            <>
              <Grid item xs={12}>
                <AccountProposal
                  handleFilter={handleFilter}
                  teamId={teamId}
                  startDate={startDate}
                  finishDate={finishDate}
                  company_ids={companyIds}
                  product_ids={productIds}
                  saleConsultantId={saleConsultantId}
                />
              </Grid>
            </>
          }

          {
            allowVisComunicados &&
            <Grid item xs={12}>
              <Statement />
            </Grid>
          }

          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" component="h1">
                  Situação das propostas
                </Typography>
              </AccordionSummary>
              <CardContent style={{ height: "500px" }}>
                <ProposalSituations
                  teamId={teamId}
                  startDate={startDate}
                  finishDate={finishDate}
                  company_ids={companyIds}
                  product_ids={productIds}
                  saleConsultantId={saleConsultantId}
                />
              </CardContent>
            </Accordion>
          </Grid>

          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" component="h1">
                  Performance das interações
                </Typography>
              </AccordionSummary>
              <CardContent style={{ height: "500px" }}>
                <InteractionsPerformance
                  teamId={teamId}
                  startDate={startDate}
                  finishDate={finishDate}
                  company_ids={companyIds}
                  product_ids={productIds}
                  saleConsultantId={saleConsultantId}
                />
              </CardContent>
            </Accordion>
          </Grid>


          {
            allowRanking &&
            <Grid item xs={12} md={12}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5" component="h1">
                    Ranking
                  </Typography>
                </AccordionSummary>
                <CardContent style={{ overflow: "auto" }}>
                  <Ranking
                    teamId={teamId}
                    startDate={startDate}
                    finishDate={finishDate}
                    company_ids={companyIds}
                    product_ids={productIds}
                    saleConsultantId={saleConsultantId}
                  />
                </CardContent>
              </Accordion>
            </Grid>
          }

        </Grid>
      </Container>

      {
        allowComunicados &&
        <>
          <Box
            sx={{
              position: "fixed",
              bottom: 60,
              right: 40,
              borderRadius: "12px",
              backgroundColor: "#a4eba4",
              transition:
                "box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0,0.2,1)",
              "&:hover": {
                boxShadow:
                  "0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)",
              },
            }}
          >
            <Button
              onClick={handleOpen}
              color="default"
              startIcon={<CreateOutlinedIcon />}
              style={{
                fontSize: "12px",
                padding: "18px",
              }}
            >
              Comunicado
            </Button>
          </Box>
          <PopoverAnnouncement open={open} onClose={() => setOpen(false)} />
        </>
      }

    </>
  );
}

import { Button } from "@material-ui/core";
import React, { forwardRef } from "react";

const buttonBase = (
  {
    onClick,
    children,
    type = "button",
    variant,
    color,
    disabled,
    endIcon,
    startIcon,
    ...rest
  },
  ref
) => {
  return (
    <Button
      color="primary"
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      ref={ref}
      type={type}
      variant={variant}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default forwardRef(buttonBase);
import { Typography, Chip, Card, Box } from "@material-ui/core";
import { formatMoneyBRL } from "../../helpers";

export default function InfoLead({
  data,
  name,
  location,
  birthdate,
  netIncome,
  gender,
}) {
  const themeChip = (type) => {
    switch (type) {
      case "Baixo":
        return "#4169E1";

      case "Médio":
        return "#FF7F00";

      case "Alto":
        return "#CC0000";

      default:
        throw new Error("Tipo não encontrado");
    }
  };

  const celsius = [
    data?.interactions.filter((item) => item.temperature !== null),
  ];

  const soma = () => {
    return data?.debit_accounts.reduce((acc, item) => {
      return acc + item.portion_amount;
    }, 0);
  };

  return (
    <Card
      container
      style={{
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        padding: 16,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* {data.debit_accounts.length === 0 ? (
          <img
            width={80}
            height={80}
            alt="Genêro"
            style={{ borderRadius: "50%", marginBottom: 8 }}
            src={gender === "M" ? maleIcon : femaleIcon}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <img src={Tomador03} alt="Logo" style={{ width: 140 }} />
          </Box>
        )} */}
        <Box>
          <Typography style={{ fontSize: "22px", textTransform: 'uppercase' }}>
            <b>{name}</b>
          </Typography>
          <Typography style={{ fontSize: "13px" }}>
            {birthdate &&
              new Date(new Date() - new Date(birthdate)).getFullYear() -
                1970 +
                " anos"}
            {location && ", " + location.name + "/" + location.state}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {data?.debits.length > 0 && (
              <Chip
                size="medium"
                variant="outlined"
                label="Inadimplente"
                style={{
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  letterSpacing: "2px",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                  marginTop: 10,
                  backgroundColor: "#F00",
                  marginRight: "10px",
                }}
              />
            )}
            {data.debit_accounts.length === 0 &&
              celsius[0].map((item) => item.temperature).length > 0 && (
                <Chip
                  size="medium"
                  variant="outlined"
                  label={celsius[0].map((item) => item.temperature)[0]}
                  style={{
                    color: "white",
                    fontSize: "15px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                    marginTop: 10,
                    backgroundColor: themeChip(
                      celsius[0].map((item) => item.temperature)[0]
                    ),
                    marginRight: "10px",
                  }}
                />
              )}
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 12, textAlign: "center" }}
        >
          <b>RENDA MENSAL</b>
        </Typography>

        <Box
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Box>Salário Bruto</Box>
          <Box>
            <b style={{ color: "green" }}>
              {formatMoneyBRL(netIncome.gross_salary || 0)}
            </b>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Box>Total de Consignados</Box>
          <Box>
            <b style={{ color: "red" }}>
              {formatMoneyBRL((netIncome.amount_consigned || 0) * -1)}
            </b>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Box>RMC</Box>
          <Box>
            <b style={{ color: "red" }}>
              {formatMoneyBRL((netIncome.reserved || 0) * -1)}
            </b>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Box>Débitos</Box>
          <Box>
            <b style={{ color: "red" }}>{formatMoneyBRL((soma() || 0) * -1)}</b>
          </Box>
        </Box>

        <Box style={{ float: "right", textAlign: "right" }}>
          <Typography variant="caption">Sálario líquido</Typography>
          <Typography variant="h6">
            {formatMoneyBRL(netIncome.final_salary - soma() || 0)}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { useState } from "react";
import SelectSaleConsultant from "../../../components/SelectSaleConsultant";

export default function FilterDialog({ open, handleClose, onFilter }) {

    const [startDate, setStartDate] = useState(null);
    const [finishDate, setFinishDate] = useState(null);
    const [saleConsultantId, setSaleConsultantId] = useState(null);

    const handleClickFilter = () => {

        let { format } = require("date-fns");

        var obj = {
            start: startDate && format(startDate, "yyyy-MM-dd"),
            finish: finishDate && format(finishDate, "yyyy-MM-dd"),
            sale_consultant_id: saleConsultantId,
            page: 1
        };

        onFilter(obj);
    }


    const handleClearFilter = () => {
        setStartDate(null);
        setFinishDate(null);
        setSaleConsultantId(null);

        var obj = {
            start: null,
            finish: null,
            sale_consultant_id: null,
            page: 1
        };

        onFilter(obj);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Filtrar agendamentos</DialogTitle>
            <DialogContent>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Início"
                                format="dd/MM/yyyy"
                                value={startDate}
                                fullWidth
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setStartDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <DatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Fim"
                                format="dd/MM/yyyy"
                                value={finishDate}
                                fullWidth
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFinishDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <SelectSaleConsultant
                            value={saleConsultantId}
                            onChange={id => setSaleConsultantId(id)}
                        />
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>

                <Button
                    onClick={() => {
                        handleClickFilter();
                        handleClose();
                    }}
                    variant="contained"
                    color="primary"
                >
                    Filtrar
                </Button>

                <Button
                    onClick={() => {
                        handleClearFilter();
                        handleClose();
                    }}
                    color="primary"
                >
                    Limpar filtros
                </Button>
            </DialogActions>
        </Dialog>
    )

}



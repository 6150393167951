import Header from "../../../components/Header";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import InteractionsPerformance from "./InteractionsPerformance";
import Reschedules from "./Reschedules";
import useToken from "../../../tokenJWT";
import DialogOpenProposal from "./DialogOpenProposal";
import DialogOpenNewService from "./DialogOpenNewService";
import { useState } from "react";
import Negotiations from "./Negotiations";
import Statement from "./Statement";

export default function SaleDashboardPage() {
  const { user, restrictions } = useToken();

  //Restrictions
  const allowAbrirProposta = restrictions({ topic: "Dashboard", name: "Abrir Proposta" })
  const allowNovoAtendimento = restrictions({ topic: "Dashboard", name: "Novo Atendimento" })
  const allowAgendamentos = restrictions({ topic: "Dashboard", name: "Visualizar Agendamentos" })
  const allowNegociacoes = restrictions({ topic: "Dashboard", name: "Visualizar Negociações" })
  const allowInteraçõesEstat = restrictions({ topic: "Dashboard", name: "Estatísticas  de Interações" })
  const allowVisComunicados = restrictions({ topic: "Dashboard", name: "Contadores e Análise" })
  //

  const [showDialogOpenProposal, setShowDialogOpenProposal] = useState(false);
  const [showDialogOpenNewService, setShowDialogOpenNewService] =
    useState(false);

  return (
    <div>
      <Header isShowBadge />
      <Container maxWidth="lg" style={{ marginTop: "86px" }}>
        <Grid container md={12} spacing={4} style={{ height: "100%" }}>
          <Grid item md={12}>
            <Typography variant="h6">Olá {user.name}!</Typography>
            <Typography variant="body2" style={{ marginBottom: 12 }}>
              O que deseja fazer agora?
            </Typography>
            <Grid container spacing={2}>
              {
                allowAbrirProposta &&
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowDialogOpenProposal(true)}
                  >
                    Abrir proposta
                  </Button>
                </Grid>
              }
              {
                allowNovoAtendimento &&
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowDialogOpenNewService(true)}
                  >
                    Novo atendimento
                  </Button>
                </Grid>
              }
            </Grid>

            {
              showDialogOpenProposal &&
              <DialogOpenProposal
                open={showDialogOpenProposal}
                handleClose={() => setShowDialogOpenProposal(false)}
              />
            }

            {
              showDialogOpenNewService &&
              <DialogOpenNewService
                open={showDialogOpenNewService}
                handleClose={() => setShowDialogOpenNewService(false)}
              />
            }

          </Grid>

          {
            allowVisComunicados &&
            <Grid item xs={12}>
              <Statement />
            </Grid>
          }

          {
            allowAgendamentos &&
            <Grid item xs={12}>
              <Reschedules />
            </Grid>
          }

          {
            allowNegociacoes &&
            <Grid item xs={12}>
              <Negotiations />
            </Grid>
          }

          {
            allowInteraçõesEstat &&
            <Grid item xs={12}>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">
                      Performance das Interações
                    </Typography>
                    <InteractionsPerformance />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          }

        </Grid>
      </Container>
    </div>
  );
}

import { Card, CardContent, Grid, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useState } from "react";
import { useGlobalMessage } from "../../../../App";
import { maskPhone } from "../../../../helpers";

export default function ListPhoneReferences({ title, value, copy, style = {} }) {

    const [message, setMessage] = useState();

    const handleClick = (data) => {
        if (value != '-' && copy !== false) {
            navigator.clipboard.writeText(value);
            setMessage('"' + data + '" copiado');
        }
    }

    return (
        <div style={{
            ...style,
            border: '1px solid #ccc',
            borderRadius: 5,
            padding: "4px 8px",
        }}>
            <Typography variant="caption">{title}</Typography>

            <Table size="small" padding="none" style={{ marginTop: 8 }}>
                <TableBody>
                    {value.map((row) => (
                        <TableRow key={row.id} align="left">
                            <TableCell style={{ padding: '4px 0', fontWeight: 'bold', fontSize: 12 }}>
                                {row.name}
                                <FileCopyIcon
                                    onClick={() => handleClick(row.name)}
                                    style={{ marginLeft: 4, color: '#333', fontSize: 14, cursor: 'pointer' }}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '4px 0', fontWeight: 'bold', fontSize: 12 }}>
                                {maskPhone(row.phone)}
                                <FileCopyIcon
                                    onClick={() => handleClick(row.phone)}
                                    style={{ marginLeft: 4, color: '#333', fontSize: 14, cursor: 'pointer' }}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '4px 0', fontWeight: 'bold', fontSize: 12 }}>
                                {row.kinship}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Snackbar
                open={message ? true : false}
                onClose={() => setMessage(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={1000}
                message={<span id="message-id">{message}</span>}
            />
        </div>
    )

}
import { Grid, Typography } from "@material-ui/core";

export default function Filter(data, loading) {
  return (
    <Grid container style={{ marginBottom: 30 }}>
      <Grid item xs={6}>
        <Typography
          component="div"
          variant="caption"
          style={{ width: "300px" }}
        >
          Total de inadimplentes
        </Typography>
        <Typography variant="h5">{data?.data.total}</Typography>
      </Grid>
    </Grid>
  );
}

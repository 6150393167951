import { useMutation } from "@apollo/client";
import { ValueScale } from "@devexpress/dx-react-chart";
import { CircularProgress, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { UPDATE_REASON } from "../../../../graphql/mutations";

export default function EditDialog({ open, handleClose, onEdit, data }) {

    // Setting mutation
    const [updateReason, { loading }] = useMutation(UPDATE_REASON);

    // Setting Formik
    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: (data || null),        
        onSubmit: (values) => {
            // Chamando a mutation de edição
            updateReason({
                variables: {
                    id: values.id,
                    name: values.name,
                    entity: values.entity,
                    type: values.type,
                }
            }).then(() => {
                onEdit();
            })
        }
    });
    
    useEffect(function () {
        if (data) {
            let entity = data.name.match(/\(([^)]+)\)/)?.[1];
            let name = data.name.replace(/ *\([^)]*\) */g, "");
            resetForm();
            setFieldValue('id', data.id);
            setFieldValue('name', name);
            setFieldValue('entity', entity);
            setFieldValue('type', data.type);
        }
    }, [data]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Editar Motivo</DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                onChange={handleChange}
                                value={values?.name}
                                required
                                id="name"
                                label="Nome"
                                name="name"
                                error={errors.name && true}
                                helperText={errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                onChange={handleChange}
                                value={values?.entity}
                                label="Entidade"
                                name="entity"
                                error={errors.entity && true}
                                helperText={errors.entity}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                        <Typography>Tipo*</Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group"
                                name="type"
                                value={values.type}
                                onChange={(e) => setFieldValue('type', e.target.value)}
                            >
                                <FormControlLabel
                                style={{ marginRight: 50 }}
                                value="Sem interesse"
                                control={<Radio />}
                                label="Sem interesse"
                                // checked={values.type === "Sem interesse"}
                                />
                                <FormControlLabel
                                value="Sem perfil"
                                control={<Radio />}
                                label="Sem perfil"
                                // checked={values.type === "Sem perfil"}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values?.name == "" || loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

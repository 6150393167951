import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_DYNAMIC_FORMS, FETCH_PRODUCTS } from "../graphql/queries";

export default function SelectDynamicFormId({ value, onChange, inputName, style }) {

    const { data, loading } = useQuery(FETCH_DYNAMIC_FORMS, {
        variables: {
            limit: 100
        }
    });

    return (
        <TextField
            select
            value={loading ? 1 : value}
            disabled={loading}
            label="Formulários"
            onChange={(e) => onChange(e.target.value)}
            variant="outlined"
            style={{ marginBottom: 24, width: '100%' }}
            name={inputName}
        >
            { loading && <MenuItem value={1}>Carregando..</MenuItem>}

            {
                data && data.fetchDynamicForms.data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Grid,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { maskCPF, isValidCPF } from "../../../../helpers";
import { useLazyQuery } from "@apollo/client";
import { SEARCH } from "../../../../graphql/queries";
import Step01 from "./Step01";
import Step02 from "./Step02";
import { removeClientSetsFromDocument } from "@apollo/client/utilities";
import { isValid } from "date-fns";

export default function DialogOpenProposal({
  open,
  handleClose,
  document,
  onProposal,
  opportunityValue,
}) {
  const [step, setStep] = useState(0);
  const [schema, setSchema] = useState(null);
  const [validCPF, setValidCPF] = useState(true);

  const [search, { data, loading, error }] = useLazyQuery(SEARCH, {
    fetchPolicy: "network-only",
  });

  const { setFieldValue, values, resetForm } = useFormik({
    initialValues: {
      document: "",
      name: "",
      pessoal_step: {},
    },
    validationSchema: schema,
  });

  useEffect(() => {
    if (document) {
      setFieldValue("document", document);
      setFieldValue("name", data.name ? data.name : "");
      setSchema(newProposalSchemaCustomer);
    }

    if (data && data.search) {
      setFieldValue("pessoal_step", data.search?.proposals[0]?.customer);
      setStep(1);
      setSchema(newProposalSchemaCustomer);
    }

    if (error) {
      setFieldValue("name", "");
      setFieldValue("phone", "");
      setSchema(newProposalSchemaCustomer);
      setStep(1);
    }
  }, [data, error, document]);

  function onCreate() {
    setStep(0);
    resetForm();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{ marginBottom: "-16px" }}>
        Atendimento manual
      </DialogTitle>

      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Dados do cliente</StepLabel>
          <StepContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                autoFocus
                variant="outlined"
                margin="normal"
                label="Nome"
                name="name"
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                  setSchema(null);
                }}
                value={values.name}
                required
                fullWidth
              />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="reason"
                  label="CPF (opcional)"
                  value={values.document}
                  onChange={(e) => {
                    setFieldValue("document", maskCPF(e.target.value));
                    setSchema(null);
                    setValidCPF(isValidCPF(e.target.value));
                  }}
                  fullWidth
                  error={values?.document?.length >= 1 && !validCPF}
                  helperText={values?.document?.length >= 1 && !validCPF ? "CPF inválido" : ""}
                />
              </Grid>
            </Grid>
            <Button
              disabled={values.name === "" || (values.document && !isValidCPF(values.document))}
              variant="contained"
              color="primary"
              onClick={() =>
                search({ variables: { keyword: values.document } })
              }
            >
              {loading && (
                <CircularProgress size={14} style={{ marginRight: 12, color: "#FFF" }} />
              )}
              Próximo
            </Button>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Contato</StepLabel>
          <StepContent>
            <Step01
              {...{ setStep }}
              data={values.pessoal_step}
              dataSearch={data}
              setFormik={setFieldValue}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Conclusão</StepLabel>
          <StepContent>
            <Step02
              {...{ setStep, step }}
              setFormik={setFieldValue}
              pessoalValues={values.pessoal_step}
              documentValue={values.document}
              nameValue={values.name}
              onNewService={() => {
                handleClose();
                onCreate();
              }}
              opportunityValue={opportunityValue}
            />
          </StepContent>
        </Step>
      </Stepper>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const newProposalSchemaCustomer = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document: Yup.string(),
});


import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useState } from "react";
import { UPDATE_RELEASE_SUBCATEGORY } from "../../../../../graphql/mutations";

export default function DialogEditSubCategory({
    open,
    handleClose,
    data,
    onEdit,
    onError
}) {

    const [updateReleaseSubcategory, { loading }] = useMutation(UPDATE_RELEASE_SUBCATEGORY)

    const [title, setTitle] = useState(data.title)

    const handleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleSubmit = async () => {
        try {
            await updateReleaseSubcategory({
                variables: {
                    id: data.id,
                    title: title
                }
            })
            onEdit()
        } catch (e) {
            console.log(e)
            onError()
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Editar tipo"}</DialogTitle>

                <DialogContent>
                    <TextField
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={handleChange}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" autoFocus disabled={loading}>
                        {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                        Editar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
import { useFormik } from "formik";
import { useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_INTERACTION } from "../../../graphql/mutations";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

export default function DialogInNegotiation({
  lead_id,
  opportunity_id,
  open,
  onClose,
  handleClose,
  onCreate,
  campaign_id,
}) {
  const queryString = window.location.search;
  const isFastway = queryString.includes("chave=");

  const { enqueueSnackbar } = useSnackbar();

  const [createInteraction, { loading }] = useMutation(CREATE_INTERACTION);

  const [newValue, setNewValue] = useState("");

  const handleChangeRadio = (e) => {
    setNewValue(e.target.value);
  };

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: { description: null },
    validationSchema: Yup.object().shape({
      description: Yup.string().required("Campo obrigatório").nullable(),
    }),
    onSubmit: (values) => {
      createInteraction({
        variables: {
          lead_id: lead_id,
          campaign_id: parseInt(campaign_id),
          opportunity_id: opportunity_id || null,
          status: "Em negociação",
          description: values.description,
          temperature: newValue,
          fastway: isFastway,
        },
      })
        .then(() => {
          if (onClose) {
            onClose();
          }
          resetForm();
          onCreate();
        })
        .catch((error) => {
          enqueueSnackbar("Preencher mínimo de 10 caracteres!", {
            variant: "error",
          });
        });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Em negociação</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              label="Observação"
              value={values.description}
              name="description"
              onChange={handleChange}
              fullWidth
              error={errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Grid style={{ marginLeft: 26, marginTop: 5, color: "#808080" }}>
        *Preencher a observação com mínimo de 10 caracteres.
      </Grid>

      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #D3D3D3",
            borderRadius: "5px",
            padding: "15px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Qual o nível de interesse do cliente?
          <FormControl>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={newValue}
              onChange={handleChangeRadio}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <FormControlLabel
                value="Alto"
                control={<Radio />}
                label="Alto"
                sx={{
                  color: "pink",
                  "&.Mui-checked": {
                    color: "pink",
                  },
                }}
              />
              <FormControlLabel
                value="Médio"
                control={<Radio />}
                label="Médio"
              />
              <FormControlLabel
                value="Baixo"
                control={<Radio />}
                label="Baixo"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={14} style={{ marginRight: 12 }} />
          )}
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

import { MUTATION_AUTH } from "../../../graphql/mutations";
import useToken from "../../../tokenJWT";

import logoSintegra from "../../../assets/logo-novo.png";
import logoSimCreditoSimples from "../../../assets/logo-sim-credito-simples.png";
import logoFocoSolucoesFinanceiras from "../../../assets/logo-foco-solucoes-financeira.png";
import logoMercantil from "../../../assets/logo-bm-marca-mercantil.png";
import logoSantander from "../../../assets/logo-santander.png";
import logoAgiBranco from "../../../assets/logo-agi-branco.png";

export default function LoginPage() {
  const [authMutation, { loading }] = useMutation(MUTATION_AUTH);
  const { enqueueSnackbar } = useSnackbar();
  
  const { setToken, user } = useToken();
  const classes = useStyles();

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px)');

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      authMutation({ variables: values })
        .then(({ data }) => {
          setToken(data.auth);
          window.location = "/";
        })
        .catch((error) => {
          enqueueSnackbar(
            "Falha na autenticação. Verifique email e senha informados.",
            { variant: "error" }
          );
        });
    },
  });

  if (user) {
    window.location = "/";
    return;
  }

  let imgWidth;
  if (isSmallScreen) {
    imgWidth = '20%';
  } else if (isMediumScreen) {
    imgWidth = '15%';
  } else if (isLargeScreen) {
    imgWidth = '10%';
  }



  return (
    <div style={{ backgroundColor: "#132994", height: "100vh" }}>
      <header
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <img src={logoSimCreditoSimples} alt="logo Sim Credito" style={{ width: imgWidth }} />
        <img src={logoFocoSolucoesFinanceiras} alt="Logo Foco Solucoes" style={{ width: imgWidth }} />
        <img src={logoMercantil} alt="Logo Bm Marca" style={{ width: "20%" }} />
        <img src={logoAgiBranco} alt="Logo Bm Marca" style={{ width: imgWidth }} />
        <img src={logoSantander} alt="Logo Agi" style={{ width: imgWidth }} />
      </header>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "80vh",
        }}
      >
        <Container maxWidth="md">
          <Box>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12} md={8}>
                <img src={logoSintegra} alt="Logo Principal" style={{ width: "100%", marginBottom: "10%" }} />
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <form onSubmit={handleSubmit} noValidate>
                    <TextField
                      variant="filled"
                      margin="normal"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoFocus
                      color="secondary"
                      error={errors.email && true}
                      helperText={errors.email}
                      className={classes.customBorder}
                    />
                    <TextField
                      variant="filled"
                      margin="normal"
                      onChange={handleChange}
                      value={values.password}
                      required
                      fullWidth
                      name="password"
                      label="Senha"
                      type="password"
                      id="password"
                      color="secondary"
                      error={errors.password && true}
                      helperText={errors.password}
                      className={classes.customBorder}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      disabled={loading}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 12, paddingTop: 12, paddingBottom: 12, borderRadius: 30 }}
                    >
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{ marginRight: 6 }}
                        />
                      )}
                      Entrar
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </main>
    </div>
  );
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

const useStyles = makeStyles({
  customBorder: {
    '& .MuiFilledInput-root': {
      borderRadius: 30,
      backgroundColor: 'white',
      '&.Mui-focused': {
        backgroundColor: 'white',
        '&:after': {
          borderColor: '#132994',
        },
      },
      '&:before': {
        borderColor: '#132994',
      },
    },
  },
});
import React from "react";
import { useQuery } from "@apollo/client";

import {
    Box,
    CircularProgress,
    Typography,
    Switch,
} from "@material-ui/core";
import { useMemo, useEffect } from "react";
import { Stack } from "@mui/material";
import { FETCH_RESTRICTIONS } from "../graphql/queries";

export default function RestrictionsPicker({
    handleChangeValue,
    formValues = {}
}) {

    const { data, loading } = useQuery(FETCH_RESTRICTIONS);

    const groupByRestrictions = useMemo(() => {
        if (data) {
            return data.fetchRestrictions.reduce((acc, item) => {
                const key = item.topic;

                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(item);
                return acc;
            }, {});
        }
    }, [data]);

    const groupByRestrictionsTitle =
        groupByRestrictions &&
        Object.entries(groupByRestrictions).map(([topic, restrictions]) => ({
            title: topic,
            restrictions: restrictions,
        }));

    useEffect(() => {
        if (groupByRestrictionsTitle && formValues?.restrictions) {
            groupByRestrictionsTitle.forEach((item, index) => {
                const idRestrictions = item.restrictions.map(
                    (restriction) => restriction.id
                );

                const allValuesChecked = idRestrictions.every(
                    (id) => formValues?.restrictions?.[id] === true
                );

                if (
                    formValues?.groupByRestrictionsTitle?.[index] !== allValuesChecked
                ) {
                    handleChangeValue(
                        `groupByRestrictionsTitle.${index}`,
                        allValuesChecked
                    );
                }
            });
        }
    }, [groupByRestrictionsTitle, formValues?.restrictions]);

    const handleCheckedAllValuesFromGroup = (value, index) => {
        const findIndexGroup = groupByRestrictionsTitle[index];

        findIndexGroup.restrictions.forEach((restriction) => {
            handleChangeValue(`restrictions.${restriction.id}`, value);
        });
        handleChangeValue(`groupByRestrictionsTitle.${index}`, value);
    };

    const isLoading = loading;

    if (isLoading) return (
        <Box marginY={2} width="100%" textAlign="center">
            <CircularProgress />
        </Box>
    )

    return (
        <Box marginTop={2}>
            {groupByRestrictionsTitle &&
                groupByRestrictionsTitle.map((item, indexGroup) => (
                    <Stack spacing={1} key={item.title}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6" fontWeight={400}>
                                {item.title}
                            </Typography>
                            <Switch
                                checked={
                                    formValues?.groupByRestrictionsTitle?.[indexGroup] ||
                                    false
                                }
                                onChange={(event) => {
                                    handleChangeValue(
                                        `groupByRestrictionsTitle.${indexGroup}`,
                                        event.target.checked
                                    );
                                    handleCheckedAllValuesFromGroup(
                                        event.target.checked,
                                        indexGroup
                                    );
                                }}
                            />
                        </Box>
                        {item.restrictions.map((restriction) => (
                            <Box
                                key={restriction.id}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid #E5E5E5"
                            >
                                <Typography variant="subtitle1" fontWeight={400}>
                                    {restriction.name}
                                </Typography>
                                <Switch
                                    onChange={(event) =>
                                        handleChangeValue(
                                            `restrictions.${restriction.id}`,
                                            event.target.checked
                                        )
                                    }
                                    checked={
                                        formValues?.restrictions?.[restriction.id] || false
                                    }
                                    size="small"
                                />
                            </Box>
                        ))}
                    </Stack>
                ))}
        </Box>
    );
}
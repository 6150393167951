import { useFormik } from "formik";
import RestrictionsPicker from "../../../../components/RestrictionsPicker";
import { useSnackbar } from "notistack";
import { CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Button, DialogActions } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE } from "../../../../graphql/mutations";

export default function CreateDialog({
    open, handleClose, onCreate, onFail
}) {

    const [createProfile, { loading }] = useMutation(CREATE_PROFILE)

    const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm } = useFormik({
        initialValues: {
            name: "",
            restrictionsForm: {
                restrictions: [],
                groupByRestrictionsTitle: []
            }
        },
        onSubmit: (values) => {
            const restrictionsIds = Object.keys(values.restrictionsForm.restrictions)
                .filter((key) => values.restrictionsForm.restrictions[key] === true)
                .map((id) => parseInt(id, 10));

            createProfile({
                variables: {
                    name: values.name,
                    restriction_ids: restrictionsIds
                }
            }).then(() => {
                onCreate()
            }).catch(e => {
                console.error(e)
                onFail()
            })
        }
    });

    const handleChangeValue = (prop, value) => {
        setFieldValue(`restrictionsForm.${prop}`, value)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Criar perfil</DialogTitle>

            <DialogContent style={{ padding: 16 }}>

                <TextField
                    variant="outlined"
                    margin="normal"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    value={values.name}
                    required
                    fullWidth
                    id="name"
                    label="Nome"
                    name="name"
                    autoFocus
                    error={errors.name && true}
                    helperText={errors.name}
                />

                <RestrictionsPicker {...{ handleChangeValue }} formValues={values.restrictionsForm} />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={values.name == "" || loading}>
                    {loading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Criar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import {
    makeStyles,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    Box,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FileUploader } from "react-drag-drop-files";
import { useFormik } from "formik";
import { DialogContent } from "@mui/material";
import { UPLOAD_RELEASES } from "../../../../graphql/mutations";
import { FETCH_RELEASES } from "../../../../graphql/queries";

export default function ImportDialog({ open, handleClose }) {
    const style = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [uploadReleases, { loading }] = useMutation(UPLOAD_RELEASES, {
        refetchQueries: [{ query: FETCH_RELEASES }]
    });

    const formikProps = useFormik({
        initialValues: {
            file: null,
        },

        onSubmit: async (values) => {

            try {
                await uploadReleases({
                    variables: {
                        file: values.file,
                    },
                })
                resetForm();
                enqueueSnackbar("Arquivo enviado com sucesso!", {
                    variant: "success",
                });
                handleClose();
            } catch (e) {
                console.error(e)
                enqueueSnackbar("Erro ao enviar arquivo, verifique a formatação", {
                    variant: "error",
                });
            }
        },
    });

    const { handleSubmit, resetForm } = formikProps;

    const file = formikProps.values.file;

    const fileTypes = ["CSV"];

    const handleAddFiles = (e) => {
        const newFile = e;

        if (file && file.name === newFile.name) {
            enqueueSnackbar("Arquivo já adicionado", { variant: "error" });
            return;
        }

        formikProps.setFieldValue("file", newFile);

        handleSubmit();
    };

    const handleSizeError = (file) => {
        enqueueSnackbar("O arquivo supera o limite máximo de 5MB", {
            variant: "error",
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Upload de Lançamentos</DialogTitle>

            <DialogContent>
                <Box className={style.uploadContainer}>
                    <FileUploader
                        handleChange={(e) => handleAddFiles(e)}
                        name="file"
                        types={fileTypes}
                        onSizeError={(e) => handleSizeError(e)}
                        maxSize={5}
                        disabled={loading}
                    >
                        <Box className={style.uploadLabel}>
                            {loading ? (
                                <CircularProgress className={style.circularProgress} />
                            ) : (
                                <>
                                    <BackupIcon className={style.uploadIcon}></BackupIcon>
                                    <Typography className={style.uploadText}>
                                        Selecione um arquivo .csv de acordo com o layout
                                        pré-estabelecido
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </FileUploader>
                </Box>

                <Box className={style.marginContainer}>
                    <a href="/exemplo_lancamentos.csv">Clique aqui</a> para baixar um .csv de
                    exemplo
                </Box>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    uploadLabel: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        padding: "12px 4px 16px 4px",
        border: "1px solid #19857b",
        borderRadius: "20px",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
    },

    uploadContainer: {
        maxWidth: "100%",
        padding: "0 2%",
    },

    uploadIcon: {
        margin: "8px 0",
        color: "#1c998d",
    },

    uploadText: {
        color: "#1c998d",
        fontSize: "13px",
        width: "320px",
    },

    marginContainer: {
        margin: 24,
    },
}));

import React, { useState } from "react";
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  Tabs,
  Tab,
  Tooltip,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import Search from "./Search";
import useToken from "../tokenJWT";
import CachedIcon from "@mui/icons-material/Cached";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logoSintegra from "../assets/logo.png";
import { FETCH_ANNOUNCEMENTS } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DialogNotification from "../components/DialogNotification";
import { useSnackbar } from "notistack";
import UserMenu from "./UserMenu";

export default function Header({
  title,
  onClickUpdate,
  updateButtonTooltip,
  shouldDisableUpdateButton,
  onBack,
  tabs,
  tab,
  onChangeTab,
  isNotShowIconButton,
  isShowExitButton,
  isShowUpdateButton,
  isShowBadge,
  isDisabledExitButton
}) {
  const { user } = useToken();
  const { enqueueSnackbar } = useSnackbar();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isPaperOpen, setIsPaperOpen] = useState(false);

  const { data } = useQuery(FETCH_ANNOUNCEMENTS);

  return (
    <>
      <Search open={showSearch} onClose={() => setShowSearch(false)} />
      <AppBar position="fixed" style={{ borderBottom: "4px solid #132994" }}>
        <Toolbar>
          {!isNotShowIconButton && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => (onBack ? onBack() : setShowDrawer(true))}
            >
              {onBack ? <ArrowBack /> : <MenuIcon />}
            </IconButton>
          )}
          <Typography variant="h6">
            {title ? (
              title
            ) : (
              <img
                src={logoSintegra}
                alt="Sintegra Logo"
                style={{
                  width: "120px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            )}
          </Typography>

          <div
            onClick={() => setShowSearch(true)}
            style={{
              borderLeft: "1px solid #FFF",
              fontWeight: "400",
              marginLeft: 24,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <SearchIcon style={{ marginLeft: 24 }} />
            <span style={{ marginLeft: 12 }}>Pesquisar</span>
          </div>

          {isShowBadge && (
            <Badge
              color="error"
              badgeContent={data?.fetchAnnouncements.length}
              max={99}
              style={{
                position: "absolute",
                right: "5%",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsPaperOpen(!isPaperOpen);
              }}
            >
              <NotificationsIcon
                style={{
                  color:
                    data?.fetchAnnouncements.length === 0 ? "gray" : "inherit",
                }}
              />
            </Badge>
          )}
          {isPaperOpen && (
            <DialogNotification
              data={data}
              open={isPaperOpen}
              onClose={() => setIsPaperOpen(false)}
            />
          )}

          {isShowUpdateButton && (
            <Tooltip title={updateButtonTooltip} placement="botton">
              <CachedIcon
                style={{
                  position: "absolute",
                  right: "220px",
                  fontSize: "33px",
                  cursor: shouldDisableUpdateButton ? "default" : "pointer",
                  color: shouldDisableUpdateButton ? "#CCC" : "#FFF",
                }}
                onClick={shouldDisableUpdateButton ? undefined : onClickUpdate}
              />
            </Tooltip>
          )}

          {isShowExitButton && (
            <Tooltip title="Sair do Atendimento Manual" placement="botton">
              <ExitToAppIcon
                style={{
                  position: "absolute",
                  right: "100px",
                  fontSize: "33px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (isDisabledExitButton) {
                    enqueueSnackbar("Favor marcar opções das tags de produto obrigatórias!", {
                      variant: "warning",
                    });
                    return
                  }
                  onBack()
                }}
              />
            </Tooltip>
          )}
        </Toolbar>

        {tabs && (
          <Tabs
            style={{ display: showSearch ? "none" : "block" }}
            value={tab}
            onChange={(event, newValue) => onChangeTab(newValue)}
            TabIndicatorProps={{ style: { backgroundColor: "yellow" } }}
          >
            {tabs.map((i) => (
              <Tab label={i} />
            ))}
          </Tabs>
        )}

        <Drawer
          anchor="left"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          <div style={{ backgroundColor: "#132994", height: "100%" }}>
            <div style={{ padding: 18 }}>
              <img
                src={logoSintegra}
                alt="Logo"
                style={{ width: 200, marginLeft: "-10px" }}
              />
              <div style={{ marginLeft: 6, marginTop: 0 }}>
                <div
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    fontSize: "11pt",
                  }}
                >
                  {user.name}
                </div>
                <div
                  style={{ color: "#FFF", fontWeight: "300", fontSize: "9pt" }}
                >
                  {user.is_admin && "Administrador"}
                  {user.is_sale_consultant && "Consultor de vendas"}
                  {user.is_supervisor && "Supervisor"}
                  {user.is_manager && "Gerente"}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#FFFFFFCC" }}
            />

            <UserMenu handleClickItem={() => setShowDrawer(false)} />

          </div>
        </Drawer>
      </AppBar>
    </>
  );
}

import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import Filter from "./Filter";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { FETCH_PROPOSALS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import Item from "./Item";
import useToken from "../../../tokenJWT";
import SyncProposals from "./SyncProposals";
import { useSnackbar } from "notistack";
import LocalProvider from "../../../components/LocalProvider";

export default function ProposalsPage() {
  const { user, restrictions } = useToken();
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const { enqueueSnackbar } = useSnackbar();

  
  //Restrictions
  const allowSendCSV = restrictions({ topic: "Propostas", name: "Enviar csv" })

  const { loading, data, refetch } = useQuery(FETCH_PROPOSALS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...filter,
      ...{
        limit: rowsPerPage,
        page: currentPage + 1,
      },
    },
  });


  useEffect(() => {
    setCurrentPage(0);
  }, [filter]);

  return (
    <>
      {!user.is_admin ? <Header isShowBadge /> : <Header />}

      <Container style={{ marginTop: 86 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Filter
              isAdmin={user.is_admin}
              onChangeFilter={(filter) => {
                setFilter(filter);
              }}
              filter={filter}
            />
            {(user.is_admin && allowSendCSV) && (
              <SyncProposals
                onUploadedFile={() => {
                  refetch();
                  enqueueSnackbar(
                    "Arquivo de sincronização processado com sucesso!"
                  );
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h1">
                  Propostas
                </Typography>

                {loading && <CircularProgress style={{ marginTop: 12 }} />}

                {data && (
                  <>
                    {data.fetchProposals.data.map((i, k) => {
                      return (
                        <Item
                          isAdmin={user.is_admin}
                          key={k}
                          data={i}
                          refetch={refetch}
                        />
                      );
                    })}
                    {data.fetchProposals.data.length == 0 && (
                      <Typography variant="caption">
                        Nenhum resultado encontrado
                      </Typography>
                    )}
                  </>
                )}

                {data && (
                  <LocalProvider locale="ptBR">
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[15, 50, 100]}
                      component="div"
                      count={data.fetchProposals.total}
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onPageChange={(event, newPage) => {
                        setCurrentPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value));
                        setCurrentPage(0);
                      }}
                    />
                  </LocalProvider>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

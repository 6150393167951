import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  GridContent,
} from "@material-ui/core";
import { useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip } from "@material-ui/core";
import { FETCH_CAMPAINGS } from "../../../graphql/queries";

export default function FilterModalCampaing({ open, loading, onChangeFilter }) {
  const [allCampaing, setAllCampaing] = useState();

  const handleClickFilter = () => {
    var obj = {
      all: allCampaing,
    };
    onChangeFilter(obj);
  };

  const handleClearFilter = () => {
    setAllCampaing(false);

    var obj = {
      all: false,
    };
    onChangeFilter(obj);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Grid container spacing={2}>
        <DialogTitle style={{ paddingRight: 0 }}>Campanhas</DialogTitle>
        <Tooltip title="Mostrar todas exibe todas as campanhas, inclusive as deletadas.">
          <IconButton>
            <InfoIcon
              style={{ color: "#919C9A", cursor: "pointer", paddingLeft: 0 }}
            />
          </IconButton>
        </Tooltip>
      </Grid>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              variant="outlined"
              label="Mostrar todas"
              value={allCampaing}
              onChange={(e) => setAllCampaing(e.target.value)}
              fullWidth
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClearFilter} color="primary">
          Limpar
        </Button>
        <Button
          onClick={() => {
            handleClickFilter();
          }}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

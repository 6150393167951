import React from "react";
import { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import mascote from "../assets/mascote.gif";
import DialogSimulator from "../components/LeadDetail/DialogSimulator";
import DialogDebitAccount from "../components/LeadDetail/DialogDebitAccount";
import Slide from "@mui/material/Slide";
import useToken from "../tokenJWT";
import DialogCallEnd from "../components/LeadDetail/DialogCallEnd";
import { useQuery } from "@apollo/client";
import { DETAIL_CAMPAING_LEADS } from "../graphql/queries";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MascoteManual({
  open,
  handleClose,
  data,
  refetch,
  tab,
  setOpenManual,
  onCallEnd,
  campaign_id,
  campaign_name,
  isDisableContinue
}) {
  const [showSimulator, setShowSimulator] = useState(false);
  const [showDebitAccount, setShowDebitAccount] = useState(false);
  const [callEnd, setCallEnd] = useState(false);
  const history = useHistory();

  const { user } = useToken();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const { data: dataLeadsId, loading } = useQuery(DETAIL_CAMPAING_LEADS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(campaign_id),
      limit: 1000000,
    },
  });

  const firstUnusedId = dataLeadsId?.detailCampaingLeads.data.find(
    (item) => item.last_interaction === null
  );

  const handleClick = () => {
    const id = firstUnusedId.id;

    handleClose();

    history.push("/campanha-manual/lead/" + id, {
      id: new URLSearchParams(id).toString(),
      campaign_id: new URLSearchParams(campaign_id).toString(),
      campaign_name: new URLSearchParams(campaign_name).toString(),
      unformated_campaign_name: campaign_name,
    });
  };

  const handleClickBack = () => {
    const id = campaign_id;

    history.push("/campanha/leads/" + id);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      transitionDuration={{ enter: 600, exit: 600 }}
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth="sm"
    >
      {loading ? (
        <DialogContent>
          <div className={classes.interaction}>
            <img src={mascote} alt="Mascote" className={classes.mascote} />
            <p
              className={classes.hello}
              style={{
                display: "flex",
                alignItems: "baseline",
                padding: 7,
                borderRadius: 10,
              }}
            >
              Olá,{" "}
              <div style={{ marginLeft: 5, fontSize: 18 }}>{user?.name}</div>{" "}
            </p>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className={`${classes.root} loaded`}>
          {firstUnusedId ? (
            <>
              <div className={classes.interaction}>
                <img src={mascote} alt="Mascote" className={classes.mascote1} />
                <p
                  className={classes.hello}
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    padding: 7,
                    borderRadius: 10,
                  }}
                >
                  Olá,{" "}
                  <div style={{ marginLeft: 5, fontSize: 18 }}>
                    {user?.name}
                  </div>{" "}
                </p>
              </div>
              <div className={classes.interaction}>
                <img src={mascote} alt="Mascote" className={classes.mascote} />
                <p
                  className={classes.hello1}
                  style={{ padding: 7, borderRadius: 10, lineHeight: 1.5 }}
                >
                  O que deseja fazer agora?
                </p>
              </div>

              <DialogActions
                style={{
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginLeft: 250,
                }}
              >
                <Button
                  onClick={() => {
                    if (isDisableContinue) {
                      enqueueSnackbar("Favor marcar opções das tags de produto obrigatórias!", {
                        variant: "warning",
                      });
                      return
                    }
                    handleClick()
                  }}
                  size="large"
                  style={{ padding: 5, marginLeft: 8 }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Atender o próximo cliente
                </Button>
                <Button
                  onClick={() => {
                    setOpenManual(false);
                  }}
                  size="large"
                  style={{ padding: 5, marginTop: 10 }}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Permanecer nesta página
                </Button>
                <Button
                  onClick={handleClickBack}
                  size="large"
                  style={{ padding: 5, marginTop: 10 }}
                  variant="outlined"
                  className={classes.exitButton}
                  fullWidth
                >
                  Voltar a lista de clientes
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <div className={classes.interaction}>
                <img src={mascote} alt="Mascote" className={classes.mascote} />
                <p
                  className={classes.hello}
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    padding: 7,
                    borderRadius: 10,
                  }}
                >
                  Olá,{" "}
                  <div style={{ marginLeft: 5, fontSize: 18 }}>{user.name}</div>{" "}
                </p>
              </div>
              <p
                className={classes.quest1}
                style={{ padding: 7, borderRadius: 10, lineHeight: 1.5 }}
              >
                Todos os Clientes desta campanha foram atendidos, o que deseja
                fazer agora?
              </p>
              <DialogActions
                style={{
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginLeft: 250,
                }}
              >
                <Button
                  onClick={() => {
                    setOpenManual(false);
                  }}
                  size="large"
                  style={{ padding: 5, marginTop: 10 }}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Permanecer nesta página
                </Button>
                <Button
                  onClick={handleClickBack}
                  size="large"
                  style={{ padding: 5, marginTop: 10 }}
                  variant="outlined"
                  className={classes.exitButton}
                  fullWidth
                >
                  Voltar a lista de clientes
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
      )}
      ;
      <DialogSimulator
        open={showSimulator}
        refetch={refetch}
        handleClose={() => {
          setShowSimulator(false);
          setOpenManual(false);
        }}
        netIncome={data?.detailLead.benefits[tab]?.net_income || {}}
      />
      <DialogDebitAccount
        open={showDebitAccount}
        lead_benefit_id={data?.detailLead.benefits[tab]?.id}
        lead_id={data?.detailLead.id}
        refetch={refetch}
        handleClose={() => {
          setShowDebitAccount(false);
          setOpenManual(false);
        }}
        netIncome={data?.detailLead.benefits[tab]?.net_income || {}}
      />
      <DialogCallEnd
        open={callEnd ? true : false}
        handleClose={() => setCallEnd(false)}
        lead_id={data.detailLead.id}
        onCallEnd={() => {
          setCallEnd(false);
          setOpenManual(false);
          onCallEnd();
        }}
      />
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  interaction: {
    display: "flex",
    justifyContent: "start",
    alignContent: "center",
  },
  mascote: {
    alignSelf: "center",
    height: 50,
    marginLeft: 10,
    marginRight: 10,
  },
  mascote1: {
    alignSelf: "center",
    height: 50,
    marginLeft: 10,
    marginRight: 10,
    opacity: 0,
  },
  quest: {
    marginLeft: 75,
    marginTop: -10,
    backgroundColor: "#19bf19",
    color: "#FFF",
    maxWidth: "270px",
  },
  quest1: {
    marginLeft: 75,
    marginTop: -10,
    backgroundColor: "#19bf19",
    color: "#FFF",
  },
  quest2: {
    marginRight: 190,
    marginLeft: 75,
    marginTop: -10,
    marginBottom: 20,
    backgroundColor: "#19bf19",
    color: "#FFF",
  },
  hello: {
    backgroundColor: "#19bf19",
    color: "#FFF",
    marginLeft: 4,
    marginBottom: 0,
  },
  hello1: {
    backgroundColor: "#19bf19",
    color: "#FFF",
    marginLeft: 4,
  },
  // paper: { maxWidth: "400px", paddingLeft: 5 },
  root: {
    fontSize: "20px",
    wordSpacing: "3px",
    padding: "12px 12px 12px 12px !important",
  },
  loaded: {
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
  },

  exitButton: {
    color: "#FF2828",
    borderColor: "#FF2828",
    "&:hover": {
      color: "#C00",
      borderColor: "#C00",
    },
  },
}));

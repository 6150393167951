import gql from "graphql-tag";

export const MUTATION_AUTH = gql`
  mutation auth($email: String!, $password: String!) {
    auth(email: $email, password: $password)
  }
`;
export const CREATE_ADMIN = gql`
  mutation createAdmin($name: String!, $email: String!, $password: String!, $restriction_ids: [Int]) {
    createAdmin(name: $name, email: $email, password: $password, restriction_ids: $restriction_ids)
  }
`;

export const CREATE_REASON = gql`
  mutation createReason($name: String!, $type: String!, $entity: String) {
    createReason(name: $name, type: $type, entity: $entity)
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmin(
    $id: Int!
    $name: String!
    $email: String!
    $password: String
    $restriction_ids: [Int]
  ) {
    updateAdmin(id: $id, name: $name, email: $email, password: $password, restriction_ids: $restriction_ids)
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($id: Int!) {
    deleteAdmin(id: $id)
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: Int!) {
    deleteAnnouncement(id: $id)
  }
`;

export const DELETE_REASON = gql`
  mutation deleteReason($id: Int!) {
    deleteReason(id: $id)
  }
`;

export const CREATE_MANAGER = gql`
  mutation input(
    $company_id: Int
    $name: String!
    $email: String!
    $password: String!
    $restriction_ids: [Int]
  ) {
    createManager(
      company_id: $company_id
      name: $name
      email: $email
      password: $password
      restriction_ids: $restriction_ids
    )
  }
`;

export const CREATE_MANUAL_SERVICE = gql`
  mutation input(
    $entity_id: Int
    $sale_origin_id: Int
    $name: String!
    $document: String
    $phone: String!
    $is_whatsapp: Boolean
  ) {
    createManualService(
      entity_id: $entity_id
      sale_origin_id: $sale_origin_id
      name: $name
      document: $document
      phone: $phone
      is_whatsapp: $is_whatsapp
    )
  }
`;
export const UPDATE_MANAGER = gql`
  mutation input(
    $company_id: Int
    $id: Int!
    $name: String!
    $email: String!
    $password: String
    $restriction_ids: [Int]
  ) {
    updateManager(
      company_id: $company_id
      id: $id
      name: $name
      email: $email
      password: $password
      restriction_ids: $restriction_ids
    )
  }
`;
export const DELETE_MANAGER = gql`
  mutation input($id: Int!) {
    deleteManager(id: $id)
  }
`;

export const CREATE_SUPERVISOR = gql`
  mutation input(
    $manager_id: Int
    $name: String!
    $email: String!
    $password: String!
    $restriction_ids: [Int]
  ) {
    createSupervisor(
      manager_id: $manager_id
      name: $name
      email: $email
      password: $password
      restriction_ids: $restriction_ids
    )
  }
`;
export const UPDATE_SUPERVISOR = gql`
  mutation input(
    $manager_id: Int
    $id: Int!
    $name: String!
    $email: String!
    $password: String
    $restriction_ids: [Int]
  ) {
    updateSupervisor(
      manager_id: $manager_id
      id: $id
      name: $name
      email: $email
      password: $password
      restriction_ids: $restriction_ids
    )
  }
`;
export const DELETE_SUPERVISOR = gql`
  mutation input($id: Int!) {
    deleteSupervisor(id: $id)
  }
`;

export const CREATE_TEAM = gql`
  mutation input($supervisor_id: Int, $name: String!) {
    createTeam(supervisor_id: $supervisor_id, name: $name)
  }
`;
export const UPDATE_TEAM = gql`
  mutation input($supervisor_id: Int, $id: Int!, $name: String!) {
    updateTeam(supervisor_id: $supervisor_id, id: $id, name: $name)
  }
`;

export const UPDATE_REASON = gql`
  mutation input($type: String, $id: Int!, $name: String, $entity: String) {
    updateReason(type: $type, id: $id, name: $name, entity: $entity)
  }
`;

export const DELETE_TEAM = gql`
  mutation input($id: Int!) {
    deleteTeam(id: $id)
  }
`;

export const DELETE_COMMON_FILE = gql`
  mutation deleteCommonFile($id: Int!) {
    deleteCommonFile(id: $id)
  }
`;

export const CREATE_LEAD = gql`
  mutation createLead(
    $document: String!
    $name: String!
    $email: String
    $birthdate: String
    $phone1: String
    $phone2: String
    $benefit_number: String
    $specie_id: Int
    $income: Float
  ) {
    createLead(
      document: $document
      name: $name
      email: $email
      birthdate: $birthdate
      phone1: $phone1
      phone2: $phone2
      benefit_number: $benefit_number
      specie_id: $specie_id
      income: $income
    )
  }
`;

export const CREATE_LEAD_FILE = gql`
  mutation createLeadFile(
    $custom: Boolean
    $filename: String
    $ufs: [String]
    $manual: Boolean!
  ) {
    createLeadFile(
      custom: $custom
      filename: $filename
      ufs: $ufs
      manual: $manual
    ) {
      id
    }
  }
`;

export const DELETE_LEAD_FILE = gql`
  mutation input($id: Int!) {
    deleteLeadFile(id: $id)
  }
`;

export const UPDATE_UF = gql`
  mutation updateUf($leadfile_id: Int!, $ufs: [String]!) {
    updateUf(leadfile_id: $leadfile_id, ufs: $ufs)
  }
`;

export const UPLOAD_PROPOSAL_FILE = gql`
  mutation uploadProposalFile($proposal_id: Int!, $file: Upload) {
    uploadProposalFile(proposal_id: $proposal_id, file: $file)
  }
`;

export const UPLOAD_COMMON_FILE = gql`
  mutation uploadCommonFile($theme: String!, $file: UploadCommonFileInput) {
    uploadCommonFile(theme: $theme, file: $file)
  }
`;

export const UPLOAD_CAMPAING_LEAD_PHONES = gql`
  mutation input($campaing_id: Int!, $file: UploadCampaingLeadPhonesFileInput) {
    uploadCampaingLeadPhones(campaing_id: $campaing_id, file: $file)
  }
`;

export const UPLOAD_SYNC_PROPOSALS = gql`
  mutation input($file: UploadSyncProposalsFileInput) {
    uploadSyncProposals(file: $file)
  }
`;

export const DELETE_PROPOSAL_FILE = gql`
  mutation input($id: Int!) {
    deleteProposalFile(id: $id)
  }
`;

export const CREATE_CAMPAING = gql`
  mutation input(
    $name: String!
    $type: String!
    $lead_file_ids: [Int]!
    $restriction: CreateCampaingResultRestriction
    $filters: FilterType
  ) {
    createCampaing(
      name: $name
      type: $type
      lead_file_ids: $lead_file_ids
      restriction: $restriction
      filters: $filters
    )
  }
`;

export const CREATE_MANUAL_CAMPAIGN_BY_FILTER = gql`
  mutation input(
    $name: String!
    $start: String
    $finish: String
    $status: String
    $opportunity_id: Int
    $sale_consultant_id: Int
    $interest_level: String
    $sale_origin_id: Int
    $entity_id: Int
    $team_id: Int
    $restriction: CreateManualCampaignByFilterRestriction
    $reasons: [String]
  ) {
    createManualCampaignByFilter(
      name: $name
      start: $start
      finish: $finish
      status: $status
      opportunity_id: $opportunity_id
      sale_consultant_id: $sale_consultant_id
      interest_level: $interest_level
      sale_origin_id: $sale_origin_id
      entity_id: $entity_id
      team_id: $team_id
      restriction: $restriction
      reasons: $reasons
    )
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation input($id: Int!, $restore: Boolean) {
    deleteCampaign(id: $id, restore: $restore)
  }
`;

export const CREATE_CAMPAING_FROM_CUSTOM_FILE = gql`
  mutation input(
    $lead_file_id: Int!
    $name: String!
    $restriction: CreateCampaingFromCustomFileRestriction
    $type: String!
  ) {
    createCampaingFromCustomFile(
      lead_file_id: $lead_file_id
      name: $name
      restriction: $restriction
      type: $type
    )
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation input(
    $title: String
    $description: String!
    $theme: String!
    $profiles: [String]!
    $expire_at: String
    $files: [UploadFilesInput]
  ) {
    createAnnouncement(
      title: $title
      description: $description
      theme: $theme
      profiles: $profiles
      expire_at: $expire_at
      files: $files
    )
  }
`;

export const CREATE_TARGET_AUDIENCE = gql`
  mutation input(
    $name: String!
    $filters: FilterType
    $filename: String
    $type: String!
  ) {
    createTargetAudience(
      name: $name
      filters: $filters
      filename: $filename
      type: $type
    )
  }
`;

export const UPDATE_TARGET_AUDIENCE = gql`
  mutation input($id: Int!, $name: String!, $filters: FilterType!) {
    updateTargetAudience(id: $id, name: $name, filters: $filters)
  }
`;

export const DELETE_TARGET_AUDIENCE = gql`
  mutation input($id: Int!) {
    deleteTargetAudience(id: $id)
  }
`;

export const CREATE_SALE_CONSULTANT = gql`
  mutation input(
    $sale_consultant_group_id: Int
    $team_id: Int
    $sale_channel_id: Int
    $name: String!
    $email: String!
    $password: String!
    $restriction_ids: [Int]
  ) {
    createSaleConsultant(
      sale_consultant_group_id: $sale_consultant_group_id
      team_id: $team_id
      sale_channel_id: $sale_channel_id
      name: $name
      email: $email
      password: $password
      restriction_ids: $restriction_ids
    )
  }
`;

export const UPDATE_SALE_CONSULTANT = gql`
  mutation input(
    $id: Int!
    $sale_consultant_group_id: Int
    $team_id: Int
    $sale_channel_id: Int
    $name: String!
    $email: String!
    $restriction_ids: [Int]
  ) {
    updateSaleConsultant(
      id: $id
      sale_consultant_group_id: $sale_consultant_group_id
      team_id: $team_id
      sale_channel_id: $sale_channel_id
      name: $name
      email: $email
      restriction_ids: $restriction_ids
    )
  }
`;

export const DELETE_SALE_CONSULTANT = gql`
  mutation input($id: Int!) {
    deleteSaleConsultant(id: $id)
  }
`;

export const CREATE_SALE_CONSULTANT_GROUP = gql`
  mutation input($name: String!) {
    createSaleConsultantGroup(name: $name)
  }
`;

export const UPDATE_SALE_CONSULTANT_GROUP = gql`
  mutation input($id: Int!, $name: String!) {
    updateSaleConsultantGroup(id: $id, name: $name)
  }
`;

export const DELETE_SALE_CONSULTANT_GROUP = gql`
  mutation input($id: Int!) {
    deleteSaleConsultantGroup(id: $id)
  }
`;

export const UPDATE_LEAD_BY_CRM = gql`
  mutation input($lead_id: Int!) {
    updateLeadByCrm(lead_id: $lead_id)
  }
`;

export const CREATE_PRODUCT = gql`
  mutation input($name: String!, $dynamic_form_id: Int) {
    createProduct(name: $name, dynamic_form_id: $dynamic_form_id)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation input($id: Int!, $name: String, $dynamic_form_id: Int) {
    updateProduct(id: $id, name: $name, dynamic_form_id: $dynamic_form_id)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation input($id: Int!) {
    deleteProduct(id: $id)
  }
`;

export const CREATE_COMPANY = gql`
  mutation input($name: String!) {
    createCompany(name: $name)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation input($id: Int!, $name: String) {
    updateCompany(id: $id, name: $name)
  }
`;

export const DELETE_COMPANY = gql`
  mutation input($id: Int!) {
    deleteCompany(id: $id)
  }
`;

export const CREATE_SALE_CHANNEL = gql`
  mutation input($name: String!) {
    createSaleChannel(name: $name)
  }
`;

export const UPDATE_SALE_CHANNEL = gql`
  mutation input($id: Int!, $name: String) {
    updateSaleChannel(id: $id, name: $name)
  }
`;

export const DELETE_SALE_CHANNEL = gql`
  mutation input($id: Int!) {
    deleteSaleChannel(id: $id)
  }
`;

export const CREATE_SALE_ORIGIN = gql`
  mutation input($name: String!) {
    createSaleOrigin(name: $name)
  }
`;

export const UPDATE_SALE_ORIGIN = gql`
  mutation input($id: Int!, $name: String) {
    updateSaleOrigin(id: $id, name: $name)
  }
`;

export const DELETE_SALE_ORIGIN = gql`
  mutation input($id: Int!) {
    deleteSaleOrigin(id: $id)
  }
`;

export const DELETE_PROPOSAL_SITUATION = gql`
  mutation input($id: Int!) {
    deleteProposalSituation(id: $id)
  }
`;

export const UPDATE_PROPOSAL_SITUATION = gql`
  mutation input($id: Int!, $name: String!) {
    updateProposalSituation(id: $id, name: $name)
  }
`;

export const CREATE_PROPOSAL_SITUATION = gql`
  mutation input($name: String!) {
    createProposalSituation(name: $name)
  }
`;

export const CREATE_BANK = gql`
  mutation input($code: Int!, $name: String!) {
    createBank(code: $code, name: $name)
  }
`;

export const UPDATE_BANK = gql`
  mutation input($id: Int!, $name: String, $code: Int) {
    updateBank(id: $id, name: $name, code: $code)
  }
`;

export const DELETE_BANK = gql`
  mutation input($id: Int!) {
    deleteBank(id: $id)
  }
`;

export const CREATE_ENTITIES = gql`
  mutation input($name: String!) {
    createEntity(name: $name)
  }
`;

export const CREATE_TAG = gql`
  mutation input($name: String!, $color: String!, $type:String!, $required: Boolean!) {
    createTag(name: $name, color: $color, type: $type, required: $required)
  }
`;

export const UPDATE_ENTITIES = gql`
  mutation input($id: Int!, $name: String, $code: Int) {
    updateEntity(id: $id, name: $name, code: $code)
  }
`;

export const UPDATE_TAG = gql`
  mutation input($id: Int!, $name: String!, $color: String!, $required: Boolean!) {
    updateTag(id: $id, name: $name, color: $color, required: $required)
  }
`;

export const DELETE_ENTITIES = gql`
  mutation input($id: Int!) {
    deleteEntity(id: $id)
  }
`;
export const DELETE_TAG = gql`
  mutation input($id: Int!) {
    deleteTag(id: $id)
  }
`;

export const CREATE_SPECIE = gql`
  mutation input($name: String!) {
    createSpecie(name: $name)
  }
`;

export const UPDATE_SPECIE = gql`
  mutation input($id: Int!, $name: String) {
    updateSpecie(id: $id, name: $name)
  }
`;

export const DELETE_SPECIE = gql`
  mutation input($id: Int!) {
    deleteSpecie(id: $id)
  }
`;

export const CREATE_TYPE = gql`
  mutation input($name: String!) {
    createType(name: $name)
  }
`;

export const UPDATE_TYPE = gql`
  mutation input($id: Int!, $name: String) {
    updateType(id: $id, name: $name)
  }
`;

export const DELETE_TYPE = gql`
  mutation input($id: Int!) {
    deleteType(id: $id)
  }
`;

export const CREATE_LOCATION = gql`
  mutation input($name: String!, $state: String!, $is_city: Boolean!) {
    createLocation(name: $name, state: $state, is_city: $is_city)
  }
`;

export const UPDATE_LOCATION = gql`
  mutation input(
    $id: Int!
    $name: String!
    $state: String
    $is_city: Boolean!
  ) {
    updateLocation(id: $id, name: $name, state: $state, is_city: $is_city)
  }
`;

export const DELETE_LOCATION = gql`
  mutation input($id: Int!) {
    deleteLocation(id: $id)
  }
`;

export const CREATE_PROPOSAL = gql`
  mutation input(
    $document: String!
    $name: String!
    $email: String
    $birthdate: String!
    $phone: String!
    $phone2: String
    $campaign_id: Int
    $cellphone: String
    $benefit_number: String
    $specie_id: Int
    $income: Float!
    $sale_origin_id: Int
    $phone_reference: [CreateProposalObj]
    $bank_id: Int
    $product_id: Int
    $type_id: Int
    $entity_id: Int
    $proposal_number: String
    $released_amount: Float
    $released_gross_amount: Float
    $released_portion_amount: Float
    $released_portion_count: Float
    $ported_bank_id: Int
    $situation_id: Int
    $fastway: Boolean
    $output: String
  ) {
    createProposal(
      document: $document
      name: $name
      email: $email
      birthdate: $birthdate
      phone: $phone
      phone2: $phone2
      campaign_id: $campaign_id
      cellphone: $cellphone
      benefit_number: $benefit_number
      specie_id: $specie_id
      income: $income
      sale_origin_id: $sale_origin_id
      phone_reference: $phone_reference
      bank_id: $bank_id
      product_id: $product_id
      type_id: $type_id
      entity_id: $entity_id
      proposal_number: $proposal_number
      released_amount: $released_amount
      released_gross_amount: $released_gross_amount
      released_portion_amount: $released_portion_amount
      released_portion_count: $released_portion_count
      ported_bank_id: $ported_bank_id
      situation_id: $situation_id
      fastway: $fastway
      output: $output
    )
  }
`;

export const CREATE_PROPOSAL_BASE_ON_LEAD_BENEFIT = gql`
  mutation input(
    $campaing_lead_id: Int
    $lead_benefit_id: Int!
    $bank_id: Int!
    $product_id: Int!
    $negotiated_portion_amount: Float!
  ) {
    createProposalBaseOnLeadBenefit(
      campaing_lead_id: $campaing_lead_id
      lead_benefit_id: $lead_benefit_id
      bank_id: $bank_id
      product_id: $product_id
      negotiated_portion_amount: $negotiated_portion_amount
    )
  }
`;

export const UPDATE_PROPOSAL_CUSTOMER = gql`
  mutation input(
    $id: Int!
    $proposal_code: String!
    $name: String!
    $document: String!
    $birthdate: String
    $mothers_name: String
    $email: String
    $zipcode: String
    $address: String
    $district: String
    $location_id: Int
    $benefit_number: String
    $benefit_type: String
    $income: Float
    $phone: String
    $phone2: String
    $cellphone: String
    $phone_reference: [phoneReferenceObj]
    $specie_id: Int
  ) {
    updateProposalCustomer(
      id: $id
      proposal_code: $proposal_code
      name: $name
      document: $document
      birthdate: $birthdate
      mothers_name: $mothers_name
      email: $email
      zipcode: $zipcode
      address: $address
      district: $district
      location_id: $location_id
      benefit_number: $benefit_number
      benefit_type: $benefit_type
      income: $income
      phone: $phone
      phone2: $phone2
      cellphone: $cellphone
      phone_reference: $phone_reference
      specie_id: $specie_id
    )
  }
`;

export const UPDATE_PROPOSAL = gql`
  mutation input(
    $id: Int!
    $status: String!
    $sale_consultant_id: Int
    $proposal_situation_id: Int
    $product_id: Int
    $bank_id: Int
    $ported_bank_id: Int
    $entity_id: Int
    $type_id: Int
    $proposal_number: String
    $benefit_number: String
    $benefit_salary: Float
    $benefit_discount_consigned: Float
    $benefit_discount_rmc: Float
    $negotiated_amount: Float
    $negotiated_portion_amount: Float
    $released_amount: Float
    $released_portion_amount: Float
    $released_portion_count: Int
    $released_date: String
    $sale_origin_id: Int
  ) {
    updateProposal(
      id: $id
      status: $status
      sale_consultant_id: $sale_consultant_id
      proposal_situation_id: $proposal_situation_id
      product_id: $product_id
      bank_id: $bank_id
      ported_bank_id: $ported_bank_id
      entity_id: $entity_id
      type_id: $type_id
      proposal_number: $proposal_number
      benefit_number: $benefit_number
      benefit_salary: $benefit_salary
      benefit_discount_consigned: $benefit_discount_consigned
      benefit_discount_rmc: $benefit_discount_rmc
      negotiated_amount: $negotiated_amount
      negotiated_portion_amount: $negotiated_portion_amount
      released_amount: $released_amount
      released_portion_amount: $released_portion_amount
      released_portion_count: $released_portion_count
      released_date: $released_date
      sale_origin_id: $sale_origin_id
    )
  }
`;

export const RESCHEDULE_CALL = gql`
  mutation input($campaing_lead_id: Int!, $datetime: String!) {
    rescheduleCall(campaing_lead_id: $campaing_lead_id, datetime: $datetime)
  }
`;

export const MARK_LEAD_UNITERESTING = gql`
  mutation input($campaing_lead_id: Int!, $reason: String!) {
    markLeadUninteresting(campaing_lead_id: $campaing_lead_id, reason: $reason)
  }
`;

export const ADVANCE_SIMULATOR = gql`
  mutation input(
    $lead_id: Int!
    $lead_benefit_id: Int
    $debit_accounts: [AdvanceSimulatorDebitAccount]!
  ) {
    advanceSimulator(
      lead_id: $lead_id
      lead_benefit_id: $lead_benefit_id
      debit_accounts: $debit_accounts
    )
  }
`;

export const UPDATE_LEAD = gql`
  mutation input(
    $id: Int!
    $document: String
    $birthdate: String
    $zipcode: String
    $address: String
    $district: String
    $location_id: Int
  ) {
    updateLead(
      id: $id
      document: $document
      birthdate: $birthdate
      zipcode: $zipcode
      address: $address
      district: $district
      location_id: $location_id
    )
  }
`;

export const UPDATE_LEAD_BENEFIT = gql`
  mutation input(
    $lead_id: Int!
    $number: String!
    $bank_id: Int
    $agency: String
    $account: String
    $start: String
    $way_of_payment: String
    $entity_id: Int
    $salary: Float
    $margin_loan: Float
    $specie_id: Int
  ) {
    updateLeadBenefit(
      lead_id: $lead_id
      bank_id: $bank_id
      agency: $agency
      account: $account
      number: $number
      start: $start
      way_of_payment: $way_of_payment
      entity_id: $entity_id
      salary: $salary
      margin_loan: $margin_loan
      specie_id: $specie_id
    )
  }
`;

export const UPDATE_LEAD_PHONE = gql`
  mutation input(
    $id: Int!
    $is_whatsapp: Boolean!
    $is_valid: Boolean!
    $reason: String
  ) {
    updateLeadPhone(
      id: $id
      is_whatsapp: $is_whatsapp
      is_valid: $is_valid
      reason: $reason
    )
  }
`;

export const UPDATE_DEBIT_ACCOUNT = gql`
  mutation input(
    $id: Int!
    $bank_id: Int
    $portion_amount: Float
    $portion_count: Int
    $portion_paid_count: Int
  ) {
    updateDebitAccount(
      id: $id
      bank_id: $bank_id
      portion_amount: $portion_amount
      portion_count: $portion_count
      portion_paid_count: $portion_paid_count
    )
  }
`;

export const CREATE_LEAD_PHONE = gql`
  mutation input($lead_id: Int!, $number: String!, $is_whatsapp: Boolean!) {
    createLeadPhone(
      lead_id: $lead_id
      number: $number
      is_whatsapp: $is_whatsapp
    )
  }
`;

export const CREATE_INTERACTION = gql`
  mutation input(
    $lead_id: Int!
    $opportunity_id: Int
    $campaign_id: Int
    $status: String!
    $description: String
    $return_in: String
    $reason: String
    $temperature: String
    $fastway: Boolean
    $dynamic_form_output: String
    $proposal_id: Int
  ) {
    createInteraction(
      lead_id: $lead_id
      opportunity_id: $opportunity_id
      campaign_id: $campaign_id
      status: $status
      description: $description
      return_in: $return_in
      reason: $reason
      temperature: $temperature
      fastway: $fastway
      dynamic_form_output: $dynamic_form_output
      proposal_id: $proposal_id
    )
  }
`;

export const CREATE_INDICATION = gql`
  mutation input(
    $user_id: Int!
    $lead_id: Int!
    $product_id: Int
    $opportunity_id: Int
    $campaign_id: Int
    $fastway: Boolean
  ) {
    createIndication(
      user_id: $user_id
      lead_id: $lead_id
      product_id: $product_id
      opportunity_id: $opportunity_id
      campaign_id: $campaign_id
      fastway: $fastway
    )
  }
`;

export const UPDATE_CONSULTANT_PASSWORD = gql`
  mutation input($id: Int!, $password: String!) {
    updateConsultantPassword(id: $id, password: $password)
  }
`;

export const CREATE_LEAD_TAG = gql`
  mutation input($lead_id: Int!, $tags: [CreateLeadTagTagsInput]!) {
    createLeadTag(lead_id: $lead_id, tags: $tags)
  }
`;

export const UPDATE_GOAL_FOR_SALE_CONSULTANT = gql`
  mutation input($sale_consultant_ids: [Int]!, $goal: Float!) {
    updateGoalForSaleConsultant(sale_consultant_ids: $sale_consultant_ids, goal: $goal)
  }
`;

export const UPLOAD_DEBITS = gql`
  mutation input($file: UploadDebitsFile) {
    uploadDebits(file: $file)
  }
`;

export const CREATE_DEBIT = gql`
  mutation input($lead_id: Int!, $amount: Float!, $from_date: String!) {
    createDebit(lead_id: $lead_id, amount: $amount, from_date: $from_date)
  }
`;

export const CREATE_MANUAL_CAMPAIGN_BY_DEBTORS = gql`
  mutation input(
    $name: String!
    $keyword: String
    $start: String
    $finish: String
    $restriction: CreateManualCampaignByDebtorsRestriction
  ) {
    createManualCampaignByDebtors(
      name: $name
      keyword: $keyword
      start: $start
      finish: $finish
      restriction: $restriction
    )
  }
`;

export const DELETE_DEBIT = gql`
  mutation deleteDebit($id: Int!) {
    deleteDebit(id: $id)
  }
`;

export const CREATE_DYNAMIC_FORM = gql`
	mutation ($name: String!, $input: String!) {
		createDynamicForm(name: $name, input: $input)
	}
`;

export const UPDATE_DYNAMIC_FORM = gql`
	mutation ($id: Int!, $name: String!, $input: String!) {
		updateDynamicForm(id: $id, name: $name, input: $input)
	}
`;

export const DELETE_DYNAMIC_FORM = gql`
  mutation ($id: Int!) {
    deleteDynamicForm(id: $id)
  }
`

export const DELETE_PROFILE = gql`
  mutation ($id: Int!) {
    deleteProfile(id: $id)
  }
`;

export const CREATE_PROFILE = gql`
  mutation ($name: String!, $restriction_ids: [Int]!) {
    createProfile(name: $name, restriction_ids: $restriction_ids)
  }
`

export const UPDATE_PROFILE = gql`
  mutation ($id: Int!, $name: String!, $restriction_ids: [Int]!) {
    updateProfile(id: $id, name: $name, restriction_ids: $restriction_ids)
  }
`

export const CREATE_RELEASE_CATEGORY = gql`
  mutation ($title: String!, $type: String!, $color: String!) {
    createReleaseCategory(title: $title, type: $type,color: $color)
  }
`

export const DELETE_RELEASE_CATEGORY = gql`
  mutation ($id: Int!) {
    deleteReleaseCategory(id: $id)
  }
`

export const UPDATE_RELEASE_CATEGORY = gql`
  mutation ($id: Int!, $title: String!, $type: String!, $color: String!) {
    updateReleaseCategory(id: $id, title: $title, type: $type, color: $color)
  }
`

export const CREATE_RELEASE = gql`
  mutation($company_id: Int, $release_subcategory_id: Int, $release_category_id: Int!, $value: Float!, $description: String, $release_at: String!) {
    createRelease(company_id: $company_id, release_subcategory_id: $release_subcategory_id, release_category_id: $release_category_id, value: $value, description: $description, release_at: $release_at)
  }
`

export const DELETE_RELEASE = gql`
  mutation($id: Int!) {
    deleteRelease(id: $id)
  }
`

export const UPDATE_RELEASE = gql`
  mutation($id: Int!, $company_id: Int, $release_subcategory_id: Int, $release_category_id: Int!, $value: Float!, $description: String, $release_at: String!) {
    updateRelease(id: $id, company_id: $company_id, release_subcategory_id: $release_subcategory_id, release_category_id: $release_category_id, value: $value, description: $description, release_at: $release_at)
  }
`

export const UPLOAD_RELEASES = gql`
  mutation input($file: UploadReleasesFile) {
    uploadReleases(file: $file)
  }
`;

export const CREATE_RELEASE_SUBCATEGORY = gql`
  mutation($release_category_id: Int!, $title: String!) {
    createReleaseSubcategory(release_category_id: $release_category_id, title: $title)
  }
`

export const UPDATE_RELEASE_SUBCATEGORY = gql`
  mutation($id: Int!, $title: String!) {
    updateReleaseSubcategory(id: $id, title: $title)
  }
`

export const DELETE_RELEASE_SUBCATEGORY = gql`
  mutation($id: Int!) {
    deleteReleaseSubcategory(id: $id)
  }
`
import 'devextreme/dist/css/dx.light.css';
import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme.js";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import ApolloCustomProvider from "./components/ApolloCustomProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LoginPage from "./pages/common/LoginPage";
import UserRoutes from "./components/UserRoutes";
import useToken from "./tokenJWT";
import MessageSnackbar from "./components/MessageSnackbar";
import Fastway from "./pages/fastway";
import 'devextreme/dist/css/dx.light.css';

export default function App() {
  const { user } = useToken();

  return (
    <MessageSnackbar>
      <ApolloCustomProvider>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
              <Switch>
                <Route path="/fastway" exact component={Fastway} />
                <Route path="/login" exact component={LoginPage} />

                {!user && <Redirect to={{ pathname: "/login" }} />}

                user.is_admin && <UserRoutes />

                <Route
                  render={(props) =>
                    props.location.pathname === "/" ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <p>{`Desculpa, nenhum conteúdo em ${props.location.pathname}`}</p>
                    )
                  }
                />
              </Switch>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloCustomProvider>
    </MessageSnackbar>
  );
}

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";

export default function InputGender({ labelBlank, value, onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [newValue, setNewValue] = useState(value);

  let display = value || labelBlank || "Todos";

  const handleChangeRadio = (e) => {
    setNewValue(e.target.value);
  };

  const handleSelect = () => {
    onChange(newValue);
    setShowDialog(false);
  };

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setShowDialog(true)}>
        <Typography variant="subtitle2">Gênero</Typography>
        <Typography variant="caption">
          {display == "M" ? "Masculino" : display == "F" ? "Feminino" : display}
        </Typography>
        <CreateIcon
          style={{
            fontSize: "15px",
            paddingLeft: "8px",
            position: "relative",
            top: "2px",
          }}
        />
      </div>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth="md"
      >
        <DialogTitle>Gênero</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={newValue}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value="M"
                control={<Radio />}
                label="Masculino"
              />
              <FormControlLabel
                value="F"
                control={<Radio />}
                label="Feminino"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <Button
            color="primary"
            onClick={() => {
              setNewValue(null);
              onChange(null);
              setShowDialog(false);
            }}
          >
            Limpar
          </Button>
          <Button onClick={handleSelect} variant="contained" color="primary">
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

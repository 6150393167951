import { useMutation, useQuery } from "@apollo/client";
import {
  CardContent,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { CONSULTANT_RANKING } from "../../../graphql/queries";
import { useEffect } from "react";
import { useState } from "react";
import { UPDATE_GOAL_FOR_SALE_CONSULTANT } from "../../../graphql/mutations";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

export default function Ranking({
  teamId,
  startDate,
  finishDate,
  saleConsultantId,
  product_ids,
  company_ids,
}) {
  const [goals, setGoals] = useState({});
  const [globalGoal, setGlobalGoal] = useState("");

  const variables = {
    start: startDate ? format(startDate, "yyyy-MM-dd") : null,
    finish: finishDate ? format(finishDate, "yyyy-MM-dd") : null,
    sale_consultant_id: saleConsultantId ? saleConsultantId : null,
    product_ids: product_ids ? product_ids : [],
    team_id: teamId ? teamId : null,
    company_ids: company_ids ? company_ids : [],
  };

  const { data, loading, refetch } = useQuery(CONSULTANT_RANKING, {
    fetchPolicy: "network-only",
    variables,
  });

  const [updateGoalForSaleConsultant] = useMutation(
    UPDATE_GOAL_FOR_SALE_CONSULTANT
  );

  const handleBlur = async (event, consultantId) => {
    const newGoal = parseFloat(event.target.value);
    try {
      if (event.target.name === "globalGoal") {
        const consultantIds = data.consultantRanking.map((item) => item.id);
        const goalValue = globalGoal || 0;

        const {
          data: { updateGoalForSaleConsultant: isSuccess },
        } = await updateGoalForSaleConsultant({
          variables: {
            sale_consultant_ids: consultantIds,
            goal: goalValue,
          },
        });
        if (isSuccess) {
          refetch();
        } else {
          console.error("A atualização da meta não foi bem-sucedida");
        }
      } else {
        await updateGoalForSaleConsultant({
          variables: {
            sale_consultant_ids: consultantId,
            goal: newGoal,
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGlobalGoalChange = (event) => {
    const newGlobalGoal = parseFloat(event.target.value);
    setGlobalGoal(newGlobalGoal);
  };

  const handleGoalChange = (consultantId, value) => {
    const newGoals = { ...goals };
    newGoals[consultantId] = value;
    setGoals(newGoals);
  };

  useEffect(() => {
    refetch();
  }, [
    teamId,
    startDate,
    finishDate,
    saleConsultantId,
    product_ids,
    company_ids,
    refetch,
  ]);

  const getIconForPosition = (position) => {
    if (position === 1) {
      return <EmojiEventsIcon sx={{ color: "#daa520" }} />;
    } else if (position === 2) {
      return <MilitaryTechIcon sx={{ color: "#9a9a9a" }} />;
    } else if (position === 3) {
      return <MilitaryTechIcon sx={{ color: "#cd7f32" }} />;
    } else {
      return null;
    }
  };

  function isWeekday(date) {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

  function getRemainingWeekdaysInMonth() {
    const today = new Date();
    const currentMonth = today.getMonth();
    let count = 0;

    while (today.getMonth() === currentMonth) {
      if (isWeekday(today)) {
        count++;
      }
      today.setDate(today.getDate() + 1);
    }

    return count;
  }

  function getPastWeekdaysInMonth() {
    const today = new Date();
    today.setDate(1);
    const currentMonth = today.getMonth();
    let count = 0;

    while (today.getMonth() === currentMonth && today < new Date()) {
      if (isWeekday(today)) {
        count++;
      }
      today.setDate(today.getDate() + 1);
    }

    return count;
  }

  const remainingDays = 22;
  const pastDays = getPastWeekdaysInMonth();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("printable-table"),
  });

  const handlePrintButtonClick = () => {
    handlePrint();
  };

  return (
    <Box>
      {loading && <CircularProgress style={{ margin: 12, marginTop: -6 }} />}

      {data && (
        <>
          {data.consultantRanking.length === 0 ? (
            <CardContent>
              <Typography variant="body2">
                Nenhum registro encontrado
              </Typography>
            </CardContent>
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="rown"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width={110}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle2" style={{ color: "#808080" }}>
                    Meta:
                  </Typography>
                  <TextField
                    style={{ borderColor: "#808080", marginLeft: 10 }}
                    fullWidth
                    variant="outlined"
                    name="globalGoal"
                    type="text"
                    value={globalGoal || ""}
                    onBlur={handleBlur}
                    onChange={handleGlobalGoalChange}
                    InputProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={handlePrintButtonClick}
                    endIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                </Box>
              </Box>
              <TableContainer id="printable-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Consultor</TableCell>
                      <TableCell>Equipe</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Propostas
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Volumes de Proposta
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Ticket Médio
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Meta
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Alcance(%)
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Projeção
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Projeção(%)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.consultantRanking &&
                      [...data.consultantRanking]
                        .sort(
                          (a, b) =>
                            b.total_proposals_by_period -
                            a.total_proposals_by_period
                        )
                        .slice(0, 20)
                        .map((item, index) => {
                          const ticketMedio =
                            item.total_proposals_by_periods !== 0 &&
                              item.total_proposals !== 0
                              ? isFinite(
                                item.total_proposals /
                                item.total_proposals_by_period
                              )
                                ? (item.total_proposals /
                                  item?.total_proposals_by_period).toFixed(2)
                                : 0
                              : 0;

                          const currentGoal =
                            goals[item.id] !== undefined
                              ? goals[item.id]
                              : item.goal;

                          const percentualAlcance =
                            currentGoal !== 0 &&
                              item.total_proposals_by_period !== 0
                              ? isFinite(
                                item.total_proposals_by_period / currentGoal
                              )
                                ? (
                                  (item.total_proposals_by_period /
                                    currentGoal) *
                                  100
                                ).toFixed(2)
                                : 0
                              : 0;

                          const percentualProjecao =
                            currentGoal !== 0
                              ? isFinite(
                                item.total_proposals_by_period / currentGoal
                              )
                                ? (
                                  (((item.total_proposals_by_period /
                                    pastDays) *
                                    remainingDays) /
                                    currentGoal) *
                                  100
                                ).toFixed(2)
                                : 0
                              : 0;

                          return (
                            <TableRow key={item.id}>
                              <TableCell
                                style={{ padding: "8px 8px 8px 24px" }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell style={{ padding: "8px" }}>
                                {getIconForPosition(index + 1)}
                              </TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.team}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {item.total_proposals_by_period}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {item.total_proposals}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {ticketMedio}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", width: 60 }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  name="goal"
                                  type="text"
                                  value={
                                    goals[item.id] !== undefined
                                      ? goals[item.id]
                                      : item.goal
                                  }
                                  onBlur={(event) => handleBlur(event, item.id)}
                                  onChange={(event) =>
                                    handleGoalChange(
                                      item.id,
                                      event.target.value
                                    )
                                  }
                                  InputProps={{
                                    style: {
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {percentualAlcance}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {(
                                  (item.total_proposals_by_period /
                                    pastDays) *
                                  remainingDays
                                ).toFixed(2)}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {percentualProjecao}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
}

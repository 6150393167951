import { Box, CircularProgress, Fab } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export function VisualizationFabButton({ isLoading, ...rest }) {
	return (
		<Box position="fixed" bottom={100} right={50}>
			<Fab color="inherit" aria-label="add" {...rest} disabled={isLoading}>
				{isLoading ? <CircularProgress /> : <VisibilityIcon color="action" />}
			</Fab>
		</Box>
	);
}
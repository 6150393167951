import React, { useState } from 'react';
import { NetworkStatus, useMutation } from '@apollo/client';
import {
  CircularProgress,
  LinearProgress,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from "@mui/material";
import LocalProvider from "../../../../../components/LocalProvider";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DELETE_RELEASE_CATEGORY } from '../../../../../graphql/mutations'
import AddIcon from '@mui/icons-material/Add';
import Row from './Row';

export default function List({ loading, refetch, error, data, networkStatus, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage, onEdit, setOpenDelete }) {

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  const handleEdit = (item) => {
    onEdit(item)
  }

  const handleDelete = (item) => {
    setOpenDelete(item.id)
  }

  return (
    <>
      <TableContainer>
        {networkStatus === NetworkStatus.refetch && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nome</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Cor</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.fetchReleaseCategories.data.map((row) => (
                <Row {...{ row, handleEdit, handleDelete, refetch }} />
              ))
            }

            {
              data.fetchReleaseCategories.data.length == 0 &&
              <TableRow>
                <TableCell colSpan={2}>Nenhuma categoria cadastrada</TableCell>
              </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>

      <LocalProvider locale="ptBR">
        <TablePagination
          showFirstButton
          showLastButton
          rowsPerPageOptions={[8, 32, 100]}
          component="div"
          count={data.fetchReleaseCategories.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </LocalProvider>
    </>
  );
}


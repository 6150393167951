import jwtDecode from 'jwt-decode';
import { useState } from 'react';

export default function useToken() {

    const [token, setToken] = useState(localStorage.getItem('token'));
    const decoded = token ? jwtDecode(token) : {};

    return {
        token: token,
        setToken: (token) => {
            localStorage.setItem('token', token);
            setToken(token);
        },
        removeToken: () => {
            setToken(null);
            localStorage.removeItem('token');
        },
        user: decoded.user,
        restrictions: (filter) => {
            const filterResults = decoded.restrictions.filter(item =>
                (filter.topic === undefined || item.topic === filter.topic) &&
                (filter.name === undefined || item.name === filter.name)
            );

            if (filterResults.length === 1) {
                return filterResults[0];
              } else if (filterResults.length > 1) {
                return filterResults;
              } else {
                return false;
              }
        },
        getSchema: () => 'default'
    }
}
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import TextCopy from "../../../../components/TextCopy";
import { maskMoney } from "../../../../helpers";

export default function ProductCard({
    data, style
}) {
    return (

        <Card style={style}>
            <CardHeader style={{ backgroundColor: "#D3C3E6" }} title="Dados do produto" />
            <CardContent>

                <Grid container spacing={1}>
                    
                    <Grid item xs={6} md={3} sm={6}>
                        <TextCopy title="Produto" value={data.product?.name || "-"} />
                    </Grid>
                    
                    <Grid item xs={6} md={3} sm={6}>
                        <TextCopy title="Valor liberado (líquido)" value={maskMoney(data.released_amount) || "-"} />
                    </Grid>

                    <Grid item xs={6} md={3} sm={6}>
                        <TextCopy title="Valor liberado (bruto)" value={maskMoney(data.released_gross_amount) || "-"} />
                    </Grid>
                    
                    <Grid item xs={6} md={3} sm={6}>
                        <TextCopy title="Nº Proposta" value={data.proposal_number || "-"} />
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    )
}
import { Grid, TextField, FormHelperText, FormControl } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

function FieldCEPField(props) {
    const { control } = useFormContext();
    return (
        <Grid item xs={12}>
            <Controller
                rules={{
                    required: props.required ? "Campo obrigatório" : false,
                }}
                name={`groups.${props.index}.fields.${props.name}`}
                control={control}
                render={(inputProps) => (
                    <FormControl fullWidth sx={{ mb: 6 }}>
                        <InputMask
                            mask="99999-999"
                            value={inputProps.field.value}
                            disabled={false}
                            onChange={(e) => inputProps.field.onChange(e.target.value)}
                        >
                            {(maskedValue, maskProps) => (
                                <TextField
                                    disabled={false}
                                    name={inputProps.field.name}
                                    type="text"
                                    label={`CEP${props.required ? "*" : ""}`}
                                    placeholder={`CEP${props.required ? "*" : ""}`}
                                    error={inputProps.fieldState.error && true}
                                    InputLabelProps={{
                                        shrink: inputProps.field.value ? true : false,
                                    }}
                                    {...maskProps}
                                />
                            )}
                        </InputMask>
                        {inputProps.fieldState.error && (
                            <FormHelperText sx={{ color: "error.main" }}>
                                {inputProps.fieldState.error.message + ""}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
        </Grid>
    );
}

export default FieldCEPField;
import { useEffect, useState } from "react";
import { InputBase, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useDebounce from "../debounce";

export default function SearchInputDebounced({ onChange, typeSearch }) {
  const [value, setValue] = useState(null);
  const debouncedSearchTerm = useDebounce(value, 300);

  useEffect(() => {
    if (debouncedSearchTerm === "") {
      setValue(null);
    }

    onChange(value);
  }, [debouncedSearchTerm]);

  return typeSearch === "inDialog" ? (
    <TextField
      fullWidth={true}
      label="Pesquisar"
      onChange={(e) => setValue(e.target.value)}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon></SearchIcon>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <InputBase
      style={{ width: "100%" }}
      autoFocus={true}
      placeholder="Palavra chave"
      inputProps={{ "aria-label": "Palavra chave" }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, LinearProgress, Link, TablePagination } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { FETCH_CUSTOMERS } from '../../../graphql/queries';
import { NetworkStatus } from '@apollo/client';
import { cpfMask } from '../../../masks'

export default function List() {

  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const { loading, error, data, networkStatus } = useQuery(FETCH_CUSTOMERS, {
    variables: {
      limit: rowsPerPage,
      page: currentPage + 1
    }
  });

  if (loading) return <CircularProgress style={{ margin: 12 }} />;
  if (error) return <div>{error}</div>;

  return (
    <>
        <TableContainer>
          { networkStatus === NetworkStatus.refetch && <LinearProgress /> }
          <Table className={classes.table} aria-label="a dense table">
              <TableHead>
                <TableRow>
                    <TableCell>Documento</TableCell>
                    <TableCell>Nome</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                data.fetchCustomers.data.map((row, k) => (
                  <TableRow key={k}>
                    <TableCell>
                      <Link href={`/clientes/${row.document}`}>{cpfMask(row.document)}</Link>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))
              }

              { 
                data.fetchCustomers.data.length == 0 &&
                <TableRow>
                  <TableCell colSpan={2}>Nenhum cliente para ser exibido</TableCell>
                </TableRow>
              }
              
              </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={data.fetchCustomers.total}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => {
            setCurrentPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
    </>
  );
}

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});
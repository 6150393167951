import { LinearProgressWithLabel } from "../../LinearProgress";
import { Box, Dialog } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CSVDownloader } from "../CSVDownloader";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FETCH_DYNAMIC_INTERACTION_REPORT } from "../../../graphql/queries";
import { formatMoney, maskCPF, maskPhone } from "../../../helpers";

export function ModalExportCSV({ open, handleClose, filter, columns }) {
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [dataExport, setDataExport] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const modifiedColumns = columns.map((column) => {
    if (column === "salary") {
      return "lead_benefits";
    }
    return column;
  });

  const { data, loading } = useQuery(FETCH_DYNAMIC_INTERACTION_REPORT, {
    fetchPolicy: "no-cache",
    variables: {
      offset: offset,
      limit: 500,
      columns: modifiedColumns,
      filters: {
        start: filter.start ? filter.start : null,
        finish: filter.finish ? filter.finish : null,
        status: filter.status ? filter.status : null,
        opportunity_id: filter.opportunity ? filter.opportunity : null,
        sale_consultant_id: filter.saleConsultantId
          ? filter.saleConsultantId
          : null,
        sale_origin_id: filter.saleOriginsId ? filter.saleOriginsId : null,
        entity_id: filter.entitiesId ? filter.entitiesId : null,
        team_id: filter.teamId ? filter.teamId : null,
        reasons: filter.reason ? filter.reason : [],
        interest_level: filter.interestLevel ? filter.interestLevel : null,
        tag_ids: filter.tag ? filter.tag : [],
      },
    },
  });

  const translationMapping = {
    lead_document: "CPF",
    lead_name: "Nome",
    salary: "Renda",
    lead_phones: "Telefone",
    sale_consultant_name: "Consultor",
    team_name: "Equipe",
    lead_sale_origin: "Origem de atendimento",
    interaction_created_at: "Data de Interação",
    interaction_status: "Status de Interação",
    lead_benefits: "Benefício",
    location_state: "UF",
    interest_level: "Nível de Interesse",
    lead_entity: "Entidade",
    lead_tags: "Tag",
  };

  const getMaxTagCount = (interactions) => {
    let maxCount = 0;

    interactions.forEach((interaction) => {
      if (interaction.tags && interaction.tags.length > maxCount) {
        maxCount = interaction.tags.length;
      }
    });

    return maxCount;
  };

  const maxTagCount = getMaxTagCount(dataExport);

  const extractAllTagColumns = (interactions, maxTagCount) => {
    const allTagColumns = [];

    interactions.forEach((interaction) => {
      const tagColumns = new Array(maxTagCount).fill("-");

      if (interaction.tags) {
        interaction.tags.forEach((tag, index) => {
          if (index < maxTagCount && tag) {
            tagColumns[index] = tag.name;
          }
        });
      }

      allTagColumns.push(...tagColumns);
    });

    return allTagColumns;
  };

  const allTagColumns = extractAllTagColumns(dataExport, maxTagCount);

  const generatePhoneColumnHeaders = () => {
    const phoneHeaders = [];
    for (let i = 1; i <= 5; i++) {
      phoneHeaders.push(`Tel ${i}`, "É válido?");
    }
    return phoneHeaders;
  };

  const generatePhoneColumnCells = (interaction) => {
    const phoneCells = [];
    const phones = interaction.phones || {};

    for (let i = 0; i < 5; i++) {
      const phone = phones[i] || {};
      if (phone.number !== null && phone.number !== undefined) {
        phoneCells.push(
          maskPhone(phone.number),
          phone.is_valid ? "Sim" : "Não"
        );
      } else {
        phoneCells.push("-", "-");
      }
    }
    return phoneCells;
  };

  const translatedHeaders = columns.flatMap((column) => {
    switch (column) {
      case "lead_phones":
        return generatePhoneColumnHeaders();
      case "lead_tags":
        const tagHeaders = [];
        for (let i = 1; i <= maxTagCount; i++) {
          tagHeaders.push(`TAG ${i}`);
        }
        return tagHeaders;
      default:
        return translationMapping[column];
    }
  });

  const rowsCsv = dataExport.map((interaction) => {
    const row = [];

    columns.forEach((column) => {
      switch (column) {
        case "lead_document":
          row.push(
            interaction.lead_document ? maskCPF(interaction.lead_document) : "-"
          );
          break;
        case "lead_name":
          row.push(interaction.lead_name ? interaction.lead_name : "-");
          break;
        case "salary":
          const salaryValue = interaction.benefits.map(
            (benefit) => benefit.salary
          );
          const formattedSalary =
            salaryValue !== undefined && salaryValue !== null
              ? formatMoney(salaryValue.toString())
              : "-";
          row.push(formattedSalary);
          break;
        case "lead_phones":
          row.push(...generatePhoneColumnCells(interaction));
          break;
        case "sale_consultant_name":
          row.push(
            interaction.sale_consultant_name
              ? interaction.sale_consultant_name
              : "-"
          );
          break;
        case "team_name":
          row.push(interaction.team_name ? interaction.team_name : "-");
          break;
        case "lead_sale_origin":
          row.push(
            interaction.lead_sale_origin ? interaction.lead_sale_origin : "-"
          );
          break;
        case "interaction_created_at":
          row.push(
            interaction.interaction_created_at
              ? format(
                  new Date(interaction.interaction_created_at),
                  "dd/MM/yyyy HH:mm"
                )
              : "-"
          );
          break;
        case "interaction_status":
          row.push(
            interaction.interaction_status
              ? String(interaction.interaction_status)
              : "-"
          );
          break;
        case "lead_benefits":
          row.push(
            interaction.benefits.map((benefit) => benefit.number)
              ? String(interaction.benefits.map((benefit) => benefit.number))
              : "-"
          );
          break;
        case "location_state":
          row.push(
            interaction.location_state
              ? String(interaction.location_state)
              : "-"
          );
          break;
        case "interest_level":
          row.push(
            interaction.interest_level ? interaction.interest_level : "-"
          );
          break;
        case "lead_entity":
          row.push(interaction.lead_entity ? interaction.lead_entity : "-");
          break;
        case "lead_tags":
          const tagColumns = allTagColumns.splice(0, maxTagCount);
          row.push(...tagColumns);
          break;
        default:
          row.push("-");
          break;
      }
    });

    return row;
  });

  useEffect(() => {
    if (data) {
      setTotal(data?.fetchDynamicInteractionReport?.total);
      setDataExport([
        ...dataExport,
        ...data?.fetchDynamicInteractionReport?.items,
      ]);
      if (
        data?.fetchDynamicInteractionReport?.total > dataExport.length &&
        offset + 500 < data?.fetchDynamicInteractionReport?.total
      ) {
        setOffset((prev) => prev + 500);
      }
    }
  }, [data]);

  const percentageDataExport = (dataExport.length * 100) / total;

  if (percentageDataExport === 100) {
    enqueueSnackbar("Exportação concluída com sucesso!", {
      variant: "success",
      preventDuplicate: true,
    });
    setTimeout(() => {
      handleClose();
    }, 2000);
  }

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth>
      <DialogTitle>{"Exportar CSV"}</DialogTitle>
      <DialogContent>
        {data &&
          data?.fetchDynamicInteractionReport?.total === dataExport.length && (
            <CSVDownloader rowsCsv={rowsCsv} headersCsv={translatedHeaders} />
          )}
        <Box sx={{ width: "100%" }}>
          {loading && offset === 0 ? (
            <CircularProgress />
          ) : (
            <LinearProgressWithLabel
              value={percentageDataExport}
              current={dataExport.length}
              total={total}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { FETCH_REASONS } from "../graphql/queries";

export default function SelectReasonNoProfile({ value, onChange, style }) {
  const { data, loading } = useQuery(FETCH_REASONS, {
    variables: {
      all: true,
    },
  });

  return (
    <TextField
      select
      name="reason"
      value={loading ? 1 : value}
      disabled={loading}
      label="Motivo"
      onChange={onChange}
      variant="outlined"
      fullWidth
    >
      {loading && <MenuItem value={1}>Carregando..</MenuItem>}

      {data &&
        data.fetchReasons.data
          .filter(option => option.type === "Sem perfil")
          .map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          )
        )
      }
    </TextField>
  );
}
